<template>
  <div class="edit-without-releases">
    <editing-option :active="true" />
    <template-option-selector :active="false" @selected="showWarning = true" />
    <new-option-object-oriented :active="false" @selected="showWarning = true"/>
    <new-option-classic :active="false" @selected="showWarning = true" />
    <incomplete-release-warning-dialog :show="showWarning" @closed="showWarning = false" />
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import EditingOption from './EditingOption.vue'
import IncompleteReleaseWarningDialog from './IncompleteReleaseWarningDialog.vue'
import NewOptionClassic from './NewOptionClassic.vue'
import NewOptionObjectOriented from './NewOptionObjectOriented.vue'
import TemplateOptionSelector from './TemplateOptionSelector.vue'

@Component({
  components: {
    EditingOption,
    IncompleteReleaseWarningDialog,
    NewOptionClassic,
    NewOptionObjectOriented,
    TemplateOptionSelector,
  },
})
export default class EditWithoutReleases extends Vue {
  public showWarning = false
}
</script>
<style lang="scss" scoped>
.edit-without-releases {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 560px;
}
</style>