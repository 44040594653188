import { RootState } from '@/store/types'
import { SolutionHardwareDefinitionModel, SolutionHardwareDefinitionState } from '@ecocoach/domain-store-modules/src/systemConfiguration/models'
import { matchSubstringCaseInsensitive } from '@ecocoach/domain-store-modules/src/utils'
import { GetterTree } from 'vuex'
import { SolutionHardwareClampLines, SolutionHardwareDefinitionsOfCategory, SolutionHardwareInstanceViewModel } from './models'
import { SolutionHardwareUiGetter, SolutionHardwareUiState } from './types'

export const getters: GetterTree<SolutionHardwareUiState, RootState> = {
  [SolutionHardwareUiGetter.solutionHardwareDefinitionsSearchResult](_, __, rootState) {
    return (filter: { search: string, includeInTest: boolean, includeObsolete: boolean }) : SolutionHardwareDefinitionsOfCategory[] => {
      const textFilter = (shd: SolutionHardwareDefinitionModel): boolean => {
        return matchSubstringCaseInsensitive(shd.name, filter.search) || matchSubstringCaseInsensitive(shd.internalName, filter.search)
      }
      const stateFilter = (shd: SolutionHardwareDefinitionModel): boolean => {
        switch(shd.state) {
        case SolutionHardwareDefinitionState.ACTIVE: return true
        case SolutionHardwareDefinitionState.IN_TEST: return filter.includeInTest === true
        case SolutionHardwareDefinitionState.OBSOLETE: return filter.includeObsolete === true
        default: return false
        }
      }

      return rootState.systemConfiguration.solutionHardwareDefinitionCategories
        .map(category => ({
          category,
          definitions: rootState.systemConfiguration.solutionHardwareDefinitions
            .filter(shd => shd.solutionHardwareDefinitionCategory === category.id && textFilter(shd) && stateFilter(shd))
            .sort((a, b) => a.name.localeCompare(b.name)),
        } as SolutionHardwareDefinitionsOfCategory))
        .filter(item => !!item.definitions.length)
        .sort((a, b) => a.category.name.localeCompare(b.category.name))
    }
  },
  [SolutionHardwareUiGetter.clampLines]({ clampLines }): SolutionHardwareClampLines {
    return clampLines
  },
  [SolutionHardwareUiGetter.modules]({ modules }): SolutionHardwareInstanceViewModel[] {
    return modules
  },
  [SolutionHardwareUiGetter.isMappedSolutionHardwareInstance](_, __, rootState) {
    return (solutionHardwareInstanceId: string): boolean => {
      const hasDeviceMappings = rootState.plcConfiguration.deviceMappings.some(m => m.solutionHardwareInstanceId === solutionHardwareInstanceId)
      const hasAutoMappings = rootState.plcConfiguration.autoMappings.some(m => m.solutionHardwareInstanceId === solutionHardwareInstanceId)
      return hasDeviceMappings || hasAutoMappings
    }
  },
  [SolutionHardwareUiGetter.isInteracted] ({ interacted }): boolean {
    return interacted
  },
}