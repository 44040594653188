import { ArchitectureType } from '../common'
import { AutoMappingModel, DeviceMappingModel, DeviceModel, DeviceUpgradeInfoItem, ReleaseModel, RoomModel, SolutionHardwareInstanceModel } from './models'

export enum PlcConfigurationAction {
  setPlcType = 'setPlcType',
  loadReleaseTemplates = 'loadReleaseTemplates',
  loadReleases = 'loadReleases',
  createRelease = 'createRelease',
  importRelease = 'importRelease',
  copyRelease = 'copyRelease',
  updateRelease = 'updateRelease',
  deleteRelease = 'deleteRelease',
  completeRelease = 'completeRelease',
  deployRelease = 'deployRelease',
  downloadRelease = 'downloadRelease',
  exportRelease = 'exportRelease',
  loadReleaseDetails = 'loadReleaseDetails',
  upgradeRelease = 'upgradeRelease',
  createRoom = 'createRoom',
  updateRoom = 'updateRoom',
  deleteRoom = 'deleteRoom',
  sortRooms = 'sortRooms',
  createDevice = 'createDevice',
  updateDevice = 'updateDevice',
  deleteDevice = 'deleteDevice',
  sortDevices = 'sortDevices',
  createSolutionHardwareInstance = 'createSolutionHardwareInstance',
  updateSolutionHardwareInstance = 'updateSolutionHardwareInstance',
  deleteSolutionHardwareInstance = 'deleteSolutionHardwareInstance',
  sortSolutionHardwareInstances = 'sortSolutionHardwareInstances',
  createDeviceMapping = 'createDeviceMapping',
  deleteDeviceMapping = 'deleteDeviceMapping',
  createAutoMapping = 'createAutoMapping',
  deleteAutoMapping = 'deleteAutoMapping',

  connectToConfigurationReleaseStateHub = 'connectToConfigurationReleaseStateHub',
  startConfigurationReleaseStateHubForPlc = 'startConfigurationReleaseStateHubForPlc',
  stopConfigurationReleaseStateHubForPlc = 'stopConfigurationReleaseStateHubForPlc',
}

export enum PlcConfigurationMutation {
  setReleaseTemplates = 'setReleaseTemplates',
  setReleases = 'setReleases',
  addRelease = 'addRelease',
  updateRelease = 'updateRelease',
  removeRelease = 'removeRelease',
  setLoadedRelease = 'setLoadedRelease',
  setRooms = 'setRooms',
  addRoom = 'addRoom',
  updateRoom = 'updateRoom',
  removeRoom = 'removeRoom',
  sortRooms = 'sortRooms',
  setDevices = 'setDevices',
  addDevice = 'addDevice',
  updateDevice = 'updateDevice',
  removeDevice = 'removeDevice',
  sortDevices = 'sortDevices',
  setSolutionHardwareInstances = 'setSolutionHardwareInstances',
  addSolutionHardwareInstance = 'addSolutionHardwareInstance',
  updateSolutionHardwareInstance = 'updateSolutionHardwareInstance',
  removeSolutionHardwareInstance = 'removeSolutionHardwareInstance',
  sortSolutionHardwareInstances = 'sortSolutionHardwareInstances',
  setDeviceMappings = 'setDeviceMappings',
  addDeviceMapping = 'addDeviceMapping',
  removeDeviceMapping = 'removeDeviceMapping',
  setAutoMappings = 'setAutoMappings',
  addAutoMapping = 'addAutoMapping',
  removeAutoMapping = 'removeAutoMapping',
  setDeviceUpgradeInfos = 'setDeviceUpgradeInfos',
}

export enum PlcConfigurationGetter {
  releaseTemplates = 'releaseTemplates',
  releases = 'releases',
  rooms = 'rooms',
  devices = 'devices',
  solutionHardwareInstances = 'solutionHardwareInstances',
  deviceMappings = 'deviceMappings',
  autoMappings = 'autoMappings',
  releaseId = 'releaseId',
}

export interface PlcConfigurationState {
  releaseTemplates: ReleaseModel[]
  plcId: string
  releaseId: string
  architectureType: ArchitectureType
  releases: ReleaseModel[]
  rooms: RoomModel[]
  devices: DeviceModel[]
  solutionHardwareInstances: SolutionHardwareInstanceModel[]
  deviceMappings: DeviceMappingModel[]
  autoMappings: AutoMappingModel[]
  deviceUpgradeInfos: DeviceUpgradeInfoItem[]
}
