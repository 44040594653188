<template>
  <card :header="'Console logging'"  :chip-text="chipText" :chip-color="chipColor" :collapsible="true" :expanded="expanded" @expandedChanged="expandedChanged">
    <log-config-editor :destination="logDestination"/>
  </card>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Card from '../../../../ui/Card.vue'

import LogConfigEditor from './LogConfigEditor.vue'

import { logLevelChipColor } from '../../../../../store/modules/logToolUi/helpers'
import { LogDestination, LogLevel } from '../../../../../store/modules/logToolUi/models'

const LogToolUi = namespace('logToolUi')

@Component({
  components: {
    Card,
    LogConfigEditor,
  },
})
export default class ConsoleLogging extends Vue { 
  @Prop() public expanded: boolean
  @LogToolUi.Getter public defaultLogLevelForDestination: (destination: LogDestination) => LogLevel

  public get logDestination() {
    return LogDestination.Console
  }

  public get defaultLogLevel() {
    return this.defaultLogLevelForDestination(this.logDestination)
  }

  public get chipText() {
    return this.defaultLogLevel
  }

  public get chipColor() {
    return logLevelChipColor(this.defaultLogLevel)
  }

  @Emit('expandedChanged') 
  public expandedChanged(expanded: boolean) {
    return expanded
  }
}
</script>
<style lang="scss" scoped>
.field {
  height: 70px;
  .field-row {
    display: flex;
  }
}
</style>