<template>
  <modal-dialog :show="show"
    :header="`common.button.updateTrumpfInverterFirmware` | translate"
    :button1-text="`OK`"
    :button2-text="'common.button.cancel' | translate"
    :button1-disabled="isInteracted || !isValidInput"
    :button2-disabled="isInteracted"
    @button1Pressed="onConfirm"
    @button2Pressed="onClose"
  >
    <v-layout column>
      <form-field :text="'trumpfinverter.update.version' | translate">
        <autocomplete-select 
          :options="trumpfInverterFirmwareVersions"
          option-value="id"
          option-label="label"
          :selected-value="versionId"
          :disabled="isInteracted"
          @selected="versionId = $event"
        />
      </form-field>
      <form-field :text="'trumpfinverter.update.ipaddress' | translate">
        <text-input
          :value="ipAddress"
          :disabled="isInteracted"
          @input="ipAddress = $event"
        />
      </form-field>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { DropdownOption } from '@ecocoach/shared-components/src'

const SelectPlcUi = namespace('selectPlcUi')

@Component({
  components: {
  },
})
export default class UpdateTrumpfInverterFirmwareDialog extends Vue {
  @Prop() public show: boolean
  @Prop() public plcId: string
  @SelectPlcUi.Getter public trumpfInverterFirmwareVersions: DropdownOption[]
  @SelectPlcUi.Action public updateTrumpfInverterFirmware: (payload: { plcId: string, versionId: string, ipAddress: string }) => Promise<void>

  public versionId = ''
  public ipAddress = ''

  public get isValidInput() {
    const ipRegex = /^(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/
    return !!this.versionId && ipRegex.test(this.ipAddress)
  }

  public async onConfirm() {
    await this.updateTrumpfInverterFirmware({ 
      plcId: this.plcId, 
      versionId: this.versionId, 
      ipAddress: this.ipAddress,
    })
    this.onClose()
  }

  @Emit('closed')
  public onClose() {
    return
  }
}
</script>
<style lang="scss" scoped>
.field {
  height: 70px;
  .text-input {
    padding-left: 10px;
    padding-right: 10px;
  }
  .label {
    padding-left: 10px;
  }
}
</style>