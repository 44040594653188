<template>
  <modal-dialog
    :show="true"
    :header="'release.applyoperationdata' | translate"
    :button1-text="'common.button.yes' | translate"
    :button2-text="'common.button.no' | translate"
    :button1-disabled="isInteracted"
    :button2-disabled="isInteracted"
    @button1Pressed="onYes"
    @button2Pressed="onNo"
  >
    {{ 'release.applyoperationdata.info' | translate }}
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const ReleaseUi = namespace('releaseUi')

@Component({
  components: {
  },
})
export default class ApplyTemplateOperationDataDialog extends Vue {
  @ReleaseUi.Getter public isInteracted: boolean
  
  public async onYes() {
    this.onClose(true)
  }

  public onNo() {
    this.onClose(false)
  }

  @Emit('close')
  public onClose(apply: boolean) {
    return apply
  }
}
</script>
<style lang="scss" scoped>
</style>
