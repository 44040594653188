import { AlarmLogUiGetter, AlarmLogUiState } from '@/store/modules/alarmLogUi/types'
import { RootState } from '@/store/types'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { filterDate, matchSubstringCaseInsensitive } from '@ecocoach/domain-store-modules/src/utils'
import { DropdownOption } from '@ecocoach/shared-components/src'
import { GetterTree } from 'vuex'
import { toViewModel } from './helpers'
import { AlarmsTableFilterSettings, AlarmViewModel } from './models'

export const getters: GetterTree<AlarmLogUiState, RootState> = {
  [AlarmLogUiGetter.isInteracted] ({ interacted }) {
    return interacted
  },
  [AlarmLogUiGetter.selectedPlcId] ({ selectedPlcId }) {
    return selectedPlcId
  },
  [AlarmLogUiGetter.filterSettings] ({ filterSettings }): AlarmsTableFilterSettings {
    return filterSettings
  },  
  [AlarmLogUiGetter.alarms] ({ selectedPlcId }, __, rootState, rootGetters): AlarmViewModel[] {
    return rootState.alarm.customPlcAlarms
      .filter(a => a.plcId === selectedPlcId)
      .map(a => {
        const alarmDefinition = rootState.alarmDefinition.alarmDefinitions.find(ad => ad.id == a.alarmDefinitionId) ?? null
        return toViewModel(a, alarmDefinition, rootGetters[`resource/${ResourceGetter.resolveStringResource}`])
      })
  },
  [AlarmLogUiGetter.filteredAlarms] ({ filterSettings }) {
    return (alarms: AlarmViewModel[]): AlarmViewModel[] => {
      let filteredAlarms = [...alarms]
      if (filterSettings.states.length) {
        filteredAlarms = filteredAlarms.filter(item => filterSettings.states.includes(item.isOpen))
      }
      if (filterSettings.deviceName) {
        filteredAlarms = filteredAlarms.filter(item => matchSubstringCaseInsensitive(item.deviceName, filterSettings.deviceName))
      }
      if (filterSettings.name) {
        filteredAlarms = filteredAlarms.filter(item => matchSubstringCaseInsensitive(item.displayName, filterSettings.name))
      }
      if (filterSettings.message) {
        filteredAlarms = filteredAlarms.filter(item => matchSubstringCaseInsensitive(item.displayMessage, filterSettings.message))
      }
      if (filterSettings.openedDate) {
        filteredAlarms = filteredAlarms.filter(item => filterDate(item.openedTimestamp, filterSettings.openedDate))
      }
      if (filterSettings.closedDate) {
        filteredAlarms = filteredAlarms.filter(item => filterDate(item.closedTimestamp, filterSettings.closedDate))
      }
      return filteredAlarms
    }
  },
  [AlarmLogUiGetter.alarmStatusOptions] (_, __, ___, rootGetters): DropdownOption[] {
    return [
      { id: true, label: rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('alarm.isopen') },
      { id: false, label: rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('alarm.isclosed') },
    ]
  },
}
