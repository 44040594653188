<template lang="html">
  <div class="checkboxWithLabel">
    <div class="icon">
      <icon-button v-if="value"
        type="materialIcon"
        materialIconName="check_box"
        :width="25"
        :height="25"
        :enabled="!disabled"
        @buttonClicked="emitChecked"
      />
      <icon-button v-else
        type="materialIcon"
        materialIconName="check_box_outline_blank"
        :width="25"
        :height="25"
        :enabled="!disabled"
        @buttonClicked="emitChecked"
      />
    </div>
    <div class="label">{{text}}</div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import IconButton from './IconButton.vue'

export default Vue.extend({
  name: 'CheckboxWithLabel',
  components: {
      IconButton,
  },
  props: {
    /** 
     * checked or not
     */
    value: {
      type: Boolean,
      default: false,
    },
    /** 
     * label
     */
    text: {
      type: String,
      default: '',
    },
    /** 
     * disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitChecked(): void {
      if(!this.disabled) {
        /**
         * checked event, contains the original value
         *
         * @event checked
         * @type {Boolean}
         */
        this.$emit('checked', this.value)
      }
    },
  },
})
</script>
<style lang="scss" scoped>
.checkboxWithLabel {
  display: flex;
  align-items: center;
  font-family: $font-family-primary;
  color: $fore-color-primary !important;
  .icon {
    flex: 0
  }
	.label {
    flex: 1
  }
}
</style>

<docs>
A checkbox with a label

Usage example
```jsx
new Vue({
  data: function() {
    return {
      value: false
    }
  },
  methods: {
    onChecked(value) {
      console.log('onChecked', value)
      this.value = !value
    }
  },
  template: `<v-app style="height: 48px;background:transparent">
   <checkbox-with-label
      :value="value"
      :text="'checked'"
      @checked="onChecked"
    />
  </v-app>`
})
```

Disabled
```jsx
  <checkbox-with-label
    :value="true"
    :text="'checked'"
    :disabled="true"
  />
```
</docs>
