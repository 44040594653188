import { AlarmLevel, AlarmOperator } from './../../common'

export interface ServiceAlarmModel {
  alarmId: string
  active: boolean
  timeStamp: string
  appIds: string[]
}

export interface MessageAlarmModel {
  alarmId: string
  timeStamp: string
  active: boolean
  message: string
  appIds: string[]
}

export interface CustomPlcAlarmModel {
  alarmInstanceId: string
  plcId: string
  isOpen: boolean
  openedTimestamp: string
  closedTimestamp: string
  peakValue: number | boolean
  operator: AlarmOperator
  thresholdValue: number | boolean // from alarm definition
  controlId: string
  alarmDefinitionId: string
  alarmDefinitionName: string
  alarmDefinitionMessage: string
  hysteresis: number
  unit: string
  isNumeric: boolean
  nameResourceId: string
  messageResourceId: string
  level: AlarmLevel
}

export interface LoadCustomPlcAlarmsPayload {
  plcId: string
  projectId: string
  itemLimit?: number
  created?: string
}

export const alarmStateHubId = 'alarmStateHub'
