import { MutationTree } from 'vuex'
import { upsert } from '../utils'
import { PlcModel } from './models'
import { PlcMutation, PlcState } from './types'


export const mutations: MutationTree<PlcState> = {
  [PlcMutation.setPlcs](state, payload: PlcModel[]) {
    state.plcs = payload
  },
  [PlcMutation.upsertPlc](state, payload: PlcModel) {
    upsert(state.plcs, payload)
  },
}
