<template>
  <div class="state-detail">
    <div class="state-name">
      <div>{{ `State: ${name}` }}</div>
      <base-material-icon v-if="toSubscribe" iconName="bolt" :height="20" />
      <base-material-icon v-if="!toSubscribe" iconName="schedule" :height="20" />
    </div>
    <form-field text="Endpoint">
      <autocomplete-select
        :options="controlDefinitionStateEndpointOptions(controlDefinitionInput.type, name)"
        :selected-value="endpoint"
        :disabled="endpointsDisabled"
        @selected="setStateProperty(name, 'endpoint', $event)"
      />
    </form-field>
    <form-field text="Subscribe/Poll">
      <autocomplete-select
        :options="['Subscribe', 'Poll']"
        :selected-value="toSubscribe ? 'Subscribe' : 'Poll'"
        :disabled="endpointSettingsDisabled"
        @selected="setStateProperty(name, 'toSubscribe', $event === 'Subscribe')"
      />
    </form-field>
    <form-field text="Polling interval (ms)" v-if="!toSubscribe">
      <text-input
        :value="pollInterval"
        :disabled="endpointSettingsDisabled"
        @changed="setStateProperty(name, 'pollInterval', $event)"
      />
    </form-field>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { ControlTypeV2 } from '../../../../../shared-components/src'

import { ControlDefinitionViewModelV2 } from '../../../store/modules/functionDefinitionEditorUi/models'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')

@Component({
  components: {
  },
})
export default class StateDetail extends Vue {
  @Prop() public name: string
  @Prop() public endpoint: string
  @Prop() public toSubscribe: boolean
  @Prop() public pollInterval: number
  @FunctionDefinitionEditorUi.Getter public controlDefinitionInput: ControlDefinitionViewModelV2
  @FunctionDefinitionEditorUi.Getter public controlDefinitionStateEndpointOptions: (controlType: ControlTypeV2, state: string) => string[]
  @FunctionDefinitionEditorUi.Mutation public setControlDefinitionStateProperty: (p: { state: string, property: string, value: any }) => void

  public get endpointsDisabled() {
    return this.controlDefinitionInput.isExisting || this.endpointSettingsDisabled
  }

  public get endpointSettingsDisabled() {
    const disabledTypes = new Set([ControlTypeV2.Links, ControlTypeV2.DeviceMessages])
    return disabledTypes.has(this.controlDefinitionInput.type) && this.name !== 'visibility'
  }

  public setStateProperty(state: string, property: string, value: any) {
    const numericStateProperties = ['pollInterval']
    const convertedValue = numericStateProperties.includes(state) ? Number(value) : value
    this.setControlDefinitionStateProperty({ state, property, value: convertedValue })
  }
}
</script>

<style lang="scss" scoped>
  .state-detail {
    margin-bottom: 10px;
    border-left: 1px solid gray;
    .state-name {
      display: flex;
      gap: 5px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .field .text-input {
    padding-left: 10px;
    padding-right: 10px;
  }
</style>

<style lang="css">
</style>