<template>
  <modal-dialog 
    :show="true"
    :header="`Select a package`"
    button1-text='OK'
    button2-text="Cancel"
    :button1-disabled="!id"
    @button1Pressed="onOk"
    @button2Pressed="onCancelled"
  >
    <form-field text="Internal name">
      <autocomplete-select 
        :options="functionDefinitionPackageInternalNames"
        option-value="id"
        option-label="label"
        :selected-value="internalName"
        @selected="internalName = $event"
      />
    </form-field>
    <form-field text="Version">
      <autocomplete-select 
        :options="functionDefinitionPackageOptions(internalName)"
        option-value="id"
        option-label="label"
        :selected-value="id"
        @selected="id = $event"
      />
    </form-field>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { DropdownOption } from '@ecocoach/shared-components/src'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')

@Component({
  components: {
  },
})
export default class SelectPackageDialog extends Vue {
  @FunctionDefinitionEditorUi.Getter public functionDefinitionPackageInternalNames: DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public functionDefinitionPackageOptions: (internalName: string) => DropdownOption[]

  public internalName = ''
  public id = ''

  @Emit('selected') 
  public onOk () {
    return this.id
  }

  @Emit('cancelled') 
  public onCancelled () {
    return
  }
}
</script>
<style lang="scss" scoped>
</style>