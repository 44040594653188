<template>
  <more-menu :header="plc.name">
    <more-menu-item v-if="isEcocoachEmployee && !plc.eccInstalled"
      :label="'common.button.migrateEcoCloudConnector' | translate"
      :disabled="isEcoCloudConnectorMigrationInProgress"
      @click="migrateEcc"
    />
    <more-menu-item v-if="isEcocoachEmployee || isWeidmuellerEmployee"
      :label="'common.button.restartEcoCloudConnector' | translate"
      @click="restartEcc"
    />
    <more-menu-item v-if="isEcocoachEmployee"
      :label="'common.button.restartPlc' | translate"
      @click="restartPlc"
    />
    <more-menu-item v-if="isEcocoachEmployee && plc.eccInstalled"
      :label="getEcoCloudConnectorInTestUpdateLabel"
      :disabled="!plc.eccTestUpdateEnabled"
      @click="updateInTestEcc"
    />
    <more-menu-item v-if="isEcocoachEmployee && plc.eccInstalled"
      :label="'common.button.resetPlcConfigurationRelease' | translate"
      :disabled="!isEcoCloudConnectorOnline"
      @click="resetPlcConfigurationRelease"
    />
    <more-menu-item v-if="isEcocoachEmployee && plc.eccInstalled"
      :label="'common.button.updateTrumpfInverterFirmware' | translate"
      :disabled="!isEcoCloudConnectorOnline"
      @click="updateTrumpfInverterFirmware"
    />
    <more-menu-item
      :label="'common.button.auditLog' | translate"
      @click="openAuditLog"
    />
    <more-menu-item v-if="(isEcocoachEmployee || isWeidmuellerEmployee) && plc.eccInstalled"
      :label="'navigation.ecoLogTool' | translate"
      :disabled="!isEcoCloudConnectorOnline"
      @click="openEcoLogTool"
    />
    <more-menu-item v-if="plc.eccInstalled"
      :label="'navigation.plcSystemInformation' | translate"
      :disabled="!hasEcoCloudConnectorSystemInformation"
      @click="showPlcSystemInformation"
    />
    <more-menu-item v-if="isEcocoachEmployee"
      :label="'common.button.collectSupportData' | translate"
      @click="collectSupportData"
    />
    <more-menu-item v-if="isEcocoachEmployee || isWeidmuellerEmployee"
      :label="'common.button.copyPlcIdToClipboard' | translate"
      @click="copyPlcIdToClipboard"
    />
    <more-menu-item v-if="isEcocoachEmployee || isWeidmuellerEmployee"
      :label="'common.button.copyProjectToClipboard' | translate"
      @click="copyProjectToClipboard"
    />
  </more-menu>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import MoreMenu from '../../../../ui/MoreMenu.vue'
import MoreMenuItem from '../../../../ui/MoreMenuItem.vue'

import { PlcStatus, PlcViewModel } from '../../../../../store/modules/selectPlcUi/models'

import { PlcType } from '@ecocoach/domain-store-modules/src/common'

import { ComponentFirmwareReleaseModel } from '@ecocoach/domain-store-modules/src/plcOperation/models'

const App = namespace('app')
const Resource = namespace('resource')
const SelectPlcUi = namespace('selectPlcUi')

@Component({
  components: {
    MoreMenu,
    MoreMenuItem,
  },
})
export default class PlcTableActions extends Vue {
  @Prop() public plc: PlcViewModel
  @SelectPlcUi.Getter public latestTestEccReleaseVersion: (plcType: PlcType) => ComponentFirmwareReleaseModel | undefined
  @App.Getter public isEcocoachEmployee: boolean
  @App.Getter public isWeidmuellerEmployee: boolean
  @Resource.Getter public dictionary

  @Emit('migrateEcc')
  public migrateEcc(){
    return this.plc.id
  }

  @Emit('restartEcc')
  public restartEcc() {
    return this.plc.id
  }

  @Emit('restartPlc')
  public restartPlc() {
    return this.plc.id
  }

  @Emit('updateInTestEcc')
  public updateInTestEcc() {
    return this.plc.id
  }

  @Emit('resetPlcConfigurationRelease')
  public resetPlcConfigurationRelease() {
    return this.plc.id
  }

  @Emit('updateTrumpfInverterFirmware')
  public updateTrumpfInverterFirmware() {
    return this.plc.id
  }

  @Emit('openAuditLog')
  public openAuditLog() {
    return this.plc.id
  }
  
  @Emit('openEcoLogTool')
  public openEcoLogTool() {
    return this.plc.id
  }

  @Emit('showPlcSystemInformation')
  public showPlcSystemInformation() {
    return this.plc
  }

  @Emit('collectSupportData')
  public collectSupportData() {
    return this.plc
  }

  public copyPlcIdToClipboard() {
    return this.$copyText(this.plc.id)
  }

  public copyProjectToClipboard() {
    return this.$copyText(this.plc.projectId)
  }
  
  public get getEcoCloudConnectorInTestUpdateLabel() {
    if (!this.plc.eccTestUpdateAvailable) {
      return this.dictionary('common.button.noEcoCloudConnectorTestVersionAvailable')
    }

    return `${this.dictionary('common.button.updateToLatestInTestEcoCloudConnector')} (${this.latestTestEccReleaseVersion(this.plc.plcType)?.version ?? 'n/a'})`
  }

  public get isEcoCloudConnectorOnline() {
    return this.plc.status === PlcStatus.Online || this.plc.status === PlcStatus.PartiallyOnline
  }

  public get isEcoCloudConnectorMigrationInProgress() {
    return this.plc.status === PlcStatus.Updating
  }

  public get hasEcoCloudConnectorSystemInformation() {
    return !!this.plc.serialNumber
  }
}
</script>
<style lang="scss" scoped>
</style>
<style lang="css">
</style>