<template>
  <v-layout column wrap style="margin-bottom: 75px;">
    <v-flex xs12>
      <v-layout row>
        <wizard-step-header
          :title="'wizard.alarms' | translate" 
          :sub-title="plcName" 
        />
      </v-layout>
    </v-flex>
    <v-flex xs12>
      <alarm-definitions-table/>
    </v-flex>
    <v-flex xs12 class="alarm-definition-actions">
      <base-text-button
        :enabled="!isInteracted && !!selectedAlarmDefinitionId"
        @buttonClicked="editAlarmDefinition(selectedAlarmDefinitionId)">
        {{'releases.edit' | translate}}
      </base-text-button>
      <base-text-button
        :enabled="!isInteracted && !!selectedAlarmDefinitionId"
        @buttonClicked="alarmDefinitionIdToDelete = selectedAlarmDefinitionId">
        {{'common.button.delete' | translate}}
      </base-text-button>
      <base-text-button
        :enabled="!isInteracted"
        @buttonClicked="newAlarmDefinition">
        {{'common.button.create' | translate}}
      </base-text-button>
    </v-flex>
    <confirm-dialog
      :show="alarmDefinitionIdToDelete"
      :header="'common.button.delete' | translate"
      :text="'common.dialog.confirm' | translate"
      :confirm-button-text="'OK'"
      :cancel-button-text="'common.button.cancel' | translate"
      :disabled="isInteracted"
      @confirmed="onDeleteAlarmDefinitionConfirmed"
      @cancelled="alarmDefinitionIdToDelete = null"
    />
    <edit-alarm-definition-dialog/>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace} from 'vuex-class'

import AlarmDefinitionsTable from './AlarmDefinitionsTable.vue'
import EditAlarmDefinitionDialog from './EditAlarmDefinitionDialog.vue'

import { PlcModel } from '@ecocoach/domain-store-modules/src/plc/models'

const App = namespace('app')
const ManageAlarmsUi = namespace('manageAlarmsUi')

@Component({
  components: {
    AlarmDefinitionsTable,
    EditAlarmDefinitionDialog,
  },
})
export default class ManageAlarms extends Vue {
  @App.Getter public selectedPlc: PlcModel | null
  @ManageAlarmsUi.Getter public isInteracted: boolean
  @ManageAlarmsUi.Getter public selectedAlarmDefinitionId: string
  @ManageAlarmsUi.Mutation public setSelectedAlarmDefinitionId: (id: string) => void
  @ManageAlarmsUi.Action public newAlarmDefinition: () => Promise<void>
  @ManageAlarmsUi.Action public editAlarmDefinition: (id: string) => Promise<void>
  @ManageAlarmsUi.Action public deleteAlarmDefinition: (id: string) => Promise<void>
  @ManageAlarmsUi.Action public loadAlarmDefinitions: () => Promise<void>

  public alarmDefinitionIdToDelete: string | null = null

  public async mounted() {
    if (!this.selectedPlc) {
      return
    }
    this.setSelectedAlarmDefinitionId('')
    await this.loadAlarmDefinitions()
  }
  
  public get plcName() {
    return this.selectedPlc?.name ?? ''
  }

  public async onDeleteAlarmDefinitionConfirmed() {
    await this.deleteAlarmDefinition(this.alarmDefinitionIdToDelete!)
    this.alarmDefinitionIdToDelete = null
  }
}
</script>
<style lang="scss" scoped>
.alarm-definition-actions {
  padding-bottom: 120px;
  display: flex;
  justify-content: flex-end;
  height: 53px;
  .v-btn {
    border: solid 1px white;
  }
}
</style>