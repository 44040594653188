import { SolutionHardwareClampLines, SolutionHardwareInstanceViewModel } from './models'

export interface SolutionHardwareUiState {
  interacted: boolean
  clampLines: SolutionHardwareClampLines
  modules: SolutionHardwareInstanceViewModel[]
}

export enum SolutionHardwareUiAction {
  createSolutionHardwareInstance = 'createSolutionHardwareInstance',
  updateSolutionHardwareInstance = 'updateSolutionHardwareInstance',
  deleteSolutionHardwareInstance = 'deleteSolutionHardwareInstance',
  sortSolutionHardwareInstances = 'sortSolutionHardwareInstances',
  computeClampLinesAndModules = 'computeClampLinesAndModules',
}

export enum SolutionHardwareUiMutation {
  setClampLines = 'setClampLines',
  setModules = 'setModules',
  setInteracted = 'setInteracted',
}

export enum SolutionHardwareUiGetter {
  solutionHardwareDefinitionsSearchResult = 'solutionHardwareDefinitionsSearchResult',
  clampLines = 'clampLines',
  modules = 'modules',
  isMappedSolutionHardwareInstance = 'isMappedSolutionHardwareInstance',
  isInteracted = 'isInteracted',
}