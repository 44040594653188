<template>
    <v-list-tile>
        <v-list-tile-content>           
            <v-list-tile-title :title="name">
                <div class="label-with-icon">
                    <svg-icon :icon="icon" width="24" height="20" class="icon"/>
                    <div class="label">{{ name }}</div>
                </div>
            </v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action v-if="descriptionPopupTitle">
            <description-popover
                :header="descriptionPopupTitle"
                :content="descriptionPopupText">
            </description-popover>
        </v-list-tile-action>
        <v-list-tile-action class="lastAction">
            <v-btn :disabled="disabled" icon ripple @click.native="emitTreeViewItemInteraction">
                <v-icon class="actionIcon">add</v-icon>
            </v-btn>
        </v-list-tile-action>
    </v-list-tile>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import SvgIcon from '../ui/SvgIcon.vue'

@Component({
  components: {
    SvgIcon,
  },
})
export default class TreeViewGroupItem extends Vue {
    @Prop({type: String}) public name: string
    @Prop({type: String}) public icon: string
    @Prop({default: false}) public disabled: boolean
    @Prop({type: String}) public descriptionPopupTitle: string
    @Prop({type: String}) public descriptionPopupText: string    
    @Emit('treeViewItemInteraction') public emitTreeViewItemInteraction () {
      return true
    }
}
</script>
<style lang="scss" scoped>
.lastAction {
    min-width: 24px;
    .actionIcon {
        color: white;    
    }
}
.label-with-icon {
    display: flex;
    .icon {
        margin-right: 10px;
        margin-top: 2px;
    }
    .label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>