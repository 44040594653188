<template>
  <div class="slaveClampLine">
    <clamp :clamp="couplerClamp" class="handle"/>
    <draggable v-model="clamps"
      class="slaveClamps"
      group="clamps"
      animation="200"
      @end="sortSolutionHardwareInstances"
    >
      <clamp 
        v-for="clamp in clamps"
        :key="clamp.id"
        :clamp="clamp"
      />
    </draggable>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Clamp from './Clamp.vue'

import Draggable from 'vuedraggable'

import { SolutionHardwareInstanceViewModel, SolutionHardwareSlaveClampLine } from '../../../../../store/modules/solutionHardwareUi/models'

const SolutionHardwareUi = namespace('solutionHardwareUi')

@Component({
  components: {
    Clamp,
    Draggable,
  },
})
export default class SlaveClampLine extends Vue {
  @Prop() public clampLine: SolutionHardwareSlaveClampLine
  @SolutionHardwareUi.Action public sortSolutionHardwareInstances: () => Promise<void>

  public get couplerClamp(): SolutionHardwareInstanceViewModel {
    return this.clampLine.couplerClamp
  }

  public get clamps(): SolutionHardwareInstanceViewModel[] {
    return this.clampLine.clamps
  }

  public set clamps(value: SolutionHardwareInstanceViewModel[]){
    this.clampLine.clamps = value
  }
}
</script>

<style lang="scss" scoped>
.slaveClampLine {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 381px;
}
.slaveClamps {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: nowrap;
  min-width: 50px;
  min-height: 315px;
}
img.plcPlaceHolder {
  height: 315px;
  color: white;
  visibility: hidden;
}
</style>