import { DropdownOption } from './../../../models'

export enum ControlTypeV2 {
  Action = 'Action',
  Actions = 'Actions',
  Toggle = 'Toggle',
  Toggles = 'Toggles',
  ConsumptionProcessToggle = 'ConsumptionProcessToggle',
  BooleanDisplay = 'BooleanDisplay',
  NumericDisplay = 'NumericDisplay',
  EnumDisplay = 'EnumDisplay',
  TimeDisplay = 'TimeDisplay',
  NumericInput = 'NumericInput',
  TextDisplay = 'TextDisplay',
  TextInput = 'TextInput',
  EnumInput = 'EnumInput',
  Links = 'Links',
  DeviceMessages = 'DeviceMessages',
  StateOfChargeConfiguration = 'StateOfChargeConfiguration',
  ArrayContainer = 'ArrayContainer',
}

export interface ControlViewModelV2 {
  id: string
  type: ControlTypeV2
  attributes: ControlAttributes
  state: {
    [state: string]: any,
  }
  pendingState: {
    [state: string]: any,
  }
  readonly?: boolean
  parentControlId?: string  // ControlType.ArrayContainer children only
  displayName?: string  // ControlType.ArrayContainer children only
  index?: number  // ControlType.ArrayContainer children only
}

export interface ControlContextV2 {
  executeCommand: (controlId: string, command: ControlCommand) => Promise<void>
  resolveStringResource: (resourceKey: string) => string
  createArrayEntry: (controlId: string) => Promise<ControlViewModelV2>
  deleteArrayEntry: (controlId: string) => Promise<void>
  setDisplayName: (controlId: string, displayName: string) => Promise<void>
}

export type ControlAttributes = 
  ActionControlAttributes | 
  ActionsControlAttributes | 
  ToggleControlAttributes | 
  TogglesControlAttributes | 
  BooleanDisplayControlAttributes |
  ConsumptionProcessToggleControlAttributes |
  DeviceMessagesControlAttributes |
  EnumDisplayControlAttributes |
  EnumInputControlAttributes |
  NumericDisplayControlAttributes | 
  NumericInputControlAttributes | 
  StateOfChargeConfigurationControlAttributes |
  TextDisplayControlAttributes | 
  TextInputControlAttributes | 
  TimeDisplayControlAttributes |
  LinksControlAttributes | 
  ArrayContainerControlAttributes

export interface ActionControlAttributes extends BaseControlAttributes {
  appearance: ActionControlAppearance
  action: {
    command: string
    text: string,
  }
  confirmationText: string
}

export interface ActionsControlAttributes extends BaseControlAttributes {
  appearance: ActionsControlAppearance
  actions: Array<{
    command: string
    icon: string,
  }>
}

export interface ToggleControlAttributes extends BaseControlAttributes {
  appearance: ToggleControlAppearance
  toggle: {
    command: string
    state: string,
  }
}

export interface TogglesControlAttributes extends BaseControlAttributes {
  appearance: TogglesControlAppearance
  toggles: Array<{
    command: string
    state: string
    icon: string,
  }>
}

export interface ConsumptionProcessToggleControlAttributes extends BaseControlAttributes {
  appearance: ConsumptionProcessToggleControlAppearance
  toggle: {
    command: string
    state: string,
  }
}

export interface BooleanDisplayControlAttributes extends BaseControlAttributes {
  appearance: BooleanDisplayControlAppearance
  iconTrue: string
  iconFalse: string
  state: string
}

export interface EnumDisplayControlAttributes extends BaseControlAttributes {
  appearance: EnumDisplayControlAppearance
  options: DropdownOption[]
  state: string
}

export interface EnumInputControlAttributes extends BaseControlAttributes {
  appearance: EnumInputControlAppearance
  options: DropdownOption[]
  command: string
  state: string
}

export interface NumericDisplayControlAttributes extends BaseControlAttributes {
  appearance: NumericDisplayControlAppearance
  unit: string
  decimals: number
  invalidValue: number
  invalidDisplayValue: string
  state: string
}

export interface NumericInputControlAttributes extends BaseControlAttributes {
  appearance: NumericInputControlAppearance
  minValue: number
  maxValue: number
  interval: number
  minValueLabel: string
  maxValueLabel: string
  unit: string
  gradient: string
  command: string
  beginCommand: string
  endCommand: string
  state: string
}

export interface StateOfChargeConfigurationControlAttributes extends BaseControlAttributes {
  appearance: StateOfChargeConfigurationControlAppearance
  selfConsumptionCapacity: {
    state: string
    label: string,
  }
  peakShavingCapacity: {
    command: string
    state: string
    label: string,
  }
  loadManagementCapacity: {
    command: string
    state: string
    label: string,
  }
  offGridCapacity: {
    command: string
    state: string
    label: string,
  }
}

export interface TextDisplayControlAttributes extends BaseControlAttributes {
  appearance: TextDisplayControlAppearance
  state: string
}

export interface TextInputControlAttributes extends BaseControlAttributes {
  appearance: TextInputControlAppearance
  maxLength: number
  command: string
  state: string
}

export interface TimeDisplayControlAttributes extends BaseControlAttributes {
  appearance: TimeDisplayControlAppearance
  timeType: TimeDisplayControlTimeType
  state: string
}

export enum TimeDisplayControlTimeType {
  UnixSeconds = 'UnixSeconds',
  UnixMilliseconds = 'UnixMilliseconds',
}

export interface LinksControlAttributes extends BaseControlAttributes {
  appearance: LinksControlAppearance
  sourceInterfaceId: string,
  linkType: string,
  maxNumSelections: number
  options: DropdownOption[]
  command: string
  state: string
}

export interface DeviceMessagesControlAttributes extends BaseControlAttributes {
  appearance: DeviceMessagesControlAppearance,
  messagesEnum: string
  state: string
}

export interface ArrayContainerControlAttributes extends BaseControlAttributes {
  maxEntries: number
  activateControlId?: string
  childDialogHeaderLabel: string
  childDialogDisplayNameInputLabel: string
}

export interface BaseControlAttributes {
  label: string
  tooltip: string
}

export enum ActionControlAppearance {
  TextButton = 'TextButton',
}

export enum ActionsControlAppearance {
  IconButton = 'IconButton',
}

export enum ToggleControlAppearance {
  Switch = 'Switch',
  Lock = 'Lock',
  Checkbox = 'Checkbox',
}

export enum TogglesControlAppearance {
  IconButtons = 'IconButtons',
}

export enum ConsumptionProcessToggleControlAppearance {
  Switch = 'Switch',
}

export enum BooleanDisplayControlAppearance {
  Icons = 'Icons',
}

export enum EnumDisplayControlAppearance {
  Label = 'Label',
}

export enum EnumInputControlAppearance {
  Dropdown = 'Dropdown',
}

export enum NumericDisplayControlAppearance {
  Label = 'Label',
  PercentageCircle = 'PercentageCircle',
}

export enum NumericInputControlAppearance {
  Input = 'Input',
  Slider = 'Slider',
  HueColorSlider = 'HueColorSlider',
}

export enum StateOfChargeConfigurationControlAppearance {
  Input = 'Input',
}

export enum TextDisplayControlAppearance {
  Label = 'Label',
}

export enum TextInputControlAppearance {
  Input = 'Input',
  IpAddress = 'IpAddress',
}

export enum TimeDisplayControlAppearance {
  DDMMYYYYHHmmss = 'DDMMYYYYHHmmss',
}

export enum DeviceMessagesControlAppearance {
  Icons = 'Icons',
}

export enum LinksControlAppearance {
  Dropdown = 'Dropdown',
}

export interface ControlCommand {
  command: string
  params: number | boolean | string | string[] | null
  pendingState?: {
    [state: string]: any,
  }
}

export interface DeviceMessage {
  id: number
  level: MessageLevel
  text: string
  timestamp: string
  value: number
}

export enum MessageLevel {
  Debug = 'Debug',
  Information = 'Information',
  Warning = 'Warning',
  Error = 'Error',
}

export const InvalidValue = 1e300
export const InvalidDisplayValue = 'n/a'