import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'
import { ConsumptionAction, ConsumptionMutation, ConsumptionState } from './types'

import { Module } from 'vuex'

const namespaced: boolean = true
export const consumption: Module<ConsumptionState,any> = {
  namespaced,
  state,
  actions,
  mutations,
}

export const types = {
  action:  ConsumptionAction,
  mutation: ConsumptionMutation,
}

export const domain = 'consumption'

export const vuexModule = {
  [domain]: consumption,
}