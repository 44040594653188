<template>
  <v-layout column wrap>
    <v-flex xs12>
      <v-layout row>
        <wizard-step-header 
          :title="dictionary('wizard.mappen')" 
          :sub-title="plcName"
        />
        <room-selector 
          slot="header" 
          :rooms="rooms"
          :selected-room-id="selectedRoomId" 
          @selected="setSelectedRoomId"
        />
      </v-layout>
    </v-flex>
    <v-flex xs12>
      <release-information-panel />
    </v-flex>
    <v-flex xs12>
      <div style="display: flex; flex-direction: row; padding-bottom: 75px;">
        <div>
          <SolutionHardwareInputList :solutionHardware="solutionHardwareInputs" />
        </div>
        <div class="devices">
          <column-header :header="dictionary('mapping.functionblocks')">
            <template v-slot:actions>
              <column-header-action
                icon="keyboard_arrow_down"
                tooltip="Expand all"
                @click="onExpandAll"
              />
              <column-header-action
                icon="keyboard_arrow_up"
                tooltip="Collapse all"
                @click="onCollapseAll"
              />
            </template>
          </column-header>
          <div style="flex: 1;">
            <v-layout row wrap v-if="mappableDevicesOfRoom.length > 0">
              <v-flex xs12 v-for="item in mappableDevicesOfRoom" :key="item.device.id">
                <device-card 
                  :device="item.device"
                  :function-block="item.functionBlock"
                  :expanded="devicesExpanded"
                />
              </v-flex>
            </v-layout>
          </div>
        </div>
        <div>
          <SolutionHardwareOutputList :solutionHardware="solutionHardwareOutputs" />
        </div>
      </div>
    </v-flex>
    <set-auto-mapping-instance-number-dialog
      :show="doesCurrentAutoMappingNeedInstanceNumber"
      :auto-mapping-slot="currentAutoMappingSolutionHardwareSlot"
      :auto-mappings="autoMappingsOfCurrentSolutionHardwareSlot"
      @selected="onInstanceNumberSelected($event)"
      @cancelled="onInstanceNumberCancelled"
    />
  </v-layout>
</template>

<script lang="ts">
import RoomSelector from '@/components/ui/RoomSelector.vue'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import ColumnHeader from './ColumnHeader.vue'
import ColumnHeaderAction from './ColumnHeaderAction.vue'
import DeviceCard from './DeviceCard.vue'
import SetAutoMappingInstanceNumberDialog from './SetAutoMappingInstanceNumberDialog.vue'
import SolutionHardwareInputList from './SolutionHardwareInputList.vue'
import SolutionHardwareOutputList from './SolutionHardwareOutputList.vue'

import { AutoMappingModel, RoomModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'
import { AutoMappingSlotModel } from '../../../../../../../eco-domain-store-modules/src/systemConfiguration/models'
import { MappableDevice, MappableSolutionHardware } from '../../../../../store/modules/mappingUi/models'
import ReleaseInformationPanel from '../manageRelease/ReleaseInformationPanel.vue'
import { PlcModel } from '@ecocoach/domain-store-modules/src/plc/models'

const RoomUi = namespace('roomUi')
const App = namespace('app')
const Resource = namespace('resource')
const MappingUi = namespace('mappingUi')

@Component({
  components: {
    ColumnHeader,
    ColumnHeaderAction,
    DeviceCard,
    ReleaseInformationPanel,
    RoomSelector,
    SetAutoMappingInstanceNumberDialog,
    SolutionHardwareInputList,
    SolutionHardwareOutputList,
  },
})
export default class MapHardware extends Vue {
  @Resource.Getter public dictionary
  @App.Getter public selectedPlc: PlcModel
  @MappingUi.Getter public solutionHardwareInputs: MappableSolutionHardware[]
  @MappingUi.Getter public mappableDevicesOfRoom: MappableDevice[]
  @MappingUi.Getter public solutionHardwareOutputs: MappableSolutionHardware[]
  @MappingUi.Mutation public resetCurrentAutoMapping: () => void
  @MappingUi.Mutation public resetCurrentManualMapping: () => void
  @MappingUi.Getter public doesCurrentAutoMappingNeedInstanceNumber: () => boolean
  @MappingUi.Getter public currentAutoMappingSolutionHardwareSlot: () => AutoMappingSlotModel
  @MappingUi.Getter public autoMappingsOfCurrentSolutionHardwareSlot: () => AutoMappingModel[]
  @MappingUi.Action public addInstanceNumberToCurrentAutoMapping: (payload: { instanceNumber: number }) => Promise<void>
  @RoomUi.Getter public rooms: RoomModel[]
  @RoomUi.Getter public selectedRoomId: string
  @RoomUi.Mutation public setSelectedRoomId: (id: string) => void
  @RoomUi.Action public initializeRoomSelection: () => Promise<void>

  public devicesExpanded = true

  public get plcName() {
    return this.selectedPlc?.name ?? ''
  }

  public onCollapseAll() {
    this.devicesExpanded = false
  }

  public onExpandAll() {
    this.devicesExpanded = true
  }

  public mounted() {
    this.initializeRoomSelection()
    this.resetCurrentManualMapping()
    this.resetCurrentAutoMapping()
  }

  public onInstanceNumberSelected(value: number) {
    this.addInstanceNumberToCurrentAutoMapping({
      instanceNumber: value,
    })
  }

  public onInstanceNumberCancelled() {
    this.resetCurrentAutoMapping()
  }
}
</script>

<style lang="scss" scoped>
.devices {
  flex-grow:1;
  max-width: calc(100% - 650px);
  height: 100%;
  padding: 0px 20px;
}
</style>
