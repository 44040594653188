import { AuditLogTableFilterSettings } from './models'

export enum AuditLogUiAction {
  openAuditLog = 'openAuditLog',
  closeAuditLog = 'closeAuditLog',
  loadFilterSettings = 'loadFilterSettings',
  updateFilterSettings = 'updateFilterSettings',
}

export enum AuditLogUiMutation {
  setInteracted = 'setInteracted',
  setSelectedPlcId = 'setSelectedPlcId',
}

export enum AuditLogUiGetter {
  isInteracted = 'isInteracted',
  selectedPlcId = 'selectedPlcId',
  filterSettings = 'filterSettings',
  entries = 'entries',
  filteredEntries = 'filteredEntries',
  actionTypeOptions = 'actionTypeOptions',
}

export interface AuditLogUiState {
  interacted: boolean
  selectedPlcId: string
  filterSettings: AuditLogTableFilterSettings
}
