import { ManageAlarmsUiAction, ManageAlarmsUiGetter, ManageAlarmsUiMutation, ManageAlarmsUiState } from '@/store/modules/manageAlarmsUi/types'
import { RootState } from '@/store/types'
import { AlarmDefinitionModel, AlarmDefinitionOptionModel, CreateAlarmDefinitionInput } from '@ecocoach/domain-store-modules/src/alarmDefinition/models'
import { AlarmDefinitionAction } from '@ecocoach/domain-store-modules/src/alarmDefinition/types'
import { AlarmLevel, AlarmOperator } from '@ecocoach/domain-store-modules/src/common'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import AppDataStorageService from '@ecocoach/domain-store-modules/src/services/appdatastorage.service'
import { intersectArrays } from '@ecocoach/domain-store-modules/src/utils'
import { DropdownOption } from '@ecocoach/shared-components/src'
import { ActionTree } from 'vuex'
import { AlarmDefinitionsTableFilterSettingsStorageKey, AlarmDefinitionTableFilterSettings } from './models'

export const actions: ActionTree<ManageAlarmsUiState, RootState> = {
  async [ManageAlarmsUiAction.loadAlarmDefinitions] ({ commit, dispatch, rootState }): Promise<void> {
    try {
      commit(ManageAlarmsUiMutation.setInteracted, true)
      await Promise.all([
        dispatch(`alarmDefinition/${AlarmDefinitionAction.loadAlarmDefinitions}`, { 
          plcId: rootState.app.selectedPlcId,
          language: rootState.app.selectedLanguage,
        }, { root: true }),
        dispatch(`alarmDefinition/${AlarmDefinitionAction.loadAlarmDefinitionOptions}`, { 
          plcId: rootState.app.selectedPlcId,
          language: rootState.app.selectedLanguage,
        }, { root: true }),
      ])
    } finally {
      commit(ManageAlarmsUiMutation.setInteracted, false)
    }
  },
  async [ManageAlarmsUiAction.loadFilterSettings] ({ state, getters }): Promise<void> {
    const loadedSettings = await AppDataStorageService.getObject<AlarmDefinitionTableFilterSettings>(AlarmDefinitionsTableFilterSettingsStorageKey)
    const validLevels = (getters[ManageAlarmsUiGetter.levelOptions] as DropdownOption[]).map(o => o.id as string)
    state.filterSettings.levels = intersectArrays(validLevels, loadedSettings?.levels ?? [])
    state.filterSettings.name = loadedSettings?.name ?? ''
    state.filterSettings.controlName = loadedSettings?.controlName ?? ''
    state.filterSettings.deviceName = loadedSettings?.deviceName ?? ''
    state.filterSettings.roomName = loadedSettings?.roomName ?? ''
    state.filterSettings.message = loadedSettings?.message ?? ''
  },
  async [ManageAlarmsUiAction.updateFilterSettings] ({ state }, payload: { property: string, value: any }): Promise<void> {
    state.filterSettings[payload.property] = payload.value
    await AppDataStorageService.setObject(AlarmDefinitionsTableFilterSettingsStorageKey, state.filterSettings)
  },
  async [ManageAlarmsUiAction.newAlarmDefinition]({ commit, rootState }): Promise<void> {
    commit(ManageAlarmsUiMutation.setEditingAlarmDefinition, {
      id: '',
      plcId: rootState.app.selectedPlcId,
      name: '',
      operator: '==',
      value: 0,
      message: '',
      hysteresis: 0,
      level: AlarmLevel.Critical,
      option: {
        controlId: null,
        roomName: '',
        deviceName: '',
        controlName: '',
        unit: '',
        isNumeric: false,
        qualifiedEndpointPath: '',
      },
    } as AlarmDefinitionModel)
  },
  async [ManageAlarmsUiAction.editAlarmDefinition]({ commit, rootState, rootGetters }, id: string): Promise<void> {
    const resolveStringResource = rootGetters[`resource/${ResourceGetter.resolveStringResource}`]
    const alarmDefinition = rootState.alarmDefinition.alarmDefinitions.find(a => a.id === id)!
    commit(ManageAlarmsUiMutation.setEditingAlarmDefinition, {
      ...alarmDefinition,
      name: alarmDefinition.name || resolveStringResource(alarmDefinition.nameResourceId),
      message: alarmDefinition.message || resolveStringResource(alarmDefinition.messageResourceId),
    } as AlarmDefinitionModel)
  },
  async [ManageAlarmsUiAction.updateAlarmDefinitionProperty]({ commit, state, rootState }, payload: { property: string, value: any }): Promise<void> {
    if (payload.property === 'option.controlId') {
      const oldOption = rootState.alarmDefinition.alarmDefinitionOptions.find(_ => _.controlId === state.editingAlarmDefinition?.option.controlId)
      const newOption = rootState.alarmDefinition.alarmDefinitionOptions.find(_ => _.controlId === payload.value)
      // in case the control type changes, reset some values according the new type
      if (oldOption?.isNumeric !== newOption?.isNumeric) {
        if (newOption?.isNumeric) { // new control is numeric
          commit(ManageAlarmsUiMutation.setEditingAlarmDefinitionProperty, {
            property: 'value',
            value: 0,
          })
        } else { // new control is boolean
          commit(ManageAlarmsUiMutation.setEditingAlarmDefinitionProperty, {
            property: 'operator',
            value: AlarmOperator.Equal,
          })
          commit(ManageAlarmsUiMutation.setEditingAlarmDefinitionProperty, {
            property: 'value',
            value: false,
          })
        }
      }
      commit(ManageAlarmsUiMutation.setEditingAlarmDefinitionProperty, {
        property: 'option',
        value: {
          ...newOption,
        } as AlarmDefinitionOptionModel,
      })
      return
    } else if(payload.property === 'option.qualifiedEndpointPath') {
      commit(ManageAlarmsUiMutation.setEditingAlarmDefinitionProperty, {
        property: 'option',
        value: {
          ...state.editingAlarmDefinition?.option,
          controlId: null,
          roomName: '',
          deviceName: '',
          controlName: '',
          qualifiedEndpointPath: payload.value,
        } as AlarmDefinitionOptionModel,
      })
      return
    } else if(payload.property === 'option.isNumeric') {
      commit(ManageAlarmsUiMutation.setEditingAlarmDefinitionProperty, {
        property: 'option',
        value: {
          ...state.editingAlarmDefinition?.option,
          isNumeric: payload.value,
          unit: payload.value ? state.editingAlarmDefinition?.option.unit : '',
        } as AlarmDefinitionOptionModel,
      })
      return
    } else if(payload.property === 'option.unit') {
      commit(ManageAlarmsUiMutation.setEditingAlarmDefinitionProperty, {
        property: 'option',
        value: {
          ...state.editingAlarmDefinition?.option,
          unit: payload.value,
        } as AlarmDefinitionOptionModel,
      })
      return
    }
    commit(ManageAlarmsUiMutation.setEditingAlarmDefinitionProperty, payload)
  },
  async [ManageAlarmsUiAction.cancelAlarmDefinition]({ commit }): Promise<void> {
    commit(ManageAlarmsUiMutation.setEditingAlarmDefinition, null)
  },
  async [ManageAlarmsUiAction.createAlarmDefinition]({ commit, dispatch, state }, selfAuthorize: boolean): Promise<void> {
    try {
      commit(ManageAlarmsUiMutation.setInteracted, true)
      await dispatch(`alarmDefinition/${AlarmDefinitionAction.createAlarmDefinition}`, {
        ...state.editingAlarmDefinition,
        selfAuthorize,
      } as CreateAlarmDefinitionInput, { root: true })
      commit(ManageAlarmsUiMutation.setEditingAlarmDefinition, null)
    } finally {
      commit(ManageAlarmsUiMutation.setInteracted, false)
    }
  },
  async [ManageAlarmsUiAction.updateAlarmDefinition]({ commit, dispatch, state }): Promise<void> {
    try {
      commit(ManageAlarmsUiMutation.setInteracted, true)
      await dispatch(`alarmDefinition/${AlarmDefinitionAction.updateAlarmDefinition}`, state.editingAlarmDefinition, { root: true })
      commit(ManageAlarmsUiMutation.setEditingAlarmDefinition, null)
    } finally {
      commit(ManageAlarmsUiMutation.setInteracted, false)
    }
  },
  async [ManageAlarmsUiAction.deleteAlarmDefinition]({ commit, dispatch, rootState }, id: string): Promise<void> {
    try {
      commit(ManageAlarmsUiMutation.setInteracted, true)
      const payload = {
        id,
        plcId: rootState.app.selectedPlcId,
      }
      await dispatch(`alarmDefinition/${AlarmDefinitionAction.deleteAlarmDefinition}`, payload, { root: true })
      commit(ManageAlarmsUiMutation.setEditingAlarmDefinition, null)
      commit(ManageAlarmsUiMutation.setSelectedAlarmDefinitionId, '')
    } finally {
      commit(ManageAlarmsUiMutation.setInteracted, false)
    }
  },
}
