<template>
  <v-expansion-panel v-model="isOpen" :expand="true" :readonly="!expandable" class="expandable-card">
    <v-expansion-panel-content :hide-actions="!expandable">
      <v-layout slot="header" row align-center justify-space-between class="expandable-card-header">
        <v-flex v-if="hasIcon" shrink class="header-icon">
          <svg-icon :icon="icon" :width="20" :height="20" />
        </v-flex>
        <v-flex v-if="hasIcon" shrink class="header-spacer"/>
        <v-flex shrink class="header-title">{{title}}</v-flex>
        <v-flex grow class="header-spacer"/>
        <v-flex shrink class="header-info" v-if="hasInfoPopover">
          <description-popover class="infopopover"
              :header="descriptionPopupTitle"
              :content="descriptionPopupText"
          />
        </v-flex>
      </v-layout>
      <slot v-show="isOpen[0]"></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import SvgIcon from './SvgIcon.vue'

@Component({
  components: {
    SvgIcon,
  },
})
export default class ExpandableCard extends Vue {
  @Prop({ default: '' }) public title: string
  @Prop({ default: '' }) public icon: string
  @Prop({ default: '' }) public descriptionPopupTitle: string
  @Prop({ default: ''}) public descriptionPopupText: string    
  @Prop({ default: true }) public expandable: boolean
  @Prop({ default: true }) public open: boolean

  public isOpen = [false]

  public get hasIcon() {
    return !!this.icon
  }

  public get hasInfoPopover() {
    return !!this.descriptionPopupTitle || !!this.descriptionPopupText
  }

  public mounted() {
    this.isOpen = [this.open]  
  }

  @Watch('open')
  public openChanged(value: boolean) {
    this.isOpen = [value]
  }
}
</script>
<style lang='scss' scoped>
.expandable-card-header {
  .header-icon {
    height: 20px;
    width: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-title {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;      
  }
  .header-spacer {
    min-width: 10px;
  }
}
</style>
<style lang="css">
.expandable-card .v-expansion-panel__container {
  background: radial-gradient(circle at center top, #1a3856, #080e19);
}
.expandable-card .v-expansion-panel__header {
  min-height: 50px;
  max-height: 50px;
  padding: 10px !important;
  background: linear-gradient(180deg,#1a3856,#080e19);
}
</style>
