<template>
  <v-layout column wrap>
    <v-flex xs12>
      <v-layout row>
        <wizard-step-header
          :title="dictionary('wizard.functions.choose')" 
          :sub-title="plcName" 
        />
        <room-selector 
          slot="header" 
          :rooms="rooms"
          :selected-room-id="selectedRoomId" 
          @selected="setSelectedRoomId"
        />
      </v-layout>
    </v-flex>
    <v-flex xs12>
      <release-information-panel />
    </v-flex>
    <v-flex xs12>
      <v-layout row wrap>
        <v-flex md3 shrink class="fbTree">
          <function-block-tree-view />
        </v-flex>
        <v-flex md9 grow class="deviceCards">
          <devices-of-room-grid />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import RoomSelector from '@/components/ui/RoomSelector.vue'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import DevicesOfRoomGrid from './DevicesOfRoomGrid.vue'
import FunctionBlockTreeView from './FunctionBlockTreeView.vue'

import { RoomModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'
import ReleaseInformationPanel from '../manageRelease/ReleaseInformationPanel.vue'

import { PlcModel } from '@ecocoach/domain-store-modules/src/plc/models'

const DeviceUi = namespace('deviceUi')
const RoomUi = namespace('roomUi')
const App = namespace('app')
const Resource = namespace('resource')

@Component({
  components: {
    RoomSelector,
    FunctionBlockTreeView,
    DevicesOfRoomGrid,
    ReleaseInformationPanel,
  },
})
export default class DeviceDevices extends Vue {
  @App.Getter public selectedPlc: PlcModel
  @Resource.Getter public dictionary
  @DeviceUi.Mutation public clearChanges: () => void
  @RoomUi.Getter public rooms: RoomModel[]
  @RoomUi.Getter public selectedRoomId: string
  @RoomUi.Mutation public setSelectedRoomId: (id: string) => void
  @RoomUi.Action public initializeRoomSelection: () => Promise<void>

  public mounted() {
    this.initializeRoomSelection()
    this.clearChanges()
  }

  public get plcName() {
    return this.selectedPlc?.name ?? ''
  }
}
</script>

<style lang="scss" scoped>
.fbTree {
  min-width: 400px;
  max-width: 600px;
  margin-bottom: 75px;
}
.deviceCards {
  flex: 1;
  margin-bottom: 75px;
}
</style>
