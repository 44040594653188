<template>
  <v-layout column wrap style="display: block;">
    <v-flex xs12>
      <wizard-step-header 
        :title="dictionary('wizard.rooms.definitions')"
        :sub-title="plcName" 
      />
    </v-flex>
    <v-flex xs12>
      <release-information-panel />
    </v-flex>
    <v-flex xs12>
      <v-layout row wrap>
        <room-form />
        <room-list />
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import RoomForm from '@/components/views/ecoSetupTool/steps/defineRooms/RoomForm.vue'
import RoomList from '@/components/views/ecoSetupTool/steps/defineRooms/RoomList.vue'
import ReleaseInformationPanel from '../manageRelease/ReleaseInformationPanel.vue'

import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { PlcModel } from '@ecocoach/domain-store-modules/src/plc/models'

const App = namespace('app')
const Resource = namespace('resource')

@Component({
  components: {
    RoomList,
    RoomForm,
    ReleaseInformationPanel,
  },
})
export default class DefineRooms extends Vue {
  @App.Getter public selectedPlc: PlcModel
  @Resource.Getter public dictionary

  public get plcName() {
    return this.selectedPlc?.name ?? ''
  }
}
</script>