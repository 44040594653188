<template lang="html">
    <div class="baseValueCircle">
      <canvas
        ref = "circle"
        width="50"
        height="50"/>
      <div class="valueLabel"> <div>{{ this.value + ' ' + this.unit }}</div> </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
    name: 'BaseValueCircle',
    props: {
      /**
       * the colors for the background gradient
       * as string array
       */
      gradientColors: {
        type: Array,
        default: () => ['red', 'darkred'],
      },
      /**
       * sets the current value
       */
      value: {
        type: Number,
        default: 0,
      },
      /**
       * the displayed unit string
       */
      unit: {
        type: String,
        default: '',
      },
    },
    computed: {
      progress(): number {
        return 2 * (this.value as number / 100) * Math.PI
      },
    },
    methods: {
      drawCircle(): void {
        const circle = this.$refs.circle as any
        const context = circle.getContext('2d')
        const fstrokeStyle = (start, stop) => {
          const strokeStyle = context.createLinearGradient(45, 5, 5, 5)
          strokeStyle.addColorStop(1, start)
          strokeStyle.addColorStop(0, stop)
          return strokeStyle
        }
        context.lineWidth = 3
        context.beginPath()
        context.strokeStyle = fstrokeStyle('black', 'black')
        context.arc(25, 25, 22.5, 0, 2 * Math.PI)
        context.stroke()

        context.beginPath()
        context.strokeStyle = fstrokeStyle((this as any).gradientColors[1] as string, (this as any).gradientColors[0] as string)
        context.arc(25, 25, 22.5, 0, (this as any).progress)
        context.stroke()
      },
    },
    mounted():any {
      // workaround
      (this as any).drawCircle()
    },
    updated():any {
      // workaround
      (this as any).drawCircle()
    },
})
</script>
<style lang="scss" scoped>
.baseValueCircle {
      color: $fore-color-primary;
      font-family: $font-family-primary;
      width: 50px;
      height: 50px;
      display: flex;
      height: auto;
      align-items: center;
      .valueLabel{
        position: relative;
        left: -75%;
        white-space: nowrap;
      }
      canvas {
        transform: rotate(-90deg);
      }
    }

</style>

<docs>
A circle value control

Usage of the circle value control
```jsx
<base-value-circle
      :value="42"
      :unit="'%'"
      />
```

Custom gradient colors
```jsx
<base-value-circle
    :value="42"
    :unit="'%'"
    :gradientColors="['blue','green']"
 />
```
</docs>
