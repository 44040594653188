import { MutationTree } from 'vuex'
import { RoomUiMutation, RoomUiState } from './types'

export const mutations: MutationTree<RoomUiState> = {
  [RoomUiMutation.setSelectedRoomId](state, payload: string) {
    state.selectedRoomId = payload
  },
  [RoomUiMutation.setRoomInputProperty](state, payload: { property: string, value: any }) {
    state.roomInput[payload.property] = payload.value
  },
  [RoomUiMutation.resetRoomInput](state) {
    state.roomInput = {
      id: '',
      name: '',
      icon: '',
      sortOrder: 0,
    }
  },
  [RoomUiMutation.setInteracted](state, payload: boolean) {
    state.interacted = payload
  },
}
