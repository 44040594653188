import { ArchitectureType, PlcType } from '../../common'

export enum ReleaseState {
  NEW = 'New',
  COMPLETED = 'Completed',
}

export enum BuildState {
  NOT_STARTED = 'NotStarted', 
  STARTING = 'Starting', 
  GENERATING_PROJECT_FILES = 'GeneratingProjectFiles', 
  WRITING_PROJECT_FILES = 'WritingProjectFiles', 
  PRE_BUILDING = 'PreBuilding', 
  GENERATING_MAPPINGS = 'GeneratingMappings', 
  BUILDING = 'Building', 
  PACKAGING = 'Packaging', 
  PUBLISHING = 'Publishing', 
  CLEANING_UP = 'CleaningUp', 
  BUILD_FAILED = 'BuildFailed', 
  SUCCESSFULLY_FINISHED = 'SuccessfullyFinished',
}

export enum DeploymentState {
  NOT_STARTED = 'NotStarted',
  UPDATING = 'Updating', 
  BACKUP_PREVIOUS_VERSION = 'BackupPreviousVersion',
  EXTRACTING_RELEASE = 'ExtractingRelease',
  ACTIVATING_NEW_RELEASE = 'ActivatingNewRelease',
  VERIFYING_NEW_RELEASE = 'VerifyingNewRelease',
  REMOVE_PREVIOUS_VERSION = 'RemovePreviousVersion',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  DEPLOYMENT_FAILED = 'DeploymentFailed',
  REVERTING_TO_PREVIOUS_VERSION = 'RevertingToPreviousVersion',
}

export interface ReleaseModel {
  id: string
  plcId: string
  state: ReleaseState
  plcType: PlcType
  createdBy: string | null
  createdAt: string
  lastModifiedBy: string | null
  lastModifiedAt: string,
  deploymentState: DeploymentState,
  deploymentStateInfo: string | null
  deploymentProgress: number
  deployedAt: string
  deployedBy: string | null
  buildState: BuildState,
  buildStateInfo: string | null
  builtAt: string
  builtBy: string | null
  isTemplate: boolean
  updateTemplateOperationData?: boolean
  name: string | null
  description: string | null
  architectureType: ArchitectureType
}

export interface UpdateReleaseInput {
  name: string | null
  description: string | null
  isTemplate: boolean
  updateTemplateOperationData: boolean
}

export interface ReleaseDetails {
  devices: DeviceModel[]
  solutionHardwareInstances: SolutionHardwareInstanceModel[]
  deviceMappings: DeviceMappingModel[]
  autoDeviceMappings: AutoMappingModel[]
  rooms: RoomModel[]
}

export interface ReleaseUpgradeDetails {
  addedDevices: DeviceModel[]
  removedDevices: string[]
  addedDeviceMappings: DeviceMappingModel[]
  removedDeviceMappings: string[]
  addedDeviceAutoMappings: AutoMappingModel[]
  removedDeviceAutoMappings: string[]
  addedSolutionHardwareInstances: SolutionHardwareInstanceModel[]
  removedSolutionHardwareInstances: string[]
  deviceSortOrder: string[]
  solutionHardwareInstanceSortOrder: string[]
  deviceUpgradeInfos: DeviceUpgradeInfoItem[]
}

export interface DeviceUpgradeInfoItem {
  deviceId: string
  deviceName: string
  roomName: string
  oldFunctionBlockName:string
  oldFunctionBlockVersion: string
  newFunctionBlockName: string
  newFunctionBlockVersion: string
}

export interface DeviceModel {
  id: string
  roomId: string
  name: string
  icon: string
  functionBlockDefinitionId: string
  sortOrder: number
}

export interface SolutionHardwareInstanceModel {
  id: string
  solutionHardwareDefinitionId: string
  lastOctet: number | null
  sortOrder: number
}

export interface DeviceMappingModel {
  id: string
  deviceId: string
  deviceEndpointId: string
  solutionHardwareInstanceId: string
  solutionHardwareEndpointId: string
}

export interface AutoMappingModel {
  id: string
  deviceId: string
  deviceSlotInternalName: string
  solutionHardwareInstanceId: string
  solutionHardwareSlotInternalName: string
  instanceNumber: number
}

export interface RoomModel {
  id: string
  name: string
  icon: string
  sortOrder: number
}

export interface UpsertRoomInput {
  name: string
  icon: string
}

export interface CreateDeviceInput extends UpdateDeviceInput {
  roomId: string
  functionBlockDefinitionId: string
}

export interface UpdateDeviceInput {
  name: string
  icon: string
}

export interface CreateDeviceMappingInput {
  deviceId: string
  solutionHardwareInstanceId: string
  solutionHardwareEndpointId: string
  deviceEndpointId: string
}

export interface CreateDeviceMappingInput {
  deviceId: string
  solutionHardwareInstanceId: string
  solutionHardwareEndpointId: string
  deviceEndpointId: string
}

export interface CreateAutoMappingInput {
  deviceId: string
  solutionHardwareInstanceId: string
  deviceSlotInternalName: string
  solutionHardwareSlotInternalName: string
  instanceNumber: number
}

export interface ReleaseStateChanged {
  id: string
  buildState: BuildState
  buildStateInfo: string
  deploymentState: DeploymentState
  deploymentStateInfo: string
  deploymentProgress: number
}

export interface CompleteReleasePayload {
  releaseId: string
  userIdentifier: string
  builtAt: string
  archive: boolean
}

export interface DeployReleasePayload {
  releaseId: string
  forceDeployment: boolean
  userIdentifier: string
  deployedAt: string
}
