import { AlarmDefinitionModel, AlarmDefinitionOptionModel } from './models'

export interface AlarmDefinitionState {
  alarmDefinitions: AlarmDefinitionModel[]
  alarmDefinitionOptions: AlarmDefinitionOptionModel[]
}

export enum AlarmDefinitionAction {
  loadAlarmDefinitions = 'loadAlarmDefinitions',
  loadAlarmDefinitionsOfMultiplePlcs = 'loadAlarmDefinitionsOfMultiplePlcs',
  loadAlarmDefinitionOptions = 'loadAlarmDefinitionOptions',
  createAlarmDefinition = 'createAlarmDefinition',
  updateAlarmDefinition = 'updateAlarmDefinition',
  deleteAlarmDefinition = 'deleteAlarmDefinition',
}

export enum AlarmDefinitionMutation {
  setAlarmDefinitions = 'setAlarmDefinitions',
  setAlarmDefinitionsOptions = 'setAlarmDefinitionsOptions',
  upsertAlarmDefinition = 'upsertAlarmDefinition',
  removeAlarmDefinition = 'removeAlarmDefinition',
}
