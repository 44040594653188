import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { state } from './state'
import { UserAgreementAction, UserAgreementGetter, UserAgreementMutation, UserAgreementState } from './types'

import { Module } from 'vuex'

const namespaced: boolean = true
export const userAgreement: Module<UserAgreementState, any> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}

export const types = {
  action: UserAgreementAction, 
  mutation: UserAgreementMutation,
  getter: UserAgreementGetter,
}

export const domain = 'userAgreement'

export const vuexModule = {
  [domain]: userAgreement,
}