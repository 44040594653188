import ApiService from '../../services/api.service'
import { AccountModel, ConsumptionReportInput, EgonlineConfigurationModel, MeterModel, MinergieConfigurationModel, MinergieVisualizationData, MinergieVisualizationDataInput, ProjectModel, UserModel } from '../models'

export default {
  async loadProjects(): Promise<ProjectModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().consumptionQueryApi}/Projects`)
    return response.data.value
  },
  async loadAccountsEcocoach(projectId: string): Promise<AccountModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().consumptionQueryApi}/Ecocoach/${projectId}/accounts`)
    return response.data.value
  },
  async loadMetersEcocoach(projectId: string): Promise<MeterModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().consumptionQueryApi}/Ecocoach/${projectId}/meters`)
    return response.data.value
  },
  async loadUsersEcocoach(projectId: string): Promise<UserModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().consumptionQueryApi}/Ecocoach/${projectId}/users`)
    return response.data.value
  },
  async loadInfoMinergie(projectId: string): Promise<MinergieConfigurationModel> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().consumptionQueryApi}/Minergie/${projectId}/objectInfo`)
    return response.data.value
  },
  async loadAccountsMinergie(projectId: string): Promise<AccountModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().consumptionQueryApi}/Minergie/${projectId}/accounts`)
    return response.data.value
  },
  async loadMetersMinergie(projectId: string): Promise<MeterModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().consumptionQueryApi}/Minergie/${projectId}/meters`)
    return response.data.value.find(a => a.projectId === projectId)?.meters ?? []
  },
  async loadConfigurationEgonline(projectId: string): Promise<EgonlineConfigurationModel> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().consumptionQueryApi}/Egonline/${projectId}/configuration`)
    return response.data.value
  },
  async loadAccountsEgonline(projectId: string): Promise<AccountModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().consumptionQueryApi}/Egonline/${projectId}/accounts`)
    return response.data.value
  },
  async loadMetersEgonline(projectId: string): Promise<MeterModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().consumptionQueryApi}/Egonline/${projectId}/meters`)
    return response.data.value.find(a => a.projectId === projectId)?.meters ?? []
  },
  async loadUsersEgonline(projectId: string): Promise<UserModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().consumptionQueryApi}/Egonline/${projectId}/users`)
    return response.data.value
  },

  async loadReport(queryParams: ConsumptionReportInput): Promise<any> {
    let reportQuery = ''
    const querySplitter = () => {
      if (reportQuery === '') {
        return '?'
      } else {
        return '&'
      }
    }
    if (queryParams.dateFrom) {
      const from = new Date(Date.parse(queryParams.dateFrom))
      from.setUTCHours(0)
      from.setUTCMinutes(0)
      from.setUTCSeconds(0)
      reportQuery += querySplitter() + 'dateFrom=' + from.toISOString()
    }
    if (queryParams.dateTo) {
      const to = new Date(Date.parse(queryParams.dateTo))
      to.setUTCHours(23)
      to.setUTCMinutes(59)
      to.setUTCSeconds(59)
      reportQuery += querySplitter() + 'dateTo=' + to.toISOString()
    }
    if (queryParams.consumptionAccountId) {
      reportQuery += querySplitter() + 'consumptionAccountId=' + queryParams.consumptionAccountId
    }
    if (queryParams.resourceLanguage) {
      reportQuery += querySplitter() + 'resourceLanguage=' + queryParams.resourceLanguage
    }
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().consumptionQueryApi}/Report/${queryParams.projectId}/aggregationInterval/${queryParams.aggregationInterval + reportQuery}`, {
      responseType: 'blob',
    })
    return response.data
  },
  async loadChartDataMinergie(input: MinergieVisualizationDataInput): Promise<MinergieVisualizationData[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().consumptionQueryApi}/Minergie/${input.projectId}/Visualization/${input.accountId}/${input.interval}?resourceLanguage=${input.language}`)
    return response.data.value
  },
}