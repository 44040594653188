<template>
  <v-container fluid>
    <router-view />
    <v-footer inset app>
      <div class="navigation">
        <navigation-bar
          v-if="showWizard()"
          :links="links"
          :active-link="activeStep()"
          :blocked="this.isWaiting"
          @goToLink="this.onGoToStep"
           />
        <navigation-buttons v-if="activeStep()==='selectPlc'" />
      </div>
    </v-footer>
    <upgrade-release-confirmation-dialog />
    <release-upgrade-log-dialog />
  </v-container>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import NavigationButtons from './NavigationButtons.vue'
import ReleaseUpgradeLogDialog from './steps/shared/ReleaseUpgradeLogDialog.vue'
import UpgradeReleaseConfirmationDialog from './steps/shared/UpgradeReleaseConfirmationDialog.vue'

const Wizard = namespace('wizard')
const Resource = namespace('resource')
const LoadProgramUi = namespace('loadProgramUi')

@Component({
  components: {
    NavigationButtons,
    ReleaseUpgradeLogDialog,
    UpgradeReleaseConfirmationDialog,
  },
})
export default class EcoSetupTool extends Vue {
  @Resource.Getter public dictionary
  @Wizard.Action public goToStep
  @Wizard.Getter public wizardSteps
  @Wizard.Mutation public wentToStep
  @Wizard.Getter public getActiveStep
  @Wizard.Getter public isWaiting
  @Prop({type: Object, default: []}) public links
  @LoadProgramUi.Getter public deploymentFinished

  public INDEX_LAST_STEP = 8
  public SELECT_PLC_STEP = 1 

  public showWizard () {
    const index = this.wizardSteps.findIndex(step=> step.name === this.getActiveStep.name)
    return index > 0 // show it from rooms to programm load
  }
  
  public activeStep() {
    return this.getActiveStep.name
  }

  public onGoToStep(stepId: string) {
    const step = this.wizardSteps.find(s => s.name === stepId)
    this.goToStep({step, router: this.$router})
  }

  private mounted () {
    this.links = this.wizardSteps
      .slice(this.SELECT_PLC_STEP, this.INDEX_LAST_STEP) // don't show "selectPlc" in navigation
      .map(s => ({
        id: s.name,
        text: this.dictionary(s.stepText),
      }))
  }
}
</script>

<style lang="scss" scoped>
.navigation {
  width: 100%;
}
.v-footer {
  background: #080e19;
  height: 60px!important;
}
</style>