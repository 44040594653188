import axios from 'axios'
import moment from 'moment'
import qs from 'qs'
import AppDataStorageService from './appdatastorage.service'
import EnvironmentService from './environment.service'

declare global {
  interface Window {
    cordova?: any,
  }
}

const ACCESS_TOKEN_KEY = 'access_token'
const ACCESS_TOKEN_EXPIRATION = 'access_token_expiration_utc'
const REFRESH_TOKEN_KEY = 'refresh_token'
const REFRESH_TOKEN_EXPIRATION = 'refresh_token_expiration_utc'

const redirectToUrl = (redirectUrl: string, callbackUrl: string, redirectCallback: (url: string) => void) => {
  if (EnvironmentService.isRunningOnWeb) {
    window.location.replace(redirectUrl)
  } else { // android/iOS: use cordova InAppBrowser plugin
    // quirk, loadstop never fires on ios, loadstart is too early on android
    const loadEventName = EnvironmentService.isRunningOnAndroid ? 'loadstop' : 'loadstart'
    const inAppBrowserRef = window.cordova.InAppBrowser.open(
      redirectUrl, '_blank', 'location=no,toolbar=no,hidden=no,zoom=no,shouldPauseOnSuspend=yes')
    const onLoaded = event => {
      if (event.url.startsWith(callbackUrl)) {
        inAppBrowserRef.removeEventListener(loadEventName, onLoaded)
        inAppBrowserRef.hide()
        redirectCallback(event.url)
      }
    }
    inAppBrowserRef.addEventListener(loadEventName, onLoaded)
  }
}

export interface AuthenticationServiceOptions {
  clientId: string
  b2cTenant: string
  b2cDefaultPolicy: string
  b2cRecoveryPolicy: string
  b2cEditPolicy: string
  scopes: string[]
  redirectUrl: string
  redirectCallback: (url: string) => void
  useRefreshToken: boolean
  tokenEndpointProxyUrl?: string
}

export class AuthenticationService {
  private options: AuthenticationServiceOptions
  private logCallback: (message: string) => void

  private get authorizationEndpointUrl() {
    return `https://${this.options.b2cTenant}.b2clogin.com/${this.options.b2cTenant}.onmicrosoft.com/${this.options.b2cDefaultPolicy}/oauth2/v2.0/authorize`
  }

  private get tokenEndpointUrl() {
    // workaround for 24h refresh token maximum for b2c 'SPA' application registrations
    // the proxy is needed to circumwent CORS using use a 'mobile' application registration
    if (this.options.tokenEndpointProxyUrl) {
      return `${this.options.tokenEndpointProxyUrl}`
    }
    return `https://${this.options.b2cTenant}.b2clogin.com/${this.options.b2cTenant}.onmicrosoft.com/${this.options.b2cDefaultPolicy}/oauth2/v2.0/token`
  }

  private get logoutEndpointUrl() {
    return `https://${this.options.b2cTenant}.b2clogin.com/${this.options.b2cTenant}.onmicrosoft.com/${this.options.b2cDefaultPolicy}/oauth2/v2.0/logout`
  }

  private get editProfileEndpointUrl() {
    return `https://${this.options.b2cTenant}.b2clogin.com/${this.options.b2cTenant}.onmicrosoft.com/oauth2/v2.0/authorize`
  }

  private get recoveryEndpointUrl() {
    return `https://${this.options.b2cTenant}.b2clogin.com/${this.options.b2cTenant}.onmicrosoft.com/${this.options.b2cRecoveryPolicy}/oauth2/v2.0/authorize`
  }

  public init = (options: AuthenticationServiceOptions) => {
    this.options = options
  }

  public logout = async () => {
    this.log(`redirecting to logout`)
    await this.clearTokenInfo()
    const params = qs.stringify({
      'post_logout_redirect_uri': this.options.redirectUrl,
    })
    redirectToUrl(`${this.logoutEndpointUrl}?${params}`, this.options.redirectUrl, this.options.redirectCallback)
  }

  public editProfile = async () => {
    this.log(`redirecting to editProfile`)
    await this.clearTokenInfo()
    const params = qs.stringify({
      'p': this.options.b2cEditPolicy,
      'client_id': this.options.clientId,
      'nonce': 'defaultNonce',
      'redirect_uri': this.options.redirectUrl,
      'response_type': 'id_token',
      'scope': this.options.scopes.join(' '),
      'prompt': 'login',
    })
    
    redirectToUrl(`${this.editProfileEndpointUrl}?${params}`, this.options.redirectUrl, this.options.redirectCallback)
  }

  public handleRedirectUrl = async (url: string) => {
    this.log(`parsing url: ${url}`)
    const urlPartAfterHash = url.split('#')[1]
    const urlQueryString = urlPartAfterHash && urlPartAfterHash.replace('/', '') || ''
    const { code, error, error_description } = qs.parse(urlQueryString)

    // errors from b2c redirect
    if (error && error_description) {
      this.log(`error ${error}, ${error_description}`)
      if ((error_description as string).startsWith('AADB2C90118')) { // reset password clicked
        return this.resetPasswordRedirect()
      } else if ((error_description as string).startsWith('AADB2C90091')) { // reset password or edit profile cancelled
        this.log(`cancelled, continuing`)
      } else {
        this.log(`unknown error`)
        return await this.logout()
      }
    }

    // authorization code from b2c redirect
    if (code) {
      try {
        await this.exchangeCode(code as string)
        return
      } catch (error) {
        this.log(`error while exchanging code, logging out: ${error}`)
        return this.logout()
      }
    }

    // refresh the token
    const hasRefreshToken = await this.hasRefreshToken()
    if (this.options.useRefreshToken && hasRefreshToken) {
      try {
        await this.refreshToken()
      } catch (error) {
        this.log(`error while refreshing token, continuing: ${error}`)
      }
    }

    // no valid token
    const hasAccessToken = await this.hasAccessToken()
    const isAccessTokenExpired = await this.isAccessTokenExpired()
    if (!hasAccessToken || isAccessTokenExpired) {
      this.log(`no or expired token, clearing token info`)
      await this.clearTokenInfo()
      this.loginRedirect()
    }
  }

  public refreshToken = async () => {
    const hasRefreshToken = await this.hasRefreshToken()
    if (!this.options.useRefreshToken || !hasRefreshToken) {
      this.log(`refresh token usage not configured or no refresh token available`)
      return
    }
    this.log(`refreshing token`)
    const refreshToken = await AppDataStorageService.get(REFRESH_TOKEN_KEY)
    const response = await axios.create().post(this.tokenEndpointUrl, qs.stringify({
      'client_id': this.options.clientId,
      'scope': this.options.scopes.join(' '),
      'redirect_uri': this.options.redirectUrl,
      'refresh_token': refreshToken,
      'grant_type': 'refresh_token',
    }))
    await this.storeTokenInfo(response.data)
    this.log(`refreshing token done`)
  }

  public clear = async () => {
    this.log(`clearing token info`)
    await this.clearTokenInfo()
  }

  public setLogCallback = (callback: (message: string) => void) => {
    this.logCallback = callback
  }

  public loginRedirect = () => {
    this.log(`redirecting to login`)
    const params = qs.stringify({
      'client_id': this.options.clientId,
      'response_type': 'code',
      'redirect_uri': this.options.redirectUrl,
      'scope': this.options.scopes.join(' '),
      'response_mode': 'fragment',
      'prompt': 'login',
    })
    redirectToUrl(`${this.authorizationEndpointUrl}?${params}`, this.options.redirectUrl, this.options.redirectCallback)
  }

  private resetPasswordRedirect = () => {
    this.log(`redirecting to reset password`)
    const params = qs.stringify({
      'client_id': this.options.clientId,
      'response_type': 'code',
      'redirect_uri': this.options.redirectUrl,
      'scope': this.options.scopes.join(' '),
      'response_mode': 'fragment',
      'prompt': 'login',
    })
    redirectToUrl(`${this.recoveryEndpointUrl}?${params}`, this.options.redirectUrl, this.options.redirectCallback)
  }

  private exchangeCode = async (code: string): Promise<void> => {
    this.log(`exchanging code`)
    const response = await axios.create().post(this.tokenEndpointUrl, qs.stringify({
      'client_id': this.options.clientId,
      'scope': this.options.scopes.join(' '),
      'redirect_uri': this.options.redirectUrl,
      'code': code,
      'grant_type': 'authorization_code',
    }))
    await this.storeTokenInfo(response.data)
    this.log(`exchanging code done`)
  }

  private storeTokenInfo = async (b2cResponse: any): Promise<void> => {
    this.log(`storing token info`)
    await AppDataStorageService.set(ACCESS_TOKEN_KEY, b2cResponse.access_token)
    await AppDataStorageService.set(REFRESH_TOKEN_KEY, b2cResponse.refresh_token)
    await AppDataStorageService.set(ACCESS_TOKEN_EXPIRATION, moment().add(b2cResponse.expires_in, 's').toISOString())
    await AppDataStorageService.set(REFRESH_TOKEN_EXPIRATION, moment().add(b2cResponse.refresh_token_expires_in, 's').toISOString())
    this.log(`storing token info done`)
  }

  private clearTokenInfo = async (): Promise<void> => {
    this.log(`clearing token info`)
    await AppDataStorageService.delete(ACCESS_TOKEN_KEY)
    await AppDataStorageService.delete(REFRESH_TOKEN_KEY)
    await AppDataStorageService.delete(ACCESS_TOKEN_EXPIRATION)
    await AppDataStorageService.delete(REFRESH_TOKEN_EXPIRATION)
    this.log(`clearing token info done`)
  }

  private hasAccessToken = async (): Promise<boolean> => {
    return !!(await AppDataStorageService.get(ACCESS_TOKEN_KEY))
  }

  private isAccessTokenExpired = async (): Promise<boolean> => {
    if (!await this.hasAccessToken()) {
      return false
    }
    const accessTokenExpiration = await AppDataStorageService.get(ACCESS_TOKEN_EXPIRATION)
    if (!accessTokenExpiration) {
      return false
    }
    return moment.utc(accessTokenExpiration).isBefore(moment.utc())
  }

  private hasRefreshToken = async (): Promise<boolean> => {
    return !!(await AppDataStorageService.get(REFRESH_TOKEN_KEY))
  }

  private log = (message: string) => {
    if (this.logCallback) {
      this.logCallback(`AuthenticationService: ${message}`)
    }
  }
}

export default new AuthenticationService()
