import { vuexModule as alarmLogUiModule } from './alarmLogUi'
import { vuexModule as alarmUiModule } from './alarmUi'
import { vuexModule as appModule } from './app'
import { vuexModule as auditLogUiModule } from './auditLogUi'
import { vuexModule as authorizationUiModule } from './authorizationUi'
import { vuexModule as consumptionUiModule } from './consumptionUi'
import { vuexModule as deviceUiModule } from './deviceUi'
import { vuexModule as functionBlockUiModule } from './functionBlockUi'
import { vuexModule as functionDefinitionEditorUiModule } from './functionDefinitionEditorUi'
import { vuexModule as loadProgramUiModule } from './loadProgramUi'
import { vuexModule as logToolUiModule } from './logToolUi'
import { vuexModule as manageAlarmsUi } from './manageAlarmsUi'
import { vuexModule as mappingUiModule } from './mappingUi'
import { vuexModule as noteUiModule } from './noteUi'
import { vuexModule as parametrizationUiModule } from './parametrizationUi'
import { vuexModule as plcConnectionHistoryUiModule } from './plcConnectionHistoryUi'
import { vuexModule as projectManagementUi } from './projectManagementUi'
import { vuexModule as releaseUiModule } from './releaseUi'
import { vuexModule as roomUiModule } from './roomUi'
import { vuexModule as selectPlcUiModule } from './selectPlcUi'
import { vuexModule as solutionHardwareUiModule } from './solutionHardwareUi'
import { vuexModule as wizardModule } from './wizard'

export const modules = () =>  ({
  ...alarmLogUiModule,
  ...alarmUiModule,
  ...appModule,
  ...auditLogUiModule,
  ...authorizationUiModule,
  ...consumptionUiModule,
  ...deviceUiModule,
  ...functionBlockUiModule,
  ...functionDefinitionEditorUiModule,
  ...loadProgramUiModule,
  ...logToolUiModule,
  ...noteUiModule,
  ...manageAlarmsUi,
  ...mappingUiModule,
  ...parametrizationUiModule,
  ...plcConnectionHistoryUiModule,
  ...projectManagementUi,
  ...releaseUiModule,
  ...roomUiModule,
  ...selectPlcUiModule,
  ...solutionHardwareUiModule,
  ...wizardModule,
})
