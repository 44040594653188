import qs from 'qs'
import ApiService from '../../services/api.service'
import { ChartCollectionModel, ChartDataModel } from '../models'

export default {
  async loadCharts(projectId: string, language: string): Promise<ChartCollectionModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().chartQueryApi}/ChartsV3/${projectId}/charts?resourceLanguage=${language}`)
    return response.data.value
  },
  async loadChartData(projectId: string, chartId: string, period: string, dateFrom: string, dateTo: string, nodeId: string | null, scale: boolean, language: string): Promise<ChartDataModel> {
    const params = qs.stringify({
      ...(nodeId && { energyManagementTopologyNodeId: nodeId }),
      ...(scale && { scaleToAuthorized: scale }),
      resourceLanguage: language,
    })
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().chartQueryApi}/ChartsV3/${projectId}/chartId/${chartId}/period/${period}/dateFrom/${dateFrom}/dateTo/${dateTo}?${params}`)
    return response.data.value
  },
  async loadReport(projectId: string, chartId: string, dateFrom: string, dateTo: string, nodeId: string | null, scale: boolean, language: string): Promise<any> {
    const params = qs.stringify({
      ...(nodeId && { energyManagementTopologyNodeId: nodeId }),
      ...(scale && { scaleToAuthorized: scale }),
      resourceLanguage: language,
    })
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().chartQueryApi}/ChartsV3/${projectId}/chartId/${chartId}/dateFrom/${dateFrom}/dateTo/${dateTo}?${params}`, {
      responseType: 'blob',
    })
    return response.data
  },
}
