import { Permission } from './models'

function includesAny(input: string, stringsToMatch: string[]): boolean {
  for (const stringToMatch of stringsToMatch) {
    if (input.includes(stringToMatch)) {
      return true
    }
  }
  return false
}

function joinRolesWithFallback(roles: string[], delimiter: string, fallbackRole: string): string {
  return roles.length > 0 ? roles.join(delimiter) : fallbackRole
}

export const permissionConverter = {
  stringToPermissions(permissionString: string): Permission[] {
    const permissions = new Set<Permission>()
    if (includesAny(permissionString, ['Read', 'FullAccess', 'Export'])) {
      permissions.add(Permission.READ)
    }
    if (includesAny(permissionString, ['Edit', 'Write', 'FullAccess'])) {
      permissions.add(Permission.EDIT)
    }
    if (includesAny(permissionString, ['Delete', 'FullAccess'])) {
      permissions.add(Permission.DELETE)
    }
    if (includesAny(permissionString, ['Execute', 'FullAccess'])) {
      permissions.add(Permission.EXECUTE)
    }
    if (includesAny(permissionString, ['Authorize', 'FullAccess'])) {
      permissions.add(Permission.AUTHORIZE)
    }
    if (includesAny(permissionString, ['SystemNotification', 'FullSystemNotification']) && !includesAny(permissionString, ['SystemNotificationEmail'])) {
      permissions.add(Permission.SYSTEM_NOTIFICATION)
    }
    if (includesAny(permissionString, ['SystemNotificationEmail', 'FullSystemNotification'])) {
      permissions.add(Permission.SYSTEM_NOTIFICATION_EMAIL)
    }
    if (includesAny(permissionString, ['CustomNotification', 'FullCustomNotification']) && !includesAny(permissionString, ['CustomNotificationEmail'])) {
      permissions.add(Permission.CUSTOM_NOTIFICATION)
    }
    if (includesAny(permissionString, ['CustomNotificationEmail', 'FullCustomNotification'])) {
      permissions.add(Permission.CUSTOM_NOTIFICATION_EMAIL)
    }
    return Array.from(permissions)
  },
  projectPermissionsToRoleString(permissions: Permission[]): string {
    const roles: string[] = []
    if (permissions.includes(Permission.READ)) {
      roles.push('Read')
    }
    if (permissions.includes(Permission.EDIT)) {
      roles.push('Write')
    }
    if (permissions.includes(Permission.DELETE)) {
      roles.push('Delete')
    }
    if (permissions.includes(Permission.AUTHORIZE)) {
      roles.push('Authorize')
    }
    return joinRolesWithFallback(roles, ',', 'Unauthorized')
  },
  consumptionPermissionsToRoleString(permissions: Permission[]): string {
    const roles: string[] = []
    if (permissions.includes(Permission.READ)) {
      roles.push('Export')
    }
    if (permissions.includes(Permission.EDIT)) {
      roles.push('Edit')
    }
    if (permissions.includes(Permission.AUTHORIZE)) {
      roles.push('Authorize')
    }
    return joinRolesWithFallback(roles, ',', 'Unauthorize')
  },
  plcPermissionsToRoleString(permissions: Permission[]): string {
    const roles: string[] = []
    if (permissions.includes(Permission.READ)) {
      roles.push('Read')
    }
    if (permissions.includes(Permission.EDIT)) {
      roles.push('Write')
    }
    if (permissions.includes(Permission.EXECUTE)) {
      roles.push('Execute')
    }
    if (permissions.includes(Permission.AUTHORIZE)) {
      roles.push('Authorize')
    }
    if (permissions.includes(Permission.SYSTEM_NOTIFICATION)) {
      roles.push('SystemNotification')
    }
    if (permissions.includes(Permission.SYSTEM_NOTIFICATION_EMAIL)) {
      roles.push('SystemNotificationEmail')
    }
    return joinRolesWithFallback(roles, ',', 'Unauthorized')
  },
  devicePermissionsToRoleString(permissions: Permission[]): string {
    const roles: string[] = []
    if (permissions.includes(Permission.READ)) {
      roles.push('Read')
    }
    if (permissions.includes(Permission.EXECUTE)) {
      roles.push('Execute')
    }
    return joinRolesWithFallback(roles, ',', 'Unauthorized')
  },
  alarmDefinitionPermissionsToRoleString(permissions: Permission[]): string {
    const roles: string[] = []
    if (permissions.includes(Permission.CUSTOM_NOTIFICATION)) {
      roles.push('CustomNotification')
    }
    if (permissions.includes(Permission.CUSTOM_NOTIFICATION_EMAIL)) {
      roles.push('CustomNotificationEmail')
    }
    return joinRolesWithFallback(roles, ',', 'Unauthorized')
  },
}
