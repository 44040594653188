<template>
  <card header="Controls">
    <template v-slot:actions>
      <card-action
        icon="add"
        tooltip="Create new control definition"
        @click="onCreateControlDefinition"
      />
      <card-action
        icon="check_box"
        tooltip="Select all"
        @click="onSelectAll"
      />
      <card-action
        icon="check_box_outline_blank"
        tooltip="Unselect all"
        @click="onUnselectAll"
      />
    </template>
    <v-layout column>
      <draggable v-model="draggableList"
        class="draggable-list"
        animation="200"
        ghost-class="dragdrop-preview"
        chosen-class="dragdrop-chosen"
        drag-class="dragdrop-dragging"
      >
        <v-flex xs12
          v-for="controlDefinition in controlDefinitions"
          :key="controlDefinition.id"
        >
          <v-layout row align-center>
            <v-flex shrink v-if="isControlDefinitionSelected(controlDefinition.id)">
              <div class="action">
                <v-tooltip bottom>
                  <v-btn slot="activator" icon ripple @click="onControlDefinitionToggled(controlDefinition.id, true)">
                      <v-icon class="actionIcon">check_box</v-icon>
                  </v-btn>
                  <span>Unselect</span>
                </v-tooltip>
              </div>
            </v-flex>
            <v-flex shrink v-else>
              <div class="action">
                <v-tooltip bottom>
                  <v-btn slot="activator" icon ripple @click="onControlDefinitionToggled(controlDefinition.id, false)">
                      <v-icon class="actionIcon">check_box_outline_blank</v-icon>
                  </v-btn>
                  <span>Select</span>
                </v-tooltip>
              </div>
            </v-flex>
            <v-flex shrink>
              <div class="action last">
                <v-tooltip bottom>
                  <v-btn slot="activator" icon ripple @click="onEditControlDefinition(controlDefinition.id)">
                      <v-icon class="actionIcon">edit</v-icon>
                  </v-btn>
                  <span>Edit</span>
                </v-tooltip>
              </div>
            </v-flex>
            <v-flex shrink>
              <div class="action">
                <v-tooltip bottom>
                  <description-popover slot="activator"
                    :header="(controlDefinition.nameResourceId || controlDefinition.attributes.label) | translate"
                    :content="controlDefinitionPopoverContent(controlDefinition.id)"
                  />
                  <span>More info</span>
                </v-tooltip>
              </div>
            </v-flex>
            <v-flex>
              <div class="control" :class="{ selected: isControlDefinitionSelected(controlDefinition.id), obsolete: controlDefinition.obsolete }">
                <v-layout column>
                  <v-flex xs12>
                    <div class="preview">
                      <preview-control :control-definition="controlDefinition" />
                    </div>
                  </v-flex>
                  <v-layout row v-if="controlDefinition.type === 'NumericInput'">
                    <div class="endpoint-title">Input range:</div>
                    <div>{{`[${controlDefinition.attributes.minValue} - ${controlDefinition.attributes.maxValue}]` }}</div>
                  </v-layout>
                  <v-layout column v-if="controlDefinitionEndpoints(controlDefinition.id).length">
                    <v-flex xs12>
                      <div class="endpoint-title">
                        Used endpoints
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <div 
                        v-for="endpoint in controlDefinitionEndpoints(controlDefinition.id)"
                        :key="endpoint"
                        class="endpoint"
                        :title="endpoint"
                      >
                        {{endpoint}}
                        <base-material-icon v-if="isSubscribedControlDefinitionEndpoint(controlDefinition.id, endpoint)" iconName="bolt" :height="16"/>
                        <base-material-icon v-if="isPolledControlDefinitionEndpoint(controlDefinition.id, endpoint)" iconName="schedule" :height="16"/>
                      </div>
                    </v-flex>
                  </v-layout>
                  <v-layout row v-if="sourceLanguage === 'CSharp'">
                      <v-chip small color="green" v-for="tag in controlDefinition.attributes.tags" :key="tag">{{ tag }}</v-chip>
                  </v-layout>
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </draggable>
    </v-layout>
    <control-definition-details-dialog
      :show="showControlDefinitionDialog"
      :is-create="showCreateDialog"
      @confirmed="onConfirmDetailsDialog"
      @closed="closeDetailsDialog"
    />
    <control-definition-details-dialog-v-2
      :show="showControlDefinitionDialogV2"
      :is-create="showCreateDialog"
      @confirmed="onConfirmDetailsDialog"
      @closed="closeDetailsDialog"
    />
  </card>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Card from '../../ui/Card.vue'
import CardAction from '../../ui/CardAction.vue'
import ControlDefinitionDetailsDialog from './ControlDefinitionDetailsDialog.vue'
import ControlDefinitionDetailsDialogV2 from './ControlDefinitionDetailsDialogV2.vue'
import PreviewControl from './PreviewControl.vue'

import Draggable from 'vuedraggable'

import { ControlDefinitionViewModel, ControlDefinitionViewModelV2, FunctionDefinitionViewModel } from '../../../store/modules/functionDefinitionEditorUi/models'

import { ArchitectureType } from '../../../../../eco-domain-store-modules/src/common'

import { SourceLanguage } from '../../../../../eco-domain-store-modules/src/common'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')

@Component({
  components: {
    Card,
    CardAction,
    ControlDefinitionDetailsDialog,
    ControlDefinitionDetailsDialogV2,
    Draggable,
    PreviewControl,
  },
})
export default class LinkControlsDetails extends Vue {
  @FunctionDefinitionEditorUi.Action public createControlDefinition: () => Promise<void>
  @FunctionDefinitionEditorUi.Action public editControlDefinition: (id: string) => Promise<void>
  @FunctionDefinitionEditorUi.Action public storeControlDefinition: () => Promise<void>
  @FunctionDefinitionEditorUi.Getter public functionDefinition: FunctionDefinitionViewModel
  @FunctionDefinitionEditorUi.Getter public controlDefinitions: Array<ControlDefinitionViewModel | ControlDefinitionViewModelV2>
  @FunctionDefinitionEditorUi.Getter public architectureType: ArchitectureType
  @FunctionDefinitionEditorUi.Getter public controlDefinitionProperties: (kind: string, type: string) => string[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionEndpointProperties: (kind: string, type: string) => string[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionInputsAndOutputs: string[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionDefaultValues: (id: string) => any
  @FunctionDefinitionEditorUi.Getter public sourceLanguage: SourceLanguage
  @FunctionDefinitionEditorUi.Mutation public linkControlDefinition: (id: string) => void
  @FunctionDefinitionEditorUi.Mutation public unlinkControlDefinition: (id: string) => void
  @FunctionDefinitionEditorUi.Mutation public setControlDefinitions: (cds: Array<ControlDefinitionViewModel | ControlDefinitionViewModelV2>) => void
  @FunctionDefinitionEditorUi.Mutation public setLinkedControlDefinitionIds: (ids: string[]) => void

  public showCreateDialog: boolean = false
  public showEditDialog: boolean = false

  public get draggableList(): Array<ControlDefinitionViewModel | ControlDefinitionViewModelV2> {
    return this.controlDefinitions
  }

  public set draggableList(value: Array<ControlDefinitionViewModel | ControlDefinitionViewModelV2>) {
    // updated control definitions list
    this.setControlDefinitions(value)

    // update function definition
    this.updateOrderOfControlDefinitions()
  }

  public get showControlDefinitionDialog() {
    return this.architectureType === ArchitectureType.Classic && (this.showCreateDialog || this.showEditDialog)
  }

  public get showControlDefinitionDialogV2() {
    return this.architectureType === ArchitectureType.ObjectOriented && (this.showCreateDialog || this.showEditDialog)
  }

  public onSelectAll() {
    this.controlDefinitions.forEach(cd =>  this.linkControlDefinition(cd.id))
  }

  public onUnselectAll() {
    this.functionDefinition.controlDefinitionMappings.forEach(mapping =>  this.unlinkControlDefinition(mapping.controlDefinitionId))
  }

  public isControlDefinitionSelected(id: string) {
    return this.functionDefinition.controlDefinitionMappings.some(_ => _.controlDefinitionId === id)
  }

  public onControlDefinitionToggled(id: string, value: boolean) {
    if (!value) {
      this.linkControlDefinition(id)
    } else {
      this.unlinkControlDefinition(id)
    }
    this.updateOrderOfControlDefinitions()
  }

  public updateOrderOfControlDefinitions() {
    const allSortedControlDefinitionIds = this.controlDefinitions.map(cd => cd.id)
    const linkedControlDefinitionIds = this.functionDefinition.controlDefinitionMappings.map(mapping => mapping.controlDefinitionId)
    const sortedLinkedControlDefinitions = allSortedControlDefinitionIds.filter(id => linkedControlDefinitionIds.includes(id))
    this.setLinkedControlDefinitionIds(sortedLinkedControlDefinitions)
  }

  public controlDefinitionPopoverContent(id: string) {
    let info: any = {}
    if (this.architectureType === ArchitectureType.ObjectOriented) {
      const controlDefinition = this.controlDefinitions.find(_ => _.id === id)! as ControlDefinitionViewModelV2
      info = controlDefinition
    } else {
      const controlDefinition = this.controlDefinitions.find(_ => _.id === id)! as ControlDefinitionViewModel
      const defaultValue = () => {
        const value = this.controlDefinitionDefaultValues(id).defaultValue
        return value !== undefined ? JSON.stringify(value) : 'not set'
      }
      info = {
        ...info,
        id: controlDefinition.id,
        kind: controlDefinition.kind,
      }
      this.controlDefinitionProperties(controlDefinition.kind, controlDefinition.type)
        .forEach(property => info[property] = controlDefinition[property])
      this.controlDefinitionEndpointProperties(controlDefinition.kind, controlDefinition.type)
        .forEach(property => info[property] = controlDefinition[property])
      info = {
        ...info,
        defaultValue: defaultValue(),
      }
    }
    return `<pre>${JSON.stringify(info, null, 2)}</pre>`
  }

  public controlDefinitionEndpoints(id: string) {
    if (this.architectureType === ArchitectureType.ObjectOriented) {
      const controlDefinition = this.controlDefinitions.find(_ => _.id === id)! as ControlDefinitionViewModelV2
      const commandEndpoints = Object.keys(controlDefinition.commands).map(key => controlDefinition.commands[key].endpoint).filter(e => e)
      const stateEndpoints = Object.keys(controlDefinition.states).map(key => controlDefinition.states[key].endpoint).filter(e => e)
      return Array.from(new Set([...commandEndpoints, ...stateEndpoints]))
    } else {
      const controlDefinition = this.controlDefinitions.find(_ => _.id === id)! as ControlDefinitionViewModel
      const allEndpoints = this.controlDefinitionInputsAndOutputs
      const endpoints = this.controlDefinitionEndpointProperties(controlDefinition.kind, controlDefinition.type).map(property => {
        return { key: property, value: controlDefinition[property] }
      })
      const usedEndpointsOfControl = endpoints.filter(ep => allEndpoints.includes(ep.value))
      return Array.from(new Set(usedEndpointsOfControl.map(e => e.value)))
    }
  }

  public isSubscribedControlDefinitionEndpoint(id: string, endpoint: string) {
    if (this.architectureType === ArchitectureType.ObjectOriented) {
      const controlDefinition = this.controlDefinitions.find(_ => _.id === id) as ControlDefinitionViewModelV2
      return Object.keys(controlDefinition.states)
        .map(key => controlDefinition.states[key])
        .some(e => e.endpoint === endpoint && e.toSubscribe) === true
    }
    return false
  }

  public isPolledControlDefinitionEndpoint(id: string, endpoint: string) {
    if (this.architectureType === ArchitectureType.ObjectOriented) {
      const controlDefinition = this.controlDefinitions.find(_ => _.id === id) as ControlDefinitionViewModelV2
      return Object.keys(controlDefinition.states)
        .map(key => controlDefinition.states[key])
        .some(e => e.endpoint === endpoint && !e.toSubscribe) === true
    }
    return false
  }

  public onCreateControlDefinition() {
    this.createControlDefinition().then(() => {
      this.showCreateDialog = true
    })
  }

  public onEditControlDefinition(id: string) {
    this.editControlDefinition(id).then(() => {
      this.showEditDialog = true
    })
  }

  public onConfirmDetailsDialog(id: string) {
    this.storeControlDefinition().then(() => {
      // link created control definition immediately
      if (this.showCreateDialog) {
        this.linkControlDefinition(id)
        this.updateOrderOfControlDefinitions()
      }

      this.closeDetailsDialog()
    })
  }

  public closeDetailsDialog() {
    this.showCreateDialog = false
    this.showEditDialog = false
  }
}
</script>
<style lang="scss" scoped>
.draggable-list {
  margin-left: -15px;
}
.dragdrop-preview, .dragdrop-chosen {
  background: #1a3856;
  opacity: 0.7;
}
.dragdrop-dragging {
  opacity: 0;
}

.popUpButton {
  width: 25px;
}
.header {
  text-align: center
}
h2, h3, li {
  color: white;
}
.controlDefinition {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: 10px;
}
.control {
  margin: 5px 0px 5px 10px;  
  border-style: solid;
  border-color: $fore-color-disabled;
  border-radius: 5px;
  &.selected {
    border-color: $fore-color-primary;
  }
  &.obsolete {
    opacity: 0.5;
  }
}
.preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 10px;
}
.controlDefinitionWrapper {
  width: 100%;
}
.endpoint-title {
  font-weight: bold;
}
.endpoint, .endpoint-title {
  margin: 0px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.action {
  max-width: 30px;
  min-width: 30px;
  &.last {
    min-width: 40px;
  }
}
</style>