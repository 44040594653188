<template>
  <v-dialog
    :value="show"
    :max-width="width + 'px'"
    persistent
    content-class="modal-dialog"
    :fullscreen="fullscreen"
  >
    <v-card>
      <v-card-title v-if="!minimal">
        <span class="headline">{{header}}</span>
        <div v-if="closingX" class="closing-x" @click="xPressed"> X </div>
      </v-card-title>
      <v-card-text>
        <v-layout wrap>
          <slot/>
        </v-layout>
      </v-card-text>
      <v-card-actions v-if="!minimal" class="baseline-buttons">
        <v-spacer></v-spacer>
        <base-text-button v-if="button1Text" class="baseline" :enabled="!button1Disabled" @buttonClicked="emitButton1">{{button1Text}}</base-text-button>
        <base-text-button v-if="button2Text" class="baseline" :enabled="!button2Disabled"@buttonClicked="emitButton2">{{button2Text}}</base-text-button>
        <base-text-button v-if="button3Text" class="baseline" :enabled="!button3Disabled"@buttonClicked="emitButton3">{{button3Text}}</base-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'

import BaseTextButton from '../base/BaseTextButton.vue'

export default Vue.extend({
  name: 'ModalDialog',
  components: {
    BaseTextButton,
  },
  props: {
    /** 
     * Flag to control the dialogs visibility from outside
     */
    show: {
      type: Boolean,
      default: false,
    },
    /** 
     * The header text
     */
    header: {
      type: String,
      default: '',
    },
    /** 
     * The text for button 1 (left)
     */
    button1Text: {
      type: String,
      required: false,
    },
    /** 
     * optional, text for button 2 (middle)
     */
    button2Text: { 
      type: String,
      required: false,
    },
    /** 
     * opetional, text for button 3 (right)
     */
    button3Text: { 
      type: String,
      required: false,
    },
    /** 
     * The text for button 1 (left)
     */
    button1Disabled: {
      type: Boolean,
      required: false,
    },
    /** 
     * optional, text for button 2 (middle)
     */
    button2Disabled: {
      type: Boolean,
      required: false,
    },
    /** 
     * opetional, text for button 3 (right)
     */
    button3Disabled: {
      type: Boolean,
      required: false,
    },
    /** 
     * optional, custom width of the dialog
     */
    width: {
      type: Number,
      default: 600,
    },
    /**
     * shows a X on top right, which closes the dialog
     */
    closingX: {
      type: Boolean,
      default: false,
    },
    /** 
     * no header, no actions, no padding
     */
    minimal: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitButton1() {
      /**
       * button 1 was pressed
       * @event button1Pressed
       */
      this.$emit('button1Pressed')
    },
    emitButton2() {
      /**
       * button 2 was pressed
       * @event button2Pressed
       */
      this.$emit('button2Pressed')
    },
    emitButton3() {
      /**
       * button 3 was pressed
       * @event button3Pressed
       */
      this.$emit('button3Pressed')
    },
    xPressed() {
      /**
       * button 3 was pressed
       * @event button3Pressed
       */
      this.$emit('xPressed')
    },
  },
})
</script>
<style lang="scss" type="text/scss" scoped>
.modal-dialog {
  .v-sheet {
    color: $fore-color-primary;
    background: $bg-primary;
  }
  .minimal {
    padding: 0;
  }
}
.v-dialog--fullscreen {
  .v-sheet {
    background: radial-gradient(#1a3856, #080e19);  
  }
}
.v-btn {
  border: solid 1px white;   
}
.headline {
  width: 95%;
}
.baseline {
  margin-bottom: 10px;
}
.baseline-buttons {
  padding-right: 15px;
}
.closing-x {
  font-weight: 400;
  font-size: 24px;
  width: 5%;
  text-align: right;
  cursor: pointer;
  letter-spacing: normal!important;
  font-family: Roboto,sans-serif!important;
}
</style>

<docs>
A modal dialog with a slot to place content

Usage example
```js
new Vue({
  data: function() {
    return {
      show: false,
    }
  },
  methods: {
    openDialog() {
      this.show = true
    },
    onCanceled() {
      console.log('canceled')
      this.show = false
    },
    onConfirmed() {
      console.log('confirmed')
      this.show = false
    },
    onXClosed() {
      console.log('x clicked')
      this.show = false
    },
  },
  template: `<v-app style="height:60px;background:transparent"><div style="display:flex">
    <base-text-button @buttonClicked="openDialog">Open dialog</base-text-button>
    <modal-dialog 
      :show="show"
      header="Modal dialog header"
      button1-text="Confirm"
      button2-text="Cancel"
      @button1Pressed="onCanceled"
      @button2Pressed="onConfirmed"
    >
      <div>
        <p>Slot content goes here</p>
        <p>Put there whatever you want</p>
      </div>
    </modal-dialog>
  </div></v-app>`
})
```
</docs>