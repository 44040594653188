export interface UserSettingsModel {
  featureSwitches: string[]
  emailMfaEnabled: boolean | null
}

export interface MfaConfigurationModel {
  isEmailMfaEnabled: boolean | null
}

// list of existing feature switches
export enum FeatureSwitches {
  plcConfigurationClassicArchitecture = 'plcconfigurationclassicarchitecture',
  downloadProjectFilesFromUranus = 'downloadprojectfilesfromuranus',
  authToolSuperPowers = 'authtoolsuperpowers',
  cSharpFunctionBlocks = 'csharpfunctionblocks'
}
