export interface UserAgreementViewModel {
    type: string
    version: string
    accepted: boolean
    name: string
    content: string
}

export interface ReleaseNotesViewModel {
    version: string
    releaseNotes: string
}

export const NavigationDrawerMinimizedStorageKey = 'navigationDrawerMinimized'
