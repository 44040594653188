<template>
  <div class="moduleWithActions">
    <div class="actions">
      <v-tooltip bottom>
        <v-btn @click="onDelete" slot="activator" icon flat small class="actionButton">
          <v-icon>delete_outline</v-icon>
        </v-btn>
        <span>{{'common.button.delete'| translate}}</span>
      </v-tooltip>
      <description-popover
        class="popUpButton"
        :header="module.name"
        :content="`Version: ${module.version}<br>${module.description}`"
      />
      <v-tooltip bottom v-if="module.obsolete">
        <v-btn slot="activator" icon flat small class="actionButton">
          <v-icon>warning</v-icon>
        </v-btn>
        <span>{{'common.button.obsolete'| translate}}</span>
      </v-tooltip>
    </div>
    <div class="hexSwitch">
      <hex-switch
        @IpChanged="updateSolutionHardwareInstance({id: module.id, lastOctet: $event})" 
        :last-octet="module.lastOctet" />
    </div>
    <v-tooltip bottom>
      <img
        slot="activator"
        class="module"
        :src="image(module.imageResourceId)"
        :alt="module.imageResourceId"
        :data-eco-image-resource-id="module.imageResourceId"
      />
      <span>{{ module.name }}</span>
    </v-tooltip>
    <confirm-dialog
      :show="showDeleteConfirmationDialog"
      :header="'common.button.delete' | translate"
      :text="'error.module.has.mappings' | translate"
      :confirm-button-text="'common.button.delete' | translate"
      :cancel-button-text="'common.button.cancel' | translate"
      :disabled="isInteracted"
      @confirmed="onDeleteConfirmed"
      @cancelled="showDeleteConfirmationDialog = false"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import HexSwitch from './HexSwitch.vue'

import { SolutionHardwareInstanceViewModel } from '../../../../../store/modules/solutionHardwareUi/models'

const Resource = namespace('resource')
const SolutionHardwareUi = namespace('solutionHardwareUi')

@Component({
  components: {
    HexSwitch,
  },
})
export default class Module extends Vue {
  @Prop() public module: SolutionHardwareInstanceViewModel
  @Resource.Getter public image
  @SolutionHardwareUi.Getter public isInteracted: boolean
  @SolutionHardwareUi.Getter public isMappedSolutionHardwareInstance: (id: string) => boolean
  @SolutionHardwareUi.Action public updateSolutionHardwareInstance: (payload: {id: string, lastOctet: number}) => Promise<void>
  @SolutionHardwareUi.Action public deleteSolutionHardwareInstance: (id: string) => Promise<void>
  
  public showDeleteConfirmationDialog = false

  public onDelete() {
    if (!this.isMappedSolutionHardwareInstance(this.module.id)) {
      this.deleteSolutionHardwareInstance(this.module.id)
    } else {
      this.showDeleteConfirmationDialog = true
    }
  }

  public onDeleteConfirmed() {
    this.deleteSolutionHardwareInstance(this.module.id)
    this.showDeleteConfirmationDialog = false
  }
}
</script>

<style lang="scss" scoped>
.moduleWithActions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 20px 20px 0px 0px;
}
.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.actionButton {
  padding: 0px;
  margin: 4px;
}
.hexSwitch{
  max-width: 150px;
}
img.module {
  height: 315px;
  cursor: grab;
}
</style>