<template>
  <card header="HW Endpoints">
    <template v-slot:actions>
    </template>
    <v-layout column>
      <!-- subtitle -->
      <v-layout row wrap>
        <v-flex xs6>
          <h3>Inputs ({{hardwareMappableInputResources.length}})</h3>
        </v-flex>
        <v-flex xs6>
          <h3>Outputs ({{hardwareMappableOutputResources.length}})</h3>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="cardbody">
        <!-- left part  -->
        <v-flex xs6>
          <v-layout row wrap
            v-for="(endpoint, index) in hardwareMappableInputResources"
            :key="`${endpointendpointPath}-${index}`"
          >
            <v-flex xs6>
              <form-field :text="`${endpoint.endpointPath} name`">
                <string-resource-select
                  :options="nameResourceOptions"
                  :selected-value="endpoint.nameResourceId"
                  :category="resourceCategory"
                  prefix="HardwareMappableEndpointDefinitions_"
                  postfix="_Name"
                  :default-value="endpoint.endpointPath"
                  @selected="onNameResourceIdSelected(endpoint.endpointPath, $event)"
                  @created="onNameResourceCreated(endpoint.endpointPath, $event)"
                />
              </form-field>
            </v-flex>
            <v-flex xs6>
              <form-field :text="`${endpoint.endpointPath} description`">
                <string-resource-select
                  :options="tooltipResourceOptions"
                  :selected-value="endpoint.descriptionResourceId"
                  :category="resourceCategory"
                  prefix="HardwareMappableEndpointDefinitions_"
                  postfix="_Description"
                  :default-value="endpoint.endpointPath"
                  @selected="onDescriptionResourceIdSelected(endpoint.endpointPath, $event)"
                  @created="onDescriptionResourceCreated(endpoint.endpointPath, $event)"
                />
              </form-field>
            </v-flex>
          </v-layout>
        </v-flex>
        <!-- right part  -->
        <v-flex xs6>
          <v-layout row wrap
            v-for="(endpoint, index) in hardwareMappableOutputResources"
            :key="`${endpointendpointPath}-${index}`"
          >
            <v-flex xs6>
              <form-field :text="`${endpoint.endpointPath} name`">
                <string-resource-select
                  :options="nameResourceOptions"
                  :selected-value="endpoint.nameResourceId"
                  :category="resourceCategory"
                  prefix="HardwareMappableEndpointDefinitions_"
                  postfix="_Name"
                  :default-value="endpoint.endpointPath"
                  @selected="onNameResourceIdSelected(endpoint.endpointPath, $event)"
                  @created="onNameResourceCreated(endpoint.endpointPath, $event)"
                />
              </form-field>
            </v-flex>
            <v-flex xs6>
              <form-field :text="`${endpoint.endpointPath} description`">
                <string-resource-select
                  :options="tooltipResourceOptions"
                  :selected-value="endpoint.descriptionResourceId"
                  :category="resourceCategory"
                  prefix="HardwareMappableEndpointDefinitions_"
                  postfix="_Description"
                  :default-value="endpoint.endpointPath"
                  @selected="onDescriptionResourceIdSelected(endpoint.endpointPath, $event)"
                  @created="onDescriptionResourceCreated(endpoint.endpointPath, $event)"
                />
              </form-field>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
  </card>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Card from '../../ui/Card.vue'
import CardAction from '../../ui/CardAction.vue'
import StringResourceSelect from './StringResourceSelect.vue'

import { CreateResourceInput } from '../../../../../eco-domain-store-modules/src/resource/models'

import { ArchitectureType } from '../../../../../eco-domain-store-modules/src/common'

import { DropdownOption } from '../../../../../shared-components/src'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')

@Component({
  components: {
    Card,
    CardAction,
    StringResourceSelect,
  },
})
export default class HardwareEndpointsDetail extends Vue {
  @FunctionDefinitionEditorUi.Getter public architectureType: ArchitectureType
  @FunctionDefinitionEditorUi.Getter public hardwareMappableInputResources
  @FunctionDefinitionEditorUi.Getter public hardwareMappableOutputResources
  @FunctionDefinitionEditorUi.Getter public hardwareMappableEndpointNameResources
  @FunctionDefinitionEditorUi.Getter public hardwareMappableEndpointDescriptionResources
  @FunctionDefinitionEditorUi.Getter public filteredSystemConfigurationResources:
    (prefix: string, postfix: string, allowEmpty: boolean) => DropdownOption[]
  @FunctionDefinitionEditorUi.Mutation public setFunctionDefinitionEndpointResourceProperty
  @FunctionDefinitionEditorUi.Action public createResource: (payload: CreateResourceInput) => Promise<void>

  public get resourceCategory() {
    return this.architectureType === ArchitectureType.ObjectOriented ? 'SystemConfiguration' : 'FunctionBlockDescriptions'
  }

  public get nameResourceOptions() {
    return this.architectureType === ArchitectureType.ObjectOriented
      ? this.filteredSystemConfigurationResources('HardwareMappableEndpointDefinitions_', '_Name', false)
      : this.hardwareMappableEndpointNameResources
  }

  public get tooltipResourceOptions() {
    return this.architectureType === ArchitectureType.ObjectOriented
      ? this.filteredSystemConfigurationResources('HardwareMappableEndpointDefinitions_', '_Description', false)
      : this.hardwareMappableEndpointDescriptionResources
  }

  public onNameResourceIdSelected(id: string, nameResourceId: string) {
    this.setProperty(id, 'nameResourceId', nameResourceId)
    // try to select the corresponding description by convention
    const descriptionResourceId = nameResourceId.replace('_Name', '_Description')
    if (this.architectureType === ArchitectureType.ObjectOriented) {
      if (this.filteredSystemConfigurationResources('HardwareMappableEndpointDefinitions_', '_Description', false)) {
        this.setProperty(id, 'descriptionResourceId', descriptionResourceId)
      }
    } else {
      if (this.hardwareMappableEndpointDescriptionResources.find(_ => _.id === descriptionResourceId)) {
        this.setProperty(id, 'descriptionResourceId', descriptionResourceId)
      }
    }
  }

  public onDescriptionResourceIdSelected(id: string, descriptionResourceId: string) {
    this.setProperty(id, 'descriptionResourceId', descriptionResourceId)
  }

  public onNameResourceCreated(id: string, input: CreateResourceInput) {
    this.createResource(input)
    this.setProperty(id, 'nameResourceId', input.id)
  }

  public onDescriptionResourceCreated(id: string, input: CreateResourceInput) {
    this.createResource(input)
    this.setProperty(id, 'descriptionResourceId', input.id)
  }

  public setProperty(endpoint: string, property: string, resourceId: any) {
    this.setFunctionDefinitionEndpointResourceProperty({
      id: endpoint,
      property,
      value: resourceId,
    })
  }
}
</script>
<style lang="scss" scoped>
  .cardbody {
    margin-left: -10px;
  }
  .popUpButton {
    flex: 0;
    padding-left: 10px;
  }
  .field {
    height: 70px;
  }
  .field .label {
    padding-left: 10px;
    overflow: hidden;
    max-height: 40px;
  }
</style>