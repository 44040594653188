<template>
  <div class="room-selector">
    <icon-button
      v-for="room in rooms"
      :key="room.d"
      :small="true"
      :name="room.name"
      :icon="room.icon ? room.icon : 'eco.default.white'"
      :active="room.id === selectedRoomId"
      @click.native="onSelect(room.id)"
    />
  </div>
</template>
<script lang="ts">
import IconButton from '@/components/ui/IconButton.vue'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { RoomModel } from '../../../../eco-domain-store-modules/src/plcConfiguration/models'

@Component({
  components: {
    IconButton,
  },
})
export default class RoomSelector extends Vue {
  @Prop({type: String, required: true}) public selectedRoomId: string
  @Prop({type: Array, required: true}) public rooms: RoomModel[]

  @Emit('selected')
  public onSelect(roomId: string) {
    return roomId
  }
}
</script>
<style lang="scss" scoped>
    .scrollable {
      overflow-x: auto;
      overflow-y: none;
    }
    .room-selector {
      display: grid;
      grid-auto-flow: column;
      grid-area: room;
      overflow-x: auto;
      margin-bottom: 35px;
    }
</style>
