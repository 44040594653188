import qs from 'qs'
import ApiService from '../../services/api.service'
import { CustomPlcAlarmModel } from '../models'

export default {
  async loadAlarmsHistoryForPlc(projectId: string, plcId: string): Promise<CustomPlcAlarmModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationQueryApi}/alarms/${projectId}/plc/${plcId}/alarmHistory`)
    return response.data.value
  },
  async loadAlarmsHistoryForProject(projectId: string, itemLimit?: number, created?: string): Promise<CustomPlcAlarmModel[]> {
    const params = qs.stringify({
      itemLimit: itemLimit ?? 20,
      ...(created && { created }),
    })
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationQueryApi}/alarms/${projectId}/alarmHistory?${params}`)
    return response.data.value
  },
}