import { MutationTree } from 'vuex'
import { upsertWithId } from '../utils'
import { CustomPlcAlarmModel, MessageAlarmModel, ServiceAlarmModel } from './models'
import { AlarmMutation, AlarmState } from './types'

export const mutations: MutationTree<AlarmState> = {
  [AlarmMutation.loadedServiceAlarms](state: AlarmState, alarms: ServiceAlarmModel[]) {
    state.serviceAlarms = alarms
  },
  [AlarmMutation.upsertServiceAlarm] (state: AlarmState, alarm: ServiceAlarmModel) {
    upsertWithId(state.serviceAlarms, alarm, a => a.alarmId)
  },
  [AlarmMutation.setServiceAlarmTrigger] (state: AlarmState, triggerValue: boolean) {
    state.serviceAlarmTrigger = triggerValue
  },
  [AlarmMutation.loadedMessageAlarms](state: AlarmState, alarms: MessageAlarmModel[]) {
    state.messageAlarms = alarms
  },
  [AlarmMutation.upsertMessageAlarm] (state: AlarmState, alarm: MessageAlarmModel) {
    upsertWithId(state.messageAlarms, alarm, a => a.alarmId)
  },
  [AlarmMutation.setMessageAlarmTrigger] (state: AlarmState, triggerValue: boolean) {
    state.messageAlarmTrigger = triggerValue
  },
  [AlarmMutation.loadedCustomPlcAlarms](state: AlarmState, alarms: CustomPlcAlarmModel[]) {
    state.customPlcAlarms = alarms
  },
  [AlarmMutation.upsertCustomPlcAlarm] (state: AlarmState, alarm: CustomPlcAlarmModel) {
    upsertWithId(state.customPlcAlarms, alarm, a => a.alarmInstanceId)
  },
  [AlarmMutation.setCustomPlcAlarmTrigger] (state: AlarmState, triggerValue: boolean) {
    state.customPlcAlarmTrigger = triggerValue
  },
}