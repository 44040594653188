import { MinergieVisualizationData, MinergieVisualizationInterval } from '@ecocoach/domain-store-modules/src/consumption/models'
import Highcharts from 'highcharts'
import moment from 'moment'

const primaryForegroundColor = 'white'
const secondaryForegroundColor = 'darkgrey'

export interface ChartOptions {
  projectName: string
  accountName: string
  averageSeriesName: string
  yearsSeriesName: string
}

export const makeChartConfig = (data: MinergieVisualizationData, options: ChartOptions): Highcharts.Options => {
  return {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
    },
    title: {
      text: makeChartTitle(options),
      style: {
        color: primaryForegroundColor,
      },
    },
    legend: {
      itemStyle: {
        color: secondaryForegroundColor,
      },
      itemHoverStyle: {
        color: primaryForegroundColor,
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: makeCategories(data),
      crosshair: true,
      labels: {
        style: {
          color: secondaryForegroundColor,
        },
      },
    },
    yAxis: {
      title: {
        text: makeYAxisTitle(data, options),
        style: {
          color: secondaryForegroundColor,
        },
      },
      labels: {
        style: {
          color: secondaryForegroundColor,
        },
      },
      plotLines: showOverallAverageValue(data) ? [makeOverallAveragePlotline(data, options)] : [],
    },
    series: makeSeries(data, options) as any,
    tooltip: makeTooltip(data),
  } as Highcharts.Options
}

const makeCategories = (data: MinergieVisualizationData): string[] => {
  if (isMonthlyChart(data)) {
    return data.xAxis.categories.map(category => {
      return moment().month(category.name).subtract(1, 'month').format('MMM')
    })
  }
  return data.xAxis.categories.map(category => category.name)
}

const makeChartTitle = (options: ChartOptions) => {
  return `${options.projectName} - ${options.accountName}`
}

const makeYAxisTitle = (data: MinergieVisualizationData, options: ChartOptions) => {
  return `${options.accountName} (${data.unit})`
}

const makeSeries = (data: MinergieVisualizationData, options: ChartOptions) => {
  const series = data.series.map(serie => {
    return {
      name: isYearlyChart(data) ? options.yearsSeriesName : serie.name,
      data: serie.data,
      type: 'column',
    }
  })

  if (showCategoryAverageSeries(data)) {
    series.push({
      name: options.averageSeriesName,
      data: data.xAxis.categories.map(c => c.average),
      type: 'line',
      dashStyle: 'Dash',
      step: 'center',
      color: secondaryForegroundColor,
      marker: {
        enabled: false,
      },
    } as any)
  }

  return series
}

const makeOverallAveragePlotline = (data: MinergieVisualizationData, options: ChartOptions) => {
  const averageValue = data.series[0]?.average?.toFixed(1)
  return {
    color: secondaryForegroundColor,
    dashStyle: 'Dash',
    width: 2,
    value: averageValue,
    label: {
      align: 'right',
      style: {
        color: secondaryForegroundColor,
      },
      text: `${options.averageSeriesName}: ${averageValue} ${data.unit}`,
      x: -10,
    },
    zIndex: 1000,
  }
}

const makeTooltip = (data: MinergieVisualizationData) => {
  const seriesName = isYearlyChart(data) ? '' : `<td style="color:{series.color};padding:0">{series.name}: </td>`
  const displayValue = `<td style="padding:0"><b>{point.y:.1f} ${data.unit}</b></td>`
  return {
    headerFormat: `<span style="font-size:10px">{point.key}</span><table>`,
    pointFormat:  `<tr>${seriesName}${displayValue}</tr>`,
    footerFormat: `</table>`,
    shared: true,
    useHTML: true,
  }
}

const showCategoryAverageSeries = (data: MinergieVisualizationData): boolean => {
  return isMonthlyChart(data) && data.series.length > 1
}

const showOverallAverageValue = (data: MinergieVisualizationData): boolean => {
  return isYearlyChart(data) && data.series[0]?.data.length > 1
}

const isMonthlyChart = (data: MinergieVisualizationData): boolean => {
  return data.interval.toLowerCase() === MinergieVisualizationInterval.MONTHLY
}

const isYearlyChart = (data: MinergieVisualizationData): boolean => {
  return data.interval.toLowerCase() === MinergieVisualizationInterval.YEARLY
}
