import { MutationTree } from 'vuex'
import { SelectPlcUiMutation, SelectPlcUiState } from './types'

export const mutations: MutationTree<SelectPlcUiState> = {
  [SelectPlcUiMutation.setInteracted](state, payload: boolean) {
    state.interacted = payload
  },
  [SelectPlcUiMutation.setNumberOfMessagesInQueue](state, payload: number) {
    state.numberOfMessagesInQueue = payload
  },
  [SelectPlcUiMutation.setSupportData](state, payload) {
    state.supportData = payload
  },
}
