import { WizardState } from '@/store/modules/wizard/types'

export const state: WizardState = {
  toastIsVisible: false,
  toastContent: '',
  waiting: false,
  releaseDialogOpened: false,
  steps: [
    {
      name: 'selectPlc',
      stepText: 'wizard.sps.choose',
      entryCondition: [],
      entryConditionFailedText: 'error.no.writable.sps.available',
    },
    // manage release is no step in wizard => dialog
    {
      name: 'defineRooms',
      stepText: 'wizard.rooms.definitions',
      entryCondition: [ 
        'app/hasSelectedPlc',
      ],
      entryConditionFailedText: 'error.no.sps.selected',
    },
    {
      name: 'defineDevices',
      stepText: 'wizard.functions.choose',
      entryCondition: [
        'app/hasPlcReleaseRooms',
      ],
      entryConditionFailedText: 'error.no.room.defined',
    },
    {
      name: 'defineSolutionHardware',
      stepText: 'wizard.hardware.define',
      entryCondition: [],
      entryConditionFailedText: '',
    },
    {
      name: 'mapHardware',
      stepText: 'wizard.mappen',
      entryCondition: [],
      entryConditionFailedText: '',
    },
    {
      name: 'loadProgram',
      stepText: 'wizard.programm.load',
      entryCondition: [],
      entryConditionFailedText: '',
    },
    {
      name: 'configureDevices',
      stepText: 'wizard.parameterisation',
      entryCondition: [],
      entryConditionFailedText: 'error.not.go.to.parameterisation',
    },
    {
      name: 'manageAlarms',
      stepText: 'wizard.alarms',
      entryCondition: [],
      entryConditionFailedText: 'error.not.go.to.alarms',
    },
  ],
  currentStep: {
    name: 'selectPlc',
    stepText: 'wizard.sps.choose',
    entryCondition: [],
    entryConditionFailedText: '',
  },
}