<template>
  <v-container fluid>
    <upload-results-dialog
      :show="showUploadResults"
      @closed="closeUploadResultsDialog"
    />
    <loader
      :show="showLoader"
      :text="loaderText"
    />
    <v-layout column>
      <v-layout row align-center>
        <v-flex grow>
          <wizard-step-header 
            title="ecoFunctionDefinitionEditor"
          />
        </v-flex>
        <v-flex v-if="showArchitectureSwitch" shrink>
          <div class="action">
            <v-tooltip bottom>
              <div slot="activator" class="toggle">
                <div class="architecture">
                  Architecture type: {{architectureType}}
                </div>
                <base-toggle-button
                  :value="architectureType === 'ObjectOriented'"
                  @buttonToggled="toggleArchitectureType"
                />
              </div>
              <span>Switch Architecture Type</span>
            </v-tooltip>
          </div>
        </v-flex>
        <v-flex v-if="showSourceLanguageSwitch" shrink>
          <div class="action">
            <v-tooltip bottom>
              <div slot="activator" class="toggle">
                <div class="architecture">
                  Source language: {{sourceLanguage}}
                </div>
                <base-toggle-button
                  :value="sourceLanguage === 'CSharp'"
                  @buttonToggled="toggleSourceLanguage"
                />
              </div>
              <span>Switch Source Language</span>
            </v-tooltip>
          </div>
        </v-flex>
        <v-flex shrink v-if="sourceLanguage !== 'CSharp'">
          <div class="action">
            <v-tooltip bottom>
              <div slot="activator" class="toggle">
                <checkbox-with-label
                  :value="preserveProjectFilesInUranus"
                  text="Preserve"
                  @checked="togglePreserveProjectFilesInUranus"
                />
              </div>
              <span>Preserve Project Files in Uranus</span>
            </v-tooltip>
          </div>
        </v-flex>
        <v-flex shrink>
          <div class="action">
            <v-tooltip bottom>
                <v-btn slot="activator" ripple outline
                  @click="onDryRun"
                  :loading="dryRunInProgress"
                  :disabled="uploadingDisabled"
                >
                  <v-icon class="actionIcon">bug_report</v-icon>
                  <span>Dry-run </span>
                  <template v-slot:loader>
                    <span>In progress...</span>
                  </template>
                </v-btn>
              <span>Dry-run upload function definition</span>
            </v-tooltip>
          </div>
        </v-flex>
        <v-flex shrink>
          <div class="action">
            <v-tooltip bottom>
                <v-btn slot="activator" ripple outline
                  @click="onUpload"
                  :loading="uploadInProgress"
                  :disabled="uploadingDisabled"
                >
                  <v-icon class="actionIcon">cloud_upload</v-icon>
                  <span>Upload </span>
                  <template v-slot:loader>
                    <span>In progress...</span>
                  </template>
                </v-btn>
              <span>Upload function definition</span>
            </v-tooltip>
          </div>
        </v-flex>
        <v-flex shrink>
          <div class="action">
            <v-tooltip bottom>
                <v-btn slot="activator" ripple outline
                  @click="showUploadResultsDialog"
                  :disabled="showResultsDisabled"
                >
                  <v-icon class="actionIcon">remove_red_eye</v-icon>
                  <span>Results</span>
                </v-btn>
              <span>Show last upload results</span>
            </v-tooltip>
          </div>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex md3 class="fdTree">
          <function-definition-tree/>
        </v-flex>
        <v-flex md9 class="editArea" v-show="showDetails">
          <v-flex md12>
            <function-block-detail/>
          </v-flex>
          <v-flex md12 v-if="isObjectOrientedArchitecture">
            <measuring-points-detail/>
          </v-flex>
          <v-flex md12 v-if="isObjectOrientedArchitecture">
            <energy-status-items-detail/>
          </v-flex>
          <v-flex md12 v-if="isObjectOrientedArchitecture">
            <default-alarm-definitions-detail/>
          </v-flex>
          <v-flex md12 v-if="isObjectOrientedArchitecture">
            <device-messages-detail/>
          </v-flex>
          <v-flex md12>
            <hardware-endpoints-detail/>
          </v-flex>
          <v-layout>
            <v-flex md6>
              <link-controls-details/>
            </v-flex>
            <v-flex md6>
              <configuration-preview-details/>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ArchitectureType, SourceLanguage } from '../../../../../eco-domain-store-modules/src/common'
import { FeatureSwitches } from '../../../../../eco-domain-store-modules/src/userSettings/models'
import CheckboxWithLabel from '../../../../../shared-components/src/components/patterns/CheckboxWithLabel.vue'
import { FunctionDefinitionViewModel } from '../../../store/modules/functionDefinitionEditorUi/models'

import ConfigurationPreviewDetails from './ConfigurationPreviewDetails.vue'
import DefaultAlarmDefinitionsDetail from './DefaultAlarmDefinitionsDetail.vue'
import DeviceMessagesDetail from './DeviceMessagesDetail.vue'
import EnergyStatusItemsDetail from './EnergyStatusItemsDetail.vue'
import FunctionBlockDetail from './FunctionBlockDetail.vue'
import FunctionDefinitionTree from './FunctionDefinitionTree.vue'
import HardwareEndpointsDetail from './HardwareEndpointsDetail.vue'
import LinkControlsDetails from './LinkControlsDetails.vue'
import Loader from './Loader.vue'
import MeasuringPointsDetail from './MeasuringPointsDetail.vue'
import UploadResultsDialog from './UploadResultsDialog.vue'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')
const UserSettings = namespace('userSettings')

@Component({
  components: {
    ConfigurationPreviewDetails,
    DefaultAlarmDefinitionsDetail,
    DeviceMessagesDetail,
    EnergyStatusItemsDetail,
    FunctionBlockDetail,
    FunctionDefinitionTree,
    HardwareEndpointsDetail,
    LinkControlsDetails,
    Loader,
    MeasuringPointsDetail,
    UploadResultsDialog,
    CheckboxWithLabel,
  },
})
export default class EcoFunctionDefinitionEditor extends Vue {
  @FunctionDefinitionEditorUi.Getter public architectureType: ArchitectureType
  @FunctionDefinitionEditorUi.Getter public sourceLanguage: SourceLanguage
  @FunctionDefinitionEditorUi.Getter public preserveProjectFilesInUranus: boolean
  @FunctionDefinitionEditorUi.Action public toggleArchitectureType: () => Promise<void>
  @FunctionDefinitionEditorUi.Action public toggleSourceLanguage: () => Promise<void>
  @FunctionDefinitionEditorUi.Action public togglePreserveProjectFilesInUranus: () => Promise<void>
  @FunctionDefinitionEditorUi.Action public initialize
  @FunctionDefinitionEditorUi.Action public uploadNewDefinitions: (dryRun: boolean) => Promise<void>
  @FunctionDefinitionEditorUi.Action public uploadNewDefinitionsV2: (dryRun: boolean) => Promise<void>
  @FunctionDefinitionEditorUi.Action public uploadCSharpDefinitions: (dryRun: boolean) => Promise<void>
  @FunctionDefinitionEditorUi.Getter public functionDefinition: FunctionDefinitionViewModel
  @FunctionDefinitionEditorUi.Getter public actionInProgress: '' | 'initializing' | 'newversion' | 'dryrun' | 'uploading'
  @FunctionDefinitionEditorUi.Getter public uploadResults: any[]
  @UserSettings.Getter public isFeatureOn: (featureId: string) => boolean

  public get showArchitectureSwitch() {
    return this.isFeatureOn(FeatureSwitches.plcConfigurationClassicArchitecture)
  }

  public get showSourceLanguageSwitch() {
    return this.architectureType === ArchitectureType.ObjectOriented && this.isFeatureOn(FeatureSwitches.cSharpFunctionBlocks)
  }

  public showUploadResults: boolean = false

  public mounted() {
    this.initialize()
  }

  public get isObjectOrientedArchitecture() {
    return this.architectureType === ArchitectureType.ObjectOriented
  }

  public onDryRun() {
    if (this.isObjectOrientedArchitecture) {
      this.sourceLanguage === SourceLanguage.CSharp
        ? this.uploadCSharpDefinitions(true)
        : this.uploadNewDefinitionsV2(true)
    } else {
      this.uploadNewDefinitions(true)
    }
  }

  public onUpload() {
    if (this.isObjectOrientedArchitecture) {
      this.sourceLanguage === SourceLanguage.CSharp
        ? this.uploadCSharpDefinitions(false)
        : this.uploadNewDefinitionsV2(false)
    } else {
      this.uploadNewDefinitions(false)
    }
  }

  @Watch('uploadResults')
  public showUploadResultsDialog() {
    this.showUploadResults = true
  }

  public closeUploadResultsDialog() {
    this.showUploadResults = false
  }

  public get showResultsDisabled() {
    return !this.uploadResults.length
  }

  public get uploadingDisabled() {
    return !this.functionDefinition.id || this.actionInProgress === 'dryrun' || this.actionInProgress === 'uploading'
  }

  public get dryRunInProgress() {
    return this.actionInProgress === 'dryrun'
  }

  public get uploadInProgress() {
    return this.actionInProgress === 'uploading'
  }

  public get showDetails() {
    return !!this.functionDefinition.id
  }

  public get showLoader() {
    return !!this.actionInProgress
  }

  public get loaderText() {
    switch (this.actionInProgress) {
    case 'initializing': return 'Loading data'
    case 'newversion': return 'Preparing new version'
    case 'dryrun': return 'Dry-run upload in progress'
    case 'uploading': return 'Upload in progress'
    default: return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.fdTree {
  margin-left: -10px;
  min-width: 400px;
  max-width: 600px;
}
.editArea {
  flex: 1;
}
.toggle {
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 0px 12px 0px 4px;
  .architecture {
    margin-right: 10px;
  }
}
</style>