<template lang="html">
  <div class="field">
    <div class="field-label" :title="text">{{ text }}</div>
    <slot/>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import DescriptionPopover from './DescriptionPopover.vue'

export default Vue.extend({
  name: 'FormField',
  components: {
      DescriptionPopover,
  },
  props: {
    /**
     * the label text
     */
    text: {
      type: String,
      default: '',
    },
  },
})
</script>

<style lang="scss" type="text/scss" scoped>
		.field {
      font-family: $font-family-primary;
      color: $fore-color-primary;
		}
    .field-label {
      padding-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .field>div {
      padding-top: 0px;
    }
</style>

<docs>
A field label with a slot to host an input component

Usage examples
```jsx
<v-app style="height: 350px; background:transparent;">
  <form-field text="Enter a text">
    <text-input placeholder="Text input"/>
  </form-field>
  <form-field text="Check or not">
    <checkbox-with-label text="Checkbox text"/>
  </form-field>
  <form-field text="Select something">
    <base-single-select :options="['foo', 'bar', 'baz']" placeholdertext="Select input"/>
  </form-field>
  <form-field text="Select something">
    <autocomplete-select :options="['foo', 'bar', 'baz']" placeholdertext="Autocomplete input"/>
  </form-field>
  <form-field text="Select something">
    <icon-select :options="[{id: '1', label:'label1'}]" :option-value="'id'" :option-label="'label'":option-icon="'icon'" placeholdertext="Select icon"/>
  </form-field>
  <form-field text="Pick a file">
    <file-select placeholdertext="File select"/>
  </form-field>
</v-app>
```
</docs>
