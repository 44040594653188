import ApiService from '../../services/api.service'
import { AcceptedUserAgreementModel, UserAgreementModel } from '../models'

export default {
  async currentUserAgreements(): Promise<UserAgreementModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().userSettingsApi}/Documents/Documents`)
    return response.data.value
  },  
  async acceptedUserAgreements(): Promise<AcceptedUserAgreementModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().userSettingsApi}/Documents/acceptedDocuments`)
    return response.data.value
  },  
}
