import { MutationTree } from 'vuex'
import { ReleaseUiMutation, ReleaseUiState } from './types'

export const mutations: MutationTree<ReleaseUiState> = {
  [ReleaseUiMutation.setInteracted](state, payload: boolean) {
    state.interacted = payload
  },
  [ReleaseUiMutation.showReleaseUpgradeConfirmationDialog](state) {
    state.releaseUpgradeConfirmationDialogVisible = true
  },
  [ReleaseUiMutation.hideShowReleaseUpgradeConfirmationDialog](state) {
    state.releaseUpgradeConfirmationDialogVisible = false
  },
  [ReleaseUiMutation.setReleasesLoaded](state, payload: boolean) {
    state.releasesLoaded = payload
  },
}
