import { AlarmDefinitionModel } from '@ecocoach/domain-store-modules/src/alarmDefinition/models'
import { AlarmDefinitionTableFilterSettings } from './models'

export enum ManageAlarmsUiAction {
  loadAlarmDefinitions = 'loadAlarmDefinitions',
  loadFilterSettings = 'loadFilterSettings',
  updateFilterSettings = 'updateFilterSettings',
  newAlarmDefinition = 'newAlarmDefinition',
  editAlarmDefinition = 'editAlarmDefinition',
  updateAlarmDefinitionProperty = 'updateAlarmDefinitionProperty',
  cancelAlarmDefinition = 'cancelAlarmDefinition',
  createAlarmDefinition = 'createAlarmDefinition',
  updateAlarmDefinition = 'updateAlarmDefinition',
  deleteAlarmDefinition = 'deleteAlarmDefinition',
}

export enum ManageAlarmsUiMutation {
  setInteracted = 'setInteracted',
  setSelectedAlarmDefinitionId = 'setSelectedAlarmDefinitionId',
  setEditingAlarmDefinition = 'setEditingAlarmDefinition',
  setEditingAlarmDefinitionProperty = 'setEditingAlarmDefinitionProperty',
}

export enum ManageAlarmsUiGetter {
  isInteracted = 'isInteracted',
  selectedAlarmDefinitionId = 'selectedAlarmDefinitionId',
  levelOptions = 'levelOptions',
  filterSettings = 'filterSettings',
  alarmDefinitions = 'alarmDefinitions',
  filteredAlarmDefinitions = 'filteredAlarmDefinitions',
  dialog = 'dialog',
}

export interface ManageAlarmsUiState {
  interacted: boolean
  selectedAlarmDefinitionId: string
  filterSettings: AlarmDefinitionTableFilterSettings
  editingAlarmDefinition: AlarmDefinitionModel | null
}
