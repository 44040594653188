export interface ResourceModel {
  id: string
  value: string
}

export interface CreateResourceInput {
  id: string
  category: ResourceCategory
  defaultValue?: string
}

export enum ResourceCategory {
  APP_ICONS = 'AppIcons',
  SOLUTION_HARDWARE_ICONS = 'SolutionHardware',
  ROOM_ICONS = 'Room',
  DEVICE_ICONS = 'Device',
  PLC_IMAGES = 'PLC',
  TERMINAL_IMAGES = 'Terminal',
  MODULE_IMAGES = 'Module',
  APP_STRINGS = 'App',
  FUNCTION_BLOCK_DESCRIPTION_STRINGS = 'FunctionBlockDescriptions',
  CHART_ICONS = 'Charts',
  SCENE_ICONS = 'Scenes',
  SYSTEM_CONFIGURATION = 'SystemConfiguration',
}

export enum ResourceType {
  ICON = 'icon',
  IMAGE = 'image',
  RESOURCESTRING  = 'resourceString',
}
