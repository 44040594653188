export interface AuthorizationUiState {
  interacted: boolean
  selectedProjectId: string
  selectedUser: string
  newUsers: string[]
}

export enum AuthorizationUiAction {
  load = 'load',
  selectProject = 'selectProject',

  grantProjectPermission = 'grantProjectPermission',
  revokeProjectPermission = 'revokeProjectPermission',

  grantConsumptionPermission = 'grantConsumptionPermission',
  revokeConsumptionPermission = 'revokeConsumptionPermission',

  grantPlcPermission = 'grantPlcPermission',
  revokePlcPermission = 'revokePlcPermission',

  grantDevicePermission = 'grantDevicePermission',
  revokeDevicePermission = 'revokeDevicePermission',

  grantDevicePermissionForRoom = 'grantDevicePermissionForRoom',
  revokeDevicePermissionForRoom = 'revokeDevicePermissionForRoom',

  grantDevicePermissionForPlc = 'grantDevicePermissionForPlc',
  revokeDevicePermissionForPlc = 'revokeDevicePermissionForPlc',

  grantAlarmDefinitionPermission = 'grantAlarmDefinitionPermission',
  revokeAlarmDefinitionPermission = 'revokeAlarmDefinitionPermission',

  grantAlarmDefinitionPermissionForPlc = 'grantAlarmDefinitionPermissionForPlc',
  revokeAlarmDefinitionPermissionForPlc = 'revokeAlarmDefinitionPermissionForPlc',

  grantAllPermissionsWithSuperPowers = 'grantAllPermissionsWithSuperPowers',
  revokeAllPermissionsWithSuperPowers = 'revokeAllPermissionsWithSuperPowers',

  resolveAppRegistrationNames = 'resolveAppRegistrationNames',
}

export enum AuthorizationUiMutation {
  setInteracted = 'setInteracted',
  setSelectedProjectId = 'setSelectedProjectId',
  setSelectedUser = 'setSelectedUser',
  addNewUser = 'addNewUser',
}

export enum AuthorizationUiGetter {
  isInteracted = 'isInteracted',
  selectedProjectId = 'selectedProjectId',
  selectedUser = 'selectedUser',
  projects = 'projects',
  plcsOfProject = 'plcsOfProject',
  roomsOfPlc = 'roomsOfPlc',
  devicesOfRoom = 'devicesOfRoom',
  alarmDefinitionsOfPlc = 'alarmDefinitionsOfPlc',
  authorizedUsers = 'authorizedUsers',
  projectPermissions = 'projectPermissions',
  consumptionPermissions = 'consumptionPermissions',
  plcPermissions = 'plcPermissions',
  devicePermissions = 'devicePermissions',
  devicePermissionsForRoom = 'devicePermissionsForRoom',
  devicePermissionsForPlc = 'devicePermissionsForPlc',
  alarmDefinitionPermissions = 'alarmDefinitionPermissions',
  alarmDefinitionPermissionsForPlc = 'alarmDefinitionPermissionsForPlc',
  appRegistrationNameForId = 'appRegistrationNameForId',
}

export const emailRegex = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$')
export const appIdRegex = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$')
