<template>
  <v-layout row align-center justify-center class="header">
    <v-flex shrink>
      <h2>{{header}}</h2>
    </v-flex>
    <slot name="actions"></slot>
  </v-layout>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class ColumnHeader extends Vue {
  @Prop({type: String}) public header: string
}
</script>

<style lang="scss" type="text/scss" scoped>
.header {
  padding: 0px 10px;
  min-height: 50px;
}
</style>