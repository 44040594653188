<template>
  <modal-dialog v-if="plcId"
    :show="true"
    :header="'manage.plc.notes' | translate"
    closing-x=true
    @xPressed="closeNotesForPlc"
    width=1000
    height=750
  >
    <v-layout column class="notes">
      <notes-table/>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import NotesTable from './NotesTable.vue'

const NoteUi = namespace('noteUi')
const Resource = namespace('resource')

@Component({
  components: {
    NotesTable,
  },
})
export default class Notes extends Vue { 
  @NoteUi.Getter public plcId: string
  @NoteUi.Action public closeNotesForPlc: () => Promise<void>
  @Resource.Getter public resolveStringResource: (id: string) => string
}
</script>
<style lang="scss" scoped>
.notes {
  .plc-name {
    font-size: 16px;
  }
}

</style>