import { MutationTree } from 'vuex'
import { AcceptedUserAgreementModel, UserAgreementModel } from './models'
import { UserAgreementMutation, UserAgreementState } from './types'

export const mutations: MutationTree<UserAgreementState> = {
  [UserAgreementMutation.setCurrentUserAgreements](state, payload: UserAgreementModel[]) {
    state.currentUserAgreements = payload
  },
  [UserAgreementMutation.setAcceptedUserAgreements](state, payload: AcceptedUserAgreementModel[]) {
    state.acceptedUserAgreements = payload
  },
  [UserAgreementMutation.addAcceptedUserAgreement](state, payload: AcceptedUserAgreementModel) {
    state.acceptedUserAgreements.push(payload)
  },
}
