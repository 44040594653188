import ApiService from '../services/api.service'
import { AlarmDefinitionModel, AlarmDefinitionOptionModel, CreateAlarmDefinitionInput, ModifyAlarmDefinitionInput } from './models'

export default {
  async loadAlarmDefinitions(plcId: string, language: string): Promise<AlarmDefinitionModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationQueryApi}/Alarms/${plcId}/alarmDefinitionsV2?resourceLanguage=${language}`)
    return response.data.value
  },
  async loadAlarmDefinitionsOptions(plcId: string, language: string): Promise<AlarmDefinitionOptionModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationQueryApi}/Alarms/${plcId}/alarmDefinitionOptions?resourceLanguage=${language}`)
    return response.data.value
  },
  async createAlarmDefinition(input: CreateAlarmDefinitionInput): Promise<{ id: string }> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Alarms/${input.plcId}/alarmDefinitionsV2`, input)
    return {
      id: response.data.id,
    }
  },
  async updateAlarmDefinition(input: ModifyAlarmDefinitionInput): Promise<void> {
    await ApiService.put(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Alarms/${input.plcId}/alarmDefinitionsV2/${input.id}`, input)
  },
  async deleteAlarmDefinition(plcId: string, id: string): Promise<void> {
    await ApiService.delete(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Alarms/${plcId}/alarmDefinitions/${id}`, {})
  },
}
