import { FunctionBlockUiGetter, FunctionBlockUiState } from '@/store/modules/functionBlockUi/types'
import { RootState } from '@/store/types'
import { FunctionBlockCategoryModel, FunctionBlockModel, FunctionBlockState } from '@ecocoach/domain-store-modules/src/systemConfiguration/models'
import { matchSubstringCaseInsensitive } from '@ecocoach/domain-store-modules/src/utils'
import { GetterTree } from 'vuex'
import { ResourceGetter } from '../../../../../eco-domain-store-modules/src/resource/types'
import { FunctionBlockDefinitionSearchParams, FunctionBlocksOfCategory, FunctionBlockTreeItemViewModel } from './models'

export const getters: GetterTree<FunctionBlockUiState, RootState> = {
  [FunctionBlockUiGetter.functionBlocksSearchResult](_, __, rootState, rootGetters) {
    return (filter: FunctionBlockDefinitionSearchParams): FunctionBlocksOfCategory[] => {
      const architectureFilter = (fb: FunctionBlockModel) => fb.architectureType === filter.architectureType
      const sourceLanguageFilter = (fb: FunctionBlockModel) => fb.sourceLanguage === filter.sourceLanguage
      const stateFilter = (fb: FunctionBlockModel): boolean => {
        switch (fb.state) {
        case FunctionBlockState.ACTIVE: return true
        case FunctionBlockState.IN_TEST: return filter.includeInTest === true
        case FunctionBlockState.OBSOLETE: return filter.includeObsolete === true
        default: return false
        }
      }
      const textFilter = (fb: FunctionBlockTreeItemViewModel): boolean => {
        return matchSubstringCaseInsensitive(fb.name, filter.search) || matchSubstringCaseInsensitive(fb.internalName, filter.search)
      }

      const releaseNotesHistory = (fb: FunctionBlockModel) => {
        if (fb.releaseNotesHistory) {
          return fb.releaseNotesHistory.map(item => {
            const tokens = item.releaseNotesResourceId.replace('FunctionDefinitions_', '').replace('_ReleaseNotes', '').split('_')
            const version = tokens.length ? tokens.pop() : ''
            return `${version}<br>${item.releaseNotes}`
          }) ?? []
        } else {
          return [fb.releaseNotesResourceId, ...(fb.releaseNotesHistoryResourceIds || [])].map(item => {
            const tokens = item.replace('FunctionDefinitions_', '').replace('_ReleaseNotes', '').split('_')
            const version = tokens.length ? tokens.pop() : ''
            return `${version}<br>${rootGetters[`resource/${ResourceGetter.dictionary}`](item)}`
          })
        }
      }

      const toViewModel = (fb: FunctionBlockModel) => {
        return {
          id: fb.id,
          name: fb.name || rootGetters[`resource/${ResourceGetter.dictionary}`](fb.nameResourceId),
          description: fb.description || rootGetters[`resource/${ResourceGetter.dictionary}`](fb.descriptionResourceId),
          iconResourceId: fb.iconResourceId,
          releaseNotes: fb.releaseNotes || rootGetters[`resource/${ResourceGetter.dictionary}`](fb.releaseNotesResourceId),
          releaseNotesHistory: releaseNotesHistory(fb),
          version: fb.version,
          internalName: fb.internalName,
          state: fb.state,
          sourceLanguage: fb.sourceLanguage,
        } as FunctionBlockTreeItemViewModel
      }

      const categoryIds = rootState.systemConfiguration.functionBlockCategories.map(c => c.id)
      const variousCategory = {
        category: {
          id: 'various',
          name: 'various',
          nameResourceId: 'various',
          iconResourceId: 'various',
        } as FunctionBlockCategoryModel,
        functionBlocks: rootState.systemConfiguration.functionBlocks
          .filter(fb => !categoryIds.includes(fb.categoryId) && stateFilter(fb) && architectureFilter(fb) && sourceLanguageFilter(fb))
          .map(fb => toViewModel(fb))
          .filter(fb => textFilter(fb))
          .sort((a, b) => a.name?.localeCompare(b.name)),
      }

      return rootState.systemConfiguration.functionBlockCategories
        .map(category => ({
          category,
          functionBlocks: rootState.systemConfiguration.functionBlocks
            .filter(fb => fb.categoryId === category.id && stateFilter(fb) && architectureFilter(fb) && sourceLanguageFilter(fb))
            .map(fb => toViewModel(fb))
            .filter(fb => textFilter(fb))
            .sort((a, b) => a.name?.localeCompare(b.name)),
        } as FunctionBlocksOfCategory))
        .concat([variousCategory])
        .filter(item => !!item.functionBlocks.length)
        .sort((a, b) => a.category.name?.localeCompare(b.category.name))
    }
  },
  [FunctionBlockUiGetter.amountOfHardwareMappableInputEndpointsById](_, __, rootState: RootState) {
    return functionBlockId => {
      const functionBlock = rootState.systemConfiguration.functionBlocks.find(fb => fb.id === functionBlockId)
      return functionBlock && functionBlock.hardwareMappableEndpoints.filter(ep => ep.isMappableHardwareInput).length || 0
    }
  },
  [FunctionBlockUiGetter.amountOfHardwareMappableOutputEndpointsById](_, __, rootState: RootState) {
    return functionBlockId => {
      const functionBlock = rootState.systemConfiguration.functionBlocks.find(fb => fb.id === functionBlockId)
      return functionBlock && functionBlock.hardwareMappableEndpoints.filter(ep => ep.isMappableHardwareOutput).length || 0
    }
  },
}