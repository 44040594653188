import { ValidationEnum, ValidationIssue, ValidationIssueViewModel } from './models'

export function toViewModel(validationIssue: ValidationIssue, resolveStringResource: (resourceId: string) => string) {
  return {
    ...validationIssue,
    issueText: validationIssueTypeText(validationIssue.validationEnum, resolveStringResource),
  } as ValidationIssueViewModel
}

function validationIssueTypeText(validationEnum: ValidationEnum, resolveStringResource: (resourceId: string) => string) {
  switch (validationEnum) {
  case ValidationEnum.ControlCurrentlyAssignedToConsumptionAccount:
  case ValidationEnum.ControlCurrentlyAssignedToMinergieConsumptionAccount:
  case ValidationEnum.ControlCurrentlyAssignedToEgonlineConsumptionAccount:
  case ValidationEnum.MeasuringPointCurrentlyAssignedToConsumptionAccount:
    return resolveStringResource('validationissue.type.missingmeter')
  case ValidationEnum.ControlCurrentlyUsedInAggregation:
  case ValidationEnum.MeasuringPointCurrentlyUsedInAggregation:
    return resolveStringResource('validationissue.type.missingchartsource')
  case ValidationEnum.DefaultAlarmNotSupported:
    return resolveStringResource('validationissue.type.defaultalarm')
  case ValidationEnum.InstantaneousReadingsNotSupported:
    return resolveStringResource('validationissue.type.InstantaneousReadingsNotSupported')
  case ValidationEnum.InformationLevelAlarmNotSupported:
    return resolveStringResource('validationissue.type.InformationLevelAlarmNotSupported')
  case ValidationEnum.DeviceMessagesControlNotSupported:
    return resolveStringResource('validationissue.type.DeviceMessagesControlNotSupported')
  default: 
    return validationEnum
  }
}
