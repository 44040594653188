import { actions } from '@/store/modules/parametrizationUi/actions'
import { getters } from '@/store/modules/parametrizationUi/getters'
import { mutations } from '@/store/modules/parametrizationUi/mutations'
import { state } from '@/store/modules/parametrizationUi/state'
import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { ParametrizationUiAction, ParametrizationUiGetter, ParametrizationUiMutation, ParametrizationUiState } from './types'

const namespaced: boolean = true
export const parametrizationUi: Module<ParametrizationUiState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action: ParametrizationUiAction, 
  mutation: ParametrizationUiMutation,
  getter: ParametrizationUiGetter,
}

export const domain = 'parametrizationUi'

export const vuexModule = {
  [domain]: parametrizationUi,
}