<template>
  <modal-dialog :show="show"
                :header="'mfa.dialog.header' | translate"
                :button1-text="'common.button.save' | translate"
                :button2-disabled="isInteracted"
                @button1Pressed="onOk">
    <v-layout column>
      <v-flex class="info-message">
        {{'mfa.dialog.info' | translate }}
      </v-flex>
      <v-flex shrink>
        <checkbox-with-label :value="isEmailFmaChecked"
                             :text="'mfa.checkbox.email' | translate"
                             :disabled="false"
                             @checked="onMfaToggled" />
      </v-flex>
      <v-flex grow />
    </v-layout>

  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const App = namespace('app')

@Component
export default class ConfigureMfaDialog extends Vue {
    @Prop({ type: Boolean }) public show: boolean
    @App.Getter public isEmailMfaEnabled: boolean | null
    @App.Getter public isInteracted: boolean
    @App.Action public setEmailMfaEnabled: (payload: boolean) => Promise<void>

    private isEmailFmaChecked = false

    @Emit('closed')
    public async onOk() {
      await this.setEmailMfaEnabled(this.isEmailFmaChecked)
    }

    public onMfaToggled(value: boolean) {
      this.isEmailFmaChecked = !value
    }
}
</script>