import ApiService from '../services/api.service'
import { NoteModel } from './models'

export default {
  async loadNotesForPlc(plcId: string): Promise<NoteModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().projectApi}/Notes/${plcId}`)
    return response.data.value
  },
  async createNoteForPlc(plcId: string, input: NoteModel): Promise<NoteModel> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().projectApi}/Notes/${plcId}`, input)
    return response.data
  },
  async updateNoteForPlc(plcId: string, input: NoteModel): Promise<NoteModel> {
    const response = await ApiService.put(`${ApiService.backendEnvironmentConfiguration().projectApi}/Notes/${plcId}/noteId/${input.id}`, input)
    return response.data
  },
  async deleteNoteForPlc(plcId: string, id: string): Promise<void> {
    await ApiService.delete(`${ApiService.backendEnvironmentConfiguration().projectApi}/Notes/${plcId}/noteId/${id}`, {})
  },
}
