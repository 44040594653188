import { AuditLogUiState } from '@/store/modules/auditLogUi/types'

export const state: AuditLogUiState = {
  interacted: false,
  selectedPlcId: '',
  filterSettings: {
    userIdentifier: '',
    date: '',
    actionTypes: [],
    actionText: '',
    actionDetails: '',
  },
}
