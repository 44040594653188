import { AlarmUiGetter, AlarmUiState  } from '@/store/modules/alarmUi/types'
import { RootState } from '@/store/types'
import { AppIdWebApp } from '@ecocoach/domain-store-modules/src/alarm/types'
import { PlcOperationState } from '@ecocoach/domain-store-modules/src/common'
import moment from 'moment'
import { GetterTree } from 'vuex'

(moment as any).suppressDeprecationWarnings = true

function resolveTimeStamp(timeStamp) {
// resolves time zones, given format for this moment.js version
  const date = moment(timeStamp)
  return date.format('DD.MM.YYYY - LTS') !== 'Invalid date' ?  date.format('DD.MM.YYYY - HH:mm:ss') : ' -'
}

export const getters: GetterTree<AlarmUiState, RootState> = {
  [AlarmUiGetter.isDisturbed]  (_, __, rootState: RootState) {
    const storedServiceAlarms = rootState.alarm.serviceAlarms
      .filter(alarm => alarm.active === true)
      .filter(alarm => alarm.appIds.includes(AppIdWebApp))
    const storedMessageAlarms = rootState.alarm.messageAlarms
      .filter(alarm => alarm.active === true)
      .filter(alarm => alarm.appIds.includes(AppIdWebApp))
    return storedServiceAlarms.length > 0 || storedMessageAlarms.length > 0
  },
  [AlarmUiGetter.hasServiceAlarm]  (_, __, rootState: RootState): boolean {
    return rootState.alarm.serviceAlarms
      .filter(alarm => alarm.active === true)
      .filter(alarm => alarm.appIds.includes(AppIdWebApp)).length > 0
  },
  [AlarmUiGetter.messageAlarmsText]  (_, __, rootState: RootState) {
    return rootState.alarm.messageAlarms
      .filter(alarm => alarm.active === true && alarm.appIds.includes(AppIdWebApp))
      .map(alarm => alarm.message)
      .join(',')
  },
  [AlarmUiGetter.serviceAlarmTime] (_, __, rootState: RootState) {
    let time = ''
    const relevantServiceAlarm = rootState.alarm.serviceAlarms
      .filter(alarm => alarm.active === true)
      .filter(alarm => alarm.appIds.includes(AppIdWebApp))
    if(relevantServiceAlarm.length > 0) {
      time = resolveTimeStamp(relevantServiceAlarm[0].timeStamp)
    }
    return time
  },
  [AlarmUiGetter.messageAlarmTime] (_, __, rootState: RootState) {
    let time = ''
    const relevantMessageAlarm = rootState.alarm.messageAlarms
      .filter(alarm => alarm.active === true)
      .filter(alarm => alarm.appIds.includes(AppIdWebApp))
    if(relevantMessageAlarm.length > 0) {
      time = resolveTimeStamp(relevantMessageAlarm[0].timeStamp)    }
    return time
  },
  [AlarmUiGetter.isAlarmTriggered] (_, getter, rootState: RootState) {
    const serviceTrigger = rootState.alarm.serviceAlarmTrigger
    const messageTrigger = rootState.alarm.messageAlarmTrigger 
    return (serviceTrigger || messageTrigger) && getter.isDisturbed
  },
  [AlarmUiGetter.isDialogOpen] (state) {
    return state.dialogOpened
  },
  [AlarmUiGetter.showPlcStateAlarm] (_, __, rootState: RootState) {
    // shows the icon on every ecoSetupTool-step except selectPlc
    return (routeFullPath: string) => {
      const stepname = rootState.wizard.currentStep.name // notify vuex ;)
      if(!rootState.app.selectedPlcId) {
        return false
      }
      const plcStates = rootState.plc.plcs.filter(c => c.id === rootState.app.selectedPlcId)
      if (plcStates.length > 0) {
        return (plcStates[0].plcOperationState === PlcOperationState.Offline || plcStates[0].plcOperationState === PlcOperationState.Disconnected)
          && routeFullPath && routeFullPath.includes('ecoSetupTool')
          && stepname !== 'selectPlc'
      }
      return false
    }
  },
  [AlarmUiGetter.isPlcOffline] (_, __, rootState: RootState) {
    if(!rootState.app.selectedPlcId) {
      return false
    }
    const plcState = rootState.plc.plcs.filter(c => c.id === rootState.app.selectedPlcId)
    if(plcState.length > 0 && 
      (plcState[0].plcOperationState === PlcOperationState.Offline || plcState[0].plcOperationState === PlcOperationState.Disconnected)) {
      return true
    } 
    return false
  },
  [AlarmUiGetter.plcNotOffline] (_, getter) {
    return !getter.isPlcOffline
  },
  [AlarmUiGetter.plcOfflineTimeStamp] (_, __, rootState: RootState) {
    let time = ''
    const relevantPlcOffline = rootState.plc.plcs
      .filter(c => c.id === rootState.app.selectedPlcId) 
      .filter(c => c.plcOperationState === PlcOperationState.Offline || c.plcOperationState === PlcOperationState.Disconnected)
    if(relevantPlcOffline.length > 0) {
      time = resolveTimeStamp(relevantPlcOffline[0].timeStamp)
    }
    return time
  },
}