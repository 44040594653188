import { GetterTree } from 'vuex'
import { AcceptedUserAgreementModel, UserAgreementModel } from './models'
import { UserAgreementGetter, UserAgreementState } from './types'

export const getters: GetterTree<UserAgreementState, {}> = {
  [UserAgreementGetter.currentUserAgreements]({ currentUserAgreements }): UserAgreementModel[] {
    return currentUserAgreements
  },
  [UserAgreementGetter.acceptedUserAgreements]({ acceptedUserAgreements }): AcceptedUserAgreementModel[] {
    return acceptedUserAgreements
  },
}
