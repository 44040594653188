<template>
  <v-dialog
    :value="show"
    persistent
    width="300"
  >
    <v-card
      color="#1a3856"
      dark
    >
      <v-card-text>
        {{text}}
        <br/>
        Please wait
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class Loader extends Vue {
  @Prop({type: Boolean, default: false}) public show: boolean
  @Prop({type: String}) public text: string
}
</script>
<style lang="scss" scoped>
</style>