import { ActionTree } from 'vuex'
import api from './api'
import { PlcModel, ProjectModel } from './models'
import { ProjectAction, ProjectMutation, ProjectState} from './types'

export const actions: ActionTree<ProjectState, {}> = {
  async [ProjectAction.loadProjects]({ commit }): Promise<void> {
    const data = await api.loadProjects()
    commit(ProjectMutation.setProjects, data)
  },
  async [ProjectAction.createProject]({ commit }, payload: { partnerId: string, project: ProjectModel }): Promise<void> {
    const data = await api.createProject(payload.partnerId, payload.project)
    commit(ProjectMutation.upsertProject, data)
  },
  async [ProjectAction.updateProject]({ commit }, payload: ProjectModel): Promise<void> {
    const data = await api.updateProject(payload)
    commit(ProjectMutation.upsertProject, data)
  },
  async [ProjectAction.deleteProject]({ commit }, payload: string): Promise<void> {
    await api.deleteProject(payload)
    commit(ProjectMutation.removeProject, payload)
  },
  async [ProjectAction.createPlc]({ commit }, payload: PlcModel): Promise<void> {
    const data = await api.createPlc(payload.projectId, payload)
    commit(ProjectMutation.upsertPlc, data)
  },
  async [ProjectAction.updatePlc]({ commit }, payload: PlcModel): Promise<void> {
    const data = await api.updatePlc(payload)
    commit(ProjectMutation.upsertPlc, data)
  },
  async [ProjectAction.deletePlc]({ commit, state }, payload: string): Promise<void> {
    const projectId = state.projects.find(p => p.plcs.some(plc => plc.id === payload))?.plcs.find(plc => plc.id === payload)?.projectId ?? ''
    await api.deletePlc(projectId, payload)
    commit(ProjectMutation.removePlc, payload)
  },
}
