<template lang="html">
  <v-layout row align-center
    class="label">
    <v-flex shrink class="text"> {{ this.text }} </v-flex>
    <v-flex shrink>
      <description-popover
        v-if="this.descriptionContent !== ''"
        class="popUpButton"
        :header="this.descriptionTitle || this.text"
        :content="this.descriptionContent"
      />
    </v-flex>
    <v-flex />
  </v-layout>
</template>

<script lang="ts">
import Vue from 'vue'
import DescriptionPopover from './DescriptionPopover.vue'

export default Vue.extend({
  name: 'LabelComponent',
  components: {
      DescriptionPopover,
  },
  props: {
    /**
     * the label text
     */
    text: {
      type: String,
      default: '',
    },
    /** 
     * the optional descirption title
     */
    descriptionTitle: {
      type: String,
      default: '',
    },
    /** 
     * the optional descirption content
     */
    descriptionContent: {
      type: String,
      default: '',
    },
  },
})
</script>

<style lang="scss" type="text/scss" scoped>
    .text {
      color: white;
			padding-right: 15px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
    }
    .popUpButton {
      padding: 0 !important;
    }
</style>

<docs>
A label with optional description popover

Usage example without description popover
```jsx
<v-app style="height: 48px; background:transparent;">
  <label-component
    text="The label text"
  />
</v-app>
```

Usage example with description popover
```jsx
<v-app style="height: 48px; background:transparent;">
  <label-component
    text="The label text"
    descriptionTitle="The description title"
    descriptionContent="The description content"
  />
</v-app>
```
</docs>
