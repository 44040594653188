<template>  
  <div class="wizardbar">
    <button
      class="direction"
      @click="emitGoToPreviousStep()"
    >
      <span>{{ previousText }}</span>
    </button>
    <button v-for="(step, index) in steps"
      class="step"
      :class="{
        last: index === (steps.length - 1),
        active: step.id === activeStep
      }"
      :key="index"
      @click="emitGoToStep(step.id)"
    >
      <div class="step__content">
        <span>{{ index + 1 }}</span>
        <span>{{ step.text }}</span>
      </div>
    </button>
    <button
      class="direction"
      @click="emitGoToNextStep()"
    >
      <span>{{ nextText }}</span>
    </button>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'WizardBar',
  props: {
    /** 
     * the wizard steps, object like 
     * {
     *   id: string,
     *   text: string
     * }
     */
    steps: {
      type: Array,
      default: [],
    },
    /** 
     * the active step id
     */
    activeStep: {
      type: String,
      default: undefined,
    },
    /** 
     * the label for the previous button
     */
    previousText: {
      type: String,
      default: 'previous',
    },
    /** 
     * the label for the next button
     */
    nextText: {
      type: String,
      default: 'next',
    },
  },
  methods: {
    emitGoToPreviousStep() {
      const activeStepIndex = this.$props.steps.findIndex(step => step.id === this.$props.activeStep)
      const newStepIndex = (activeStepIndex > 0) ? activeStepIndex - 1 : activeStepIndex
      const newStepId = this.$props.steps[newStepIndex].id
      this.$emit('goToStep', newStepId)
    },
    emitGoToNextStep() {
      const activeStepIndex = this.$props.steps.findIndex(step => step.id === this.$props.activeStep)
      const newStepIndex = (activeStepIndex >= 0 && activeStepIndex < this.$props.steps.length - 1) ? activeStepIndex + 1 : activeStepIndex
      const newStepId = this.$props.steps[newStepIndex].id
      this.$emit('goToStep', newStepId)
    },
    emitGoToStep(stepId: string) {
      /**
       * goToStep event
       * @event goToStep
       * @type {string}
       */
      
      this.$emit('goToStep', stepId)
    },
  },
})
</script>
<style lang="scss" type="text/scss" scoped>
  .wizardbar {
    display: flex;
    flex: 1;
    height: 60px;
    button.step {
      flex: 1;
      border-style: none;
      position: relative;
      background: linear-gradient(to right, #080e19 , #1a3856);
      &:focus {
        outline: none;
      }
      &::after {
        content: "";
        border-top: 30px solid #080e19;
        border-bottom: 30px solid #080e19;
        border-left: 20px solid transparent;
        position: absolute;
        right: 0px;
        top: 0;
      }
      &.last{
        &::after  {
          border: none;
          position: absolute;
          right: 0px;
          top: 0;          
        }
      }
      .step__content {
        display: flex;
        flex-direction: column;
        span {
          color: #fff;
          text-transform: uppercase;
          font-family: Titillium Web,sans-serif;
        }
        @media screen and (max-width: 1364px) {
          font-size: 12px;
        }
      }
      &.active {
        background: linear-gradient(to right, #fa0f36,#78022d);
        .step__content {
          span {
            font-weight: 700;
          }
        }
      }
    }
    button.direction {
      border: none;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 800;
      color: #fff;
      min-width: 135px;
      background: #1a3856;
      background: linear-gradient(to bottom, #1a3856, #080e19);
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0,#1a3856),
        color-stop(.4,#080e19)
      );
      &:focus {
        outline: none;
      }
    }
  }
  .above-the-bar {
    bottom: 90px;
  }
</style>

<docs>
A wizard bar

Usage example
```js
new Vue({
  data: function() {
    return {
      steps: [
        {id: "id1", text: "wizard step 1"},
        {id: "id2", text: "wizard step 2"},
        {id: "id3", text: "wizard step 3"}
      ],
      activeStep: 'id1',
    }
  },
  methods: {
    onGoToStep(value) {
      console.log(value)
      this.activeStep = value
    },
  },
  template: `<v-app style="height:60px;background:transparent"><div style="display:flex">
    <wizard-bar 
      :steps="steps"
      :active-step="activeStep"
      @goToStep="onGoToStep"
    />
  </div></v-app>`
})
```
</docs>