<template>
  <card :header="'logtool.endpointrecorder' | translate"  :chip-text="chipText" :chip-color="chipColor" :collapsible="true" :expanded="expanded" @expandedChanged="expandedChanged">
    <template v-slot:actions>
      <card-action
        icon="add"
        tooltip="Create a new endpoint recording"
        :disabled="isInteracted || isLoading"
        @click="showCreateDialog = true"
      />
      <card-action
        icon="delete"
        tooltip="Clear all recordings"
        :disabled="isInteracted || isLoading || !endpointRecordingConfigurations.length"
        @click="showDeleteAllConfirmationDialog = true"
      />
      <card-action
        icon="download"
        tooltip="Download all recording data as CSV"
        :disabled="isInteracted || isLoading || !endpointRecordingConfigurations.length"
        @click="showDownloadDialog = true"
      />
      <card-action
        icon="refresh"
        tooltip="Reload configuration from PLC"
        :disabled="isInteracted || isLoading"
        @click="reloadEndpointRecordingConfigurations"
      />
      <card-action
        icon="delete"
        tooltip="Clear all logs"
        :disabled="isInteracted || isLoading || !endpointLogEntries.length"
        @click="clearEndpointLogEntries"
      />
    </template>
    <endpoint-recordings-table/>
    <edit-endpoint-recording-dialog 
      :show="showCreateDialog"
      @closed="showCreateDialog = false"
    />
    <v-tabs class="tabs">
      <v-tab>{{'logtool.device' | translate}}</v-tab>
      <v-tab>{{'logtool.control' | translate}}</v-tab>
      <v-tab>{{'logtool.endpoint' | translate}}</v-tab>
      <v-tab-item>
        <div class="read-config">
          <form-field class="read-device" :text="'logtool.device' | translate">
            <autocomplete-select 
              :options="deviceOptions"
              option-value="id"
              option-label="label"
              :selected-value="selectedDeviceId"
              :disabled="isInteracted"
              @selected="selectDevice($event)"
            />
          </form-field>
          <form-field class="read-endpoint" :text="'logtool.endpoint' | translate">
            <autocomplete-select
              :options="endpointOptions"
              option-value="id"
              option-label="label"
              :selected-value="endpoint"
              :disabled="isInteracted || !selectedDeviceId"
              @selected="endpoint = $event"
            />
          </form-field>
          <form-field class="read-endpoint-postfix" :text="'logtool.endpointpostfix' | translate">
            <text-input
              :value="endpointPostfix"
              :disabled="!endpoint"
              @input="endpointPostfix = $event"
            />
          </form-field>
          <base-text-button class="read-button" 
            @buttonClicked="readDeviceEndpoint(endpoint + endpointPostfix)"
            :enabled="!isInteracted && !isLoading && !!selectedDeviceId && !!endpoint"
          >
            {{ 'common.button.readonce' | translate }}
          </base-text-button>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="read-config">
          <form-field class="read-device" :text="'logtool.device' | translate">
            <autocomplete-select 
              :options="deviceOptions"
              option-value="id"
              option-label="label"
              :selected-value="selectedDeviceId"
              :disabled="isInteracted"
              @selected="selectDevice($event)"
            />
          </form-field>
          <form-field class="read-endpoint" :text="'logtool.control' | translate">
            <autocomplete-select
              :options="controlOptions"
              option-value="id"
              option-label="label"
              :selected-value="selectedControlId"
              :disabled="isInteracted || !selectedDeviceId"
              @selected="selectedControlId = $event"
            />
          </form-field>
          <base-text-button class="read-button" 
            @buttonClicked="readControlEndpoints(selectedControlId)"
            :enabled="!isInteracted && !isLoading && !!selectedDeviceId && !!selectedControlId"
          >
            {{ 'common.button.readonce' | translate }}
          </base-text-button>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="read-config">
          <form-field class="read-endpoint" :text="'logtool.endpoint' | translate">
            <text-input
              :value="endpoint"
              @input="endpoint = $event"
            />
          </form-field>
          <base-text-button class="read-button" 
            @buttonClicked="readPlcEndpoint(endpoint)"
            :enabled="!isInteracted && !isLoading && !!endpoint"
          >
            {{ 'common.button.readonce' | translate }}
          </base-text-button>
        </div>
      </v-tab-item>
    </v-tabs>
    <div class="log-entries-table">
      <virtual-log-entries-table :log-entries="endpointLogEntries"/>
    </div>
    <download-endpoint-recording-data-dialog
      :show="showDownloadDialog"
      @closed="showDownloadDialog = false"
    />
    <confirm-dialog
      :show="showDeleteAllConfirmationDialog"
      :header="'common.button.delete' | translate"
      :text="'common.dialog.confirm' | translate"
      :confirm-button-text="'OK'"
      :cancel-button-text="'common.button.cancel' | translate"
      :disabled="isInteracted"
      @confirmed="onClearConfirmed"
      @cancelled="showDeleteAllConfirmationDialog = false"
    />
  </card>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { DropdownOption } from '@ecocoach/shared-components/src'

import Card from '../../../../ui/Card.vue'
import CardAction from '../../../../ui/CardAction.vue'

import DownloadEndpointRecordingDataDialog from './DownloadEndpointRecordingDataDialog.vue'
import EditEndpointRecordingDialog from './EditEndpointRecordingDialog.vue'
import EndpointRecordingsTable from './EndpointRecordingsTable.vue'
import VirtualLogEntriesTable from './VirtualLogEntriesTable.vue'

import { EndpointRecordingViewModel, LogEntryModel } from '../../../../../store/modules/logToolUi/models'

const LogToolUi = namespace('logToolUi')

@Component({
  components: {
    Card,
    CardAction,
    DownloadEndpointRecordingDataDialog,
    EditEndpointRecordingDialog,
    EndpointRecordingsTable,
    VirtualLogEntriesTable,
  },
})
export default class EndpointRecorder extends Vue { 
  @Prop() public expanded: boolean
  @LogToolUi.Getter public isInteracted: boolean
  @LogToolUi.Getter public isLoading: boolean
  @LogToolUi.Getter public endpointRecordingConfigurations: EndpointRecordingViewModel[]
  @LogToolUi.Getter public deviceOptions: DropdownOption[]
  @LogToolUi.Getter public controlOptions: DropdownOption[]
  @LogToolUi.Getter public endpointOptions: DropdownOption[]
  @LogToolUi.Getter public selectedDeviceId: string
  @LogToolUi.Getter public endpointLogEntries: LogEntryModel[]
  @LogToolUi.Mutation public clearEndpointLogEntries: () => void
  @LogToolUi.Action public selectDevice: (id: string) => Promise<void>
  @LogToolUi.Action public readPlcEndpoint: (qualifiedEndpointPath: string) => Promise<void>
  @LogToolUi.Action public readDeviceEndpoint: (endpointPath: string) => Promise<void>
  @LogToolUi.Action public readControlEndpoints: (controlId: string) => Promise<void>
  @LogToolUi.Action public reloadEndpointRecordingConfigurations: () => Promise<void>
  @LogToolUi.Action public deleteAllEndpointRecordingConfigurations: () => Promise<void>

  public showCreateDialog = false
  public showDownloadDialog = false
  public showDeleteAllConfirmationDialog = false
  public selectedControlId = ''
  public endpoint = ''
  public endpointPostfix = ''

  public get chipText() {
    return this.endpointRecordingConfigurations.length.toString()
  }

  public get chipColor() {
    return this.endpointRecordingConfigurations.length ? 'red' : 'gray'
  }

  public async onClearConfirmed() {
    await this.deleteAllEndpointRecordingConfigurations()
    this.showDeleteAllConfirmationDialog = false
  }

  @Emit('expandedChanged') 
  public expandedChanged(expanded: boolean) {
    return expanded
  }
}
</script>
<style lang="scss" scoped>
.read-config {
  display: flex;
  align-items: center;
  .read-device {
    flex: 1;
  }
  .read-endpoint {
    flex: 1;
  }
  .field {
    height: 70px;
    .text-input {
      padding-left: 10px;
      padding-right: 10px;
    }
    .label {
      padding-left: 10px;
    }
  }
}
.log-entries-table {
  height: 250px;
}
.v-btn {
  border: 1px solid white;
}
</style>
<style lang="scss">
.theme--dark.v-tabs__bar {
  background-color: transparent !important;
  margin-bottom: 10px;
}
</style>
