import { AppMutation, AppState } from '@/store/modules/app/types'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<AppState> = {
  [AppMutation.setInteracted](state, isInteracted: boolean) {
    state.interacted = isInteracted
  },
  [AppMutation.setNavigationDrawerMinimized](state, minimized: boolean) {
    state.navigationDrawerMinimized = minimized
  },
  [AppMutation.loading] (state: AppState) {
    state.hydrated = false
  },
  [AppMutation.loaded] (state: AppState) {
    state.hydrated = true
  },
  [AppMutation.setLoadingFailure] (state: AppState) {
    state.loadingApiFailed = true
  },
  [AppMutation.selectLanguage](state: AppState, language: string) {
    state.selectedLanguage = language
  },
  [AppMutation.setProjectSelection] (state: AppState, projectId: string) {
    state.selectedProjectId = projectId
  },
  [AppMutation.setSelectedPlc](state, plcId: string) {
    state.selectedPlcId = plcId
  },
}