<template>
  <div>
    <releases-list
      class="releases-table"
      @selectedRelease="selectRelease"
    />
    <div v-if="selectedReleaseId" class="next-button-wrapper">
      <base-text-button
        :enabled="!isInteracted"
        @buttonClicked="showReleasePropertiesDialog = true">
        {{'releases.edit' | translate}}
      </base-text-button>
      <base-text-button
        :enabled="!isInteracted && canDeleteRelease"
        @buttonClicked="askForDeleteReleaseConfirmation(selectedReleaseId)">
        {{'common.button.delete' | translate}}
      </base-text-button>
      <base-text-button v-if="canDownloadRelease"
        :enabled="!isInteracted"
        @buttonClicked="downloadRelease(selectedReleaseId)">
        {{'common.button.download' | translate}}
      </base-text-button>
      <base-text-button v-if="canExportRelease"
        :enabled="!isInteracted"
        @buttonClicked="exportRelease(selectedReleaseId)">
        {{'common.button.export' | translate}}
      </base-text-button>
      <base-text-button
        :enabled="!isInteracted"
        @buttonClicked="useCompletedRelease()">
        {{'releases.load' | translate}}
      </base-text-button>
      <base-text-button
        :enabled="!isInteracted"
        @buttonClicked="useReleaseForCopy()">
        {{'releases.choose' | translate}}
      </base-text-button>
    </div>
    <set-release-properties-dialog
      :show="showReleasePropertiesDialog"
      :disabled="isInteracted"
      :show-is-template-option="true"
      :show-update-template-operation-data-option="selectedReleaseIsObjectOrientedArchitecture"
      :show-archive-option="false"
      :initial-name="selectedReleaseName"
      :initial-description="selectedReleaseDescription"
      :initial-is-template="selectedReleaseIsTemplate"
      @confirmed="onSetReleasePropertiesConfirmed($event)"
      @cancelled="onSetReleasePropertiesCancelled"
    />
    <confirm-dialog
      :show="releaseIdToDelete"
      :header="'common.button.delete' | translate"
      :text="'common.dialog.confirm' | translate"
      :confirm-button-text="'OK'"
      :cancel-button-text="'common.button.cancel' | translate"
      :disabled="isInteracted"
      @confirmed="onDeleteReleaseConfirmed"
      @cancelled="releaseIdToDelete = null"
    />
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import ReleasesList from './ReleasesList.vue'

import SetReleasePropertiesDialog from '../shared/SetReleasePropertiesDialog.vue'

import { ReleaseProperties } from '../../../../../store/modules/releaseUi/models'

import { ArchitectureType } from '../../../../../../../eco-domain-store-modules/src/common'
import { ReleaseModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'
import { FeatureSwitches } from '../../../../../../../eco-domain-store-modules/src/userSettings/models'

const Wizard = namespace('wizard')
const App = namespace('app')
const ReleaseUi = namespace('releaseUi')
const UserSettings = namespace('userSettings')

@Component({
  components: {
    ReleasesList,
    SetReleasePropertiesDialog,
  },
})
export default class OldOptionContent extends Vue {
  @Prop() public preventCopy: boolean
  @Wizard.Action public goToStep
  @Wizard.Getter public wizardSteps
  @Wizard.Mutation public closeReleaseDialog
  @ReleaseUi.Action public copyRelease: (releaseId: string) => Promise<void>
  @ReleaseUi.Action public loadReleaseDetails: (releaseId: string) => Promise<void>
  @App.Getter public selectedLanguage: string
  @App.Getter public userIdentifier: string
  @App.Getter public isEcocoachEmployee: boolean
  @ReleaseUi.Mutation public setInteracted
  @ReleaseUi.Getter public isInteracted
  @ReleaseUi.Getter public isCurrentlyDeployed: (releaseId: string) => boolean
  @ReleaseUi.Getter public releaseById: (id: string) => ReleaseModel
  @ReleaseUi.Getter public currentReleaseShouldBeUpgraded: boolean
  @ReleaseUi.Getter public currentNewRelease: ReleaseModel
  @ReleaseUi.Action public setProperties: (payload: { releaseId: string, input: ReleaseProperties }) => Promise<void>
  @ReleaseUi.Action public deleteRelease: (id: string) => Promise<void>
  @ReleaseUi.Action public downloadRelease: (id: string) => Promise<void>
  @ReleaseUi.Action public exportRelease: (id: string) => Promise<void>
  @ReleaseUi.Action public upgradeRelease: (useInTestDefinitions: boolean) => Promise<void>
  @ReleaseUi.Mutation public showReleaseUpgradeConfirmationDialog: () => void
  @UserSettings.Getter public isFeatureOn: (featureId: string) => boolean

  @Emit('copyPrevented')
  public onCopyPrevented() {
    return
  }

  public showReleasePropertiesDialog = false
  public releaseIdToDelete: string | null = null

  public selectedReleaseId = ''

  public async mounted() {
    this.selectedReleaseId = ''
  }

  public get canDownloadRelease() {
    return this.isFeatureOn(FeatureSwitches.downloadProjectFilesFromUranus)
  }

  public get canExportRelease() {
    return this.isEcocoachEmployee && this.releaseById(this.selectedReleaseId)?.architectureType === ArchitectureType.ObjectOriented
  }

  public async useReleaseForCopy() {
    if (this.preventCopy) {
      this.onCopyPrevented()
      return
    }
    await this.copyRelease(this.selectedReleaseId)
    if (this.shouldShowReleaseUpgradeConfirmationDialog) {
      this.showReleaseUpgradeConfirmationDialog()
      return
    }
    if (!this.isEcocoachEmployee) {
      await this.upgradeReleaseSilentlyIfRequired()
    }
    this.closeReleaseDialog()
    const step = this.wizardSteps.find(s => s.name === 'defineRooms')
    this.goToStep({step, router: this.$router})
  }

  public get shouldShowReleaseUpgradeConfirmationDialog() {
    return this.currentNewRelease.architectureType === ArchitectureType.ObjectOriented
      ? this.isEcocoachEmployee
      : (this.currentReleaseShouldBeUpgraded || this.isEcocoachEmployee)
  }

  public async upgradeReleaseSilentlyIfRequired() {
    if (!this.isEcocoachEmployee && this.currentNewRelease.architectureType === ArchitectureType.ObjectOriented) {
      await this.upgradeRelease(false)
    }
  }

  public async useCompletedRelease() {
    await this.loadReleaseDetails(this.selectedReleaseId)
    this.closeReleaseDialog()
    const step = this.wizardSteps.find(s => s.name === 'defineRooms')
    this.goToStep({step, router: this.$router})
  }

  public selectRelease(id: string) {
    this.selectedReleaseId = id
  }

  public get selectedReleaseName(): string {
    return this.selectedReleaseId ? this.releaseById(this.selectedReleaseId).name! : ''
  }

  public get selectedReleaseDescription() {
    return this.selectedReleaseId ? this.releaseById(this.selectedReleaseId).description : ''
  }

  public get selectedReleaseIsTemplate(): boolean {
    return this.selectedReleaseId ? this.releaseById(this.selectedReleaseId).isTemplate : false
  }

  public get selectedReleaseIsObjectOrientedArchitecture(): boolean {
    return this.selectedReleaseId ? this.releaseById(this.selectedReleaseId).architectureType === ArchitectureType.ObjectOriented : false
  }

  public async onSetReleasePropertiesConfirmed(releaseProperties: ReleaseProperties) {
    await this.setProperties({
      releaseId: this.selectedReleaseId,
      input: releaseProperties,
    })
    this.showReleasePropertiesDialog = false
  }

  public onSetReleasePropertiesCancelled() {
    this.showReleasePropertiesDialog = false
  }

  public get canDeleteRelease() {
    return !this.isCurrentlyDeployed(this.selectedReleaseId)
  }

  public askForDeleteReleaseConfirmation(releaseId: string) {
    this.releaseIdToDelete = releaseId
  }

  public async onDeleteReleaseConfirmed() {
    this.selectedReleaseId = ''
    await this.deleteRelease(this.releaseIdToDelete!)
    this.releaseIdToDelete = null
  }
}
</script>
<style lang="scss" scoped>
.next-button-wrapper {
  padding-top: 5px;
  display: flex;
  justify-content: flex-end;
  height: 53px;
}
.v-btn {
  border: solid 1px white;
}
</style>