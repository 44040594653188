var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{class:{
    'labeledControl': true,
    'column': _vm.direction!== 'row',
    'row': _vm.direction === 'row',
    'align-center': _vm.alignCenter,
    'align-left': _vm.alignLeft,
    'align-start': _vm.alignStart,
  }},[_c('v-flex',{class:{'labelContainer': true}},[_c('label-component',{attrs:{"text":_vm.label,"description-title":_vm.label,"description-content":_vm.tooltip}}),_c('div',[_vm._t("hint")],2)],1),_c('v-flex',{class:{'left-padding': (_vm.direction === 'row')},attrs:{"shrink":""}},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }