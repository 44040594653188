import ApiService from '../../services/api.service'
import { MfaConfigurationModel } from '../models'

export default {
  async currentUserFeatureSwitches(): Promise<string[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().userSettingsApi}/FeatureSwitches/forUser`)
    return response.data.value
  },
  async currentUserMfaConfiguration(): Promise<MfaConfigurationModel> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().userSettingsApi}/Accounts/mfa/configuration`)
    return response.data
  },
  async updateMfaConfiguration(mfaConfiguration: MfaConfigurationModel): Promise<void> {
    await ApiService.put(`${ApiService.backendEnvironmentConfiguration().userSettingsApi}/Accounts/mfa/configuration`, mfaConfiguration)
  },

}
