<template>
  <v-card dark class="room">
    <v-card-title primary-title>
      <h3>{{ 'rooms.rooms' | translate }} </h3>
      <span style="flex: 1;"></span>
    </v-card-title>
    <v-container class="room-form-content" style="min-height: 282px;">
      <draggable v-model="draggableList" class="room-selector"
        animation="200"
        ghost-class="dragdrop-preview"
        chosen-class="dragdrop-chosen"
        drag-class="dragdrop-dragging"
      >
        <div v-for="room in draggableList"
          :roomId="room.id"
          :key="room.id"
          :class="{ active: room.id === getRoomInputProperty('id') }"
          @click="editRoom(room.id)"
          class="button circle">
          <div class="sortHandler">
            <v-tooltip bottom>
              <v-btn 
                slot="activator" 
                class="sorter"
                @mousedown="cancelRoom"
                icon flat><v-icon>drag_handle</v-icon></v-btn>
              <span>{{ 'common.button.sortorder' | translate }}</span>
            </v-tooltip>
          </div>
          <div class="room-button-content">
            <svg-icon :icon="room.icon" width="35" height="35"/>
            <span>{{ room.name }}</span>
          </div>
        </div>
      </draggable>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import IconButton from '@/components/ui/IconButton.vue'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Draggable from 'vuedraggable'

import SvgIcon from '../../../../ui/SvgIcon.vue'

import { RoomModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'

const RoomUi = namespace('roomUi')

@Component({
  components: {
    Draggable,
    IconButton,
    SvgIcon,
  },
})

export default class RoomList extends Vue {
  @RoomUi.Getter public rooms: RoomModel[]
  @RoomUi.Getter public getRoomInputProperty: (property: string) => any
  @RoomUi.Action public editRoom: (id: string) => Promise<void>
  @RoomUi.Action public cancelRoom: () => Promise<void>
  @RoomUi.Action public sortRooms: (ids: string[]) => Promise<void>

  public get draggableList(): RoomModel[] {
    return this.rooms
  }

  public set draggableList(value: RoomModel[]) {
    this.sortRooms(value.map(v => v.id))
  }
}
</script>
<style lang="scss">
.v-input-group__details {
        display: none !important;
      }
</style>

<style lang="scss" scoped>
.room {
  width: 372px !important;
  background-image: radial-gradient(circle at center top, #1a3856, #080e19);
  margin-top: 10px;
  margin-left: 35px;
  margin-bottom: 75px;
}
.v-card__title {
  background: linear-gradient(to bottom, #1a3856, #080e19);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #1a3856), color-stop(0.6, #080e19));
}
.v-card__title--primary {
  padding-top: 15px;
}
.v-card__actions {
  padding: 0;
  .button {
    margin: 0;
  }
}
.room-selector {
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-flow: row;
  grid-area: room;
  outline: none;
}
.button.circle {
  width: 130px;
  height: 130px;
  border: 2px solid #080e19;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 130px;
  margin: 20px;
  &.active {
    border: 2px solid #fa0f36;
  }
  &:focus {
    outline: none;
  }
  cursor: pointer;
  .sortHandler{
    width: 50px;
    height: 24px;
    margin-bottom: 20px;
    margin-left: calc(50% - 25px);
    .sorter {
      cursor: grab;
    }
  }
  .room-button-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 25px;
    img {
      display: inline-block;
    }
    span {
      font-size: 10px;
      margin-top: 5px;
      text-transform: uppercase;
      font-family: Titillium Web, sans-serif;
      color: #fff;
    }
  }
}
.dragdrop-preview, .dragdrop-chosen {
  background: #1a3856;
  opacity: 0.7;
}
.dragdrop-dragging {
  opacity: 0;
}
</style>