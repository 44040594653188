import { EnergyStatusDevice, EnergyStatusItem } from './models'

export interface EnergyStatusState {
  projectId: string
  projectName: string
  isKioskMode: boolean
  energyStatusDevices: EnergyStatusDevice[]
  energyStatusItemsFilter: (control: EnergyStatusItem) => boolean
}

export enum EnergyStatusAction {
  loadEnergyStatusData = 'loadEnergyStatusData',
  connectHub = 'connectHub',
  disconnectHub = 'disconnectHub',
  setEnergyStatusItemsFilter = 'setEnergyStatusItemsFilter',
  startNotifications = 'startNotifications',
  stopNotifications = 'stopNotifications',
  energyStatusChangesReceived = 'energyStatusChangesReceived',
}

export enum EnergyStatusMutation {
  setProjectId = 'setProjectId',
  setProjectName = 'setProjectName',
  setKioskMode = 'setKioskMode',
  setEnergyStatusData = 'setEnergyStatusData',
  setEnergyStatusValue = 'setEnergyStatusValue',
  setEnergyStatusItemsFilter = 'setEnergyStatusItemsFilter',
}

export enum EnergyStatusGetter {
  producers = 'producers',
  consumers = 'consumers',
  productionContributions = 'productionContributions',
  consumptionContributions = 'consumptionContributions',
}
