<template>
    <v-list-tile>
        <v-list-tile-content>           
            <v-list-tile-title :title="title" class="label-with-icon">
                <svg-icon :icon="functionBlock.iconResourceId" width="24" height="20" class="icon"/>
                <div class="label">{{ title }}</div>
            </v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action>
            <description-popover
                :header="title"
                :content="info">
            </description-popover>
        </v-list-tile-action>
        <v-list-tile-action class="action">
            <v-tooltip bottom>
                <v-btn slot="activator" icon>
                    <v-icon class="actionIcon">{{stateIcon}}</v-icon>
                </v-btn>
                <span>{{functionBlock.state}}</span>
            </v-tooltip>
        </v-list-tile-action>
        <v-list-tile-action class="action">
            <v-tooltip bottom>
                <v-btn slot="activator" icon ripple @click.native="createNewVersion">
                    <v-icon class="actionIcon">add</v-icon>
                </v-btn>
                <span>Create new version</span>
            </v-tooltip>
        </v-list-tile-action>
        <v-list-tile-action class="lastAction">
            <function-definition-tree-item-actions
                :functionBlock="functionBlock"
                @setActive="setActive"
                @setObsolete="setObsolete"
                @editReleaseNotes="editReleaseNotes"
                @downloadXmlFile="downloadXmlFile"
                @copyIdToClipboard="copyIdToClipboard"
            />
        </v-list-tile-action>
    </v-list-tile>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import FunctionDefinitionTreeItemActions from './FunctionDefinitionTreeItemActions.vue'

import SvgIcon from '../../ui/SvgIcon.vue'

import { FunctionBlockTreeItemViewModel } from '../../../store/modules/functionBlockUi/models'

@Component({
  components: {
    FunctionDefinitionTreeItemActions,
    SvgIcon,
  },
})
export default class FunctionDefinitionTreeItem extends Vue {
  @Prop() public functionBlock: FunctionBlockTreeItemViewModel

  public get title() {
    return `${this.functionBlock.name}, Version: ${this.functionBlock.version}`
  }

  public get info() {
    return [
      `${this.functionBlock.description}`,
      `Internal name: ${this.functionBlock.internalName}`,
      `Version: ${this.functionBlock.version}`,
      `State: ${this.functionBlock.state}`,
      `Release notes: ${this.functionBlock.releaseNotes || 'n/a'}`,
    ].join('<br>')
  }
  
  @Emit('createNewVersion') 
  public createNewVersion () {
    return
  }

  @Emit('setActive')
  public setActive () {
    return
  }

  @Emit('setObsolete')
  public setObsolete () {
    return
  }

  @Emit('editReleaseNotes') 
  public editReleaseNotes () {
    return
  }

  @Emit('downloadXmlFile')
  public downloadXmlFile() {
    return
  }

  @Emit('copyIdToClipboard')
  public copyIdToClipboard() {
    return
  }

  public get stateIcon() {
    switch (this.functionBlock.state) {
    case 'Active': return 'check_circle'
    case 'InTest': return 'bug_report'
    case 'Obsolete': return 'warning'
    default: return 'help'
    }
  }
}
</script>
<style lang="scss" scoped>
.label-with-icon {
    display: flex;
    .icon {
        margin-right: 10px;
        margin-top: 2px;
    }
    .label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.actionIcon {
    color: white;    
}
.action {
    min-width: 24px;
}
.lastAction {
    min-width: 24px;
}
</style>