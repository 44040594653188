import fileHelper from '../../helpers/fileHelper'
import ApiService from '../../services/api.service'
import {
  ControlModelV2,
  ExecuteControlCommandPayload,
  SceneModel,
  SceneTimeScheduleModel,
  SetControlAppDisplayLevelPayload,
  SetControlColorGradientPayload,
  SetDisplayNamePayload,
} from '../models'

export default {
  async addArrayEntry(controlId: string, language: string): Promise<ControlModelV2[]> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Controls/${controlId}/addArrayEntry/?resourceLanguage=${language}`, {})
    response.data.dynamicControlsV2.forEach(c => c.state = {})
    return response.data.dynamicControlsV2
  },
  async removeArrayEntry(controlId: string, entryControlId: string): Promise<string[]> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Controls/${controlId}/removeArrayEntry/${entryControlId}`, {})
    return response.data.removedControlIds
  },
  async executeControlCommand(controlId: string, payload: ExecuteControlCommandPayload): Promise<{ correlationId: string }> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Commands/control/${controlId}/execute`, payload)
    return response.data
  },
  async setDisplayName(controlId: string, payload: SetDisplayNamePayload): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Controls/${controlId}/setDisplayName`, payload)
  },
  async setAppDisplayLevel(controlId: string, payload: SetControlAppDisplayLevelPayload): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Controls/${controlId}/setAppDisplayLevel`, payload)
  },
  async setControlColorGradient(controlId: string, payload: SetControlColorGradientPayload): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Controls/${controlId}/setColorGradient`, payload)
  },
  async setDeviceIgnoreInCharts(deviceId: string, ignoreInCharts: boolean): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Device/${deviceId}/setIgnoreInCharts`, {
      ignoreInCharts,
    })
  },
  async setDeviceConsumptionValidationEnabled(deviceId: string, consumptionValidationEnabled: boolean): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Device/${deviceId}/setConsumptionValidation`, {
      consumptionValidationEnabled,
    })
  },
  async setConsumptionProcessOwnerLockEnabled(deviceId: string, consumptionProcessOwnerLockEnabled: boolean): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Device/${deviceId}/setConsumptionProcessOwnerLock`, {
      consumptionProcessOwnerLockEnabled,
    })
  },
  async setMeasuringPointColor(deviceId: string, measuringPointId: string, colorGradient: string): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Device/${deviceId}/setMeasuringPointColor/${measuringPointId}`, {
      colorGradient,
    })
  },
  async setEnergyStatusItemColor(deviceId: string, energyStatusItemId: string, colorGradient: string): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Device/${deviceId}/setEnergyStatusItemColor/${energyStatusItemId}`, {
      colorGradient,
    })
  },  
  async createFavorite(projectId: string, deviceId: string): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Projects/${projectId}/favorites/${deviceId}`, {})
  },
  async deleteFavorite(projectId: string, deviceId: string): Promise<void> {
    await ApiService.delete(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Projects/${projectId}/favorites/${deviceId}`, {})
  },
  async createScene(scene: SceneModel): Promise<{ id: string, unauthorizedControlIds: string[] }> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Projects/${scene.projectId}/scenes`, scene)
    return response.data
  },
  async modifyScene(scene: SceneModel): Promise<{ id: string, unauthorizedControlIds: string[] }> {
    const response = await ApiService.put(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Projects/${scene.projectId}/scenes/${scene.id}`, scene)
    return response.data
  },
  async deleteScene(projectId: string, sceneId: string): Promise<void> {
    await ApiService.delete(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Projects/${projectId}/scenes/${sceneId}`, {})
  },
  async activateScene(projectId: string, sceneId: string): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Projects/${projectId}/scenes/${sceneId}/activate`, {})
  },
  async createSceneTimeSchedule(projectId: string, sceneId: string, schedule: SceneTimeScheduleModel): Promise<{ id: string }> {
    const response =  await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Projects/${projectId}/scenes/${sceneId}/schedule`, schedule)
    return { 
      id: response.data.id,
    }
  },
  async modifySceneTimeSchedule(projectId: string, sceneId: string, schedule: SceneTimeScheduleModel): Promise<void> {
    await ApiService.put(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Projects/${projectId}/scenes/${sceneId}/schedule/${schedule.id}`, schedule)
  },
  async deleteSceneTimeSchedule(projectId: string, sceneId: string, scheduleId: string): Promise<void> {
    await ApiService.delete(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Projects/${projectId}/scenes/${sceneId}/schedule/${scheduleId}`, {})
  },
  async deleteAlarmDefinition(plcId: string, id: string): Promise<void> {
    await ApiService.delete(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Alarms/${plcId}/alarmDefinitions/${id}`, {})
  },
  async initializePlc(plcId: string, fileName: string): Promise<void> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Plcs/${plcId}/initializePlc2/true`, {
      responseType: 'blob',
    })
    fileHelper.saveBlobToFile(response.data, fileName)
  },
  async migratePlcToEcoCloudConnector(plcId: string): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Plcs/${plcId}/migrateEcoCloudConnector`, {})
  },
  async confirmDevice(plcId: string, deviceId: string): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/${plcId}/devices/${deviceId}/confirm`, {})
  },
  async unconfirmDevice(plcId: string, deviceId: string): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/${plcId}/devices/${deviceId}/undoConfirmation`, {})
  },
  async restartPlc(plcId: string): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Plcs/${plcId}/restartPlc`, {})
  },
  async restartEcoCloudConnector(plcId: string): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Plcs/${plcId}/restartEcoCloudConnector`, {})
  },
  async deployLatestPlcEcoCloudConnectorRelease(plcId: string, forceUpdate: boolean): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Plcs/${plcId}/deployLatestPlcEcoCloudConnectorRelease`, {
      forceUpdate,
    })
  },
  async deployPlcEcoCloudConnectorRelease(plcId: string, versionId: string, forceUpdate: boolean): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Plcs/${plcId}/deployPlcEcoCloudConnectorRelease/${versionId}`, {
      deployInTestPlcEcoCloudConnector: true,
      forceUpdate,
    })
  },
  async deployComponentFirmware(plcId: string, versionId: string, ipAddress: string): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/ComponentFirmware/${plcId}/deploy/${versionId}`, {
      ipAddress,
    })
  },
  async resetPlcConfigurationRelease(plcId: string): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/Plcs/${plcId}/plcConfigurationReset`, {})
  },
}
