import { ArchitectureType } from '@ecocoach/domain-store-modules/src/common'
import { PlcModel } from '@ecocoach/domain-store-modules/src/plc/models'

export interface PlcViewModel extends PlcModel {
  status: PlcStatus
  lastOnline: string
  eccUpdateAvailable: boolean
  eccUpdateEnabled: boolean
  eccTestUpdateAvailable: boolean
  eccTestUpdateEnabled: boolean
  eccInstalled: boolean
}

export enum PlcStatus {
  Online = 'Online',
  PartiallyOnline = 'PartiallyOnline',
  Offline = 'Offline',
  Updating = 'Updating',
  Unknown = 'Unknown',
}

export interface PlcTableFilterSettings {
  projectIds: string[]
  plcName: string
  plcTypes: string[]
  versions: string[]
  statuses: string[]
  alarms: boolean[]
}

export interface PlcSupportData {
  plcId: string
  architectureType: ArchitectureType | null
  functionBlockDefinitions: Array<{
    internalName: string
    version: string
  }>
}

export const PlcTableFilterSettingsStorageKey = 'plcFilterSettings'
