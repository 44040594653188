<template>
  <div class="old-releases">
    <div class="overview">
      <old-option-selector :active="oldOptionActive" @selected="useOldReleaseOption"/>
      <template-option-selector :active="templateOptionActive" @selected="useTemplateOption"/>
      <new-option-object-oriented :active="newOptionObjectOrientedActive" @selected="useNewOptionObjectOriented"/>
      <new-option-classic :active="newOptionClassicActive" @selected="useNewOptionClassic" />
    </div>
    <old-option-content v-if="oldOptionActive" />
    <template-option-content v-if="templateOptionActive"/>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import NewOptionClassic from './NewOptionClassic.vue'
import NewOptionObjectOriented from './NewOptionObjectOriented.vue'
import OldOptionContent from './OldOptionContent.vue'
import OldOptionSelector from './OldOptionSelector.vue'
import TemplateOptionContent from './TemplateOptionContent.vue'
import TemplateOptionSelector from './TemplateOptionSelector.vue'

@Component({
  components: {
    NewOptionClassic,
    NewOptionObjectOriented,
    OldOptionContent,
    OldOptionSelector,
    TemplateOptionContent,
    TemplateOptionSelector,
  },
})
export default class OldReleases extends Vue {
  public oldOptionActive = true
  public templateOptionActive = false
  public newOptionClassicActive = false
  public newOptionObjectOrientedActive = false

  public useOldReleaseOption() {
    this.oldOptionActive = true
    this.templateOptionActive = false
    this.newOptionClassicActive = false
    this.newOptionObjectOrientedActive = false
  }

  public useTemplateOption() {
    this.oldOptionActive = false
    this.templateOptionActive = true
    this.newOptionClassicActive = false
    this.newOptionObjectOrientedActive = false
  }

  public useNewOptionClassic() {
    this.oldOptionActive = false
    this.templateOptionActive = false
    this.newOptionClassicActive = true
    this.newOptionObjectOrientedActive = false
  }

  public useNewOptionObjectOriented() {
    this.oldOptionActive = false
    this.templateOptionActive = false
    this.newOptionClassicActive = false
    this.newOptionObjectOrientedActive = true
  }
}
</script>
<style lang="scss" scoped>
.old-releases {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 560px;
}
.overview {
  color: white;
  flex: 0;
  min-height: 290px;
  display: flex;
  flex-direction: row; 
  justify-content: center;
}
</style>