import { render, staticRenderFns } from "./PlcTableVersion.vue?vue&type=template&id=4f9321e8&scoped=true"
import script from "./PlcTableVersion.vue?vue&type=script&lang=ts"
export * from "./PlcTableVersion.vue?vue&type=script&lang=ts"
import style0 from "./PlcTableVersion.vue?vue&type=style&index=0&id=4f9321e8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.9.1_prettier@2.8.8_vue-template-compiler@2.6.6_webpack@5.90.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f9321e8",
  null
  
)

export default component.exports