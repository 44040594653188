import { PlcSupportData, PlcTableFilterSettings } from './models'

export interface SelectPlcUiState {
  interacted: boolean
  filterSettings: PlcTableFilterSettings
  numberOfMessagesInQueue: number
  supportData: PlcSupportData
}

export enum SelectPlcUiAction {
  initialize = 'initialize',
  migratePlcToEcoCloudConnector = 'migratePlcToEcoCloudConnector',
  updateToLatestEcoCloudConnector = 'updateToLatestEcoCloudConnector',
  updateToLatestInTestEcoCloudConnectorVersion = 'updateToLatestInTestEcoCloudConnectorVersion',
  updateTrumpfInverterFirmware = 'updateTrumpfInverterFirmware',
  restartEcoCloudConnector = 'restartEcoCloudConnector',
  restartPlc = 'restartPlc',
  resetPlcConfigurationRelease = 'resetPlcConfigurationRelease',
  collectSupportData = 'collectSupportData',
  loadFilterSettings = 'loadFilterSettings',
  updateFilterSettings = 'updateFilterSettings',
}

export enum SelectPlcUiMutation {
  setInteracted = 'setInteracted',
  setNumberOfMessagesInQueue = 'setNumberOfMessagesInQueue',
  setSupportData = 'setSupportData',
}

export enum SelectPlcUiGetter {
  isInteracted = 'isInteracted',
  filterSettings = 'filterSettings',
  plcs = 'plcs',
  projects = 'projects',
  plcTypes = 'plcTypes',
  versions = 'versions',
  statuses = 'statuses',
  alarms = 'alarms',
  filteredPlcs = 'filteredPlcs',
  trumpfInverterFirmwareVersions = 'trumpfInverterFirmwareVersions',
  numberOfMessagesInQueue = 'numberOfMessagesInQueue',
  activeEccReleaseVersion = 'activeEccReleaseVersion',
  latestTestEccReleaseVersion = 'latestTestEccReleaseVersion',
  supportData = 'supportData',
}
