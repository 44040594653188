import { RoomUiState } from '@/store/modules/roomUi/types'

export const state: RoomUiState = {
  selectedRoomId: '',
  roomInput: {
    id: '',
    name: '',
    icon: '',
    sortOrder: 0,
  },
  interacted: false,
}
