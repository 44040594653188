<template lang="html">
    <div class="checkinput">
      <input
        :checked="isChecked"
        :readonly="disabled"
        type="checkbox">
      <label
        :style="gradientBackgroundStyle"
        @click="emitButtonToggled()"/>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
import BaseToggleButton from '../base/BaseToggleButton.vue'

export default Vue.extend({
    name: 'BaseCheckbox',
    mixins: [BaseToggleButton],
    props: {
      activePrimaryColor: {
        type: String,
        default: 'white',
      },
      activeSecondaryColor: {
        type: String,
        default: 'white',
      },
      inactivePrimaryColor: {
        type: String,
        default: 'grey',
      },
      inactiveSecondaryColor: {
        type: String,
        default: 'grey',
      },
    },
})
</script>
<style lang="scss" scoped>
.checkinput {
		padding-right: 1px;
		display: flex;
	}
	input {
    display: none;
	}
	label {
		box-sizing: border-box;
		position: relative;
		display: inline-block;
		width: 26px;
		height: 24px;
		border: 2px solid transparent;
		border-radius: 2px;
		box-shadow: 0 0 0 1px darkgrey;
  }
  input ~ label {
		box-sizing: border-box;
		border: 2px solid #121620;
  }
	input:checked ~ label {
		&::after {
			position: absolute;
			display: inline-block;
			content: '';
			top: 4px;
			left: 5px;
			width: 13px;
			height: 8px;
			border-left: 3px solid #000;
			border-bottom: 3px solid #000;
			border-top: 0;
			border-right: 0;
			transform: rotate(310deg);
		}
  }
</style>

<docs>
A checkbox

Usage example
```jsx
new Vue({
  data: function() {
    return {
      value: false
    }
  },
  methods: {
    onSelected(value) {
      console.log('firstToggleState', value)
      this.value = !value
    }
  },
  template: `<v-app style="height: 48px;background:transparent">
   <base-checkbox
      :value="value"
      @buttonToggled="onSelected"
      />
  </v-app>`
})
```

Interacted/pending state
```jsx
new Vue({
  data: function() {
    return {
      value: false,
    }
  },
  methods: {
    onSelected(value) {
      console.log('firstToggleState', value)
      this.value = !value
    }
  },
  template: `<v-app style="height: 48px;background:transparent">
   <base-checkbox
      :label-text="'alarm with info'"
      :information-text="'this could be some error info'"
      :current-value="value"
      :pending-value="!value"
      @buttonToggled="onSelected"
      />
  </v-app>`
})
```

Advanced usage example with pending state until removed
```jsx
new Vue({
  data: function() {
    return {
      currentValue: false,
      pendingValue: null,
    }
  },
  methods: {
    onButtonToggled(value) {
      console.log(value)
      this.pendingValue = !value
      setTimeout(() => {
        this.currentValue = !value
        this.pendingValue = null
      }, 2000)
    }
  },
  template: `
    <base-checkbox
     :value="currentValue"
     @buttonToggled="onButtonToggled"
     :pending-value="pendingValue"/>
  `
})
```

Disabled
```jsx
  <base-checkbox
    :value="true"
    :disabled="true"
  />
```

Custom gradient colors
```js
new Vue({
  data: function() {
    return {
      value: false
    }
  },
  methods: {
    onButtonToggled(value) {
      console.log(value)
      this.value = !value
    }
  },
  template: `
    <base-checkbox
     :value="value"
     activePrimaryColor="pink"
     activeSecondaryColor="green"
     inactivePrimaryColor="blue"
     inactiveSecondaryColor="black"
     @buttonToggled="onButtonToggled"
    />
  `
})
```
</docs>
