import { GetterTree } from 'vuex'
import { ControlModelV2, DeviceModel, PendingControlCommand, RoomModel, SceneModel } from './models'
import { PlcOperationGetter, PlcOperationState } from './types'

export const getters: GetterTree<PlcOperationState, {}> = {
  [PlcOperationGetter.devices]({ devices }): DeviceModel[] {
    return devices
  },
  [PlcOperationGetter.devicesOfRoom]({ devices }) {
    return (roomId: string) => {
      return devices.filter(device => device.roomId === roomId)
    }
  },
  [PlcOperationGetter.rooms]({ rooms }): RoomModel[] {
    return rooms
  },
  [PlcOperationGetter.roomsOfPlc] ({ rooms }) {
    return (plcId: string) => {
      return rooms.filter(room => room.plcId === plcId)
    }
  },
  [PlcOperationGetter.controls]({ controls }): ControlModelV2[] {
    return controls
  },
  [PlcOperationGetter.filteredControls]({ controls, controlsFilter }): ControlModelV2[] {
    return controls.filter(controlsFilter)
  },
  [PlcOperationGetter.hasControlUpdateFailed] (state) {
    return state.controlUpdateFailed
  },
  [PlcOperationGetter.hasControlUpdateTimeout] (state) {
    return state.controlUpdateTimeout
  },
  [PlcOperationGetter.pendingControlCommand] (state) {
    return (controlId: string): PendingControlCommand | undefined => state.pendingControlCommands.find(c => c.controlId === controlId)
  },
  [PlcOperationGetter.scenes]({ scenes }): SceneModel[] {
    return scenes
  },
  [PlcOperationGetter.sceneById](state: PlcOperationState) {
    return (sceneId: string) => {
      const scene = state.scenes.filter(fScene => fScene.id === sceneId)
      if(scene.length > 0) {
        return scene[0]
      } else {
        return null
      }
    }
  },
  [PlcOperationGetter.schedulesOfScene](state: PlcOperationState) {
    return (sceneId: string) => {
      const scene = state.scenes.filter(fScene => fScene.id === sceneId)
      if(scene.length > 0) {
        return scene[0].timeSchedules
      } else {
        return null
      }
    }
  },  
  [PlcOperationGetter.favorites] (state): DeviceModel[] {
    return state.devices.filter(device => state.favorites.includes(device.id))
  },
  [PlcOperationGetter.confirmedDeviceIds]({ confirmedDeviceIds }): string[] {
    return confirmedDeviceIds
  },
  [PlcOperationGetter.activePlcConfigurationReleaseId] ({activePlcConfigurationReleaseId}) {
    return activePlcConfigurationReleaseId
  },
}
