import { MutationTree } from 'vuex'
import { remove, sortedByName, upsert } from '../utils'
import { AlarmDefinitionModel, AlarmDefinitionOptionModel } from './models'
import { AlarmDefinitionMutation, AlarmDefinitionState } from './types'

export const mutations: MutationTree<AlarmDefinitionState> = {
  [AlarmDefinitionMutation.setAlarmDefinitions](state, payload: AlarmDefinitionModel[]) {
    state.alarmDefinitions = sortedByName(payload)
  },
  [AlarmDefinitionMutation.setAlarmDefinitionsOptions](state, payload: AlarmDefinitionOptionModel[]) {
    state.alarmDefinitionOptions = payload
  },
  [AlarmDefinitionMutation.upsertAlarmDefinition](state, payload: AlarmDefinitionModel) {
    upsert(state.alarmDefinitions, payload)
    state.alarmDefinitions = sortedByName(state.alarmDefinitions)
  },
  [AlarmDefinitionMutation.removeAlarmDefinition](state, payload: string) {
    remove(state.alarmDefinitions, payload)
  },
}
