import { ArrayWithLookup } from '../helpers/arrayWithLookup'
import api from './api'
import { ResourceCategory, ResourceModel, ResourceType } from './models'
import { ResourceState } from './types'

export const loadResourcesFromApi = async (category: ResourceCategory, language: string): Promise<ResourceModel[]> => {
  const resourceType = resourceTypeForCategory(category)
  switch (resourceType) {
  case ResourceType.RESOURCESTRING:
    return await api.strings(category, language)
  case ResourceType.ICON:
    return await api.icons(category, language)
  case ResourceType.IMAGE:
    return await api.images(category, language)
  default: throw new Error(`Invalid resource type: ${resourceType}`)
  }
}

export const stateForResourceCategory = (state: ResourceState, category: ResourceCategory): ArrayWithLookup<ResourceModel> => {
  switch (category) {
  case ResourceCategory.APP_STRINGS: return state.appStrings
  case ResourceCategory.FUNCTION_BLOCK_DESCRIPTION_STRINGS: return state.fbStrings
  case ResourceCategory.APP_ICONS: return state.appIcons
  case ResourceCategory.DEVICE_ICONS: return state.deviceIcons
  case ResourceCategory.SOLUTION_HARDWARE_ICONS: return state.solutionHardwareIcons
  case ResourceCategory.ROOM_ICONS: return state.roomIcons
  case ResourceCategory.SCENE_ICONS: return state.sceneIcons
  case ResourceCategory.CHART_ICONS: return state.chartIcons
  case ResourceCategory.PLC_IMAGES: return state.plcImages
  case ResourceCategory.TERMINAL_IMAGES: return state.terminalImages
  case ResourceCategory.MODULE_IMAGES: return state.moduleImages
  case ResourceCategory.SYSTEM_CONFIGURATION: return state.systemConfigurationStrings
  default: throw new Error(`Invalid resource category: ${category}`)
  }
}

export const resourceTypeForCategory = (category: ResourceCategory): ResourceType => {
  switch (category) {
  case ResourceCategory.APP_STRINGS:
  case ResourceCategory.FUNCTION_BLOCK_DESCRIPTION_STRINGS:
  case ResourceCategory.SYSTEM_CONFIGURATION:  
    return ResourceType.RESOURCESTRING
  case ResourceCategory.APP_ICONS:
  case ResourceCategory.DEVICE_ICONS:
  case ResourceCategory.SOLUTION_HARDWARE_ICONS:
  case ResourceCategory.ROOM_ICONS:
  case ResourceCategory.SCENE_ICONS:
  case ResourceCategory.CHART_ICONS:
    return ResourceType.ICON
  case ResourceCategory.PLC_IMAGES:
  case ResourceCategory.TERMINAL_IMAGES:
  case ResourceCategory.MODULE_IMAGES:
    return ResourceType.IMAGE
  default: throw new Error(`Invalid resource category: ${category}`)
  }
}