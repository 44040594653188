<template>
  <div>
    <draggable v-model="draggableList" class="devices"
      filter="input"
      :preventOnFilter="false"
      animation="200"
      ghost-class="dragdrop-preview"
      chosen-class="dragdrop-chosen"
      drag-class="dragdrop-dragging"
    >
      <div class="tile" v-for="device in draggableList" :key="device.id">
        <div class="device" :class="{'disabled': isInteracted}">
          <DeviceWithEndpointIndicators :device="device" :disabled="isInteracted" :allowChangeIcon="true"/>
          <div class="device-content-block">
            <div class="actions">
              <v-tooltip bottom v-if="device.obsolete" class="device-action hint">
                <v-icon slot="activator">warning</v-icon>
                <span>{{'common.button.obsolete'| translate}}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="device.license" class="device-action hint">
                <v-icon slot="activator">vpn_key</v-icon>
                <span>{{'device.license.needed' | translate}} {{device.license}}</span>
              </v-tooltip>
              <v-tooltip bottom class="device-action">
                <v-btn slot="activator" icon flat class="sorter"><v-icon>drag_handle</v-icon></v-btn>
                <span>{{ 'common.button.sortorder' | translate }}</span>
              </v-tooltip>
              <v-tooltip bottom class="device-action">
                <v-btn slot="activator" @click="saveDevice(device)" icon flat :disabled="!device.dirty || isInteracted"><v-icon>save</v-icon></v-btn>
                <span>{{ 'common.button.save' | translate }}</span>
              </v-tooltip>
              <v-tooltip bottom class="device-action">  
                <v-btn slot="activator" :disabled="isInteracted" @click="onDeleteDeviceClicked(device)" icon flat><v-icon>delete_outline</v-icon></v-btn>
                <span>{{ 'common.button.delete' | translate }}</span>
              </v-tooltip>
              <description-popover class="popUpButton"
                :header="device.functionBlockName"
                :content="`Version: ${device.version}<br>${device.functionBlockDescription}`"
              />
            </div>
            <div class="editor">
              <input @change="updateName(device.id, $event.target.value)" class="device-name" type="text" :value="device.name" :disabled="isInteracted">
              <button class="clearing" @click="updateName(device.id, '')" :disabled="isInteracted">
                <i class="material-icons">clear</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </draggable>
    <confirm-dialog
      :show="deviceToDelete"
      :header="'common.button.delete' | translate"
      :text="'error.device.has.mappings' | translate"
      :confirm-button-text="'common.button.delete' | translate"
      :cancel-button-text="'common.button.cancel' | translate"
      :disabled="isInteracted"
      @confirmed="onDeleteConfirmed"
      @cancelled="deviceToDelete = null"
    />
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Draggable from 'vuedraggable'

import DeviceWithEndpointIndicators from './DeviceWithEndpointIndicators.vue'

import { DeviceModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'

import { DeviceViewModel } from '../../../../../store/modules/deviceUi/models'

const Resource = namespace('resource')
const DeviceUi = namespace('deviceUi')

@Component({
  components: {
    DeviceWithEndpointIndicators,
    Draggable,
  },
})
export default class extends Vue {
  @Resource.Getter public dictionary
  @DeviceUi.Getter public devicesOfRoom: DeviceViewModel[]
  @DeviceUi.Getter public isInteracted: boolean
  @DeviceUi.Getter public isMappedDevice: (id: string) => boolean
  @DeviceUi.Mutation public setDeviceName: (payload: { id: string, name: string}) => void
  @DeviceUi.Action public saveDevice: (device: DeviceViewModel) => Promise<void>
  @DeviceUi.Action public deleteDevice: (id: string) => Promise<void>
  @DeviceUi.Action public sortDevices: (ids: string[]) => Promise<void>

  public deviceToDelete: DeviceModel | null = null

  public get draggableList(): DeviceViewModel[] {
    return this.devicesOfRoom
  }

  public set draggableList(value: DeviceViewModel[]) {
    this.sortDevices(value.map(v => v.id))
  }

  public updateName(deviceId: string, name: string) {
    this.setDeviceName({
      id: deviceId,
      name: name.trim(),
    })
  }

  public onDeleteDeviceClicked(device: DeviceModel){
    if (!this.isMappedDevice(device.id)) {
      this.deleteDevice(device.id)
    } else {
      this.deviceToDelete = device
    }
  }

  public onDeleteConfirmed() {
    this.deleteDevice(this.deviceToDelete!.id)
    this.deviceToDelete = null
  }
}
</script>
<style lang="scss" scoped>
.devices {
  width: 100%;
  position: relative;
  display: inline-block;
  outline: none;
  .tile {
    outline: none;
    float: left;
    height: 150px;
    max-width: 450px;
    min-width: 400px;
    margin: 9px;
    .inverse {
      border-radius: 8px;
      box-shadow: inset 0 0 12px #000000;
      height: 150px;
      background: linear-gradient(to bottom, #0e131b, #1b344b);
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0, #0e131b),
        color-stop(0.8, #1b344b)
      );
    }
    .device {
      border-radius: 8px;
      box-shadow: inset 0 0 12px #000000;
      background: linear-gradient(to bottom, #1b344b, #0e131b);
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0, #1b344b),
        color-stop(0.6, #0e131b)
      );
      &.disabled {
        opacity: 0.5;
      }
      display: flex;
      flex-direction: row;
      height: 150px;
      cursor: pointer;
      .device-icon-block {
        width: 150px;
        border-right: 1px solid rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
      .device-content-block {
        flex: 1;
        padding: 5px 20px;
        .actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          text-align: right;
          .device-action {
            width: 35px;
            .sorter {
              cursor: grab;
            }
          }
          .hint {
            cursor: default;
          }
          button.btn {
            color: #fff !important;
            margin-right: 0;
          }
          .popUpButton {
            max-width: 40px;
            margin-right: -8px;
          }
        }
        .editor {
          display: flex;
          background-color: #14364a;
          padding: 5px 10px;
          border-radius: 5px;
          input {
            flex: 1;
            background-color: rgba(0, 0, 0, 0);
            color: #fff;
            border: 0px;
            font-family: Titillium Web, sans-serif;
            font-size: 16px;
          }
          button.btn {
             color: #fff !important;
             margin-right: 0;
          }
          button.clearing {
            &:focus {
              outline: none;
            }
            display: flex;
            i {
              margin: 0px 0;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.dragdrop-preview, .dragdrop-chosen {
  background: #1a3856;
  opacity: 0.7;
}
.dragdrop-dragging {
  opacity: 0;
}
</style>
