import { ActionTree } from 'vuex'
import ApiService from '../services/api.service'
import HubService from '../services/hub.service'
import { upsert, upsertMany } from '../utils'
import api from './api'
import { MultiplePlcStateChanged, PlcOperationStateChanged, plcStateHubId } from './models'
import { PlcAction, PlcMutation, PlcState} from './types'

export const actions: ActionTree<PlcState, {}> = {
  async [PlcAction.loadPlcs]({ commit }): Promise<void> {
    const data = await api.loadPlcs()
    commit(PlcMutation.setPlcs, data)
  },
  async [PlcAction.connectToPlcStateHub]({ state }): Promise<void> {
    await HubService.connect({
      hubId: plcStateHubId,
      hubUrl: `${ApiService.backendEnvironmentConfiguration().plcStateHub}`,
      methodCallbacks: [{
        method: 'plcOperationStateChanged',
        callback: (payload: PlcOperationStateChanged) => {
          const plc = state.plcs.find(p => p.id === payload.plcId)
          if (plc) {
            upsert(state.plcs, {
              ...plc,
              serialNumber: payload.serialNumber,
              plcOperationState: payload.plcOperationState,
              ecoCloudConnectorVersion: payload.ecoCloudConnectorVersion,
              threadHealthStates: payload.threadHealthStates,
              mainThreadState: payload.mainThreadState,
              openCriticalAlarmsCount: payload.openCriticalAlarmsCount,
              openInformationAlarmsCount: payload.openInformationAlarmsCount,
              twinCatVersion: payload.twinCatVersion,
              totalMemoryInBytes: payload.totalMemoryInBytes,
              totalDiskSpaceInBytes: payload.totalDiskSpaceInBytes,
              timeStamp: payload.timeStamp,
            })
          }
        },
      }, {
        method: 'multiplePlcStateChanged',
        callback: (payloads: MultiplePlcStateChanged) => {
          const plcIds = payloads.plcOperationStateList.map(plc => plc.plcId)
          const plcs = state.plcs.filter(plc => plcIds.includes(plc.id))
          const updatedPlcs = plcs
            .map(plc => {
              const payload = payloads.plcOperationStateList.find(p => p.plcId === plc.id)!
              return {
                ...plc,
                serialNumber: payload.serialNumber,
                plcOperationState: payload.plcOperationState,
                ecoCloudConnectorVersion: payload.ecoCloudConnectorVersion,
                threadHealthStates: payload.threadHealthStates,
                mainThreadState: payload.mainThreadState,
                openCriticalAlarmsCount: payload.openCriticalAlarmsCount,
                openInformationAlarmsCount: payload.openInformationAlarmsCount,
                twinCatVersion: payload.twinCatVersion,
                totalMemoryInBytes: payload.totalMemoryInBytes,
                totalDiskSpaceInBytes: payload.totalDiskSpaceInBytes,
                timeStamp: payload.timeStamp,
              }
            })
          state.plcs = upsertMany(state.plcs, updatedPlcs)
        },
      }],
    })
  },
  async [PlcAction.disconnectFromPlcStateHub](): Promise<void> {
    await HubService.disconnect(plcStateHubId)
  },
  async [PlcAction.startForPlcs](_, payload: string[]): Promise<void> {
    HubService.invoke({
      hubId: plcStateHubId,
      method: 'StartForPlcs',
      args: [payload],
    })
  },
  async [PlcAction.stopForPlcs](_, payload: string[]): Promise<void> {
    HubService.invoke({
      hubId: plcStateHubId,
      method: 'StopForPlcs',
      args: [payload],
    })
  },
}
