import ApiService from '../services/api.service'
import { ResourceCategory, ResourceModel, ResourceType } from './models'

export default {
  async strings(category: string, language: string): Promise<ResourceModel[]> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().resourceApi}/Resources/Strings`, {
      language,
      category,
    })
    return response.data.resources
  },
  async icons(category: string, language: string): Promise<ResourceModel[]> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().resourceApi}/Resources/Icons`, {
      language,
      category,
    })
    return response.data.resourceMediasets
  },
  async images(category: string, language: string): Promise<ResourceModel[]> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().resourceApi}/Resources/Images`, {
      language,
      category,
    })
    return response.data.resourceMediasets
  },
  async createResource(resourceId: string, category: ResourceCategory, resourceType: ResourceType, defaultValue?: string): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().resourceApi}/Resources`, {
      resourceId,
      category,
      resourceType,
      defaultValue,
    })
  },
}