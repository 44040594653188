<template>
  <div class="hardware-panel">
    <column-header :header="dictionary('mapping.outputs')">
      <template v-slot:actions>
        <column-header-action
          icon="keyboard_arrow_down"
          tooltip="Expand all"
          @click="onExpandAll"
        />
        <column-header-action
          icon="keyboard_arrow_up"
          tooltip="Collapse all"
          @click="onCollapseAll"
        />
      </template>
    </column-header>
    <expandable-card v-for="item in solutionHardware" :key="item.instance.id"
      :title="description(item.instance, item.definition)"
      :description-popup-title="item.definition.name"
      :description-popup-text="`Version: ${item.definition.version}<br>${item.definition.description}`"
      :expandable="isExpandable(item.definition)"
      :open="treeExpanded"
      class="expandable-card"
      :class="{ slavelinedelimiter: isSlaveLineStart(item.definition) }"
    >
      <auto-mapping-solution-hardware-slot 
          v-for="slot in item.autoMappingSlots"
          :key="slot.internalName"
          :auto-mapping-slot="slot"
          :solution-hardware-instance="item.instance"
          :solution-hardware-definition="item.definition"
      />
      <manual-mapping-solution-hardware-endpoint
        v-for="endpoint in item.endpoints"
        :key="endpoint.id"
        :endpoint="endpoint"
        :index="solutionHardwareIndex(item.instance)"
        :solution-hardware-instance="item.instance"
        :solution-hardware-definition="item.definition"
      />
    </expandable-card>
  </div>
</template>

<script lang="ts">
import AutoMappingSolutionHardwareSlot from '@/components/views/ecoSetupTool/steps/mapHardware/AutoMappingSolutionHardwareSlot.vue'
import ManualMappingSolutionHardwareEndpoint from '@/components/views/ecoSetupTool/steps/mapHardware/ManualMappingSolutionHardwareEndpoint.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { SolutionHardwareInstanceModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'

import { SolutionHardwareDefinitionModel, SolutionHardwareInterfaceType } from '../../../../../../../eco-domain-store-modules/src/systemConfiguration/models'

import { MappableSolutionHardware } from '../../../../../store/modules/mappingUi/models'

import ExpandableCard from '../../../../ui/ExpandableCard.vue'

import ColumnHeader from './ColumnHeader.vue'
import ColumnHeaderAction from './ColumnHeaderAction.vue'

const MappingUi = namespace('mappingUi')
const Resource = namespace('resource')

@Component ({
  components: { 
    AutoMappingSolutionHardwareSlot,
    ColumnHeader,
    ColumnHeaderAction,
    ExpandableCard,
    ManualMappingSolutionHardwareEndpoint,
  },
})
export default class SolutionHardwareOutputList extends Vue {
  @Prop() public solutionHardware: MappableSolutionHardware[]
  @MappingUi.Getter public solutionHardwareIndex
  @Resource.Getter public dictionary

  public treeExpanded = true

  public onCollapseAll() {
    this.treeExpanded = false
  }

  public onExpandAll() {
    this.treeExpanded = true
  }

  public isExpandable(definition: SolutionHardwareDefinitionModel) {
    return definition.autoMappingSlots.length > 0 || 
      definition.hardwareEndpoints.some(e => e.isOutput === true)
  }

  public isSlaveLineStart(definition: SolutionHardwareDefinitionModel) {
    return definition.interfaceType === SolutionHardwareInterfaceType.EBUS_EXTENSION
  }

  public description(instance: SolutionHardwareInstanceModel, definition: SolutionHardwareDefinitionModel) {
    return `${this.solutionHardwareIndex(instance)} - ${definition.name}`
  }
}
</script>

<style lang="scss" scoped>
.hardware-panel {
  width: 333px;
  display: flex;
  flex-direction: column;
  .expandable-card {
    &.slavelinedelimiter {
      background: transparent;
      opacity: 0.33;
      margin-top: 25px;
    }
  }
}
</style>