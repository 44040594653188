import { UserDetails } from '../authentication/models'
import AppDataStorageService from './appdatastorage.service'

const TOKEN_KEY = 'access_token'

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to AppDataStorageService (localStorage). Local Storage should always be
 * accessed through this instace.
 */
export const translator = {
  dictionary: {
    ['Ã„']: 'Ä', ['Ã¤']: 'ä',
    ['Ã–']: 'Ö', ['Ã¶']: 'ö',
    ['Ãœ']: 'Ü', ['Ã¼']: 'ü',
  },
  translate (text) {
    let translation = text
    Object.keys(this.dictionary).forEach(key => {
      translation = text && text.includes(key) ? translation.replace(key, this.dictionary[key]) : translation
    })
    return translation
  },
}

const TokenService = {
  translator, 
  async getToken(): Promise<string> {
    return await AppDataStorageService.get(TOKEN_KEY)
  },
  async parseToken(): Promise<UserDetails> {
    try {
      // change
      const storageToken = await this.getToken()
      const base64Url = storageToken.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const token = JSON.parse(window.atob(base64))
      return {
        firstName: this.translator.translate(token.given_name || ''),
        lastName: this.translator.translate(token.family_name || ''),
        userIdentifier: token.emails && token.emails.length ? token.emails[0].toLowerCase() : '',
      } as UserDetails
    } catch(err) {
      return {
        firstName: '',
        lastName: '',
        userIdentifier: '',
      } as UserDetails  
    }
  },
}

export { TokenService }