import { AuthorizationsModelBase, Permission } from '@ecocoach/domain-store-modules/src/authorization/models'
import { AuthorizationGetter } from '@ecocoach/domain-store-modules/src/authorization/types'

export const getProjectPermissions = (rootGetters: any, projectId: string, userIdentifier: string): Permission[] => {
  return rootGetters[`authorization/${AuthorizationGetter.projectPermissionsForUser}`](projectId, userIdentifier)
}

export const hasProjectPermission = (rootGetters: any, projectId: string, userIdentifier: string, permission: Permission): boolean => {
  return getProjectPermissions(rootGetters, projectId, userIdentifier).includes(permission)
}

export const usersWithProjectPermissions = (authorizations: AuthorizationsModelBase[], projectId: string): string[] => {
  return authorizations.filter(a => a.id === projectId)
    .map(a => a.permissions).reduce((a, b) => a.concat(b), [])
    .filter(p => p.permissions.length > 0)
    .map(p => p.userIdentifier)
}

export const getPlcPermissions = (rootGetters: any, plcId: string, userIdentifier: string): Permission[] => {
  return rootGetters[`authorization/${AuthorizationGetter.plcPermissionsForUser}`](plcId, userIdentifier)
}

export const hasPlcPermission = (rootGetters: any, plcId: string, userIdentifier: string, permission: Permission): boolean => {
  return getPlcPermissions(rootGetters, plcId, userIdentifier).includes(permission)
}

export const usersWithPlcPermissions = (authorizations: AuthorizationsModelBase[], plcIds: string[]): string[] => {
  return authorizations.filter(a => plcIds.includes(a.id))
    .map(a => a.permissions).reduce((a, b) => a.concat(b), [])
    .filter(p => p.permissions.length > 0)
    .map(p => p.userIdentifier)
}

export const getDevicePermissions = (rootGetters: any, deviceId: string, userIdentifier: string): Permission[] => {
  return rootGetters[`authorization/${AuthorizationGetter.devicePermissionsForUser}`](deviceId, userIdentifier)
}

export const hasDevicePermission = (rootGetters: any, deviceId: string, userIdentifier: string, permission: Permission): boolean => {
  return getDevicePermissions(rootGetters, deviceId, userIdentifier).includes(permission)
}

export const usersWithDevicePermissions = (authorizations: AuthorizationsModelBase[], deviceIds: string[]): string[] => {
  return authorizations.filter(a => deviceIds.includes(a.id))
    .map(a => a.permissions).reduce((a, b) => a.concat(b), [])
    .filter(p => p.permissions.length > 0)
    .map(p => p.userIdentifier)
}

export const getAlarmDefinitionPermissions = (rootGetters: any, alarmDefinitionId: string, userIdentifier: string): Permission[] => {
  return rootGetters[`authorization/${AuthorizationGetter.alarmDefinitionPermissionsForUser}`](alarmDefinitionId, userIdentifier)
}

export const hasAlarmDefinitionPermission = (rootGetters: any, alarmDefinitionId: string, userIdentifier: string, permission: Permission)
  : boolean => {
  return getAlarmDefinitionPermissions(rootGetters, alarmDefinitionId, userIdentifier).includes(permission)
}

export const usersWithAlarmDefinitionPermissions = (authorizations: AuthorizationsModelBase[], alarmDefinitionIds: string[]): string[] => {
  return authorizations.filter(a => alarmDefinitionIds.includes(a.id))
    .map(a => a.permissions).reduce((a, b) => a.concat(b), [])
    .filter(p => p.permissions.length > 0)
    .map(p => p.userIdentifier)
}

export const getConsumptionPermissions = (rootGetters: any, projectId: string, userIdentifier: string): Permission[] => {
  return rootGetters[`authorization/${AuthorizationGetter.consumptionPermissionsForUser}`](projectId, userIdentifier)
}

export const hasConsumptionPermission = (rootGetters: any, projectId: string, userIdentifier: string, permission: Permission): boolean => {
  return getConsumptionPermissions(rootGetters, projectId, userIdentifier).includes(permission)
}

export const usersWithOneClickPermissions = (authorizations: AuthorizationsModelBase[], projectId: string): string[] => {
  return authorizations.filter(a => a.id === projectId)
    .map(a => a.permissions).reduce((a, b) => a.concat(b), [])
    .filter(p => p.permissions.length > 0)
    .map(p => p.userIdentifier)
}
