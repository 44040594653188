<template>
  <v-flex class="arraycontainer">
    <div>
      <eco-labeled-control
        :context="context"
        :attributes="attributes"
      >
        <icon-button class="actionbutton"
          @buttonClicked="onCreateArrayEntryButtonClicked"
          type="materialIcon" 
          materialIconName="add" 
          width=20 
          height=20
          :enabled="canAddMoreEntries"
        />
      </eco-labeled-control>
    </div>
    <div v-for="arrayEntryControl in arrayEntries"
      :key="arrayEntryControl.id"
    >
      <span>{{arrayEntryControl.index}}: {{arrayEntryControl.displayName}}</span>
      <icon-button class="actionbutton"
        @buttonClicked="onEditArrayEntryButtonClicked(arrayEntryControl)"
        type="materialIcon" 
        materialIconName="edit" 
        width=20 
        height=20
      />
      <icon-button class="actionbutton"
        @buttonClicked="onDeleteArrayEntryButtonClicked(arrayEntryControl)"
        type="materialIcon" 
        materialIconName="delete" 
        width=20 
        height=20
      />
    </div>
    <control-component-v-2 v-if="activateControl" class="control-component"
      :context="context"
      :control="activateControl"
      :potential-descendent-controls="[]"
    />
    <confirm-dialog
      :show="arrayEntryToDelete"
      :header="context.resolveStringResource('common.button.delete')"
      :text="context.resolveStringResource('common.dialog.confirm')"
      :confirm-button-text="'OK'"
      :cancel-button-text="context.resolveStringResource('common.button.cancel')"
      @confirmed="onDeleteArrayEntryConfirmed"
      @cancelled="arrayEntryToDelete = null"
    />
    <eco-edit-array-entry-dialog v-if="arrayEntryBeingEdited"
      :show="arrayEntryBeingEdited"
      :context="context"
      :control="arrayEntryBeingEdited"
      :parent-control="control"
      :potential-descendent-controls="potentialDescendentControls"
      @closed="onEditArrayEntryDialogClosed"
    />
  </v-flex>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import ConfirmDialog from '../../dialogs/ConfirmDialog.vue'
import IconButton from '../../patterns/IconButton.vue'
import ControlComponentV2 from '../ControlComponentV2.vue'
import EcoEditArrayEntryDialog from './shared/EcoEditArrayEntryDialog.vue'
import EcoLabeledControl from './shared/EcoLabeledControl.vue'

import { ArrayContainerControlAttributes, ControlContextV2, ControlViewModelV2 } from './models'

@Component({
  components: {
    ConfirmDialog,
    ControlComponentV2,
    EcoEditArrayEntryDialog,
    IconButton,
    EcoLabeledControl,
  },
})
export default class EcoArrayContainerControl extends Vue {
  @Prop() public context: ControlContextV2
  @Prop() public control: ControlViewModelV2
  @Prop() public potentialDescendentControls: ControlViewModelV2[]

  public arrayEntryBeingEdited: ControlViewModelV2 | null = null
  public arrayEntryToDelete: ControlViewModelV2 | null = null

  public get attributes() {
    return this.control.attributes as ArrayContainerControlAttributes
  }

  public get canAddMoreEntries(): boolean {
    return this.arrayEntries.length < this.attributes.maxEntries
  }

  public get arrayEntries(): ControlViewModelV2[] {
    return this.potentialDescendentControls
      .filter(c => c.parentControlId === this.control.id && c.id !== this.attributes.activateControlId)
      .sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
  }

  public get activateControl(): ControlViewModelV2 | undefined {
    return this.potentialDescendentControls.find(c => c.id === this.attributes.activateControlId)!
  }

  public async onCreateArrayEntryButtonClicked() {
    const createdArrayEntry = await this.context.createArrayEntry(this.control.id)
    this.arrayEntryBeingEdited = createdArrayEntry
  }

  public onEditArrayEntryButtonClicked(control: ControlViewModelV2) {
    this.arrayEntryBeingEdited = control
  }

  public onEditArrayEntryDialogClosed() {
    this.arrayEntryBeingEdited = null
  }

  public onDeleteArrayEntryButtonClicked(control: ControlViewModelV2) {
    this.arrayEntryToDelete = control
  }

  public async onDeleteArrayEntryConfirmed() {
    await this.context.deleteArrayEntry(this.arrayEntryToDelete!.id)
    this.arrayEntryToDelete = null
  }
}
</script>

<style lang="scss" scoped>
.actionbutton {
  margin: 0px -5px 0px 0px;
}
</style>

<style lang="css">
</style>
