<template>
  <option-selector
    :title="'releases.editing.title' | translate"
    :description="'releases.editing.guide' | translate"
    :active="active"
    activeImage="EditChecked.svg"
    inactiveImage="Edit.svg"
    @selected="onSelected"
  >
    <div v-if="active" class="edited-date">
      <v-icon small>done</v-icon>
      <span v-if="selectedLanguage === 'de'">{{ currentNewRelease && timeStampToDateWithTime(currentNewRelease.lastModifiedAt) }} {{ 'alarm.clock' | translate }}</span>
      <span v-else>{{ currentNewRelease && timeStampToDateWithTime(currentNewRelease.lastModifiedAt) }}</span> 
    </div>
    <div v-if="active" class="edited-by">
      <v-icon v-if="currentNewRelease && currentNewRelease.lastModifiedBy" small>done</v-icon>
      <span v-if="currentNewRelease && currentNewRelease.lastModifiedBy">
        {{ 'releases.edited.by' | translate }}
        {{ currentNewRelease && currentNewRelease.lastModifiedBy }}
      </span>
    </div>
    <base-text-button
      v-if="active"
      :enabled="!isInteracted"
      @buttonClicked="startEditRelease"
    >{{'releases.keepgoing' | translate}}</base-text-button>
    <base-text-button
      v-if="active"
      :enabled="!isInteracted"
      @buttonClicked="stopEditRelease"
    >{{'releases.delete' | translate}}</base-text-button>
  </option-selector>
</template>
<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ReleaseModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'

import OptionSelector from './OptionSelector.vue'

const App = namespace('app')
const ReleaseUi = namespace('releaseUi')
const Wizard = namespace('wizard')

@Component({
  components: {
    OptionSelector,
  },
})
export default class EditingOption extends Vue {
  @Prop() public active: boolean
  @App.Getter public selectedLanguage
  @Wizard.Action public goToStep
  @Wizard.Getter public wizardSteps
  @Wizard.Mutation public closeReleaseDialog
  @ReleaseUi.Action public loadReleaseDetails: (releaseId: string) => Promise<void>
  @ReleaseUi.Action public deleteRelease: (id: string) => Promise<void>
  @ReleaseUi.Getter public currentNewRelease: ReleaseModel
  @ReleaseUi.Getter public timeStampToDateWithTime: (timeStamp: string) => string
  @ReleaseUi.Mutation public setInteracted
  @ReleaseUi.Getter public isInteracted

  @Emit('selected')
  public onSelected() {
    return
  }

  public editIconClicked() {
    this.$emit('editingClicked')
  }

  public async startEditRelease() {
    await this.loadReleaseDetails(this.currentNewRelease.id)
    this.closeReleaseDialog()
    const step = this.wizardSteps.find(s => s.name === 'defineRooms')
    this.goToStep({step, router: this.$router})
  }

  public async stopEditRelease() {
    try {
      this.setInteracted(true)
      await this.deleteRelease(this.currentNewRelease.id)
    } finally {
      this.setInteracted(false)
    }
  }
}
</script>
<style lang="scss" scoped>
.edited-date {
  margin-bottom: 15px;
  text-align: left;
}
.edited-by {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  margin-bottom: 10px;
}
.v-btn {
  margin-top: 5px;
  border: solid 1px white;
}
</style>