import { AccountModel, MeterModel, UserModel } from '@ecocoach/domain-store-modules/src/consumption/models'

export interface AccountViewModel extends AccountModel {
  active: boolean
}

export interface MeterViewModel extends MeterModel {
  selected: boolean
  disabled: boolean
  accountName: string
}

export interface UserViewModel extends UserModel {
  selected: boolean
  disabled: boolean
  accountName: string
}

export const OneClickAdminProjectIdStorageKey = 'oneclickadminprojectId'
export const OneClickAdminTargetSystemStorageKey = 'oneclickadmintargetsystem'
export const OneClickExportProjectIdStorageKey = 'oneclickexportprojectId'
export const OneClickVisualizationProjectIdStorageKey = 'oneclickvisualizationprojectId'
