import { ManageAlarmsUiMutation, ManageAlarmsUiState } from '@/store/modules/manageAlarmsUi/types'
import { AlarmDefinitionModel } from '@ecocoach/domain-store-modules/src/alarmDefinition/models'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<ManageAlarmsUiState> = {
  [ManageAlarmsUiMutation.setInteracted](state, payload: boolean) {
    state.interacted = payload
  },
  [ManageAlarmsUiMutation.setSelectedAlarmDefinitionId](state, payload: string) {
    state.selectedAlarmDefinitionId = payload
  },
  [ManageAlarmsUiMutation.setEditingAlarmDefinition](state, payload: AlarmDefinitionModel | null) {
    state.editingAlarmDefinition = payload
  },
  [ManageAlarmsUiMutation.setEditingAlarmDefinitionProperty](state, payload: { property: string, value: any }) {
    state.editingAlarmDefinition![payload.property] = payload.value
  },
}