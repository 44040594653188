import { AlarmUiAction, AlarmUiMutation, AlarmUiState } from '@/store/modules/alarmUi/types'
import { RootState } from '@/store/types'
import { AlarmMutation } from '@ecocoach/domain-store-modules/src/alarm/types'
import { ActionContext, ActionTree } from 'vuex'

export const actions: ActionTree<AlarmUiState, RootState> = {
  async [AlarmUiAction.openAlarmDialog]  ({ commit }: ActionContext<AlarmUiState, RootState>): Promise<void> {
    commit(AlarmUiMutation.openDialog)
    commit(`alarm/${AlarmMutation.setServiceAlarmTrigger}`, false, {root:true})
    commit(`alarm/${AlarmMutation.setMessageAlarmTrigger}`, false, {root:true})
  },
  async [AlarmUiAction.closeAlarmDialog]  ({ commit }
        : ActionContext<AlarmUiState, RootState>): Promise<void> {
    commit(AlarmUiMutation.closeDialog)
  },
}
