// domain Modules
import { vuexModule as alarmModule } from './alarm'
import { vuexModule as alarmDefinitionModule } from './alarmDefinition'
import { vuexModule as auditLogModule } from './auditLog'
import { vuexModule as authenticationModule } from './authentication'
import { vuexModule as authorizationModule } from './authorization'
import { vuexModule as chartModule } from './chart'
import { vuexModule as consumptionModule } from './consumption'
import { vuexModule as energyStatusModule } from './energyStatus'
import { vuexModule as noteModule } from './note'
import { vuexModule as plcModule } from './plc'
import { vuexModule as plcConfigurationModule } from './plcConfiguration'
import { vuexModule as plcConnectionModule } from './plcConnection'
import { vuexModule as plcOperationModule } from './plcOperation'
import { vuexModule as projectModule } from './project'
import { vuexModule as resourceModule } from './resource'
import { vuexModule as systemConfigurationModule } from './systemConfiguration'
import { vuexModule as userAgreementModule } from './userAgreement'
import { vuexModule as userSettingsModule } from './userSettings'

// services
import ApiService from './services/api.service'
import { EnvironmentService } from './services/environment.service'
import HubService from './services/hub.service'

export const modules = {
  alarmModule,
  alarmDefinitionModule,
  auditLogModule,
  authenticationModule,
  authorizationModule,
  chartModule,
  consumptionModule,
  energyStatusModule,
  noteModule,
  plcModule,
  plcConfigurationModule,
  plcConnectionModule,
  plcOperationModule,
  projectModule,
  resourceModule,
  systemConfigurationModule,
  userAgreementModule,
  userSettingsModule,
}

export const Services = {
  ApiService,
  HubService,
  EnvironmentService,
}