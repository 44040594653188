<template>
  <modal-dialog v-if="validationIssues.length"
    :show="true"
    :width="900"
    :header="'release.validationIssues' | translate"
    :button1-text="'release.deploy' | translate"
    :button2-text="'common.button.cancel' | translate"
    @button1Pressed="onConfirm"
    @button2Pressed="onClose"
  >
    <v-layout column>
      <v-data-table :headers="headers" :items="validationIssues" item-key="deviceId"
        class="validation-issues-table"
        :rows-per-page-items="[10]"
      >
        <template v-slot:items="props">
          <tr :key="props.item.deviceId">
            <td :title="`${props.item.issueText}`">{{ props.item.issueText }}</td>
            <td :title="`${props.item.roomName}`">{{ props.item.roomName }}</td>
            <td :title="`${props.item.deviceName}`">{{ props.item.deviceName }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ValidationIssue, ValidationIssueViewModel } from '../../../../../store/modules/loadProgramUi/models'

const LoadProgramUi = namespace('loadProgramUi')
const Resource = namespace('resource')

@Component({
  components: {
  },
})
export default class ReleaseValidationIssuesDialog extends Vue { 
  @LoadProgramUi.Getter public validationIssues: ValidationIssueViewModel[]
  @LoadProgramUi.Mutation public setValidationIssues: (payload: ValidationIssue[]) => void
  @LoadProgramUi.Action public deployRelease: (payload: { forceDeployment: boolean }) => Promise<void>
  @Resource.Getter public resolveStringResource: (key: string) => string

  public get headers() {
    return [
      {
        text: this.resolveStringResource('release.validationissue'),
        value: 'issueText',
        align: 'left',
        width: '33%',
      },
      {
        text: this.resolveStringResource('alarmdefinition.roomName'),
        value: 'roomName',
        align: 'left',
        width: '33%',
      },
      {
        text: this.resolveStringResource('alarmdefinition.deviceName'),
        value: 'deviceName',
        align: 'left',
        width: '33%',
      },
    ]
  }
  
  public onConfirm() {
    this.setValidationIssues([])
    this.deployRelease({
      forceDeployment: true,
    })
  }

  public onClose() {
    this.setValidationIssues([])
  }
}
</script>
<style lang="scss" scoped>
th, td {
  overflow: hidden;
  text-overflow: ellipsis;
}
thead tr {
  border-bottom: 1px solid white!important;
}
</style>
<style lang="css">
.validation-issues-table .theme--dark.v-table {
  background-color: transparent !important;
  table-layout: fixed;
  width: 100%;
}
.validation-issues-table .v-datatable__actions {
  background-color: transparent !important;
}
</style>