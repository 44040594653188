import { actions } from '@/store/modules/auditLogUi/actions'
import { getters } from '@/store/modules/auditLogUi/getters'
import { mutations } from '@/store/modules/auditLogUi/mutation'
import { state } from '@/store/modules/auditLogUi/state'
import {
  AuditLogUiAction,
  AuditLogUiGetter,
  AuditLogUiMutation,
  AuditLogUiState,
} from '@/store/modules/auditLogUi/types'
import { RootState } from '@/store/types'
import { Module } from 'vuex'

const namespaced: boolean = true

export const auditLogUi: Module<AuditLogUiState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action: AuditLogUiAction,
  mutation: AuditLogUiMutation,
  getter: AuditLogUiGetter,
}

export const domain = 'auditLogUi'

export const vuexModule = {
  [domain]: auditLogUi,
}
