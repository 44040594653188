import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { state } from './state'
import { AuthorizationAction, AuthorizationGetter, AuthorizationMutation, AuthorizationState } from './types'

import { Module } from 'vuex'

const namespaced: boolean = true
export const authorization: Module<AuthorizationState, any> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}

export const types = {
  action: AuthorizationAction, 
  mutation: AuthorizationMutation,
  getter: AuthorizationGetter,
}

export const domain = 'authorization'

export const vuexModule = {
  [domain]: authorization,
}