import { actions } from '@/store/modules/app/actions'
import { getters } from '@/store/modules/app/getters'
import { mutations } from '@/store/modules/app/mutations'
import { state } from '@/store/modules/app/state'
import { AppAction, AppGetter, AppMutation, AppState } from '@/store/modules/app/types'
import { RootState } from '@/store/types'
import { Module } from 'vuex'

const namespaced: boolean = true
export const app: Module<AppState,RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action:  AppAction,
  mutation: AppMutation,
  getter: AppGetter,
}

export const domain = 'app'

export const vuexModule = {
  [domain]: app,
}