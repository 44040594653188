import { addToMap, removeFromMap, removeManyWithId, removeWithId, toMap, upsertManyWithId, upsertWithId } from '../utils'

export class ArrayWithLookup<T> {
  private data: T[]
  private idSelector: (item: T) => string
  private lookup: Map<string, T>

  constructor(data: T[], idSelector: (item: T) => string) {
    this.data = data
    this.idSelector = idSelector
    this.lookup = toMap(data, idSelector)
  }

  public get = (id: string): T | undefined => {
    return this.lookup.get(id)
  }

  public getAll = (): T[] => {
    return this.data
  }

  public has = (id: string): boolean => {
    return this.lookup.has(id)
  }

  public filter = (predicate: (item: T) => boolean): T[] => {
    return this.data.filter(predicate)
  }

  public some = (predicate: (item: T) => boolean): boolean => {
    return this.data.some(predicate)
  }

  public upsert = (item: T) => {
    upsertWithId(this.data, item, this.idSelector)
    this.lookup = addToMap(this.lookup, this.idSelector(item), item)
  }

  public remove = (id: string) => {
    removeWithId(this.data, id, this.idSelector)
    this.lookup = removeFromMap(this.lookup, id)
  }

  public upsertMany = (items: T[]) => {
    if (items.length === 1) {
      this.upsert(items[0])
    } else {
      this.data = upsertManyWithId(this.data, items, this.idSelector)
      this.lookup = toMap(this.data, this.idSelector)
    }
  }

  public removeMany = (ids: string[]) => {
    if (ids.length === 1) {
      this.remove(ids[0])
    } else {
      this.data = removeManyWithId(this.data, ids, this.idSelector)
      this.lookup = toMap(this.data, this.idSelector)
    }
  }
}
