import { WizardMutation, WizardState, WizardStep } from '@/store/modules/wizard/types'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<WizardState> = {
  [WizardMutation.wentToStep](state: WizardState, step: WizardStep) {
    state.currentStep = step
  },
  [WizardMutation.hideToast] (state: WizardState) {
    state.toastIsVisible = false
  },
  [WizardMutation.showToast] (state: WizardState) {
    state.toastIsVisible = true
  },
  [WizardMutation.setToastContent] (state: WizardState, content) {
    state.toastContent = content
  },
  [WizardMutation.setWaiting] (state: WizardState, waiting: boolean) {
    state.waiting = waiting
  },
  [WizardMutation.openReleaseDialog] (state: WizardState) {
    state.releaseDialogOpened = true
  },
  [WizardMutation.closeReleaseDialog] (state: WizardState) {
    state.releaseDialogOpened = false
  },
}
