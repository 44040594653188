<template>
  <card header="Device messages">
    <div>
      <v-data-table v-if="!!functionDefinition.messagesEnumInternalName" :headers="headers" :items="models" item-key="value"
        class="device-messages-table"
        :rows-per-page-items="[10]"
      >
        <template v-slot:items="props">
          <tr :key="props.item.value">
            <td>{{ props.item.value }}</td>
            <td>{{ props.item.name }}</td>
            <td>
              <div class="label truncate" :title="nameResourceKey(props.item.value)">{{ nameResourceKey(props.item.value) }}</div>
              <div class="label truncate" :title="descriptionResourceKey(props.item.value)">{{ descriptionResourceKey(props.item.value) }}</div>
            </td>
            <td>
              <div class="label truncate" :title="nameResourceValue(props.item.value)">{{ nameResourceValue(props.item.value) }}</div>
              <div class="label truncate" :title="descriptionResourceValue(props.item.value)">{{ descriptionResourceValue(props.item.value) }}</div>
            </td>
            <td>
              <div class="device-messages-table-actions">
                <v-tooltip bottom class="device-messages-table-action">
                  <v-btn slot="activator" @click="onEditNameResource(props.item.value)" icon flat>
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <span>Edit name resource default value</span>
                </v-tooltip>
                <v-tooltip bottom class="device-messages-table-action">
                  <v-btn slot="activator" @click="onCopyNameResourceKey(props.item.value)" icon flat>
                    <v-icon small>content_copy</v-icon>
                  </v-btn>
                  <span>Copy name resource key to clipboard</span>
                </v-tooltip>
              </div>
              <div class="device-messages-table-actions">
                <v-tooltip bottom class="device-messages-table-action">
                  <v-btn slot="activator" @click="onEditDescriptionResource(props.item.value)" icon flat>
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <span>Edit description resource default value</span>
                </v-tooltip>
                <v-tooltip bottom class="device-messages-table-action">
                  <v-btn slot="activator" @click="onCopyDescriptionResourceKey(props.item.value)" icon flat>
                    <v-icon small>content_copy</v-icon>
                  </v-btn>
                  <span>Copy description resource key to clipboard</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div v-else>
        None defined. Add the 'messagesEnum' attribute to the function block to use this feature
      </div>
      <modal-dialog v-if="valueToEdit != null"
        :show="true"
        header="Device message" 
        button1-text='Save'
        button2-text="Cancel"
        :button1-disabled="!!actionInProgress"
        :button2-disabled="!!actionInProgress"
        @button1Pressed="onSave"
        @button2Pressed="onClose"
      >
        <form-field text="Resource default value">
          <text-input
            :value="resource"
            @input="resource = $event"
          />
        </form-field>
      </modal-dialog>
    </div>
  </card>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Card from '../../ui/Card.vue'

import { FunctionDefinitionViewModel } from '../../../store/modules/functionDefinitionEditorUi/models'

import { CreateResourceInput, ResourceCategory } from '../../../../../eco-domain-store-modules/src/resource/models'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')
const Resource = namespace('resource')

@Component({
  components: {
    Card,
  },
})
export default class DeviceMessagesDetail extends Vue {
  @FunctionDefinitionEditorUi.Getter public functionDefinition: FunctionDefinitionViewModel
  @FunctionDefinitionEditorUi.Getter public actionInProgress: string
  @FunctionDefinitionEditorUi.Mutation public removeFunctionDefinitionEnergyStatusItem: (payload: string) => void
  @Resource.Getter public resolveStringResource: (key: string) => string
  @FunctionDefinitionEditorUi.Action public createResource: (payload: CreateResourceInput) => Promise<void>

  public valueToEdit: number | null = null
  public isEditingNameResource = false
  public resource = ''

  public get models() {
    return this.functionDefinition.messagesEnumValues
  }

  public nameResourceKey(value: number) {
    return `Message_${this.functionDefinition.messagesEnumInternalName}_${value}_Name`
  }

  public descriptionResourceKey(value: number) {
    return `Message_${this.functionDefinition.messagesEnumInternalName}_${value}_Description`
  }

  public nameResourceValue(value: number) {
    const resourceKey = this.nameResourceKey(value)
    const resolvedResource = this.resolveStringResource(resourceKey)
    return resolvedResource !== resourceKey ? resolvedResource : 'n/a'
  }

  public descriptionResourceValue(value: number) {
    const resourceKey = this.descriptionResourceKey(value)
    const resolvedResource = this.resolveStringResource(resourceKey)
    return resolvedResource !== resourceKey ? resolvedResource : 'n/a'
  }

  public get headers() {
    return [
      {
        text: 'Value',
        value: 'value',
        align: 'left',
        width: '10%',
      },
      {
        text: 'Name',
        value: 'name',
        align: 'left',
        width: '20%',
      },
      {
        text: 'Recource keys',
        align: 'left',
        sortable: false,
        width: '25%',
      },
      {
        text: 'Messages (message, resolution hint)',
        align: 'left',
        sortable: false,
        width: '35%',
      },
      {
        text: '',
        sortable: false,
        width: '10%',
      },    
    ]
  }

  public onEditNameResource(value: number) {
    this.valueToEdit = value
    this.isEditingNameResource = true
  }

  public onEditDescriptionResource(value: number) {
    this.valueToEdit = value
    this.isEditingNameResource = false
  }

  public onCopyNameResourceKey(value: number) {
    this.$copyText(this.nameResourceKey(value))
  }

  public onCopyDescriptionResourceKey(value: number) {
    this.$copyText(this.descriptionResourceKey(value))
  }

  public async onSave() {
    await this.createResource({
      id: this.isEditingNameResource ? this.nameResourceKey(this.valueToEdit!) : this.descriptionResourceKey(this.valueToEdit!),
      category: ResourceCategory.SYSTEM_CONFIGURATION,
      defaultValue: this.resource,
    } as CreateResourceInput)
    this.onClose()
  }

  public onClose() {
    this.valueToEdit = null
  }
}
</script>
<style lang="scss" scoped>
  .cardbody {
    margin-left: -10px;
  }
  .field {
    height: 70px;
    width: 100%;
  }
  .field .label {
    overflow: hidden;
    max-height: 40px;
  }
  thead tr {
    border-bottom: 1px solid white!important;
  }
  .device-messages-table {
    .truncate {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .label {
      margin: 10px 0px;
    }
    .device-messages-table-actions {
      display: flex;
      justify-content: flex-end;
      .device-messages-table-action {
        width: 36px;
        button {
          margin: 0px;
        }
      }
    }
  }
</style>
<style lang="css">
.device-messages-table .theme--dark.v-table {
  background-color: transparent !important;
  table-layout: fixed;
  width: 100%;
}
.device-messages-table .v-datatable__actions {
  background-color: transparent !important;
}
</style>