import { MutationTree } from 'vuex'
import { remove, upsert } from '../utils'
import { PlcModel, ProjectModel } from './models'
import { ProjectMutation, ProjectState } from './types'

export const mutations: MutationTree<ProjectState> = {
  [ProjectMutation.setProjects](state, payload: ProjectModel[]) {
    state.projects = payload
  },  
  [ProjectMutation.upsertProject](state, payload: ProjectModel) {
    upsert(state.projects, payload)
  },
  [ProjectMutation.removeProject](state, payload: string) {
    remove(state.projects, payload)
  },
  [ProjectMutation.upsertPlc](state, payload: PlcModel) {
    upsert(state.projects.find(p => p.id === payload.projectId)?.plcs ?? [], payload)
  },
  [ProjectMutation.removePlc](state, payload: string) {
    const projectId = state.projects.find(p => p.plcs.some(plc => plc.id === payload))?.plcs.find(plc => plc.id === payload)?.projectId ?? ''
    remove(state.projects.find(p => p.id === projectId)?.plcs ?? [], payload)
  },
}
