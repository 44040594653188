import { AlarmState } from './types'

export const state: AlarmState = {
  serviceAlarms: [],
  messageAlarms: [],
  customPlcAlarms: [],
  serviceAlarmTrigger: false,
  messageAlarmTrigger: false,
  customPlcAlarmTrigger: false,
}
