<template>
  <modal-dialog
    :show="show"
    :header="'oneclick.account.add' | translate"
    button1-text="OK"
    @button1Pressed="onOk"
    :button2-text="'common.button.cancel' | translate"
    @button2Pressed="onCancelled"
  >
    <v-layout column>
      <v-flex>
        <form-field :text="'oneclick.account.name' | translate" class="form-field">
          <text-input
            :value="name"
            :placeholder="'oneclick.account.name' | translate"
            @changed="name = $event"
          />
        </form-field>
        <form-field :text="'oneclick.account.custom' | translate" class="form-field">
          <text-input
            :value="customId"
            :placeholder="'oneclick.account.custom' | translate"
            @changed="customId = $event"
          />
        </form-field>
      </v-flex>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { AccountModel } from '../../../../../../eco-domain-store-modules/src/consumption/models'

@Component
export default class AddAccountDialog extends Vue {
  @Prop({ default: false }) public show: boolean
  public name = ''
  public customId = ''

  @Watch('show')
  public setDefaults() {
    if (this.show) {
      this.name = ''
      this.customId = ''
    }
  }

  @Emit('confirmed') 
  public onOk() {
    return {
      id: '',
      name: this.name.trim(),
      customId: this.customId.trim(),
    } as AccountModel
  }

  @Emit('cancelled') 
  public onCancelled() {
    return
  }
}
</script>

<style lang="scss" scoped>
.form-field {
  margin-left: -10px;
  margin-bottom: 10px;
  div {
    margin-left: 10px;
  }
}
</style>