<template>
  <v-expansion-panel>
    <v-expansion-panel-content>
      <div slot="header">
        <img v-if="icon !== ''" :src="icon" height="18" width="18" style="margin-right: 10px; margin-bottom: -2px;"/>
        <span>{{ title }}</span>
      </div>
      <slot></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>


<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'AccordionItem',
  props: {
    /**
     * the title 
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * item header icon as base64 string 
     */
    icon: {
      type: String,
      default: '',
    },
  },
})
</script>

<style lang="scss" scoped>
</style>

<docs>
See Accordion.vue
</docs>
