import { MutationTree } from 'vuex'
import { AuthorizationUiMutation, AuthorizationUiState } from './types'

export const mutations: MutationTree<AuthorizationUiState> = {
  [AuthorizationUiMutation.setInteracted](state, payload: boolean) {
    state.interacted = payload
  },
  [AuthorizationUiMutation.setSelectedProjectId](state, payload: string) {
    state.selectedProjectId = payload
  },
  [AuthorizationUiMutation.setSelectedUser](state, payload: string) {
    state.selectedUser = payload
  },
  [AuthorizationUiMutation.addNewUser](state, payload: string) {
    if (!state.newUsers.includes(payload)) {
      state.newUsers.push(payload)
    }
  },
}
