import { ActionContext, ActionTree } from 'vuex'
import fileHelper from '../helpers/fileHelper'
import CacheService from '../services/cache.service'
import api from './api/query'
import { ChartDataInput, ChartReportInput } from './models'
import { ChartAction, ChartMutation, ChartState } from './types'

export const actions: ActionTree<ChartState, {}> = {
  async [ChartAction.loadChartCollections]({ commit }: ActionContext<ChartState, {}>, payload: { projectId: string, language: string }): Promise<void> {
    await CacheService.load({
      action: ChartAction.loadChartCollections,
      parameters: [payload.projectId, payload.language],
      load: () => api.loadCharts(payload.projectId, payload.language),
      commit: data => commit(ChartMutation.setChartCollections, data),
    })
  },
  async [ChartAction.loadChartData]({ commit }, payload: { input: ChartDataInput, nodeId: string | null, scale: boolean, language: string }): Promise<void> {
    const response = await api.loadChartData(payload.input.projectId, payload.input.chartId, payload.input.period, payload.input.dateFrom, payload.input.dateTo, payload.nodeId, payload.scale, payload.language)
    commit(ChartMutation.setChartData, response)
  },
  async [ChartAction.loadReport](_, payload: { input: ChartReportInput, nodeId: string | null, scale: boolean, language: string }): Promise<void> {
    const data = await api.loadReport(payload.input.projectId, payload.input.chartId, payload.input.dateFrom, payload.input.dateTo, payload.nodeId, payload.scale, payload.language)
    fileHelper.saveBlobToFile(data, payload.input.fileName)
  },
}