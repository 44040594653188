<template>
  <eco-labeled-control
    :context="context"
    :attributes="attributes"
  >
    <div class="buttons">
      <icon-button v-for="toggle in toggles" :key="toggle.command" 
        :class="{
          'active': isToggled(toggle.state), 
          'pending': isPending(toggle.state),
        }"
        type="materialIcon"
        :materialIconName="toggle.icon"
        :enabled="!readonly"
        @buttonClicked="onToggle(toggle.command, isToggled(toggle.state))"
        :height="25"
      />
    </div>
  </eco-labeled-control>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import IconButton from '../../patterns/IconButton.vue'
import { ControlContextV2, TogglesControlAttributes } from './models'
import EcoLabeledControl from './shared/EcoLabeledControl.vue'

@Component({
  components: {
    IconButton,
    EcoLabeledControl,
  },
})
export default class EcoTogglesControl extends Vue {
  @Prop() public id: string
  @Prop() public context: ControlContextV2
  @Prop() public attributes: TogglesControlAttributes
  @Prop() public state: object
  @Prop() public pendingState: object
  @Prop() public readonly: boolean

  public onToggle(command: string, value: boolean) {
    const state = this.attributes.toggles.find(_ => _.command === command)!.state
    this.context.executeCommand(this.id, {
      command,
      params: !value,
      pendingState: {
        [state]: !value,
      },
    })
  }

  public get toggles() {
    return this.attributes.toggles
  }

  public isToggled(state: string) {
    return this.state[state] === true
  }

  public isPending(state: string) {
    return this.pendingState[state] !== undefined
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-right: -8px;
  display: flex;
  .v-btn {
    border-width: 1px;
    border-color: white;
    border-style: solid;
    &.pending {
      border-color: #39404f;
    }
    &.active {
        background-color: $grey1-secondary;
      }
  }
}
</style>

<style lang="css">
</style>
