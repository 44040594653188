import { render, staticRenderFns } from "./AlarmDefinitionsTable.vue?vue&type=template&id=2d842cfa&scoped=true"
import script from "./AlarmDefinitionsTable.vue?vue&type=script&lang=ts"
export * from "./AlarmDefinitionsTable.vue?vue&type=script&lang=ts"
import style0 from "./AlarmDefinitionsTable.vue?vue&type=style&index=0&id=2d842cfa&prod&lang=scss&scoped=true"
import style1 from "./AlarmDefinitionsTable.vue?vue&type=style&index=1&id=2d842cfa&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.9.1_prettier@2.8.8_vue-template-compiler@2.6.6_webpack@5.90.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d842cfa",
  null
  
)

export default component.exports