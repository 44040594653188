<template>
    <button
        class="circle"
        :class="{ small, active }">
        <div class="room-button-content">
          <svg-icon :icon="icon" width="35" height="35"/>
          <span>{{ name }}</span>
        </div>
    </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import SvgIcon from './SvgIcon.vue'

@Component({
  components: {
    SvgIcon,
  },
})
export default class IconButton extends Vue {
    @Prop({type: String, required: true}) public name
    @Prop({type: String, required: true}) public icon
    @Prop({type: Boolean, required: true}) public active
    @Prop({type: Boolean, required: true}) public small
}
</script>
<style lang="scss" scoped>
button.circle {
  width: 130px;
  height: 130px;
  border: 2px solid #080e19;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 130px;
  margin: 20px;
  &.small {
    width: 100px;
    height: 100px;
  }
  &.active {
    border: 2px solid #fa0f36;
  }
  &:focus {
    outline: none;
  }
  .room-button-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      display: inline-block;
    }
    span {
      font-size: 10px;
      margin-top: 5px;
      text-transform: uppercase;
      font-family: Titillium Web, sans-serif;
      color: #fff;
    }
  }
}
</style>