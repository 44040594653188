import { RootState } from '@/store/types'
import { ControlModelV2, SetConsumptionProcessOwnerLockEnabledInput, SetControlColorGradientInput, SetDeviceConsumptionValidationEnabledInput, SetDeviceIgnoreInChartsInput, SetEnergyStatusItemColorInput, SetMeasuringPointColorInput } from '@ecocoach/domain-store-modules/src/plcOperation/models'
import { PlcOperationAction } from '@ecocoach/domain-store-modules/src/plcOperation/types'
import { ActionTree } from 'vuex'
import { AppGetter } from '../app/types'
import { ParametrizationUiAction, ParametrizationUiMutation, ParametrizationUiState } from './types'
import { ControlTypeV2 } from '@ecocoach/domain-store-modules/src/common'

export const actions: ActionTree<ParametrizationUiState, RootState> = {
  async [ParametrizationUiAction.startControlUpdatesForDevice] ({ commit, dispatch, rootGetters }, deviceId: string): Promise<void> {
    try {
      commit(ParametrizationUiMutation.setInteracted, true)
      const isEcocoachEmployee = rootGetters[`app/${AppGetter.isEcocoachEmployee}`]
      const filter = (control: ControlModelV2) => {
        const deviceFilter = (c: ControlModelV2) => c.deviceId === deviceId || c.type === ControlTypeV2.DeviceMessages
        const ecocoachFilter = (c: ControlModelV2) => isEcocoachEmployee || !c?.attributes?.ecocoachOnly
        return deviceFilter(control) && ecocoachFilter(control)
      }
      await dispatch(`plcOperation/${PlcOperationAction.setControlsFilter}`, filter, { root: true })
    } finally {
      commit(ParametrizationUiMutation.setInteracted, false)
    }
  },
  async [ParametrizationUiAction.stopControlUpdates] ({ commit, dispatch }): Promise<void> {
    try {
      commit(ParametrizationUiMutation.setInteracted, true)
      await dispatch(`plcOperation/${PlcOperationAction.setControlsFilter}`, () => false, { root: true })
    } finally {
      commit(ParametrizationUiMutation.setInteracted, false)
    }
  },
  async [ParametrizationUiAction.confirmDevice] ({ commit, dispatch, rootState }, payload: string): Promise<void> {
    try {
      commit(ParametrizationUiMutation.setInteracted, true)
      const input = {
        plcId: rootState.app.selectedPlcId,
        deviceId: payload,
      }
      await dispatch('plcOperation/confirmDevice', input, { root: true })
    } finally {
      commit(ParametrizationUiMutation.setInteracted, false)
    }
  },
  async [ParametrizationUiAction.setControlColorGradient] ({ commit, dispatch }, input: SetControlColorGradientInput): Promise<void> {
    try {
      commit(ParametrizationUiMutation.setInteracted, true)
      await dispatch(`plcOperation/${PlcOperationAction.setControlColorGradient}`, input, { root: true })
    } finally {
      commit(ParametrizationUiMutation.setInteracted, false)
    }
  },
  async [ParametrizationUiAction.setDeviceIgnoreInCharts] ({ commit, dispatch }, input: SetDeviceIgnoreInChartsInput):
    Promise<void> {
    try {
      commit(ParametrizationUiMutation.setInteracted, true)
      await dispatch(`plcOperation/${PlcOperationAction.setDeviceIgnoreInCharts}`, input, { root: true })
    } finally {
      commit(ParametrizationUiMutation.setInteracted, false)
    }
  },
  async [ParametrizationUiAction.setDeviceConsumptionValidationEnabled] ({ commit, dispatch }, input: SetDeviceConsumptionValidationEnabledInput):
    Promise<void> {
    try {
      commit(ParametrizationUiMutation.setInteracted, true)
      await dispatch(`plcOperation/${PlcOperationAction.setDeviceConsumptionValidationEnabled}`, input, { root: true })
    } finally {
      commit(ParametrizationUiMutation.setInteracted, false)
    }
  },
  async [ParametrizationUiAction.setConsumptionProcessOwnerLockEnabled] ({ commit, dispatch }, input: SetConsumptionProcessOwnerLockEnabledInput):
    Promise<void> {
    try {
      commit(ParametrizationUiMutation.setInteracted, true)
      await dispatch(`plcOperation/${PlcOperationAction.setConsumptionProcessOwnerLockEnabled}`, input, { root: true })
    } finally {
      commit(ParametrizationUiMutation.setInteracted, false)
    }
  },
  async [ParametrizationUiAction.setMeasuringPointColor] ({ commit, dispatch }, input: SetMeasuringPointColorInput): Promise<void> {
    try {
      commit(ParametrizationUiMutation.setInteracted, true)
      await dispatch(`plcOperation/${PlcOperationAction.setMeasuringPointColor}`, input, { root: true })
    } finally {
      commit(ParametrizationUiMutation.setInteracted, false)
    }
  },
  async [ParametrizationUiAction.setEnergyStatusItemColor] ({ commit, dispatch }, input: SetEnergyStatusItemColorInput): Promise<void> {
    try {
      commit(ParametrizationUiMutation.setInteracted, true)
      await dispatch(`plcOperation/${PlcOperationAction.setEnergyStatusItemColor}`, input, { root: true })
    } finally {
      commit(ParametrizationUiMutation.setInteracted, false)
    }
  },
}
