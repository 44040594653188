import { RootState } from '@/store/types'
import { Permission, SetAlarmDefinitionAuthorizationsPayload, SetConsumptionAuthorizationPayload, SetDeviceAuthorizationsPayload, SetPlcAuthorizationPayload, SetProjectAuthorizationPayload } from '@ecocoach/domain-store-modules/src/authorization/models'
import { AuthorizationAction } from '@ecocoach/domain-store-modules/src/authorization/types'
import { delay } from '@ecocoach/domain-store-modules/src/utils'
import { ActionTree } from 'vuex'
import { AppGetter } from '../app/types'
import { WizardMutation } from '../wizard/types'
import { getAlarmDefinitionPermissions, getConsumptionPermissions, getDevicePermissions, getPlcPermissions, getProjectPermissions, hasAlarmDefinitionPermission, hasConsumptionPermission, hasDevicePermission, hasProjectPermission } from './helpers'
import { appIdRegex, AuthorizationUiAction, AuthorizationUiGetter, AuthorizationUiMutation, AuthorizationUiState } from './types'

const DELAY_BETWEEN_CALLS_FOR_BATCH_AUTHORIZATIONS = 100 // ms

const concatPermissions = (existingPermissions: Permission[], ...permissionsToAdd: Permission[]): Permission[] => {
  return Array.from(new Set([...existingPermissions, ...permissionsToAdd]))
}

const exceptPermissions = (existingPermissions: Permission[], ...permissionsToRemove: Permission[]): Permission[] => {
  return existingPermissions.filter(p => !permissionsToRemove.includes(p))
}

const includedProjectPermissionsFor = (permission: Permission): Permission[] => {
  switch (permission) {
  case Permission.EDIT:
    return [Permission.READ]
  default: return []
  }
}

const includedPlcPermissionsFor = (permission: Permission): Permission[] => {
  switch (permission) {
  case Permission.EXECUTE:
    return [Permission.READ]
  default: return []
  }
}

const includedDevicePermissionsFor = (permission: Permission): Permission[] => {
  switch (permission) {
  case Permission.EXECUTE:
    return [Permission.READ]
  default: return []
  }
}

const dependingProjectPermissionsFor = (permission: Permission): Permission[] => {
  switch (permission) {
  case Permission.READ:
    return [
      Permission.EDIT,
    ]
  default: return []
  }
}

const dependingPlcPermissionsFor = (permission: Permission): Permission[] => {
  switch (permission) {
  case Permission.READ:
    return [
      Permission.EXECUTE, 
    ]
  default: return []
  }
}

const dependingDevicePermissionsFor = (permission: Permission): Permission[] => {
  switch (permission) {
  case Permission.READ:
    return [
      Permission.EXECUTE, 
    ]
  default: return []
  }
}

export const actions: ActionTree<AuthorizationUiState, RootState> = {
  async [AuthorizationUiAction.load] ({ commit, dispatch }): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      await dispatch(`authorization/${AuthorizationAction.loadProjects}`, null, { root: true })
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.selectProject] ({ commit, dispatch, getters }, projectId: string): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      await dispatch(`authorization/${AuthorizationAction.loadProject}`, projectId, { root: true })
      commit(`wizard/${WizardMutation.hideToast}`, undefined, { root:true }) // in case of 403 when not authorized on project
      const appIds = getters[AuthorizationUiGetter.authorizedUsers](projectId).filter(u => appIdRegex.test(u.userIdentifier)).map(u => u.userIdentifier)
      await dispatch(`authorization/${AuthorizationAction.resolveAppRegistrationNames}`, appIds, { root: true })
      commit(AuthorizationUiMutation.setSelectedProjectId, projectId)
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.grantProjectPermission] ({ state, commit, dispatch, rootGetters },
    payload: { projectId: string, permission: Permission }): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      const userIdentifier = state.selectedUser
      const oldPermissions = getProjectPermissions(rootGetters, payload.projectId, userIdentifier)
      const newPermissions = concatPermissions(oldPermissions, payload.permission, ...includedProjectPermissionsFor(payload.permission))
      await dispatch(`authorization/${AuthorizationAction.updateProjectAuthorization}`, {
        projectId: payload.projectId,
        userIdentifier,
        permissions: newPermissions,
      } as SetProjectAuthorizationPayload, { root: true })
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.revokeProjectPermission] ({ state, commit, dispatch, rootGetters },
    payload: { projectId: string, permission: Permission }): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      const userIdentifier = state.selectedUser
      const oldPermissions = getProjectPermissions(rootGetters, payload.projectId, userIdentifier)
      const newPermissions = exceptPermissions(oldPermissions, payload.permission, ...dependingProjectPermissionsFor(payload.permission))
      await dispatch(`authorization/${AuthorizationAction.updateProjectAuthorization}`, {
        projectId: payload.projectId,
        userIdentifier,
        permissions: newPermissions,
      } as SetProjectAuthorizationPayload, { root: true })
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.grantConsumptionPermission] ({ state, commit, dispatch, rootState, rootGetters },
    payload: { projectId: string, permission: Permission }): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      const project = rootState.authorization.consumptionProjects.find(p => p.id === payload.projectId)!
      const userIdentifier = state.selectedUser
      const oldPermissions = getConsumptionPermissions(rootGetters, payload.projectId, userIdentifier)
      const newPermissions = concatPermissions(oldPermissions, payload.permission)
      await dispatch(`authorization/${AuthorizationAction.updateConsumptionAuthorization}`, {
        projectId: project.id,
        userIdentifier,
        permissions: newPermissions,
      } as SetConsumptionAuthorizationPayload, { root: true })
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.revokeConsumptionPermission] ({ state, commit, dispatch, rootState, rootGetters },
    payload: { projectId: string, permission: Permission }): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      const project = rootState.authorization.consumptionProjects.find(p => p.id === payload.projectId)!
      const userIdentifier = state.selectedUser
      const oldPermissions = getConsumptionPermissions(rootGetters, payload.projectId, userIdentifier)
      const newPermissions = exceptPermissions(oldPermissions, payload.permission)
      await dispatch(`authorization/${AuthorizationAction.updateConsumptionAuthorization}`, {
        projectId: project.id,
        userIdentifier,
        permissions: newPermissions,
      } as SetConsumptionAuthorizationPayload, { root: true })
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.grantPlcPermission] ({ state, commit, dispatch, rootState, rootGetters },
    payload: { plcId: string, permission: Permission }): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      const plc = rootState.authorization.plcAuthorizations.find(p => p.id === payload.plcId)!
      const userIdentifier = state.selectedUser
      const oldPermissions = getPlcPermissions(rootGetters, payload.plcId, userIdentifier)
      const newPermissions = concatPermissions(oldPermissions, payload.permission, ...includedPlcPermissionsFor(payload.permission))
      await dispatch(`authorization/${AuthorizationAction.updatePlcAuthorization}`, {
        plcId: plc.id,
        userIdentifier,
        permissions: newPermissions,
      } as SetPlcAuthorizationPayload, { root: true })
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.revokePlcPermission] ({ state, commit, dispatch, rootState, rootGetters },
    payload: { plcId: string, permission: Permission }): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      const plc = rootState.authorization.plcAuthorizations.find(p => p.id === payload.plcId)!
      const userIdentifier = state.selectedUser
      const oldPermissions = getPlcPermissions(rootGetters, payload.plcId, userIdentifier)
      const newPermissions = exceptPermissions(oldPermissions, payload.permission, ...dependingPlcPermissionsFor(payload.permission))
      await dispatch(`authorization/${AuthorizationAction.updatePlcAuthorization}`, {
        plcId: plc.id,
        userIdentifier,
        permissions: newPermissions,
      } as SetPlcAuthorizationPayload, { root: true })
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.grantDevicePermission] ({ state, commit, dispatch, rootState, rootGetters },
    payload: { deviceId: string, permission: Permission }): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      const device = rootState.authorization.deviceAuthorizations.find(d => d.id === payload.deviceId)!
      const userIdentifier = state.selectedUser
      const oldPermissions = getDevicePermissions(rootGetters, payload.deviceId, userIdentifier)
      const newPermissions = concatPermissions(oldPermissions, payload.permission, ...includedDevicePermissionsFor(payload.permission))
      await dispatch(`authorization/${AuthorizationAction.updateDeviceAuthorizations}`, {
        deviceIds: [device.id],
        userIdentifier,
        permissions: newPermissions,
      } as SetDeviceAuthorizationsPayload, { root: true })
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.revokeDevicePermission] ({ state, commit, dispatch, rootState, rootGetters },
    payload: { deviceId: string, permission: Permission }): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      const device = rootState.authorization.deviceAuthorizations.find(d => d.id === payload.deviceId)!
      const userIdentifier = state.selectedUser
      const oldPermissions = getDevicePermissions(rootGetters, payload.deviceId, userIdentifier)
      const newPermissions = exceptPermissions(oldPermissions, payload.permission, ...dependingDevicePermissionsFor(payload.permission))
      await dispatch(`authorization/${AuthorizationAction.updateDeviceAuthorizations}`, {
        deviceIds: [device.id],
        userIdentifier,
        permissions: newPermissions,
      } as SetDeviceAuthorizationsPayload, { root: true })
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.grantDevicePermissionForRoom] ({ state, commit, dispatch, rootState, rootGetters },
    payload: { roomId: string, permission: Permission }): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      const userIdentifier = state.selectedUser
      const devices = rootState.authorization.deviceAuthorizations
        .filter(d => d.roomId === payload.roomId && !hasDevicePermission(rootGetters, d.id, userIdentifier, payload.permission))
      if (devices.length > 0) {
        await dispatch(`authorization/${AuthorizationAction.updateDeviceAuthorizations}`, {
          userIdentifier,
          deviceIds: devices.map(d => d.id),
          permissions: [payload.permission, ...includedDevicePermissionsFor(payload.permission)],
        } as SetDeviceAuthorizationsPayload, { root: true })
      }
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.revokeDevicePermissionForRoom] ({ state, commit, dispatch, rootState, rootGetters },
    payload: { roomId: string, permission: Permission }): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      const userIdentifier = state.selectedUser
      const devices = rootState.authorization.deviceAuthorizations
        .filter(d => d.roomId === payload.roomId && hasDevicePermission(rootGetters, d.id, userIdentifier, payload.permission))
      if (devices.length > 0) {
        await dispatch(`authorization/${AuthorizationAction.updateDeviceAuthorizations}`, {
          userIdentifier,
          deviceIds: devices.map(d => d.id),
          permissions: includedDevicePermissionsFor(payload.permission),
        } as SetDeviceAuthorizationsPayload, { root: true })
      }
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.grantDevicePermissionForPlc] ({ state, commit, dispatch, rootState, rootGetters },
    payload: { plcId: string, permission: Permission }): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      const userIdentifier = state.selectedUser
      const devices = rootState.authorization.deviceAuthorizations
        .filter(d => d.plcId === payload.plcId && !hasDevicePermission(rootGetters, d.id, userIdentifier, payload.permission))
      if (devices.length > 0) {
        await dispatch(`authorization/${AuthorizationAction.updateDeviceAuthorizations}`, {
          userIdentifier,
          deviceIds: devices.map(d => d.id),
          permissions: [payload.permission, ...includedDevicePermissionsFor(payload.permission)],
        } as SetDeviceAuthorizationsPayload, { root: true })
      }
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.revokeDevicePermissionForPlc] ({ state, commit, dispatch, rootState, rootGetters },
    payload: { plcId: string, permission: Permission }): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      const userIdentifier = state.selectedUser
      const devices = rootState.authorization.deviceAuthorizations
        .filter(d => d.plcId === payload.plcId && hasDevicePermission(rootGetters, d.id, userIdentifier, payload.permission))
      if (devices.length > 0) {
        await dispatch(`authorization/${AuthorizationAction.updateDeviceAuthorizations}`, {
          userIdentifier,
          deviceIds: devices.map(d => d.id),
          permissions: includedDevicePermissionsFor(payload.permission),
        } as SetDeviceAuthorizationsPayload, { root: true })
      }
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.grantAlarmDefinitionPermission] ({ state, commit, dispatch, rootState, rootGetters },
    payload: { alarmDefinitionId: string, permission: Permission }): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      const alarmDefinition = rootState.authorization.alarmDefinitionAuthorizations.find(a => a.id === payload.alarmDefinitionId)!
      const userIdentifier = state.selectedUser
      const oldPermissions = getAlarmDefinitionPermissions(rootGetters, payload.alarmDefinitionId, userIdentifier)
      const newPermissions = concatPermissions(oldPermissions, payload.permission)
      await dispatch(`authorization/${AuthorizationAction.updateAlarmDefinitionAuthorizations}`, {
        alarmDefinitionIds: [alarmDefinition.id],
        userIdentifier,
        permissions: newPermissions,
      } as SetAlarmDefinitionAuthorizationsPayload, { root: true })
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.revokeAlarmDefinitionPermission] ({ state, commit, dispatch, rootState, rootGetters },
    payload: { alarmDefinitionId: string, permission: Permission }): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      const alarmDefinition = rootState.authorization.alarmDefinitionAuthorizations.find(a => a.id === payload.alarmDefinitionId)!
      const userIdentifier = state.selectedUser
      const oldPermissions = getAlarmDefinitionPermissions(rootGetters, payload.alarmDefinitionId, userIdentifier)
      const newPermissions = exceptPermissions(oldPermissions, payload.permission)
      await dispatch(`authorization/${AuthorizationAction.updateAlarmDefinitionAuthorizations}`, {
        alarmDefinitionIds: [alarmDefinition.id],
        userIdentifier,
        permissions: newPermissions,
      } as SetAlarmDefinitionAuthorizationsPayload, { root: true })
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.grantAlarmDefinitionPermissionForPlc] ({ state, commit, dispatch, rootState, rootGetters },
    payload: { plcId: string, permission: Permission }): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      const userIdentifier = state.selectedUser
      const alarmDefinitions = rootState.authorization.alarmDefinitionAuthorizations.filter(a => a.plcId === payload.plcId)
        .filter(alarmDefinition => !hasAlarmDefinitionPermission(rootGetters, alarmDefinition.id, userIdentifier, payload.permission))
      if (alarmDefinitions.length > 0) {
        await Promise.all(alarmDefinitions.map(alarmDefinition => dispatch(AuthorizationUiAction.grantAlarmDefinitionPermission, {
          alarmDefinitionId: alarmDefinition.id,
          permission: payload.permission,
        })))
      }
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.revokeAlarmDefinitionPermissionForPlc] ({ state, commit, dispatch, rootState, rootGetters },
    payload: { plcId: string, permission: Permission }): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      const userIdentifier = state.selectedUser
      const alarmDefinitions = rootState.authorization.alarmDefinitionAuthorizations.filter(a => a.plcId === payload.plcId)
        .filter(alarmDefinition => hasAlarmDefinitionPermission(rootGetters, alarmDefinition.id, userIdentifier, payload.permission))
      if (alarmDefinitions.length > 0) {
        await Promise.all(alarmDefinitions.map(alarmDefinition => dispatch(AuthorizationUiAction.revokeAlarmDefinitionPermission, {
          alarmDefinitionId: alarmDefinition.id,
          permission: payload.permission,
        })))
      }
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.grantAllPermissionsWithSuperPowers] ({ commit, dispatch, state, getters, rootState, rootGetters }): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      const projectId = state.selectedProjectId
      const userIdentifier = state.selectedUser
      const currentUserIdentifier: string = rootGetters[`app/${AppGetter.userIdentifier}`]
      const currentUserHasProjectAuthorizationPermission = 
        hasProjectPermission(rootGetters, projectId, currentUserIdentifier, Permission.AUTHORIZE)
      const currentUserHasConsumptionAuthorizationPermission = 
        hasConsumptionPermission(rootGetters, projectId, currentUserIdentifier, Permission.AUTHORIZE)
      if (currentUserHasProjectAuthorizationPermission) {
        await dispatch(`authorization/${AuthorizationAction.updateProjectAuthorization}`, {
          projectId,
          userIdentifier,
          permissions: [Permission.READ, Permission.EDIT, Permission.AUTHORIZE],
        } as SetProjectAuthorizationPayload, { root: true })
        await delay(DELAY_BETWEEN_CALLS_FOR_BATCH_AUTHORIZATIONS)
      }
      if (currentUserHasConsumptionAuthorizationPermission) {
        await dispatch(`authorization/${AuthorizationAction.updateConsumptionAuthorization}`, {
          projectId,
          userIdentifier,
          permissions: [Permission.READ, Permission.EDIT, Permission.AUTHORIZE],
        } as SetConsumptionAuthorizationPayload, { root: true })
        await delay(DELAY_BETWEEN_CALLS_FOR_BATCH_AUTHORIZATIONS)
      }
      const plcIds: string[] = getters[AuthorizationUiGetter.plcsOfProject](projectId).map(_ => _.id)
      for (const plcId of plcIds) {
        await dispatch(`authorization/${AuthorizationAction.updatePlcAuthorization}`, {
          plcId,
          userIdentifier,
          permissions: [Permission.READ, Permission.EXECUTE, Permission.EDIT, Permission.SYSTEM_NOTIFICATION, Permission.AUTHORIZE],
        } as SetPlcAuthorizationPayload, { root: true })
        await delay(DELAY_BETWEEN_CALLS_FOR_BATCH_AUTHORIZATIONS)
        const deviceIds = rootState.authorization.deviceAuthorizations.filter(_ => _.plcId === plcId).map(_ => _.id)
        if (deviceIds.length > 0) {
          await dispatch(`authorization/${AuthorizationAction.updateDeviceAuthorizations}`, {
            userIdentifier,
            deviceIds,
            permissions: [Permission.READ, Permission.EXECUTE],
          } as SetDeviceAuthorizationsPayload, { root: true })
          await delay(DELAY_BETWEEN_CALLS_FOR_BATCH_AUTHORIZATIONS)
        }
        const alarmDefinitionIds =  rootState.authorization.alarmDefinitionAuthorizations.filter(_ => _.plcId === plcId).map(_ => _.id)
        if (alarmDefinitionIds.length > 0) {
          await dispatch(`authorization/${AuthorizationAction.updateAlarmDefinitionAuthorizations}`, {
            userIdentifier,
            alarmDefinitionIds,
            permissions: [Permission.CUSTOM_NOTIFICATION],
          } as SetAlarmDefinitionAuthorizationsPayload, { root: true })
          await delay(DELAY_BETWEEN_CALLS_FOR_BATCH_AUTHORIZATIONS)
        }
      }
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.revokeAllPermissionsWithSuperPowers] ({ commit, dispatch, state, getters, rootState, rootGetters }): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      const projectId = state.selectedProjectId
      const userIdentifier = state.selectedUser
      const currentUserIdentifier: string = rootGetters[`app/${AppGetter.userIdentifier}`]
      const currentUserHasProjectAuthorizationPermission = 
        hasProjectPermission(rootGetters, projectId, currentUserIdentifier, Permission.AUTHORIZE)
      const currentUserHasOneClickAuthorizationPermission = 
        hasConsumptionPermission(rootGetters, projectId, currentUserIdentifier, Permission.AUTHORIZE)
      if (currentUserHasProjectAuthorizationPermission) {
        await dispatch(`authorization/${AuthorizationAction.updateProjectAuthorization}`, {
          projectId,
          userIdentifier,
          permissions: [],
        } as SetProjectAuthorizationPayload, { root: true })
        await delay(DELAY_BETWEEN_CALLS_FOR_BATCH_AUTHORIZATIONS)
      }
      if (currentUserHasOneClickAuthorizationPermission) {
        await dispatch(`authorization/${AuthorizationAction.updateConsumptionAuthorization}`, {
          projectId,
          userIdentifier,
          permissions: [],
        } as SetConsumptionAuthorizationPayload, { root: true })
        await delay(DELAY_BETWEEN_CALLS_FOR_BATCH_AUTHORIZATIONS)
      }
      const plcIds: string[] = getters[AuthorizationUiGetter.plcsOfProject](projectId).map(_ => _.id)
      for (const plcId of plcIds) {
        await dispatch(`authorization/${AuthorizationAction.updatePlcAuthorization}`, {
          plcId,
          userIdentifier,
          permissions: [],
        } as SetPlcAuthorizationPayload, { root: true })
        await delay(DELAY_BETWEEN_CALLS_FOR_BATCH_AUTHORIZATIONS)
        const deviceIds = rootState.authorization.deviceAuthorizations.filter(_ => _.plcId === plcId).map(_ => _.id)
        if (deviceIds.length > 0) {
          await dispatch(`authorization/${AuthorizationAction.updateDeviceAuthorizations}`, {
            userIdentifier,
            deviceIds,
            permissions: [],
          } as SetDeviceAuthorizationsPayload, { root: true })
          await delay(DELAY_BETWEEN_CALLS_FOR_BATCH_AUTHORIZATIONS)
        }
        const alarmDefinitionIds = rootState.authorization.alarmDefinitionAuthorizations.filter(_ => _.plcId === plcId).map(_ => _.id)
        if (alarmDefinitionIds.length > 0) {
          await dispatch(`authorization/${AuthorizationAction.updateAlarmDefinitionAuthorizations}`, {
            userIdentifier,
            alarmDefinitionIds,
            permissions: [],
          } as SetAlarmDefinitionAuthorizationsPayload, { root: true })
          await delay(DELAY_BETWEEN_CALLS_FOR_BATCH_AUTHORIZATIONS)
        }
      }
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
  async [AuthorizationUiAction.resolveAppRegistrationNames] ({ commit, dispatch }, payload: string[] ): Promise<void> {
    try {
      commit(AuthorizationUiMutation.setInteracted, true)
      await dispatch(`authorization/${AuthorizationAction.resolveAppRegistrationNames}`, payload, { root: true })
    } finally {
      commit(AuthorizationUiMutation.setInteracted, false)
    }
  },
}
