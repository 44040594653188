<template>
  <eco-labeled-control
    :context="context"
    :attributes="attributes"
  >
    <div class="eco-device-messages-control">
      <icon-with-badge v-if="hasErrors"
        material-icon="error"
        :badge="errorBage"
        badge-color="red"
      />
      <icon-with-badge v-if="hasWarnings"
        material-icon="report_problem"
        :badge="warningBage"
        badge-color="orange"
      />
      <icon-with-badge v-if="hasInformations"
        material-icon="info"
        :badge="informationBadge"
        badge-color="blue"
      />
      <popover v-if="hasErrors || hasWarnings || hasInformations" 
        class="popover"
        :header="header"
        icon="open_in_new"
      >
        <div v-for="message in messages" :key="message" class="message">
          <div class="message-text">{{ message.text }}</div>
          <div v-if="message.hint"><popover :header="message.title">{{ message.hint }}</popover></div>
        </div>
        <base-text-button @buttonClicked="copyToClipboard">
          {{ 'common.button.copydetailstoclipboard' | translate }}
        </base-text-button>
      </popover>
      <div v-else class="no-messages" >
        <span>{{ 'n/a' }}</span>
       </div>
    </div>
  </eco-labeled-control>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ControlContextV2, DeviceMessage, DeviceMessagesControlAttributes, MessageLevel } from './models'

import EcoLabeledControl from './shared/EcoLabeledControl.vue'

import IconWithBadge from '../../patterns/IconWithBadge.vue'

import moment from 'moment'
  
@Component({
  components: {
    EcoLabeledControl,
    IconWithBadge,
  },
})
export default class EcoDeviceMessagesControl extends Vue {
  @Prop() public id: string
  @Prop() public context: ControlContextV2
  @Prop() public attributes: DeviceMessagesControlAttributes
  @Prop() public state: object

  public get currentValue(): DeviceMessage[]  {
    return this.state[this.attributes.state] ?? []
  }

  public get messagesEnum() {
    return this.attributes.messagesEnum ?? ''
  }

  public get hasErrors() {
    return this.levelCount(MessageLevel.Error) > 0
  }

  public get hasWarnings() {
    return this.levelCount(MessageLevel.Warning) > 0
  }

  public get hasInformations() {
    return this.levelCount(MessageLevel.Information) > 0
  }

  public get errorBage() {
    return this.levelCount(MessageLevel.Error)
  }

  public get warningBage() {
    return this.levelCount(MessageLevel.Warning)
  }

  public get informationBadge() {
    return this.levelCount(MessageLevel.Information)
  }

  public levelCount(level: MessageLevel) {
    return this.levelFilter(level).length
  }

  public levelFilter(level: MessageLevel) {
    return this.currentValue.filter(_ => _.level === level)
  }

  public get header() {
    return this.context.resolveStringResource(this.attributes.label)
  }

  public get messages() {
    const errors = this.levelFilter(MessageLevel.Error)
      .map(message => this.toMessageProps(message, this.context.resolveStringResource('messagelevel.error')))
    const warnings = this.levelFilter(MessageLevel.Warning)
      .map(message => this.toMessageProps(message, this.context.resolveStringResource('messagelevel.warning')))
    const informations = this.levelFilter(MessageLevel.Information)
      .map(message => this.toMessageProps(message, this.context.resolveStringResource('messagelevel.information')))
    return [...errors, ...warnings, ...informations]
  }

  public toMessageProps(message: DeviceMessage, level: string) {
    return {
      title: this.resolveMessageNameResource(message),
      text: `${level}: ${this.resolveMessageNameResource(message)} (${message.value}) - ${moment.utc(message.timestamp).local().format('DD.MM.YYYY HH:mm:ss')}`,
      hint: this.resolveMessageDescriptionResource(message),
    }
  }

  public resolveMessageNameResource = (message: DeviceMessage): string => {
    const resourceKey = `Message_${this.messagesEnum}_${message.id}_Name`
    const resolvedResource = this.context.resolveStringResource(resourceKey)
    return resolvedResource !== resourceKey ? resolvedResource : message.text
  }

  public resolveMessageDescriptionResource = (message: DeviceMessage): string => {
    const resourceKey = `Message_${this.messagesEnum}_${message.id}_Description`
    const resolvedResource = this.context.resolveStringResource(resourceKey)
    return resolvedResource !== resourceKey ? resolvedResource : ''
  }

  public copyToClipboard() {
    if ((this as any).$copyText) {
      (this as any).$copyText(this.messages.map(m => [m.text, m.hint].join(' ')).join('\n'))
    }
  }
}
</script>

<style lang="scss" scoped>
.eco-device-messages-control {
  display: flex;
  align-items: center;
  .popover {
    margin-bottom: 5px;
  }
  .no-messages {
    padding: 5px;
    line-height: 1.2;
    border-radius: 5px;
    background: rgba(123, 139, 154, 0.1);
  }
}
.message {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-items: center;
  .message-text {
    margin-right: 10px;
  }
}
.v-btn {
  border: 1px solid white;
} 

</style>

<style lang="css">
</style>
