import { Endpoint } from '@ecocoach/domain-store-modules/src/systemConfiguration/models'
import { EndpointRecordingConfigurationModel, LogEntryModel, LoggerConfigurationModel } from './models'

export enum LogToolUiAction {
  openLogToolForPlc = 'openLogToolForPlc',
  closeLogTool = 'closeLogTool',
  loadEndpointRecordingConfigurations = 'loadEndpointRecordingConfigurations',
  reloadEndpointRecordingConfigurations = 'reloadEndpointRecordingConfigurations',
  createPlcEndpointRecordingConfiguration = 'createPlcEndpointRecordingConfiguration',
  createDeviceEndpointRecordingConfiguration = 'createDeviceEndpointRecordingConfiguration',
  createControlEndpointsRecordingConfiguration = 'createControlEndpointsRecordingConfiguration',
  deleteEndpointRecordingConfiguration = 'deleteEndpointRecordingConfiguration',
  deleteAllEndpointRecordingConfigurations = 'deleteAllEndpointRecordingConfigurations',
  downloadEndpointRecordingData = 'downloadEndpointRecordingData',
  downloadAllEndpointRecordingData = 'downloadAllEndpointRecordingData',
  selectDevice = 'selectDevice',
  connectToLogHub = 'connectToLogHub',
  disconnectFromLogHub = 'disconnectFromLogHub',
  requestLoggerConfigurations = 'requestLoggerConfigurations',
  setLogLevel = 'setLogLevel',
  resetLogSource = 'resetLogSource',
  startReceivingLogs = 'startReceivingLogs',
  stopReceivingLogs = 'stopReceivingLogs',
  readPlcEndpoint = 'readPlcEndpoint',
  readDeviceEndpoint = 'readDeviceEndpoint',
  readControlEndpoints = 'readControlEndpoints',
  requestReadOfControlStates = 'requestReadOfControlStates',
  retrieveLogsFromDb = 'retrieveLogsFromDb',
  purgeLogsFromDb = 'purgeLogsFromDb',
  loggerConfigurationsReceived = 'loggerConfigurationsReceived',
  logEntriesReceived = 'logEntriesReceived',
  downloadCloudLogEntries = 'downloadCloudLogEntries',
  downloadDbLogEntries = 'downloadDbLogEntries',
}

export enum LogToolUiMutation {
  setInteracted = 'setInteracted',
  setLoading = 'setLoading',
  setSelectedPlcId = 'setSelectedPlcId',
  setEndpointRecordingConfigurations = 'setEndpointRecordingConfigurations',
  upsertEndpointRecordingConfiguration = 'upsertEndpointRecordingConfiguration',
  removeEndpointRecordingConfiguration = 'removeEndpointRecordingConfiguration',
  setSelectedDeviceId = 'setSelectedDeviceId',
  setEndpoints = 'setEndpoints',
  setLoggingConfigurations = 'setLoggingConfigurations',
  upsertLoggingConfiguration = 'upsertLoggingConfiguration',
  removeLoggingConfiguration = 'removeLoggingConfiguration',
  insertEndpointLogEntries = 'insertEndpointLogEntries',
  insertCloudLogEntries = 'insertCloudLogEntries',
  insertDbLogEntries = 'insertDbLogEntries',
  clearEndpointLogEntries = 'clearEndpointLogEntries',
  clearCloudLogEntries = 'clearCloudLogEntries',
  clearDbLogEntries = 'clearDbLogEntries',
}

export enum LogToolUiGetter {
  isInteracted = 'isInteracted',
  isLoading = 'isLoading',
  selectedPlcId = 'selectedPlcId',
  endpointRecordingConfigurations = 'endpointRecordingConfigurations',
  deviceOptions = 'deviceOptions',
  controlOptions = 'controlOptions',
  endpointOptions = 'endpointOptions',
  selectedDeviceId = 'selectedDeviceId',
  defaultLogLevelForDestination = 'defaultLogLevelForDestination',
  loggingConfigurationsForDestination = 'loggingConfigurationsForDestination',
  logLevelOptions = 'logLevelOptions',
  logSourceOptions = 'logSourceOptions',
  endpointLogEntries = 'endpointLogEntries',
  cloudLogEntries = 'cloudLogEntries',
  dbLogEntries = 'dbLogEntries',
}

export interface LogToolUiState {
  interacted: boolean
  loading: boolean
  selectedPlcId: string
  endpointRecordingConfigurations: EndpointRecordingConfigurationModel[]
  selectedDeviceId: string
  endpoints: Endpoint[]
  loggingConfigurations: LoggerConfigurationModel[]
  endpointLogEntries: LogEntryModel[]
  cloudLogEntries: LogEntryModel[]
  dbLogEntries: LogEntryModel[]
}
