import ApiService from '../../services/api.service'
import { ComponentFirmwareReleaseModel, ComponentFirmwareType, PlcModel, PlcOperationReleaseOutput, ProjectModel, SceneModel } from '../models'

export default {
  async loadProjects(includeDemoProjects: boolean): Promise<ProjectModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationQueryApi}/Projects?includeDemoProjects=${includeDemoProjects}`)
    return response.data.value
  },
  async activeReleaseByPlc(plcId: string, language: string): Promise<PlcOperationReleaseOutput> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationQueryApi}/Plcs/${plcId}/activePlcConfigurationRelease/?resourceLanguage=${language}`)
    response.data.value.controlsV2.forEach(c => c.state = {})
    return {
      plcs: [],
      rooms: response.data.value.rooms,
      devices: response.data.value.devices,
      controls: response.data.value.controlsV2,
      activePlcConfigurationReleaseId: response.data.value.activePlcConfigurationReleaseId,
    }
  },
  async activeReleasesByProject(projectId: string, language: string): Promise<PlcOperationReleaseOutput> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationQueryApi}/Projects/${projectId}/activePlcConfigurationReleases/?resourceLanguage=${language}`)
    response.data.value.controlsV2.forEach(c => c.state = {})
    response.data.value.plcs.forEach((plc: PlcModel) => {
      plc.plcOperationState = plc.plcOperationState ?? null
      plc.plcOperationStateTimeStamp = plc.plcOperationStateTimeStamp ?? null
    })
    
    return {
      plcs: response.data.value.plcs,
      rooms: response.data.value.rooms,
      devices: response.data.value.devices,
      controls: response.data.value.controlsV2,
      activePlcConfigurationReleaseId: '',
    }
  },
  async scenesByProjectId(projectId: string): Promise<SceneModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationQueryApi}/Projects/${projectId}/scenes`)
    return response.data.value
  },
  async favoritesByProjectId(projectId: string): Promise<string[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationQueryApi}/Projects/${projectId}/favorites`)
    return response.data.value
  },
  async confirmedDevices(plcId: string): Promise<string[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationQueryApi}/Plcs/${plcId}/confirmedDevices`)
    return response.data.value.filter(d => d.isConfirmed).map(d => d.deviceId)
  },
  async componentFirmwareReleases(type: ComponentFirmwareType): Promise<ComponentFirmwareReleaseModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationQueryApi}/ComponentFirmware/${type}`)
    return response.data.value ?? []
  },
}
