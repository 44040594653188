import { LogToolUiMutation, LogToolUiState } from '@/store/modules/logToolUi/types'
import { Endpoint } from '@ecocoach/domain-store-modules/src/systemConfiguration/models'
import { remove, removeWithSelector, upsert, upsertWithSelector } from '@ecocoach/domain-store-modules/src/utils'
import { MutationTree } from 'vuex'
import { EndpointRecordingConfigurationModel, LogEntryModel, LoggerConfigurationModel } from './models'

export const mutations: MutationTree<LogToolUiState> = {
  [LogToolUiMutation.setInteracted](state, payload: boolean) {
    state.interacted = payload
  },
  [LogToolUiMutation.setLoading](state, payload: boolean) {
    state.loading = payload
  },
  [LogToolUiMutation.setSelectedPlcId](state, payload: string) {
    state.selectedPlcId = payload
  },
  [LogToolUiMutation.setEndpointRecordingConfigurations](state, payload: EndpointRecordingConfigurationModel[]) {
    state.endpointRecordingConfigurations = payload
  },
  [LogToolUiMutation.upsertEndpointRecordingConfiguration](state, payload: EndpointRecordingConfigurationModel) {
    upsert(state.endpointRecordingConfigurations, payload)
  },
  [LogToolUiMutation.removeEndpointRecordingConfiguration](state, id: string) {
    remove(state.endpointRecordingConfigurations, id)
  },
  [LogToolUiMutation.setSelectedDeviceId](state, payload: string) {
    state.selectedDeviceId = payload
  },
  [LogToolUiMutation.setEndpoints](state, payload: Endpoint[]) {
    state.endpoints = payload
  },
  [LogToolUiMutation.setLoggingConfigurations](state, payload: LoggerConfigurationModel[]) {
    state.loggingConfigurations = payload
  },
  [LogToolUiMutation.upsertLoggingConfiguration](state, payload: LoggerConfigurationModel) {
    upsertWithSelector(state.loggingConfigurations, payload,
      c => c.logDestination === payload.logDestination && c.logSource === payload.logSource)
  },
  [LogToolUiMutation.removeLoggingConfiguration](state, payload: LoggerConfigurationModel) {
    state.loggingConfigurations = removeWithSelector(state.loggingConfigurations,
      c => c.logDestination === payload.logDestination && c.logSource === payload.logSource)
  },
  [LogToolUiMutation.insertEndpointLogEntries](state, payload: LogEntryModel[]) {
    state.endpointLogEntries.unshift(...payload)
  },
  [LogToolUiMutation.insertCloudLogEntries](state, payload: LogEntryModel[]) {
    state.cloudLogEntries.unshift(...payload)
  },
  [LogToolUiMutation.insertDbLogEntries](state, payload: LogEntryModel[]) {
    state.dbLogEntries = [...state.dbLogEntries, ...payload]
      .sort((a, b) => b.logTimestamp.localeCompare(a.logTimestamp))
  },
  [LogToolUiMutation.clearEndpointLogEntries](state) {
    state.endpointLogEntries = []
  },
  [LogToolUiMutation.clearCloudLogEntries](state) {
    state.cloudLogEntries = []
  },
  [LogToolUiMutation.clearDbLogEntries](state) {
    state.dbLogEntries = []
  },
}