import { ActionTree } from 'vuex'
import api from './api'
import { AuditLogAction, AuditLogMutation, AuditLogState} from './types'

export const actions: ActionTree<AuditLogState, {}> = {
  async [AuditLogAction.loadAuditLogEntries]({ commit }, plcId: string): Promise<void> {
    const data = await api.loadAuditLogEntries(plcId)
    commit(AuditLogMutation.setAuditLogEntries, data)
  },
}
