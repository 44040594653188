<template>
  <eco-labeled-control
    :context="context"
    :attributes="attributes"
  >
    <base-toggle-button v-if="isSwitch"
      :value="currentValue"
      :pending-value="pendingValue"
      :disabled="readonly"
      @buttonToggled="onToggle"
    />
    <lock-toggle-button v-else-if="isLock"
      :value="currentValue"
      :pending-value="pendingValue"
      :disabled="readonly"
      @buttonToggled="onToggle"
    />
    <base-checkbox v-else
      :value="currentValue"
      :pending-value="pendingValue"
      :disabled="readonly"
      @buttonToggled="onToggle"
    />
  </eco-labeled-control>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import BaseToggleButton from '../../base/BaseToggleButton.vue'
import BaseCheckbox from '../../patterns/BaseCheckbox.vue'
import LockToggleButton from '../../patterns/LockToggleButton.vue'
import { ControlContextV2, ToggleControlAppearance, ToggleControlAttributes } from './models'
import EcoLabeledControl from './shared/EcoLabeledControl.vue'
  
@Component({
  components: {
    BaseCheckbox,
    BaseToggleButton,
    EcoLabeledControl,
    LockToggleButton,
  },
})
export default class EcoToggleControl extends Vue {
  @Prop() public id: string
  @Prop() public context: ControlContextV2
  @Prop() public attributes: ToggleControlAttributes
  @Prop() public state: object
  @Prop() public pendingState: object
  @Prop() public readonly: boolean

  public onToggle(value: boolean) {
    this.context.executeCommand(this.id, {
      command: this.attributes.toggle.command,
      params: !value,
      pendingState: {
        [this.attributes.toggle.state]: !value,
      },
    })
  }

  public get isSwitch() {
    return this.attributes?.appearance === ToggleControlAppearance.Switch
  }

  public get isLock() {
    return this.attributes?.appearance === ToggleControlAppearance.Lock
  }

  public get currentValue() {
    return this.state[this.attributes.toggle.state]
  }

  public get pendingValue() {
    return this.pendingState[this.attributes.toggle.state]
  }
}
</script>

<style lang="scss" scoped>
</style>

<style lang="css">
</style>
