import { actions } from '@/store/modules/plcConnectionHistoryUi/actions'
import { getters } from '@/store/modules/plcConnectionHistoryUi/getters'
import { mutations } from '@/store/modules/plcConnectionHistoryUi/mutations'
import { state } from '@/store/modules/plcConnectionHistoryUi/state'
import { PlcConnectionHistoryUiAction, PlcConnectionHistoryUiGetter, PlcConnectionHistoryUiMutation, PlcConnectionHistoryUiState } from '@/store/modules/plcConnectionHistoryUi/types'
import { RootState } from  '@/store/types'
import { Module } from 'vuex'

const namespaced: boolean = true
export const plcConnectionHistoryUi: Module<PlcConnectionHistoryUiState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}

export const types = {
  action: PlcConnectionHistoryUiAction,
  mutation: PlcConnectionHistoryUiMutation,
  getter: PlcConnectionHistoryUiGetter,
}

export const domain = 'plcConnectionHistoryUi'

export const vuexModule = {
  [domain]: plcConnectionHistoryUi,
}