import { MutationTree } from 'vuex'
import { UpgradeRequiredReason, ValidationIssue } from './models'
import { LoadProgramUiMutation, LoadProgramUiState } from './types'

export const mutations: MutationTree<LoadProgramUiState> = {
  [LoadProgramUiMutation.setInteracted](state, payload: boolean) {
    state.interacted = payload
  },
  [LoadProgramUiMutation.setUpgradeRequiredReason](state, payload: UpgradeRequiredReason) {
    state.upgradeRequiredReason = payload
  },
  [LoadProgramUiMutation.setValidationIssues](state, payload: ValidationIssue[]) {
    state.validationIssues = payload
  },
}
