<template>
  <modal-dialog :show="show"
    :header="`${note ? 'note.editnote' : 'note.createnote'}` | translate"
    :button1-text="`${note ? 'common.button.save' : 'common.button.create'}` | translate"
    :button2-text="'common.button.cancel' | translate"
    :button1-disabled="isInteracted || !canCreate"
    :button2-disabled="isInteracted"
    @button1Pressed="onConfirm"
    @button2Pressed="onClose"
  >
    <v-layout column>
      <form-field :text="'note.text' | translate">
        <textarea class="note-text-area " :class="{ disabled: isInteracted }"
          :value="text"
          :disabled="isInteracted"
          @input="text = $event.target.value"
        />
      </form-field>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { NoteModel } from '../../../../../../eco-domain-store-modules/src/note/models'

const MAX_TEXT_LENGTH = 512

const NoteUi = namespace('noteUi')

@Component({
  components: {
  },
})
export default class EditNoteDialog extends Vue {
  @Prop() public show: boolean
  @Prop() public note: NoteModel
  @NoteUi.Getter public isInteracted: boolean
  @NoteUi.Action public createNoteForPlc: (payload: NoteModel) => Promise<void>
  @NoteUi.Action public updateNoteForPlc: (payload: NoteModel) => Promise<void>

  public MAX_TEXT_LENGTH = 512

  public text = ''

  @Watch('show')
  public async onNoteChanged() {
    if (this.note) { // edit
      this.text = this.note.text
    } else { // create
      this.text = ''
    }
  }

  public get canCreate() {
    return !!this.text && this.text.length < MAX_TEXT_LENGTH
  }

  public async onConfirm() {
    if (this.note) {
      await this.updateNoteForPlc({
        ...this.note,
        text: this.text.trim(),
      } as NoteModel)
    } else {
      await this.createNoteForPlc({
        text: this.text.trim(),
      } as NoteModel)
    }
    this.onClose()
  }

  @Emit('closed')
  public onClose() {
    return
  }
}
</script>
<style lang="scss" scoped>
.field {
  height: 300px;
  margin-right: 10px;
  margin-bottom: 10px;
  .text-input {
    padding-left: 10px;
    padding-right: 10px;
  }
  .label {
    padding-left: 10px;
  }
  textarea {
    margin-left: 10px;
  }
}
.note-text-area {
  resize: none;  
  box-sizing: border-box;
  font-family: $font-family-primary;
  height: 100%;
  width: 100%;
  border-width: $default-border-width;
  border-style: solid;
  border-color: $fore-color-primary;
  padding: $default-padding;
  color: $fore-color-primary;
  background: transparent;
  outline: none;
  &.disabled {
    color: $fore-color-disabled;
  }
}
</style>