<template>
  <modal-dialog 
    :show="show"
    :header="'mapping.dialog.setnodeid' | translate"
    button1-text="OK"
    :button2-text="'common.button.cancel' | translate"
    @button1Pressed="onOk"
    @button2Pressed="onCancelled"
  >
    <v-layout column>
      <v-flex>
        <form-field :text="'mapping.nodeid' | translate">
          <base-single-select 
            :options="instanceNumberOptions"
            option-value="instanceNumber"
            option-label="text"
            :selected-value="instanceNumber"
            :disabled="instanceNumberOptions.length <= 1"
            @selected="onSelected"
          />
        </form-field>
      </v-flex>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

import { AutoMappingSlotModel } from '../../../../../../../eco-domain-store-modules/src/systemConfiguration/models'

import { AutoMappingModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'

@Component
export default class SetAutoMappingInstanceNumberDialog extends Vue {
  @Prop({type: Boolean}) public show: boolean
  @Prop() public autoMappingSlot: AutoMappingSlotModel
  @Prop() public autoMappings: AutoMappingModel[]

  public instanceNumber = 0

  @Watch('show')
  public setDefaults() {
    if (this.show) {
      const usedInstanceNumbers = this.usedInstanceNumbers
      const highestUsedInstanceNumber = usedInstanceNumbers[usedInstanceNumbers.length -1] || 0
      this.instanceNumber = highestUsedInstanceNumber + 1
    }
  }

  public get instanceNumberOptions() {
    const usedInstanceNumbers = this.usedInstanceNumbers
    const maxCount = this.autoMappingSlot && this.autoMappingSlot.maxCount || 0
    return Array.from(Array<number>(maxCount + 1).keys()).slice(1)
      .filter(id => !usedInstanceNumbers.includes(id))
      .map(id => ({
        instanceNumber: id,
        text: id.toString(),
      }))
  }

  public get usedInstanceNumbers(): number[] {
    return (this.autoMappings || []).map(m => m.instanceNumber!).sort((a, b) => a - b)
  }

  public onSelected(value: number) {
    this.instanceNumber = value
  }

  @Emit('selected') 
  public onOk () {
    return this.instanceNumber
  }

  @Emit('cancelled') 
  public onCancelled () {
    return true
  }
}
</script>
<style lang="scss" scoped>
</style>