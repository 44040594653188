<template> 
  <v-layout column wrap>
    <wizard-step-header 
      :title="'wizard.sps.choose' | translate"
      :sub-title="subtitle"
    />
    <div class="plc-number">
      {{ numPlcsLabel }}
    </div>
    <div class="plc-list">
      <plc-table 
        @selected="onSelectPlc"
        @openAlarmLog="onOpenAlarmLog"
        @openPlcConnectionHistory="onOpenPlcConnectionHistory"
        @migrateEcc="askForMigrateEcoCloudConnectorConfirmation"
        @restartEcc="askForRestartEcoCloudConnectorConfirmation"
        @restartPlc="askForRestartPlcConfirmation"
        @updateEcc="askForUpdateEcoCloudConnectorConfirmation"
        @updateInTestEcc="askForUpdateInTestEcoCloudConnectorConfirmation"
        @resetPlcConfigurationRelease="askForResetPlcConfigurationReleaseConfirmation"
        @updateTrumpfInverterFirmware="showUpdateTrumpfInverterFirmwareDialog"
        @openAuditLog="onOpenAuditLog"
        @openEcoLogTool="onOpenEcoLogTool"
        @showPlcSystemInformation="showPlcSystemInformationDialog"
        @collectSupportData="collectSupportDataDialog"
      />
    </div>
    <confirm-dialog
      :show="plcIdToMigrateEcoCloudConnector"
      :header="'common.button.migrateEcoCloudConnector' | translate"
      :text="'common.dialog.confirm' | translate"
      :confirm-button-text="'OK'"
      :cancel-button-text="'common.button.cancel' | translate"
      @confirmed="onMigrateEcoCloudConnectorConfirmation"
      @cancelled="plcIdToMigrateEcoCloudConnector = null"
    />
    <confirm-dialog
      :show="plcIdToRestartEcoCloudConnector"
      :header="'common.button.restartEcoCloudConnector' | translate"
      :text="'common.dialog.confirm' | translate"
      :confirm-button-text="'OK'"
      :cancel-button-text="'common.button.cancel' | translate"
      @confirmed="onRestartEcoCloudConnectorConfirmation"
      @cancelled="plcIdToRestartEcoCloudConnector = null"
    />
    <confirm-dialog
      :show="plcIdToRestart"
      :header="'common.button.restartPlc' | translate"
      :text="'common.dialog.confirm' | translate"
      :confirm-button-text="'OK'"
      :cancel-button-text="'common.button.cancel' | translate"
      @confirmed="onRestartPlcConfirmation"
      @cancelled="plcIdToRestart = null"
    />
    <confirm-dialog
      :show="plcIdToUpdateEcoCloudConnector"
      :header="'common.button.updateToLatestEcoCloudConnector' | translate"
      :text="'updateEcc.confirmation' | translate"
      :confirm-button-text="'OK'"
      :cancel-button-text="'common.button.cancel' | translate"
      @confirmed="onUpdateEcoCloudConnectorConfirmation(false)"
      @cancelled="plcIdToUpdateEcoCloudConnector = null"
    />
    <confirm-dialog
        :show="numberOfMessagesInQueue > 0 && isInTestCalled === false"
        :header="'common.button.forceEcoCloudConnectorUpdate' | translate"
        :text="'updateEcc.forceConfirmation' | translate"
        :confirm-button-text="'OK'"
        :cancel-button-text="'common.button.cancel' | translate"
        @confirmed="onUpdateEcoCloudConnectorConfirmation(true)"
        @cancelled="setNumberOfMessagesInQueue(0)"
    />
    <confirm-dialog
      :show="plcIdToUpdateInTestEcoCloudConnector"
      :header="'common.button.updateToLatestInTestEcoCloudConnector' | translate"
      :text="'updateInTestEcc.confirmation' | translate"
      :confirm-button-text="'OK'"
      :cancel-button-text="'common.button.cancel' | translate"
      @confirmed="onUpdateInTestEcoCloudConnectorConfirmation(false)"
      @cancelled="plcIdToUpdateInTestEcoCloudConnector = null"
    />
    <confirm-dialog
        :show="numberOfMessagesInQueue > 0 && isInTestCalled === true"
        :header="'common.button.forceEcoCloudConnectorUpdate' | translate"
        :text="'updateInTestEcc.forceConfirmation' | translate"
        :confirm-button-text="'OK'"
        :cancel-button-text="'common.button.cancel' | translate"
        @confirmed="onUpdateInTestEcoCloudConnectorConfirmation(true)"
        @cancelled="setNumberOfMessagesInQueue(0)"
    />
    <confirm-dialog
      :show="plcIdToResetPlcConfigurationRelease"
      :header="'common.button.resetPlcConfigurationRelease' | translate"
      :text="'resetPlcConfigurationRelease.confirmation' | translate"
      :confirm-button-text="'OK'"
      :cancel-button-text="'common.button.cancel' | translate"
      @confirmed="onResetPlcConfigurationReleaseConfirmation"
      @cancelled="plcIdToResetPlcConfigurationRelease = null"
    />
    <update-trumpf-inverter-firmware-dialog
      :show="plcIdToUpdateTrumpfInverterFirmware"
      :plcId="plcIdToUpdateTrumpfInverterFirmware"
      @closed="plcIdToUpdateTrumpfInverterFirmware = null"
    />
    <alarm-log/>
    <plc-connection-history/>
    <audit-log/>
    <log-tool/>
    <plc-system-information-dialog
      :show="plcToShowSystemInformation"
      :plc="plcToShowSystemInformation"
      @closed="plcToShowSystemInformation = null"
    />
    <plc-support-data-dialog
      :show="plcTocollectSupportData"
      :plc="plcTocollectSupportData"
      :support-data="supportData"
      @closed="plcTocollectSupportData = null"
    />
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import PlcSupportDataDialog from './PlcSupportDataDialog.vue'
import PlcSystemInformationDialog from './PlcSystemInformationDialog.vue'
import PlcTable from './PlcTable.vue'
import UpdateTrumpfInverterFirmwareDialog from './UpdateTrumpfInverterFirmwareDialog.vue'

import AlarmLog from '../alarmLog/index.vue'
import AuditLog from '../auditLog/index.vue'
import LogTool from '../logTool/index.vue'
import PlcConnectionHistory from '../plcConnectionHistory/index.vue'

import { PlcSupportData, PlcViewModel } from '../../../../../store/modules/selectPlcUi/models'

import { PlcModel } from '@ecocoach/domain-store-modules/src/plc/models'

const Resource = namespace('resource')
const Wizard = namespace('wizard')
const App = namespace('app')
const SelectPlcUi = namespace('selectPlcUi')
const AlarmLogUi = namespace('alarmLogUi')
const LogToolUi = namespace('logToolUi')
const PlcConnectionHistoryUi = namespace('plcConnectionHistoryUi')
const AuditLogUi = namespace('auditLogUi')
const Plc = namespace('plc')

@Component({
  components: {
    AlarmLog,
    AuditLog,
    LogTool,
    PlcConnectionHistory,
    PlcSystemInformationDialog,
    PlcSupportDataDialog,
    PlcTable,
    UpdateTrumpfInverterFirmwareDialog,
  },
})
export default class SelectPlc extends Vue { 
  @Resource.Getter public dictionary
  @App.Action public selectPlc: (plcId: string) => Promise<void>
  @App.Getter public selectedPlc: PlcModel | null
  @App.Getter public writablePlcs: PlcModel[]
  @Wizard.Mutation public wentToStep
  @Wizard.Getter public wizardSteps
  @SelectPlcUi.Getter public plcs: PlcViewModel[]
  @SelectPlcUi.Getter public numberOfMessagesInQueue: number
  @SelectPlcUi.Getter public filteredPlcs: (plcs: PlcViewModel[]) => PlcViewModel[]
  @SelectPlcUi.Getter public supportData: PlcSupportData
  @SelectPlcUi.Action public initialize: () => Promise<void>
  @SelectPlcUi.Action public migratePlcToEcoCloudConnector: (plcId: string) => Promise<void>
  @SelectPlcUi.Action public updateToLatestEcoCloudConnector: (payload: {plcId: string, forceUpdate: boolean}) => Promise<void>
  @SelectPlcUi.Action public resetPlcConfigurationRelease: (plcId: string) => Promise<void>
  @SelectPlcUi.Action public updateToLatestInTestEcoCloudConnectorVersion: (payload: {plcId: string, forceUpdate: boolean}) => Promise<void>
  @SelectPlcUi.Action public restartEcoCloudConnector: (plcId: string) => Promise<void>
  @SelectPlcUi.Action public restartPlc: (plcId: string) => Promise<void>
  @SelectPlcUi.Action public collectSupportData: (plcId: string) => Promise<void>
  @SelectPlcUi.Mutation public setNumberOfMessagesInQueue: (numberOfMessagesInQueue: number) => void
  @AlarmLogUi.Action public openAlarmLogForPlc: (id: string) => Promise<void>
  @PlcConnectionHistoryUi.Action public openPlcConnectionHistoryForPlc: (id: string) => Promise<void>
  @AuditLogUi.Action public openAuditLog: (plcId: string) => Promise<void>
  @LogToolUi.Action public openLogToolForPlc: (id: string) => Promise<void>
  @Plc.Action public startForPlcs: (plcIds: string[]) => Promise<void>

  public plcIdToMigrateEcoCloudConnector: string | null = null
  public plcIdToRestartEcoCloudConnector: string | null = null
  public plcIdToRestart: string | null = null
  public plcIdToUpdateEcoCloudConnector: string | null = null
  public plcIdToForceUpdateEcoCloudConnector: string | null = null
  public plcIdToUpdateInTestEcoCloudConnector: string | null = null
  public plcIdToResetPlcConfigurationRelease: string | null = null
  public plcIdToForceUpdateInTestEcoCloudConnector: string | null = null
  public plcIdToUpdateTrumpfInverterFirmware: string | null = null
  public plcToShowSystemInformation: PlcModel | null = null
  public plcTocollectSupportData: PlcModel | null = null
  public isInTestCalled: boolean | null = null

  public mounted() { 
    const step = this.wizardSteps.find(s => s.name === 'selectPlc')
    this.wentToStep(step)
    this.initialize()
  }

  public async onSelectPlc(plcId: string) {
    if(!this.selectedPlc) {
      await this.selectPlc(plcId)
    } else if (plcId !== this.selectedPlc.id) {
      await this.selectPlc(plcId)
    }
  }

  public async onOpenAlarmLog(plcId: string) {
    this.openAlarmLogForPlc(plcId)
  }

  public async onOpenPlcConnectionHistory(plcId: string) {
    this.openPlcConnectionHistoryForPlc(plcId)
  }

  public askForMigrateEcoCloudConnectorConfirmation(plcId: string) {
    this.plcIdToMigrateEcoCloudConnector = plcId
  }

  public askForRestartEcoCloudConnectorConfirmation(plcId: string) {
    this.plcIdToRestartEcoCloudConnector = plcId
  }

  public askForRestartPlcConfirmation(plcId: string) {
    this.plcIdToRestart = plcId
  }

  public askForUpdateEcoCloudConnectorConfirmation(plcId: string) {
    this.plcIdToUpdateEcoCloudConnector = plcId
  }

  public askForUpdateInTestEcoCloudConnectorConfirmation(plcId: string) {
    this.plcIdToUpdateInTestEcoCloudConnector = plcId
  }

  public askForResetPlcConfigurationReleaseConfirmation(plcId: string) {
    this.plcIdToResetPlcConfigurationRelease = plcId
  }
  public showUpdateTrumpfInverterFirmwareDialog(plcId: string) {
    this.plcIdToUpdateTrumpfInverterFirmware = plcId
  }

  public async onMigrateEcoCloudConnectorConfirmation() {
    this.migratePlcToEcoCloudConnector(this.plcIdToMigrateEcoCloudConnector!)
    this.plcIdToMigrateEcoCloudConnector = null
  }

  public async onRestartEcoCloudConnectorConfirmation() {
    this.restartEcoCloudConnector(this.plcIdToRestartEcoCloudConnector!)
    this.plcIdToRestartEcoCloudConnector = null
  }

  public async onRestartPlcConfirmation() {
    this.restartPlc(this.plcIdToRestart!)
    this.plcIdToRestart = null
  }

  public async onUpdateEcoCloudConnectorConfirmation(forceUpdate: boolean) {
    this.isInTestCalled = false
    this.plcIdToForceUpdateEcoCloudConnector = this.plcIdToUpdateEcoCloudConnector ?? this.plcIdToForceUpdateEcoCloudConnector
    this.plcIdToUpdateEcoCloudConnector = null
    await this.updateToLatestEcoCloudConnector({ plcId: this.plcIdToForceUpdateEcoCloudConnector!, forceUpdate })
    if (this.numberOfMessagesInQueue === 0) {
      this.plcIdToForceUpdateEcoCloudConnector = null
    }
  }

  public async onUpdateInTestEcoCloudConnectorConfirmation(forceUpdate: boolean) {
    this.isInTestCalled = true
    this.plcIdToForceUpdateInTestEcoCloudConnector = this.plcIdToUpdateInTestEcoCloudConnector ?? this.plcIdToForceUpdateInTestEcoCloudConnector
    this.plcIdToUpdateInTestEcoCloudConnector = null
    await this.updateToLatestInTestEcoCloudConnectorVersion({ plcId: this.plcIdToForceUpdateInTestEcoCloudConnector!, forceUpdate })
    if (this.numberOfMessagesInQueue === 0) {
      this.plcIdToForceUpdateInTestEcoCloudConnector = null
    }
  }

  public async onResetPlcConfigurationReleaseConfirmation(){
    this.resetPlcConfigurationRelease(this.plcIdToResetPlcConfigurationRelease!)
    this.plcIdToResetPlcConfigurationRelease = null
  }

  public onOpenAuditLog(plcId: string) {
    this.openAuditLog(plcId)
  }

  public onOpenEcoLogTool(plcId: string) {
    this.openLogToolForPlc(plcId)
  }

  public showPlcSystemInformationDialog(plc: PlcViewModel) {
    this.plcToShowSystemInformation = plc ?? null
  }

  public async collectSupportDataDialog(plc: PlcViewModel) {
    await this.collectSupportData(plc.id)
    this.plcTocollectSupportData = plc ?? null
  }

  public get subtitle() {
    if(this.selectedPlc) {
      return this.selectedPlc.name
    } else {
      return this.dictionary('select.plc.all.plcs')
    }
  }

  public get numPlcsLabel() {
    return `${this.dictionary('select.plc.number')}: ${this.filteredPlcs(this.plcs).length}/${this.plcs.length}`
  }
}
</script>
<style lang="scss" scoped>
  .plc-number {
    margin-bottom: 10px;
  }
  .plc-list {
    flex: 1;
    padding-bottom: 60px;
  } 
</style>