<template>
  <div class="BaseRangeSlider">
    <div class="bar" :style="backgroundGradient"></div>
    <v-slider class="slider" ref="slider"
      v-model="sliderValue"
      :min="minimalValue"
      :max="maximalValue"
      :step="interval"
      :disabled="readonly"
      height="35"
      color="transparent"
      track-color="transparent"
      @input="sliderChanged"
      @start="emitStartedSliding"
      @end="emitStoppedSliding"
    />
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

const SLIDER_TIMEOUT = 1000

@Component({
  components: {
  },
})
export default class BaseRangeSlider extends Vue {
  @Prop({ type: Number, default: 0 }) public minimalValue: number
  @Prop({ type: Number, default: 100 }) public maximalValue: number
  @Prop({ type: Number, default: 10 }) public interval: number
  @Prop({ type: Number, default: 0 }) public currentValue: number
  @Prop({ type: Array, default: ['red','orange'] }) public gradientColors: string[]
  @Prop({ default: false }) public readonly: boolean

  public startedSlidingEmitted = false
  public selectedNewValueEmitted = false
  public waitingForUpdates = false
  public sliderTimeoutHandle: any = null
  public sliderValue = 0

  public beforeMount() {
    this.sliderValue = this.currentValue
  }

  @Emit('startedSliding')
  public emitStartedSliding() {
    this.startedSlidingEmitted = true
    this.bindGlobalEvents()
  }

  @Emit('selectedNewValue')
  public emitSelectedNewValue(value: number) {
    this.selectedNewValueEmitted = true
    return value
  }

  @Emit('stoppedSliding')
  public emitStoppedSliding() {
    if (!this.selectedNewValueEmitted) {
      this.emitSelectedNewValue(this.currentValue ?? this.minimalValue)
    }
    this.selectedNewValueEmitted = false
    this.startedSlidingEmitted = false
    this.removeGlobalEvents()
  }

  public get backgroundGradient(): any {
    return {
      background: `-webkit-linear-gradient(left, ${ (this.gradientColors).join(' ,')})`,
    }
  }

  @Watch('currentValue')
  public currentValueChanged(value: number) {
    if (value !== null && !this.waitingForUpdates) {
        this.applyCurrentValue()
    }
  }

  public sliderChanged(value: number) {
    const needToEmitStartStopEvents = !this.startedSlidingEmitted
    if (needToEmitStartStopEvents) {
        this.emitStartedSliding()
    }

    this.emitSelectedNewValue(value)
    this.delayApplyCurrentValue()

    if (needToEmitStartStopEvents) {
        this.emitStoppedSliding()
    }
  }

  public delayApplyCurrentValue() {
    this.waitingForUpdates = true
    clearTimeout(this.sliderTimeoutHandle)
    this.sliderTimeoutHandle = setTimeout(() => {
      this.waitingForUpdates = false
      this.applyCurrentValue()
      this.sliderTimeoutHandle = null
    }, SLIDER_TIMEOUT)
  }

  public applyCurrentValue() {
    // if a value outside the min-max range of the slider is set, an unwanted input event with the corrected value is fired from v-slider
    const clampedValue = Math.min(Math.max(this.currentValue, this.minimalValue), this.maximalValue)
    this.sliderValue = clampedValue
  }

  // workaround helper methods to detect mouseup outside the window
  public bindGlobalEvents() {
    window.addEventListener('mouseup', this.onWindowMouseUp)
  }

  public removeGlobalEvents() {
    window.removeEventListener('mouseup', this.onWindowMouseUp)
  }

  public onWindowMouseUp() {
    (this.$refs.slider as any).onSliderMouseUp()
  }
}
</script>
<style lang="scss" scoped>
.BaseRangeSlider {
  touch-action: manipulation;
  flex: 1;
  height: 35px;
  .slider {
    padding-left: 12px;
    padding-right: 12px;
  }
  .bar {
    width: 100%;
    height: 2px;
    margin-top: 18px;
    margin-bottom: -35px;
  }
}
</style>

<style lang="css">
.BaseRangeSlider .v-slider__thumb {
  width: 30px;
  height: 30px;
  background-color: white !important;
  border-color: white !important;
  transform: translateY(-50%) !important;
}
</style>

<docs>
A range slider

The default range slider
```jsx
<base-range-slider />
```

Custom ranges -10 to +10
```jsx
<base-range-slider
    :minimal-value="-10"
    :maximal-value="10"
    :interval="-1"
    :current-value="0"
/>
```

Custom gradient colors
```jsx
<base-range-slider
    :gradientColors="['blue','green']"
 />
```
</docs>
