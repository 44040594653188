import { ConsumptionReportAggregationInterval, MinergieVisualizationInterval } from '@ecocoach/domain-store-modules/src/consumption/models'

export enum ConsumptionUiAction {
  // admin
  initializeAdmin = 'initializeAdmin',
  selectAdminProject = 'selectAdminProject',
  selectAdminTargetSystem = 'selectAdminTargetSystem',
  createAccount = 'createAccount',
  updateAccount = 'updateAccount',
  deleteAccount = 'deleteAccount',
  assignMeter = 'assignMeter',
  unassignMeter = 'unassignMeter',
  assignUser = 'assignUser',
  unassignUser = 'unassignUser',
  linkAccountMinergie = 'linkAccountMinergie',
  unlinkAccountMinergie = 'unlinkAccountMinergie',
  verifyAccountMinergie = 'verifyAccountMinergie',
  linkProjectEgonline = 'linkProjectEgonline',
  unlinkProjectEgonline = 'unlinkProjectEgonline',

  // export
  initializeExport = 'initializeExport',
  selectExportProject = 'selectExportProject',
  resetExportForm = 'resetExportForm',
  downloadReport = 'downloadReport',

  // visualization
  initializeVisualization = 'initializeVisualization',
  selectVisualizationProject = 'selectVisualizationProject',
  selectVisualizationInterval = 'selectVisualizationInterval',
  selectVisualizationConsumptionAccountId = 'selectVisualizationConsumptionAccountId',
  loadVisualizationData = 'loadVisualizationData',
  configureChart = 'configureChart',

  // navigation
  topNavigationClicked = 'topNavigationClicked',
  setNavigationMinimized = 'setNavigationMinimized',
}

export enum ConsumptionUiMutation {
  setInteracted = 'setInteracted',
  setSelectedAccountId = 'setSelectedAccountId',
  setAccountFilterString = 'setAccountFilterString',
  setMeterFilterString = 'setMeterFilterString',
  setUserFilterString = 'setUserFilterString',

  // export
  setSelectedReportAggregationInterval = 'setSelectedReportAggregationInterval',
  setSelectedReportConsumptionAccountId = 'setSelectedReportConsumptionAccountId',
  setSelectedReportFromDate = 'setSelectedReportFromDate',
  setSelectedReportToDate = 'setSelectedReportToDate',

  // visualization
  setSelectedVisualizationInterval = 'setSelectedVisualizationInterval',
  setSelectedVisualizationConsumptionAccountId = 'setSelectedVisualizationConsumptionAccountId',

  // navigation
  setNavigationMinimized = 'setNavigationMinimized',
}

export enum ConsumptionUiGetter {
  isInteracted = 'isInteracted',
  selectedProjectId = 'selectedProjectId',

  // admin
  adminProjects = 'adminProjects',
  adminTargetSystems = 'adminTargetSystems',
  adminSelectedTargetSystem = 'adminSelectedTargetSystem',
  adminAccounts = 'adminAccounts',
  adminSelectedAccountId = 'adminSelectedAccountId',
  adminAccountFilterString = 'adminAccountFilterString',
  adminMeters = 'adminMeters',
  adminMeterFilterString = 'adminMeterFilterString',
  adminUsers = 'adminUsers',
  adminUserFilterString = 'adminUserFilterString',
  adminMinergieInfo = 'adminMinergieInfo',
  adminEgonlineConfiguration = 'adminEgonlineConfiguration',

  // export
  exportProjects = 'exportProjects',
  exportAccounts = 'exportAccounts',
  exportIntervals = 'exportIntervals',
  selectedReportAggregationInterval = 'selectedReportAggregationInterval',
  selectedReportConsumptionAccountId = 'selectedReportConsumptionAccountId',
  selectedReportFromDate = 'selectedReportFromDate',
  selectedReportToDate = 'selectedReportToDate',

  // visualization
  visualizationProjects = 'visualizationProjects',
  visualizationAccounts = 'visualizationAccounts',
  visualizationIntervals = 'visualizationIntervals',
  selectedVisualizationInterval = 'selectedVisualizationInterval',
  selectedVisualizationConsumptionAccountId = 'selectedVisualizationConsumptionAccountId',

  // navigation
  hasOneClickEditProjects = 'hasOneClickEditProjects',
  hasOneClickExportProjects = 'hasOneClickExportProjects',
  hasOneClickVisualizationProjects = 'hasOneClickVisualizationProjects',
  showOneClickTopNavigation = 'showOneClickTopNavigation',
  showOneClickSubNavigation = 'showOneClickSubNavigation',
}

export interface ConsumptionUiState {
  interacted: boolean

  // admin
  selectedAccountId: string
  accountFilterString: string
  meterFilterString: string
  userFilterString: string

  // export
  selectedReportAggregationInterval: ConsumptionReportAggregationInterval
  selectedReportConsumptionAccountId: string
  selectedReportFromDate: string
  selectedReportToDate: string

  // visualization
  selectedVisualizationInterval: MinergieVisualizationInterval
  selectedVisualizationConsumptionAccountId: string

  // navigation
  navigationMinimized: boolean
}
