import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr'
import { TokenService } from '../services/tokenstorage.service'
import { delay } from '../utils'

class HubService {
  private hubConnections = new Map<string, HubConnection>()
  private reconnectingHandler: (options: HubOptions) => Promise<void>
  private reconnectedHandler: (options: HubOptions) => Promise<void>
  private logCallback: (message: string) => void
  private signalRLogLevel = 'error'

  public connect = async (options: HubOptions) => {
    this.log(options.hubId, 'hubservice.connect')
    await this.disconnect(options.hubId)
    const connection = this.createConnection(options)
    if (!connection) {
      this.log(options.hubId, 'hubservice.notokenforconnection')
      return
    }
    options.methodCallbacks?.forEach(({ method, callback }) => {
      connection.on(method, callback)
    })

    await this.start(connection, options.hubId)
    this.log(options.hubId, 'hubservice.connected')
  }

  public disconnect = async (hubId: string) => {
    const connection = this.hubConnections.get(hubId)
    if (connection) {
      try {
        this.log(hubId, 'connection.stop')
        await connection.stop()
      } catch (error) { // log and ignore
        this.log(hubId, `connection.stop error: ${(error as any)?.message}, ${error}`)
      }
    }
  }

  public invoke = async (options: InvokeOptions) => {
    let attempt = 0
    while (++attempt < 3) {
      try {
        const args = options.args || []
        this.log(options.hubId, 'connection.invoke')
        await this.hubConnections.get(options.hubId)!.invoke(options.method, ...args)
        break
      } catch (error) { // log and try again
        this.log(options.hubId, `connection.invoke error: ${(error as any)?.message}, ${error}`)
        await delay(5000)
      }
    }
  }

  public registerReconnectingHandler = (handler: (options: HubOptions) => Promise<void>) => {
    this.reconnectingHandler = handler

  }
  public registerReconnectedHandler = (handler: (options: HubOptions) => Promise<void>) => {
    this.reconnectedHandler = handler
  }

  public setLogCallback = (callback: (message: string) => void) => {
    this.logCallback = callback
  }

  public setSignalRLogLevel = (logLevel: 'debug' | 'info' | 'warn' | 'error') => {
    this.signalRLogLevel = logLevel
  }

  private createConnection = (options: HubOptions) => {
    this.hubConnections.delete(options.hubId)

    const connection = new HubConnectionBuilder()
      .withUrl(`${options.hubUrl}`, {
        accessTokenFactory: () => TokenService.getToken(),
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: () => 10000,
      })
      .configureLogging(this.signalRLogLevel)
      .build()

    connection.onreconnecting(async () => {
      if (this.reconnectingHandler) {
        await this.reconnectingHandler(options)
      }
    })
    connection.onreconnected(async () => {
      if (this.reconnectedHandler) {
        await this.reconnectedHandler(options)
      }
    })
    this.hubConnections.set(options.hubId, connection)
    return connection
  }

  private start = async (connection: HubConnection, hubId: string) => {
    while (connection.state !== HubConnectionState.Connected) {
      try {
        this.log(hubId, 'connection.start')
        await connection.start()
      } catch (error) { // log and try again
        this.log(hubId, `connection.start error: ${(error as any)?.message}, ${error}`)
        await delay(10000)
      }
    }
  }

  private log = (hubId: string, message: string) => {
    if (this.logCallback) {
      this.logCallback(`HubService:${hubId}: ${message}`)
    }
  }
}

export default new HubService()

export interface HubOptions {
  hubId: string
  hubUrl: string
  methodCallbacks: Array<{
    method: string
    callback: (payload: any) => void
  }>
  disconnectHandler?: () => void
  reconnectHandler?: () => void
}

export interface InvokeOptions {
  hubId: string
  method: string
  args?: any[]
}