<template>
  <modal-dialog :show="show" :minimal="true">
    <v-layout column class="color-gradient-picker-dialog">
      <v-flex class="color-gradient-picker-dialog-title">
        {{ 'parameter.chartcolor' | translate }}
      </v-flex>
      <v-layout row wrap class="color-gradient-picker-item-list">
        <v-flex shrink v-for="gradient in gradients" class="option color-option"
          :key="gradient"
          :class="{ 'selected': gradient === selectedGradient}"
          :style="gradientStyle(gradient)"
          @click="onGradientSelected(gradient)"
        />
      </v-layout>
      <div>
        <base-text-button @buttonClicked="onOk" :enabled="!disabled">{{ 'common.button.close' | translate }}</base-text-button>
      </div>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import { Gradients } from '@ecocoach/shared-components/src'

@Component({
  components: {
  },
})
export default class ColorGradientPickerDialog extends Vue {
  @Prop() public show: boolean
  @Prop() public gradients: string[]
  @Prop() public selectedGradient: string
  @Prop() public disabled: boolean

  @Emit('closed')
  public onOk() {
    return true
  }

  @Emit('gradientSelected')
  public onGradientSelected(gradient: string) {
    return gradient
  }

  public gradientStyle(gradient: string) {
    return Gradients.style(gradient, true)
  }
}
</script>
<style lang='scss' scoped>
.color-gradient-picker-dialog {
  .color-gradient-picker-dialog-title {
    text-align: left;
    font-size: 14px;
    margin-left: 5px;
  }
  .color-gradient-picker-item-list {
    .option {
      height: 35px;
      width: 35px;
      border-radius: 5px;
      margin: 5px;
      &.selected {
        border: 2px solid white !important;
      }
    }
  }
}
.v-btn {
  border: 1px solid white;
}
</style>
