<template>
  <manual-mapping-solution-hardware-item
    :index="index"
    :name="name"
    :description="description"
    :is-mapped="false"
    :is-currently-mapping="isCurrentlyMapping"
    :disabled="disabled"
    @click="onClick"
  />
</template>
<script lang="ts">
import ManualMappingSolutionHardwareItem from '@/components/views/ecoSetupTool/steps/mapHardware/ManualMappingSolutionHardwareItem.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { SolutionHardwareDefinitionEndpointModel } from '../../../../../../../eco-domain-store-modules/src/systemConfiguration/models'

const MappingUi = namespace('mappingUi')
const Resource = namespace('resource')

@Component ({
  components: {
    ManualMappingSolutionHardwareItem,
  },
})
export default class ManualMappingSolutionHardwareUnmappedEndpoint extends Vue {
  @Prop() public endpoint: SolutionHardwareDefinitionEndpointModel
  @Prop() public index: string
  @Prop({ default: false }) public isCurrentlyMapping: boolean
  @Prop({ default: false }) public disabled: boolean
  @MappingUi.Mutation public resetCurrentManualMapping
  @Resource.Getter public dictionary

  public get name() {
    return this.endpoint.name || this.dictionary(this.endpoint.nameResourceId)
  }

  public get description() {
    return this.endpoint.description || this.dictionary(this.endpoint.descriptionResourceId)
  }

  public onClick() {
    if (this.isCurrentlyMapping) {
      this.resetCurrentManualMapping()
    } else {
      this.$emit('click')
    }
  }
}
</script>
