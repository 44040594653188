import { MutationTree } from 'vuex'
import { stateForResourceCategory } from './helpers'
import { ResourceCategory, ResourceModel } from './models'
import { ResourceMutation, ResourceState } from './types'

export const mutations: MutationTree<ResourceState> = {
  [ResourceMutation.upsertResources](state, payload: { category: ResourceCategory, resources: ResourceModel[] }) {
    stateForResourceCategory(state, payload.category).upsertMany(payload.resources)
  },
}
