
<template>
    <div>
        <v-expansion-panel class="tree-view">
            <slot></slot>
        </v-expansion-panel>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class TreeView extends Vue {}
</script>
<style lang="scss">
ul.v-expansion-panel.tree-view {
    box-shadow: none;
    -webkitbox-shadow: none;
    li.v-expansion-panel__container {
      font-family: Titillium Web,sans-serif;
      background-color: transparent;
      color: white;
      div.v-expansion-panel__header {
        padding: 12px 24px 12px 0px !important;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        i.v-icon.material-icons {
          color: white;
          position: relative;
          padding-bottom: 4px;
          margin-right: 12px;
        }
      }
      div.v-expansion-panel__body {
        .v-card {
          background-color: transparent;
          .v-list {
            background-color: transparent;
            color: white;
            div > .v-list__tile {
              height: 42px;
              .v-list__tile__content {
                padding-left: 14px;
                margin-right: 24px;
                img {
                  vertical-align: sub;
                }
              }
              .v-list__tile__action {
                position: relative;
                left: -20px;
                min-width: 24px !important;              
              }
            } 
          }
        }
      }
    }
  }
</style>