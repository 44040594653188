<template>
  <more-menu :header="name">
    <more-menu-item
      label="Set active"
      :disabled="!canSetActive"
      @click="setActive"
    />
    <more-menu-item
      label="Set obsolete"
      :disabled="!canSetObsolete"
      @click="setObsolete"
    />
    <more-menu-item
      label="Edit release notes"
      :disabled="!canEditReleaseNotes"
      @click="editReleaseNotes"
    />
    <more-menu-item v-if="canDownloadXmlFile"
      label="Download XML file"
      @click="downloadXmlFile"
    />
    <more-menu-item
      label="Copy ID to clipoard"
      @click="copyIdToClipboard"
    />
  </more-menu>
</template>

<script lang="ts">
import { SourceLanguage } from '@ecocoach/domain-store-modules/src/common'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { FunctionBlockState } from '../../../../../eco-domain-store-modules/src/systemConfiguration/models'
import { FunctionBlockTreeItemViewModel } from '../../../store/modules/functionBlockUi/models'

import MoreMenu from '../../ui/MoreMenu.vue'
import MoreMenuItem from '../../ui/MoreMenuItem.vue'

@Component({
  components: {
    MoreMenu,
    MoreMenuItem,
  },
})
export default class FunctionDefinitionTreeItemActions extends Vue {
  @Prop() public functionBlock: FunctionBlockTreeItemViewModel

  @Emit('editReleaseNotes') 
  public editReleaseNotes() {
    return
  }
  
  @Emit('setActive')
  public setActive() {
    return
  }

  @Emit('setObsolete')
  public setObsolete() {
    return
  }

  @Emit('downloadXmlFile')
  public downloadXmlFile() {
    return
  }

  @Emit('copyIdToClipboard')
  public copyIdToClipboard() {
    return
  }

  public get name() {
    return this.functionBlock.name
  }

  public get canSetActive() {
    return this.functionBlock.state === FunctionBlockState.IN_TEST
  }

  public get canSetObsolete() {
    return this.functionBlock.state === FunctionBlockState.ACTIVE || this.functionBlock.state === FunctionBlockState.IN_TEST
  }

  public get canEditReleaseNotes() {
    return this.functionBlock.state !== FunctionBlockState.OBSOLETE
  }

  public get canDownloadXmlFile() {
    return this.functionBlock.sourceLanguage === SourceLanguage.StructuredText
  }
}
</script>
<style lang="scss" scoped>
</style>
<style lang="css">
</style>