<template>
  <div>
    <header>
      <div xs12 class="dashboard__title">
        <h1>{{ 'permissions.title' | translate }}</h1>
        <h2>{{ 'permissions.subTitle' | translate }}</h2>
      </div>
    </header>
    <v-container fluid>
      <v-layout justify-center row wrap>
        <v-flex xs12 md6 lg4 v-if="selectedProjectId" class="authContent">
          <user-list
            :allowAdd="true"
            inputType="email"
            @selected="setSelectedUser($event)"
            @added="onUserAdded"
            :selected="selectedUser"
            :title="'authorize.emails' | translate"
            :entries="users"
          />
        </v-flex>
        <v-flex xs12 md6 lg8>
          <v-layout row>
            <v-flex class="permissions" xs4 lg2>
              <div class="authorisation-sub-title">{{ 'consumption.project' | translate }}</div>
            </v-flex>
            <v-flex class="permissions" xs8 lg10>
              <autocomplete-select
                :options="projects"
                option-value="id"
                option-label="name"
                :selectedValue="selectedProjectId"
                @selected="onSelectProject($event)"
                placeholdertext="Select a project"
                class="project-selection"
              />
            </v-flex>
          </v-layout>
          <div class="permissions" v-if="selectedUser">
            <div class="authorisation-sub-title">{{ 'project.based.authorisation' | translate }}</div>
            <project-based-authorisation v-if="projectPermissions(selectedProjectId)"
              :permissions-of-project="projectPermissions(selectedProjectId)"
            />
            <div v-else>{{ 'authorizationLevel.unauthorized' | translate }}</div>
            <div class="authorisation-sub-title">{{ 'ecooneclick.authorisation' | translate }}</div>
            <one-click-permission v-if="consumptionPermissions(selectedProjectId)"
              :permissions-of-project="consumptionPermissions(selectedProjectId)"
            />
            <div v-else>{{ 'authorizationLevel.unauthorized' | translate }}</div>
            <div class="authorisation-sub-title">{{ 'authorize.sps' | translate }}</div>
            <div v-for="plc in plcsOfProject(selectedProjectId)" :key="plc.id" class="plcPermissions">
              <plc-permission :plc="plc" />
            </div>
            <div v-if="plcsOfProject(selectedProjectId).length === 0">{{ 'authorizationLevel.unauthorized' | translate }}</div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script lang="ts">
import { PlcAuthorizationsModel, ProjectModel } from '@ecocoach/domain-store-modules/src/authorization/models'
import OneClickPermission from './OneClickPermission.vue'
import PlcPermission from './PlcPermission.vue'
import ProjectBasedAuthorisation from './ProjectBasedAuthorisation.vue'
import UserList from './UserList.vue'

import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { PermissionsViewModel, UserViewModel } from '@/store/modules/authorizationUi/models'
import { appIdRegex, emailRegex } from '@/store/modules/authorizationUi/types'

const Resource = namespace('resource')
const AuthorizationUi = namespace('authorizationUi')

@Component({
  components: {
    PlcPermission,
    OneClickPermission,
    ProjectBasedAuthorisation,
    UserList,
  },
})
export default class AuthTool extends Vue {
  @Resource.Getter public dictionary
  @AuthorizationUi.Getter public isInteracted: boolean
  @AuthorizationUi.Getter public selectedProjectId: string
  @AuthorizationUi.Getter public selectedUser: string
  @AuthorizationUi.Getter public projects: ProjectModel[]
  @AuthorizationUi.Getter public projectPermissions: (projectId: string) => PermissionsViewModel | null
  @AuthorizationUi.Getter public consumptionPermissions: (projectId: string) => PermissionsViewModel | null
  @AuthorizationUi.Getter public plcsOfProject: (projectId: string) => PlcAuthorizationsModel[]
  @AuthorizationUi.Getter public authorizedUsers: (projectId: string) => UserViewModel[]
  @AuthorizationUi.Getter public appRegistrationNameForId: (appId: string) => string | null
  @AuthorizationUi.Mutation public setSelectedProjectId: (projectId: string) => void
  @AuthorizationUi.Mutation public setSelectedUser: (user: string) => void
  @AuthorizationUi.Mutation public addNewUser: (user: string) => void
  @AuthorizationUi.Action public load: () => Promise<void>
  @AuthorizationUi.Action public selectProject: (projectId: string) => Promise<void>
  @AuthorizationUi.Action public resolveAppRegistrationNames: (appIds: string[]) => Promise<void>

  public async mounted() {
    await this.load()
    const projectToSelect = this.projects.find(p => p.id === this.selectedProjectId) || this.projects[0]
    if (projectToSelect && projectToSelect.id) {
      await this.onSelectProject(projectToSelect.id)
    }
  }

  public get users() {
    return this.authorizedUsers(this.selectedProjectId)
  }

  public async onSelectProject(projectId: string) {
    await this.selectProject(projectId)
    const userToSelect = this.authorizedUsers(projectId).find(u => u.userIdentifier === this.selectedUser) ? this.selectedUser : this.authorizedUsers(projectId)[0]?.userIdentifier ?? ''
    this.setSelectedUser(userToSelect || '')
  }

  public async onUserAdded(userIdentifier: string) {
    const insensitiveIdentifier = userIdentifier.toLocaleLowerCase()
    if(this.users.find(user => user.userIdentifier.toLocaleLowerCase() === insensitiveIdentifier)){
      this.setSelectedUser(insensitiveIdentifier)
      this.$store.commit('wizard/setToastContent', this.dictionary('error.user.already.exist'), { root: true })
      this.$store.commit('wizard/showToast', { root: true })
      return
    }
    if (emailRegex.test(insensitiveIdentifier) || await this.tryValidateAppRegistration(userIdentifier)) {
      this.addNewUser(insensitiveIdentifier)
      this.setSelectedUser(insensitiveIdentifier)
    } else {
      this.$store.commit('wizard/setToastContent', this.dictionary('error.no.valid.mailadress'), { root: true })
      this.$store.commit('wizard/showToast', { root: true })
    }
  }

  public async tryValidateAppRegistration(appId: string) {
    if (!appIdRegex.test(appId)) {
      return false
    }
    await this.resolveAppRegistrationNames([appId])
    return !!this.appRegistrationNameForId(appId)
  }
}
</script>
<style lang="scss" scoped>
h1 {
  text-transform: uppercase;
  text-align: left;
  color: #fff;
  font-weight: 200;
  font-family: Titillium Web, sans-serif;
}
h2 {
  color: #fff;
  font-weight: 100;
  text-transform: uppercase;
  font-style: italic;
  font-family: Titillium Web, sans-serif;
  padding-bottom: 16px;
}
header {
  padding: 30px 0 0 25px;
  height: 100px;
}
.room {
  margin-left: 0;
}
.authContent {
  padding: 10px 34px 10px 0px;
}
.permissions {
  padding: 10px;
  padding-right: 34px;
}
.authorisation-sub-title {
  margin-right: 30px;
  flex: 1 0 1;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
  padding-top: 10px;
}
</style>

