import { newGuid } from '@/store/helpers'
import { AppGetter } from '@/store/modules/app/types'
import { LogToolUiAction, LogToolUiGetter, LogToolUiMutation, LogToolUiState } from '@/store/modules/logToolUi/types'
import { RootState } from '@/store/types'
import { ArchitectureType } from '@ecocoach/domain-store-modules/src/common'
import fileHelper from '@ecocoach/domain-store-modules/src/helpers/fileHelper'
import { PlcOperationAction } from '@ecocoach/domain-store-modules/src/plcOperation/types'
import ApiService from '@ecocoach/domain-store-modules/src/services/api.service'
import HubService from '@ecocoach/domain-store-modules/src/services/hub.service'
import { toLowerCaseKeys } from '@ecocoach/domain-store-modules/src/utils'
import jsonexport from 'jsonexport/dist'
import { ActionTree } from 'vuex'
import commandApi from './api/command'
import queryApi from './api/query'
import { EndpointRecordingConfigurationOptions, EndpointRecordingDataInput, GetLogsFromDbPayload, LogEntriesPayload, LogEntryModel, LoggerConfigurationModel, LoggerConfigurationsPayload, logHubId } from './models'

export const actions: ActionTree<LogToolUiState, RootState> = {
  async [LogToolUiAction.openLogToolForPlc] ({ commit, dispatch, rootState, rootGetters }, plcId: string): Promise<void> {
    try {
      commit(LogToolUiMutation.setLoading, true)
      const language = rootState.app.selectedLanguage
      await dispatch(`plcOperation/${PlcOperationAction.loadConfigurationByPlcId}`, { plcId, language }, { root:true })
      commit(LogToolUiMutation.setSelectedPlcId, plcId)
      dispatch(LogToolUiAction.connectToLogHub).then(() => { 
        dispatch(LogToolUiAction.startReceivingLogs)
        dispatch(LogToolUiAction.requestLoggerConfigurations)
      })
      const isEcocoachEmployee = rootGetters[`app/${AppGetter.isEcocoachEmployee}`]
      if (isEcocoachEmployee) {
        dispatch(LogToolUiAction.loadEndpointRecordingConfigurations, plcId) // don't await, can fail when plc offline
      }
    } finally {
      commit(LogToolUiMutation.setLoading, false)
    }
  },
  async [LogToolUiAction.closeLogTool] ({ commit, dispatch }): Promise<void> {
    commit(LogToolUiMutation.setSelectedPlcId, '')
    commit(LogToolUiMutation.setEndpointRecordingConfigurations, [])
    commit(LogToolUiMutation.setSelectedDeviceId, '')
    commit(LogToolUiMutation.setEndpoints, [])
    commit(LogToolUiMutation.setLoggingConfigurations, [])
    commit(LogToolUiMutation.clearEndpointLogEntries)
    commit(LogToolUiMutation.clearCloudLogEntries)
    commit(LogToolUiMutation.clearDbLogEntries)
    dispatch(LogToolUiAction.disconnectFromLogHub)
  },
  async [LogToolUiAction.loadEndpointRecordingConfigurations] ({ commit }, plcId: string): Promise<void> {
    try {
      commit(LogToolUiMutation.setInteracted, true)
      const data = await queryApi.loadEndpointRecordingConfigurations(plcId)
      commit(LogToolUiMutation.setEndpointRecordingConfigurations, data)
    } finally {
      commit(LogToolUiMutation.setInteracted, false)
    }
  },
  async [LogToolUiAction.reloadEndpointRecordingConfigurations] ({ commit, dispatch, state }): Promise<void> {
    try {
      commit(LogToolUiMutation.setLoading, true)
      await dispatch(LogToolUiAction.loadEndpointRecordingConfigurations, state.selectedPlcId)
    } finally {
      commit(LogToolUiMutation.setLoading, false)
    }
  },
  async [LogToolUiAction.createPlcEndpointRecordingConfiguration] ({ commit, state },
    payload: { qualifiedEndpointPath: string, options: EndpointRecordingConfigurationOptions}): Promise<void> {
    try {
      commit(LogToolUiMutation.setInteracted, true)
      const data = await commandApi.createPlcEndpointRecordingConfiguration(state.selectedPlcId, payload.qualifiedEndpointPath, payload.options)
      commit(LogToolUiMutation.setEndpointRecordingConfigurations, state.endpointRecordingConfigurations.concat(data))
    } finally {
      commit(LogToolUiMutation.setInteracted, false)
    }
  },
  async [LogToolUiAction.createDeviceEndpointRecordingConfiguration] ({ commit, state },
    payload: { endpointPath: string, options: EndpointRecordingConfigurationOptions}): Promise<void> {
    try {
      commit(LogToolUiMutation.setInteracted, true)
      const data = await commandApi.createDeviceEndpointRecordingConfiguration(
        state.selectedPlcId, state.selectedDeviceId, payload.endpointPath, payload.options)
      commit(LogToolUiMutation.setEndpointRecordingConfigurations, state.endpointRecordingConfigurations.concat(data))
    } finally {
      commit(LogToolUiMutation.setInteracted, false)
    }
  },
  async [LogToolUiAction.createControlEndpointsRecordingConfiguration] ({ commit, state },
    payload: { controlId: string, options: EndpointRecordingConfigurationOptions}): Promise<void> {
    try {
      commit(LogToolUiMutation.setInteracted, true)
      const data = await commandApi.createControlEndpointsRecordingConfiguration(state.selectedPlcId, payload.controlId, payload.options)
      commit(LogToolUiMutation.setEndpointRecordingConfigurations, state.endpointRecordingConfigurations.concat(data))
    } finally {
      commit(LogToolUiMutation.setInteracted, false)
    }
  },
  async [LogToolUiAction.deleteEndpointRecordingConfiguration] ({ commit, state }, id: string): Promise<void> {
    try {
      commit(LogToolUiMutation.setInteracted, true)
      await commandApi.deleteEndpointRecordingConfiguration(state.selectedPlcId, id)
      commit(LogToolUiMutation.removeEndpointRecordingConfiguration, id)
    } finally {
      commit(LogToolUiMutation.setInteracted, false)
    }
  },
  async [LogToolUiAction.deleteAllEndpointRecordingConfigurations] ({ commit, state }): Promise<void> {
    try {
      commit(LogToolUiMutation.setInteracted, true)
      await commandApi.deleteAllEndpointRecordingConfigurations(state.selectedPlcId)
      commit(LogToolUiMutation.setEndpointRecordingConfigurations, [])
    } finally {
      commit(LogToolUiMutation.setInteracted, false)
    }
  },
  async [LogToolUiAction.downloadEndpointRecordingData] ({ commit, state }, payload: EndpointRecordingDataInput): Promise<void> {
    try {
      commit(LogToolUiMutation.setInteracted, true)
      const fileName = 'endpointRecordingData.csv'
      await queryApi.downloadEndpointRecordingData(state.selectedPlcId, fileName, payload)
    } finally {
      commit(LogToolUiMutation.setInteracted, false)
    }
  },
  async [LogToolUiAction.downloadAllEndpointRecordingData] ({ commit, state }, payload: EndpointRecordingDataInput): Promise<void> {
    try {
      commit(LogToolUiMutation.setInteracted, true)
      const fileName = 'endpointRecordingData.csv'
      await queryApi.downloadAllEndpointRecordingData(state.selectedPlcId, fileName, payload)
    } finally {
      commit(LogToolUiMutation.setInteracted, false)
    }
  },
  async [LogToolUiAction.selectDevice] ({ commit, rootState }, id: string): Promise<void> {
    try {
      commit(LogToolUiMutation.setInteracted, true)
      const device = rootState.plcOperation.devicesLookup.get(id)!
      const functionBlockDefinition = rootState.systemConfiguration.functionBlocks.find(fb => fb.id === device.functionBlockDefinitionId)!
      const endpoints = functionBlockDefinition.architectureType === ArchitectureType.ObjectOriented 
        ? await queryApi.endpointDefinitions(functionBlockDefinition.id, rootState.app.selectedLanguage)
        : await queryApi.endpoints(functionBlockDefinition.id)
      const selectableEndpoints = endpoints.filter(e => !e.isMethod && !e.isWriteOnlyProperty)
      commit(LogToolUiMutation.setEndpoints, selectableEndpoints)
      commit(LogToolUiMutation.setSelectedDeviceId, id)
    } finally {
      commit(LogToolUiMutation.setInteracted, false)
    }
  },
  async [LogToolUiAction.connectToLogHub]({ dispatch }) {
    await HubService.connect({
      hubId: logHubId,
      hubUrl: `${ApiService.backendEnvironmentConfiguration().logHub}`,
      methodCallbacks: [
        {
          method: 'ResponseLoggerConfigurationEvent',
          callback: (payload: LoggerConfigurationsPayload) => {
            dispatch(LogToolUiAction.loggerConfigurationsReceived, payload.logConfigs)
          },
        },
        {
          method: 'LogEntryEvent',
          callback: (payload: LogEntryModel) => {
            dispatch(LogToolUiAction.logEntriesReceived, [payload])
          },
        }, 
        {
          method: 'MultipleLogEntryEvent',
          callback: (payload: LogEntriesPayload) => {
            dispatch(LogToolUiAction.logEntriesReceived, payload.logEntries)
          },
        },
      ],
    })
  },
  async [LogToolUiAction.disconnectFromLogHub] () {
    await HubService.disconnect(logHubId)
  },
  async [LogToolUiAction.requestLoggerConfigurations]({ state }) {
    HubService.invoke({
      hubId: logHubId,
      method: 'RequestLoggerConfiguration',
      args: [state.selectedPlcId],
    })
  },
  async [LogToolUiAction.setLogLevel]({ state, commit }, payload: LoggerConfigurationModel) {
    await HubService.invoke({
      hubId: logHubId,
      method: 'SetLogLevel',
      args: [state.selectedPlcId, payload.logLevel, payload.logDestination, payload.logSource],
    })
    commit(LogToolUiMutation.upsertLoggingConfiguration, payload)
  },
  async [LogToolUiAction.resetLogSource]({ state, commit }, payload: LoggerConfigurationModel) {
    await HubService.invoke({
      hubId: logHubId,
      method: 'ResetLogSource',
      args: [state.selectedPlcId, payload.logSource, payload.logDestination],
    })
    commit(LogToolUiMutation.removeLoggingConfiguration, payload)
  },
  async [LogToolUiAction.startReceivingLogs]({ state }) {
    HubService.invoke({
      hubId: logHubId,
      method: 'StartReceivingLogs',
      args: [state.selectedPlcId],
    })
  },
  async [LogToolUiAction.stopReceivingLogs]({ state }) {
    HubService.invoke({
      hubId: logHubId,
      method: 'StopReceivingLogs',
      args: [state.selectedPlcId],
    })
  },
  async [LogToolUiAction.readPlcEndpoint]({ state, commit }, qualifiedEndpointPath: string) {
    try {
      commit(LogToolUiMutation.setInteracted, true)
      await commandApi.readPlcEndpoint(state.selectedPlcId, qualifiedEndpointPath)
    } finally {
      commit(LogToolUiMutation.setInteracted, false)
    }
  },
  async [LogToolUiAction.readDeviceEndpoint]({ state, commit }, endpointPath: string) {
    try {
      commit(LogToolUiMutation.setInteracted, true)
      await commandApi.readDeviceEndpoint(state.selectedPlcId, state.selectedDeviceId, endpointPath)
    } finally {
      commit(LogToolUiMutation.setInteracted, false)
    }
  },
  async [LogToolUiAction.readControlEndpoints]({ state, commit }, controlId: string) {
    try {
      commit(LogToolUiMutation.setInteracted, true)
      await commandApi.readControlEndpoints(state.selectedPlcId, controlId)
    } finally {
      commit(LogToolUiMutation.setInteracted, false)
    }
  },
  async [LogToolUiAction.retrieveLogsFromDb]({ state }, payload: GetLogsFromDbPayload) {
    HubService.invoke({
      hubId: logHubId,
      method: 'RetrieveLogsFromDb',
      args: [state.selectedPlcId, payload.logLevel, payload.numEntries],
    })
  },
  async [LogToolUiAction.purgeLogsFromDb]({ state }, logLevel: string) {
    HubService.invoke({
      hubId: logHubId,
      method: 'PurgeLogsFromDb',
      args: [state.selectedPlcId, logLevel],
    })
  },
  async [LogToolUiAction.loggerConfigurationsReceived] ({ commit }, payload: LoggerConfigurationModel[]) {
    commit(LogToolUiMutation.setLoggingConfigurations, payload)
  },
  async [LogToolUiAction.logEntriesReceived] ({ commit }, payload: LogEntryModel[]) {
    payload.sort((a, b) => b.logTimestamp.localeCompare(a.logTimestamp)).forEach(logEntry => {
      logEntry.id = newGuid()
      if (logEntry.logSource.startsWith('Ecocoach.EcoCloudConnector.Events.Consumer.LogEndpointEventHandler')) {
        commit(LogToolUiMutation.insertEndpointLogEntries, [logEntry])
      } else if (logEntry.logSource.startsWith('Ecocoach.EcoCloudConnector.Events.Consumer.RetrieveLogMessagesFromDbEventHandler')) {
        const parsedEntries = toLowerCaseKeys(JSON.parse(JSON.parse(logEntry.logMessage).LogMessage))
        parsedEntries.forEach(parsedLogEntry => {
          parsedLogEntry.id = newGuid()
        })
        commit(LogToolUiMutation.insertDbLogEntries, parsedEntries)
      } else {
        commit(LogToolUiMutation.insertCloudLogEntries, [logEntry])
      }
    })
  },
  async [LogToolUiAction.downloadCloudLogEntries] ({ commit, getters }): Promise<void> {
    try {
      commit(LogToolUiMutation.setInteracted, true)
      const data = (getters[LogToolUiGetter.cloudLogEntries] as LogEntryModel[])
      const csv = await jsonexport(data, { rowDelimiter: ';' })
      fileHelper.saveBlobToFile(csv, 'cloudLog.csv')
    } finally {
      commit(LogToolUiMutation.setInteracted, false)
    }
  },
  async [LogToolUiAction.downloadDbLogEntries] ({ commit, getters }): Promise<void> {
    try {
      commit(LogToolUiMutation.setInteracted, true)
      const data = (getters[LogToolUiGetter.dbLogEntries] as LogEntryModel[])
      const csv = await jsonexport(data, { rowDelimiter: ';' })
      fileHelper.saveBlobToFile(csv, 'dbLog.csv')
    } finally {
      commit(LogToolUiMutation.setInteracted, false)
    }
  },
}
