import { MappingUiGetter, MappingUiState  } from '@/store/modules/mappingUi/types'
import { RootState } from '@/store/types'
import { AutoMappingModel, DeviceMappingModel, SolutionHardwareInstanceModel } from '@ecocoach/domain-store-modules/src/plcConfiguration/models'
import { AutoMappingSlotModel, FunctionBlockEndpointModel, SolutionHardwareDefinitionEndpointModel, SolutionHardwareInterfaceType } from '@ecocoach/domain-store-modules/src/systemConfiguration/models'
import { GetterTree } from 'vuex'
import { ResourceGetter } from '../../../../../eco-domain-store-modules/src/resource/types'
import { MappableDevice, MappableSolutionHardware } from './models'

const solutionHardareEndpointsSortFunction = (first: SolutionHardwareDefinitionEndpointModel, second: SolutionHardwareDefinitionEndpointModel) => {
  const sortResultByName = first.name.localeCompare(second.name, undefined, { numeric: true })
  if (sortResultByName !== 0) {
    return sortResultByName
  } else {
    return first.description.localeCompare(second.description, undefined, { numeric: true })
  }
}

export const getters: GetterTree<MappingUiState, RootState> = {
  [MappingUiGetter.solutionHardwareInputs] (_, __, rootState): MappableSolutionHardware[] {
    return rootState.plcConfiguration.solutionHardwareInstances
      .map(instance => {
        const definition = rootState.systemConfiguration.solutionHardwareDefinitions
          .find(shd => instance.solutionHardwareDefinitionId === shd.id)!
        return {
          instance,
          definition,
          endpoints: definition.hardwareEndpoints.filter(e => e.isOutput === false)
            .sort(solutionHardareEndpointsSortFunction),
          autoMappingSlots: definition.autoMappingSlots,
        } as MappableSolutionHardware
      })
      .filter(item => 
        item.endpoints.length > 0 || 
        item.autoMappingSlots.length > 0 || 
        item.definition.interfaceType === SolutionHardwareInterfaceType.EBUS_EXTENSION)
  },
  [MappingUiGetter.solutionHardwareOutputs] (_, __, rootState): MappableSolutionHardware[] {
    return rootState.plcConfiguration.solutionHardwareInstances
      .map(instance => {
        const definition = rootState.systemConfiguration.solutionHardwareDefinitions
          .find(shd => instance.solutionHardwareDefinitionId === shd.id)!
        return {
          instance,
          definition,
          endpoints: definition.hardwareEndpoints.filter(e => e.isOutput === true)
            .sort(solutionHardareEndpointsSortFunction),
          autoMappingSlots: definition.autoMappingSlots,
        } as MappableSolutionHardware
      })
      .filter(item => 
        item.endpoints.length > 0 || 
        item.autoMappingSlots.length > 0 ||
        item.definition.interfaceType === SolutionHardwareInterfaceType.EBUS_EXTENSION)
  },
  [MappingUiGetter.mappableDevicesOfRoom] (_, __, rootState): MappableDevice[] {
    const roomId = rootState.roomUi.selectedRoomId
    return rootState.plcConfiguration.devices
      .filter(d => d.roomId === roomId)
      .map(d => ({
        device: d,
        functionBlock: rootState.systemConfiguration.functionBlocks.find(fb => d.functionBlockDefinitionId === fb.id),
      } as MappableDevice))
      .filter(item => item.functionBlock &&
        item.functionBlock.hardwareMappableEndpoints.length > 0 || 
        item.functionBlock.autoMappingSlots.length > 0)
  },
  [MappingUiGetter.isManualMappingStarted] ({ currentManualMapping }) {
    return !!currentManualMapping.type &&
      !(currentManualMapping.solutionHardwareInstanceId && currentManualMapping.deviceId)
  },
  [MappingUiGetter.isAutoMappingStarted] ({ currentAutoMapping }) {
    return !!currentAutoMapping.interfaceType &&
      !(currentAutoMapping.solutionHardwareInstanceId && currentAutoMapping.deviceId)
  },
  [MappingUiGetter.doesCurrentAutoMappingNeedInstanceNumber] ({ currentAutoMapping }) {
    return !!currentAutoMapping.interfaceType &&
      !!currentAutoMapping.solutionHardwareInstanceId &&
      !!currentAutoMapping.deviceId &&
      ((currentAutoMapping.interfaceType === 'CanOpenNodeCommunication') && (currentAutoMapping.instanceNumber === -1))
  },
  [MappingUiGetter.currentAutoMappingSolutionHardwareSlot] ({ currentAutoMapping }, _, rootState) {
    const solutionHardwareInstance = rootState.plcConfiguration.solutionHardwareInstances
      .find(shi => shi.id === currentAutoMapping.solutionHardwareInstanceId)
    const solutionHardwareDefinition = solutionHardwareInstance && rootState.systemConfiguration.solutionHardwareDefinitions
      .find(shd => shd.id === solutionHardwareInstance.solutionHardwareDefinitionId)
    return solutionHardwareDefinition && solutionHardwareDefinition.autoMappingSlots
      .find(slot => slot.interfaceType === currentAutoMapping.interfaceType)
  },
  [MappingUiGetter.autoMappingsOfCurrentSolutionHardwareSlot] ({ currentAutoMapping }, _ , rootState) {
    return rootState.plcConfiguration.autoMappings.filter(m => 
      m.solutionHardwareInstanceId === currentAutoMapping.solutionHardwareInstanceId &&
      m.solutionHardwareSlotInternalName === currentAutoMapping.solutionHardwareSlotInternalName)
  },
  [MappingUiGetter.isCurrentManualMappingComplete] ({ currentManualMapping }) {
    return !!currentManualMapping.type &&
      !!currentManualMapping.solutionHardwareInstanceId &&
      !!currentManualMapping.deviceId
  },
  [MappingUiGetter.isCurrentAutoMappingComplete] ({ currentAutoMapping }) {
    return !!currentAutoMapping.interfaceType &&
      !!currentAutoMapping.solutionHardwareInstanceId &&
      !!currentAutoMapping.deviceId &&
      ((currentAutoMapping.interfaceType !== 'CanOpenNodeCommunication') || (currentAutoMapping.instanceNumber !== -1))
  },
  [MappingUiGetter.existingManualMappingsForSolutionHardwareEndpoint] (_, __, rootState: RootState) {
    return (id: string, endpointId: string) => rootState.plcConfiguration.deviceMappings
      .filter(mm => mm.solutionHardwareInstanceId === id && mm.solutionHardwareEndpointId === endpointId)
  },
  [MappingUiGetter.existingManualMappingsForDeviceEndpoint] (_, __, rootState: RootState) {
    return (id: string, endpointId: string) => rootState.plcConfiguration.deviceMappings
      .filter(mm => mm.deviceId === id && mm.deviceEndpointId === endpointId)
  },
  [MappingUiGetter.existingAutoMappingsForSolutionHardwareInstanceSlot] (_, __, rootState: RootState) {
    return (id: string, slotName: string) => rootState.plcConfiguration.autoMappings
      .filter(am => am.solutionHardwareInstanceId === id && am.solutionHardwareSlotInternalName === slotName)
      .sort((a, b) => a.instanceNumber - b.instanceNumber)
  },
  [MappingUiGetter.existingAutoMappingsForDeviceSlot] (_, __, rootState: RootState) {
    return (id: string, slotName: string) => rootState.plcConfiguration.autoMappings
      .filter(am => am.deviceId === id && am.deviceSlotInternalName === slotName)
  },
  [MappingUiGetter.doesSolutionHardwareEndpointAcceptAdditionalManualMappings] (_, getter) {
    return (id: string, endpoint: SolutionHardwareDefinitionEndpointModel) => {
      return !(endpoint.isOutput && getter.existingManualMappingsForSolutionHardwareEndpoint(id, endpoint.id).length > 0)
    }
  },
  [MappingUiGetter.doesDeviceEndpointAcceptAdditionalManualMappings] (_, getter) {
    return (id: string, endpoint: FunctionBlockEndpointModel) => {
      return !(endpoint.isMappableHardwareInput && getter.existingManualMappingsForDeviceEndpoint(id, endpoint.id).length > 0)
    }
  },
  [MappingUiGetter.doesSolutionHardwareSlotAcceptAdditionalAutoMappings] (_, getter) {
    return (id: string, slot: AutoMappingSlotModel) => {
      const existingAutoMappings = getter.existingAutoMappingsForSolutionHardwareInstanceSlot(id, slot.internalName)
      return slot.maxCount > existingAutoMappings.length
    }
  },
  [MappingUiGetter.doesDeviceSlotAcceptAdditionalAutoMappings] (_, getter) {
    return (id: string, slot: AutoMappingSlotModel) => {
      const existingAutoMappings = getter.existingAutoMappingsForDeviceSlot(id, slot.internalName)
      return slot.maxCount > existingAutoMappings.length
    }
  },
  [MappingUiGetter.isCurrentlyManualMappingSolutionHardwareEndpoint] ({ currentManualMapping }) {
    return (id: string, endpointId: string) => 
      currentManualMapping.solutionHardwareInstanceId === id &&
      currentManualMapping.solutionHardwareEndpointId === endpointId
  },
  [MappingUiGetter.isCurrentlyManualMappingDeviceEndpoint] ({ currentManualMapping }) {
    return (id: string, endpointId: string) => 
      currentManualMapping.deviceId === id &&
      currentManualMapping.deviceEndpointId === endpointId
  },
  [MappingUiGetter.isCurrentlyAutoMappingSolutionHardwareSlot] ({ currentAutoMapping }) {
    return (id: string, slotName: string) => 
      currentAutoMapping.solutionHardwareInstanceId === id &&
      currentAutoMapping.solutionHardwareSlotInternalName === slotName
  },
  [MappingUiGetter.isCurrentlyAutoMappingDeviceAutoMappingSlot] ({ currentAutoMapping }) {
    return (id: string, slotName: string) => 
      currentAutoMapping.deviceId === id &&
      currentAutoMapping.deviceSlotInternalName === slotName
  },
  [MappingUiGetter.isMatchingSolutionHardwareManualMappingEndpoint] ({ currentManualMapping }, getter) {
    return (id: string, endpoint: SolutionHardwareDefinitionEndpointModel) => {
      if (currentManualMapping.solutionHardwareInstanceId) {
        return false
      }
      if (endpoint.isOutput !== (currentManualMapping.type === 'output')) {
        return false
      }
      if (getter.doesSolutionHardwareEndpointAcceptAdditionalManualMappings(id, endpoint)) {
        return true
      }
      return false
    }
  },
  [MappingUiGetter.isMatchingDeviceManualMappingEndpoint] ({ currentManualMapping }, getter) {
    return (id: string, endpoint: FunctionBlockEndpointModel) => {
      if (currentManualMapping.deviceId) {
        return false
      }
      if (endpoint.isMappableHardwareOutput !== (currentManualMapping.type === 'output')) {
        return false
      }
      if (getter.doesDeviceEndpointAcceptAdditionalManualMappings(id, endpoint)) {
        return true
      }
      return false
    }
  },
  [MappingUiGetter.isMatchingSolutionHardwareAutoMappingSlot] ({ currentAutoMapping }, getter) {
    return (id: string, slot: AutoMappingSlotModel) => {
      if (currentAutoMapping.solutionHardwareInstanceId) {
        return false
      }
      if (slot.interfaceType !== currentAutoMapping.interfaceType) {
        return false
      }
      if (getter.doesSolutionHardwareSlotAcceptAdditionalAutoMappings(id, slot)) {
        return true
      }
      return false
    }
  },
  [MappingUiGetter.isMatchingDeviceAutoMappingSlot] ({ currentAutoMapping }, getter) {
    return (id: string, slot: AutoMappingSlotModel) => {
      if (currentAutoMapping.deviceId) {
        return false
      }
      if (slot.interfaceType !== currentAutoMapping.interfaceType) {
        return false
      }
      if (getter.doesDeviceSlotAcceptAdditionalAutoMappings(id, slot)) {
        return true
      }
      return false
    }
  },
  [MappingUiGetter.autoMappingSlotDescription] (_, __, rootState, rootGetters) {
    return (slot: AutoMappingSlotModel, autoMapping?: AutoMappingModel): string => {
      const description = [slot.description || rootGetters[`resource/${ResourceGetter.dictionary}`](slot.descriptionResourceId)]
      const slotInfo = [
        `Interface type: ${slot.interfaceType}`,
        `Internal name: ${slot.internalName}`,
        `Min count: ${slot.minCount}`,
        `Max count: ${slot.maxCount}`,
      ]

      const solutionHardwareInstance = autoMapping && rootState.plcConfiguration.solutionHardwareInstances
        .find(i => i.id === autoMapping.solutionHardwareInstanceId)
      const solutionHardwareDefinition = solutionHardwareInstance && rootState.systemConfiguration.solutionHardwareDefinitions
        .find(shd => solutionHardwareInstance.solutionHardwareDefinitionId === shd.id)!

      const device = autoMapping && rootState.plcConfiguration.devices.find(d => d.id === autoMapping.deviceId)

      const mappingInfo = autoMapping ? [
        `Mapped device: ${device && device.name}`,
        `Mapped device slot: ${autoMapping.deviceSlotInternalName}`,
        `Mapped solution hardware: ${solutionHardwareDefinition && solutionHardwareDefinition.name}`,
        `Mapped solution hardware slot: ${autoMapping.solutionHardwareSlotInternalName}`,
      ] : []
      return description.concat(slotInfo).concat(mappingInfo).join('<br>')
    }
  },
  [MappingUiGetter.autoMappingSlotName] (_, __, ___, rootGetters) {
    return (slot: AutoMappingSlotModel, autoMapping?: AutoMappingModel): string => {
      const slotName = slot.name || rootGetters[`resource/${ResourceGetter.dictionary}`](slot.nameResourceId)
      return (autoMapping && autoMapping.instanceNumber !== -1) ? `${slotName} - ${autoMapping.instanceNumber}` : slotName
    }
  },
  [MappingUiGetter.solutionHardwareIndex] (_, __, rootState) {
    const hexadecimalBase = 16
    const lastTwoDigits = -2
    const getLastOctetAsHexString = (lastOctet) => ('0'+(lastOctet || 0).toString(hexadecimalBase).toUpperCase()).slice(lastTwoDigits)

    return (instance: SolutionHardwareInstanceModel) => {
      const definition = rootState.systemConfiguration.solutionHardwareDefinitions
        .find(shd => instance.solutionHardwareDefinitionId === shd.id)
      const isEthernet = definition && (definition.interfaceType === SolutionHardwareInterfaceType.ETHERNET)
      return isEthernet ? getLastOctetAsHexString(instance.lastOctet) : (instance.sortOrder + 1).toString()
    }
  },
  [MappingUiGetter.manualMappingSolutionHardwareEndpointDescription] (_, __, rootState, rootGetters) {
    return (mapping: DeviceMappingModel) => {
      const device = rootState.plcConfiguration.devices.find(d => d.id === mapping.deviceId)
      const functionBlock = device && rootState.systemConfiguration.functionBlocks.find(fb => device.functionBlockDefinitionId === fb.id)
      const endpoint = functionBlock && functionBlock.hardwareMappableEndpoints.find(ep => ep.id === mapping.deviceEndpointId)
      const endpointName = endpoint?.name || rootGetters[`resource/${ResourceGetter.dictionary}`](endpoint?.nameResourceId)
      const room = device && rootState.plcConfiguration.rooms.find(r => r.id === device.roomId)
      return `${room && room.name || 'no room'} - ${device && device.name || 'no device'} - ${endpointName || 'no endpoint'}`
    }
  },
  [MappingUiGetter.manualMappingDeviceEndpointDescription] (_, getter, rootState, rootGetters) {
    return (mapping: DeviceMappingModel) => {
      const solutionHardwareInstance = rootState.plcConfiguration.solutionHardwareInstances
        .find(i => i.id === mapping.solutionHardwareInstanceId)
      const solutionHardwareDefinition = solutionHardwareInstance && rootState.systemConfiguration.solutionHardwareDefinitions
        .find(shd => solutionHardwareInstance.solutionHardwareDefinitionId === shd.id)

      const endpoint = solutionHardwareDefinition && 
        solutionHardwareDefinition.hardwareEndpoints.find(ep => ep.id === mapping.solutionHardwareEndpointId)
      const endpointName = endpoint?.name || rootGetters[`resource/${ResourceGetter.dictionary}`](endpoint?.nameResourceId)
      const endpointDescription = endpoint?.description || rootGetters[`resource/${ResourceGetter.dictionary}`](endpoint?.descriptionResourceId)
      return {
        index: solutionHardwareInstance && getter.solutionHardwareIndex(solutionHardwareInstance) || 'n/a',
        name: endpointName || 'n/a',
        description: endpointDescription || 'n/a',
      }
    }
  },
  [MappingUiGetter.isInteracted] ({ interacted }) {
    return interacted
  },
}