import BaseBase64Icon from './components/base/BaseBase64Icon.vue'
import BaseMaterialIcon from './components/base/BaseMaterialIcon.vue'
import BaseProgressBar from './components/base/BaseProgressBar.vue'
import BaseSingleSelect from './components/base/BaseSingleSelect.vue'
import BaseSpinner from './components/base/BaseSpinner.vue'
import BaseTextButton from './components/base/BaseTextButton.vue'
import BaseToggleButton from './components/base/BaseToggleButton.vue'

import ConfirmDialog from './components/dialogs/ConfirmDialog.vue'
import ModalDialog from './components/dialogs/ModalDialog.vue'

import Accordion from './components/patterns/Accordion.vue'
import AccordionItem from './components/patterns/AccordionItem.vue'
import AutocompleteInput from './components/patterns/AutocompleteInput.vue'
import AutocompleteSelect from './components/patterns/AutocompleteSelect.vue'
import BaseCheckbox from './components/patterns/BaseCheckbox.vue'
import CheckboxWithLabel from './components/patterns/CheckboxWithLabel.vue'
import ColorSelect from './components/patterns/ColorSelect.vue'
import DateSelect from './components/patterns/DateSelect.vue'
import DescriptionPopover from './components/patterns/DescriptionPopover.vue'
import FileSelect from './components/patterns/FileSelect.vue'
import FormField from './components/patterns/FormField.vue'
import IconButton from './components/patterns/IconButton.vue'
import IconSelect from './components/patterns/IconSelect.vue'
import LabelComponent from './components/patterns/LabelComponent.vue'
import Popover from './components/patterns/Popover.vue'
import StringAddComponent from './components/patterns/StringAddComponent.vue'
import StringFilterComponent from './components/patterns/StringFilterComponent.vue'
import TextInput from './components/patterns/TextInput.vue'
import TimeSelect from './components/patterns/TimeSelect.vue'

import NavigationBar from './components/wizard/NavigationBar.vue'
import WizardBar from './components/wizard/WizardBar.vue'
import WizardStepHeader from './components/wizard/WizardStepHeader.vue'

// shared
import EcoLabeledControl from './components/deviceControls/v2/shared/EcoLabeledControl.vue'

// gradients
import Gradients from './components/deviceControls/v1/shared/gradients'

// general models
import { DropdownOption } from './models'

// controls v2
import {
  ActionControlAppearance,
  ActionControlAttributes,
  ActionsControlAppearance,
  ActionsControlAttributes,
  ArrayContainerControlAttributes,
  BooleanDisplayControlAppearance,
  BooleanDisplayControlAttributes,
  ControlAttributes,
  ControlTypeV2,
  DeviceMessagesControlAppearance,
  DeviceMessagesControlAttributes,
  EnumDisplayControlAppearance,
  EnumDisplayControlAttributes,
  EnumInputControlAppearance,
  EnumInputControlAttributes,
  LinksControlAppearance,
  LinksControlAttributes,
  NumericDisplayControlAppearance,
  NumericDisplayControlAttributes,
  NumericInputControlAppearance,
  NumericInputControlAttributes,
  StateOfChargeConfigurationControlAppearance,
  StateOfChargeConfigurationControlAttributes,
  TextDisplayControlAppearance,
  TextDisplayControlAttributes,
  TextInputControlAppearance,
  TextInputControlAttributes,
  TimeDisplayControlAppearance,
  TimeDisplayControlAttributes,
  TimeDisplayControlTimeType,
  ToggleControlAppearance,
  ToggleControlAttributes,
  TogglesControlAppearance,
  TogglesControlAttributes,
} from './components/deviceControls/v2/models'

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
Vue.use(Vuetify)

// Export components individually
export { 
  // base
  BaseBase64Icon,
  BaseMaterialIcon,
  BaseProgressBar,
  BaseSingleSelect,
  BaseSpinner,
  BaseTextButton,
  BaseToggleButton,

  // dialog
  ConfirmDialog,
  ModalDialog,
  
  // patterns
  Accordion,
  AccordionItem,
  AutocompleteInput,
  AutocompleteSelect,
  BaseCheckbox,
  CheckboxWithLabel,
  ColorSelect,
  DateSelect,
  DescriptionPopover,
  FileSelect,
  FormField,
  IconButton,
  IconSelect,
  LabelComponent,
  Popover,
  StringAddComponent,
  StringFilterComponent,
  TextInput,
  TimeSelect,

  // wizard
  WizardBar,
  WizardStepHeader,
  NavigationBar,
  
  // gradients
  Gradients,

  // shared
  EcoLabeledControl,

  // general models
  type DropdownOption,

  // controls v2
  ActionControlAppearance,
  type ActionControlAttributes,
  ActionsControlAppearance,
  type ActionsControlAttributes,
  type ArrayContainerControlAttributes,
  BooleanDisplayControlAppearance,
  type BooleanDisplayControlAttributes,
  type ControlAttributes,
  ControlTypeV2,
  DeviceMessagesControlAppearance,
  type DeviceMessagesControlAttributes,
  EnumDisplayControlAppearance,
  type EnumDisplayControlAttributes,
  LinksControlAppearance,
  type LinksControlAttributes,
  NumericDisplayControlAppearance,
  type NumericDisplayControlAttributes,
  NumericInputControlAppearance,
  type NumericInputControlAttributes,
  EnumInputControlAppearance,
  type EnumInputControlAttributes,
  StateOfChargeConfigurationControlAppearance,
  type StateOfChargeConfigurationControlAttributes,
  TextDisplayControlAppearance,
  type TextDisplayControlAttributes,
  TextInputControlAppearance,
  type TextInputControlAttributes,
  TimeDisplayControlAppearance,
  type TimeDisplayControlAttributes,
  TimeDisplayControlTimeType,
  type ToggleControlAppearance,
  type ToggleControlAttributes,
  TogglesControlAppearance,
  type TogglesControlAttributes,
}

function install(vue: any) {
  // base
  vue.component('BaseBase64Icon', BaseBase64Icon)
  vue.component('BaseMaterialIcon', BaseMaterialIcon)
  vue.component('BaseProgressBar', BaseProgressBar)
  vue.component('BaseSingleSelect', BaseSingleSelect)
  vue.component('BaseSpinner', BaseSpinner)
  vue.component('BaseTextButton', BaseTextButton)
  vue.component('BaseToggleButton', BaseToggleButton)

  // dialogs
  vue.component('ConfirmDialog', ConfirmDialog)
  vue.component('ModalDialog', ModalDialog)

  // patterns
  // eslint-disable-next-line vue/multi-word-component-names
  vue.component('Accordion', Accordion)
  vue.component('AccordionItem', AccordionItem)
  vue.component('AutocompleteInput', AutocompleteInput)
  vue.component('AutocompleteSelect', AutocompleteSelect)
  vue.component('BaseCheckbox', BaseCheckbox)
  vue.component('CheckboxWithLabel', CheckboxWithLabel)
  vue.component('ColorSelect', ColorSelect)
  vue.component('DateSelect', DateSelect)
  vue.component('DescriptionPopover', DescriptionPopover)
  vue.component('IconButton', IconButton)
  vue.component('IconSelect', IconSelect)
  vue.component('FileSelect', FileSelect)
  vue.component('FormField', FormField)
  vue.component('LabelComponent', LabelComponent)
  // eslint-disable-next-line vue/multi-word-component-names
  vue.component('Popover', Popover)
  vue.component('StringAddComponent', StringAddComponent)
  vue.component('StringFilterComponent', StringFilterComponent)
  vue.component('TextInput', TextInput)
  vue.component('TimeSelect', TimeSelect)
  
  // wizard
  vue.component('WizardBar', WizardBar)
  vue.component('WizardStepHeader', WizardStepHeader)
  vue.component('NavigationBar', NavigationBar)
}

export default { install }
