import { PlcOperationStateHistoryItemModel } from '@ecocoach/domain-store-modules/src/plcConnection/models'

export interface PlcOperationStateHistoryItemViewModel extends PlcOperationStateHistoryItemModel {
  status: PlcStatus
  dateTimeFormatted: string
}

export enum PlcStatus {
  Online = 'Online',
  PartiallyOnline = 'PartiallyOnline',
  Offline = 'Offline',
  Updating = 'Updating',
  Unknown = 'Unknown',
}

export interface PlcConnectionHistoryTableFilterSettings {
  statuses: string[]
  date: string
}

export const PlcConnectionHistoryTableFilterSettingsStorageKey = 'plcConnectionHistoryTableFilterSettings'
