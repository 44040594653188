import { RootState } from '@/store/types'
import { BuildState, DeploymentState, ReleaseModel } from '@ecocoach/domain-store-modules/src/plcConfiguration/models'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { GetterTree } from 'vuex'
import { toViewModel } from './helpers'
import { ReleaseProgressStep, ReleaseStepInfo, UpgradeRequiredReason, ValidationIssueViewModel } from './models'
import { LoadProgramUiGetter, LoadProgramUiState } from './types'

const computeStep = (release: ReleaseModel): ReleaseProgressStep => {
  if (!release || !release.buildState) {
    return ReleaseProgressStep.CreateRelease
  }

  if (release.buildState !== BuildState.SUCCESSFULLY_FINISHED) {
    switch (release.buildState) {
    case BuildState.NOT_STARTED:
      return ReleaseProgressStep.CreateRelease
    case BuildState.STARTING:
      return ReleaseProgressStep.PreparingBuild
    case BuildState.BUILD_FAILED:
      return ReleaseProgressStep.BuildError
    default:
      return ReleaseProgressStep.Building
    }
  } else {
    switch (release.deploymentState) {
    case null:
    case DeploymentState.NOT_STARTED:
      return ReleaseProgressStep.DeployRelease
    case DeploymentState.INACTIVE:
      return ReleaseProgressStep.RedeployRelease
    case DeploymentState.UPDATING:
      return ReleaseProgressStep.PreparingDeployment
    case DeploymentState.VERIFYING_NEW_RELEASE:
    case DeploymentState.REMOVE_PREVIOUS_VERSION:
    case DeploymentState.REVERTING_TO_PREVIOUS_VERSION:
      return ReleaseProgressStep.Verifying
    case DeploymentState.ACTIVE:
      return ReleaseProgressStep.ReleaseActive
    case DeploymentState.DEPLOYMENT_FAILED:
      return ReleaseProgressStep.DeployError
    default:
      return ReleaseProgressStep.Deploying
    }
  }
}

export const getters: GetterTree<LoadProgramUiState, RootState> = {
  [LoadProgramUiGetter.currentStep] (_, __, rootState): ReleaseStepInfo {
    const release = rootState.plcConfiguration.releases.find(r => r.id === rootState.plcConfiguration.releaseId)!
    return {
      step: computeStep(release),
      release,
    }
  },
  [LoadProgramUiGetter.isInteracted] ({ interacted }): boolean {
    return interacted
  },
  [LoadProgramUiGetter.upgradeRequiredReason] ({ upgradeRequiredReason }): UpgradeRequiredReason {
    return upgradeRequiredReason
  },
  [LoadProgramUiGetter.deploymentFinished] (_, __, rootState): boolean {
    const release = rootState.plcConfiguration.releases.find(r => rootState.plcConfiguration.releaseId !== ''
        && r.id === rootState.plcConfiguration.releaseId)
    return release !== undefined  && release.deploymentState === DeploymentState.ACTIVE
  },
  [LoadProgramUiGetter.validationIssues] ({ validationIssues }, _, __, rootGetters): ValidationIssueViewModel[] {
    const resolveStringResource = rootGetters[`resource/${ResourceGetter.resolveStringResource}`]
    return validationIssues.map(v => toViewModel(v, resolveStringResource))
  },
}