<template>
  <v-card dark style="background-image: radial-gradient(circle at center top, #1a3856, #080e19);">
    <v-card-title primary-title>
      <h3>{{ title | translate  }} </h3>
    </v-card-title>
    <v-container>
      <div v-for="(entry, index) in entries" :key="index" class="user-row" @click="$emit('selected', entry.userIdentifier)">
        <div class="user-email" :class="{ 'selected' : selected === entry.userIdentifier, 'selectable' : selected !== entry.userIdentifier}" :title="entry.userIdentifier">{{ entry.label }}</div>
        <div class="user-actions" v-if="selected === entry.userIdentifier && hasAuthToolSuperPowers">
          <v-tooltip bottom class="user-action">
            <v-btn slot="activator" @click="showGrantAllPermissionsWithSuperPowers = true" icon flat>
              <v-icon small>group</v-icon>
            </v-btn>
            <span>Alle Berechtigungen geben (mit AuthToolSuperPowers)</span>
          </v-tooltip>
          <v-tooltip bottom class="user-action">
            <v-btn slot="activator" @click="showRevokeAllPermissionsWithSuperPowers = true" icon flat>
              <v-icon small>group_off</v-icon>
            </v-btn>
            <span>Alle Berechtigungen entziehen (mit AuthToolSuperPowers)</span>
          </v-tooltip>
        </div>
      </div>
      <div v-if="allowAdd" class="user-row">
        <input 
        :type="inputType"
        v-model="newEntry"
        @keyup.enter="onAdded"
        :placeholder="'authorize.add.email' | translate"
        class="emailInput"/>
        <v-icon 
        @click="onAdded"
        color="grey lighten-1">add</v-icon>
      </div>
    </v-container>
    <confirm-dialog
      :show="showGrantAllPermissionsWithSuperPowers"
      header="Alle Berechtigungen geben"
      :text="`Benutzer ${selected} alle Berechtigungen im Projekt geben, für die dies möglich ist? Dies kann einen Moment dauern.`"
      :confirm-button-text="'OK'"
      :cancel-button-text="'common.button.cancel' | translate"
      :disabled="isInteracted"
      @confirmed="onGrantAllPermissionsWithSuperPowers"
      @cancelled="showGrantAllPermissionsWithSuperPowers = false"
    />
    <confirm-dialog
      :show="showRevokeAllPermissionsWithSuperPowers"
      header="Alle Berechtigungen entziehen"
      :text="`Benutzer ${selected} alle Berechtigungen im Projekt entziehen, für die dies möglich ist? Dies kann einen Moment dauern.`"
      :confirm-button-text="'OK'"
      :cancel-button-text="'common.button.cancel' | translate"
      :disabled="isInteracted"
      @confirmed="onRevokeAllPermissionsWithSuperPowers"
      @cancelled="showRevokeAllPermissionsWithSuperPowers = false"
    />
  </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const AuthorizationUi = namespace('authorizationUi')
const UserSettings = namespace('userSettings')

import { FeatureSwitches } from '../../../../../eco-domain-store-modules/src/userSettings/models'
import { UserViewModel } from '@/store/modules/authorizationUi/models'

@Component({
  components: {
  },
})
export default class UserList extends Vue {
  @Prop({type: Array}) public entries: UserViewModel[]
  @Prop({type: String}) public selected: string
  @Prop({type: String}) public title: string
  @Prop({type: Boolean, default: false}) public allowAdd: boolean
  @Prop({type: String, default: 'text'}) public inputType: string
  @AuthorizationUi.Getter public isInteracted: boolean
  @UserSettings.Getter public isFeatureOn: (featureId: string) => boolean
  @AuthorizationUi.Action public grantAllPermissionsWithSuperPowers: () => Promise<void>
  @AuthorizationUi.Action public revokeAllPermissionsWithSuperPowers: () => Promise<void>

  public newEntry = ''

  public showGrantAllPermissionsWithSuperPowers = false
  public showRevokeAllPermissionsWithSuperPowers = false

  public get hasAuthToolSuperPowers() {
    return this.isFeatureOn(FeatureSwitches.authToolSuperPowers)
  }

  public onAdded() {
    this.$emit('added', this.newEntry)
    this.newEntry = ''
  }

  public async onGrantAllPermissionsWithSuperPowers() {
    await this.grantAllPermissionsWithSuperPowers()
    this.showGrantAllPermissionsWithSuperPowers = false
  }

  public async onRevokeAllPermissionsWithSuperPowers() {
    await this.revokeAllPermissionsWithSuperPowers()
    this.showRevokeAllPermissionsWithSuperPowers = false
  }
}
</script>
<style lang="scss" scoped>
.v-card__title {
  background: linear-gradient(to bottom, #1a3856, #080e19);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #1a3856), color-stop(0.6, #080e19));
}
.v-card__title--primary {
  padding-top: 15px;
}
.user-row {
  cursor: pointer;
  display: flex;
  align-items: center;
  max-height: 42px;
  border-bottom: solid;
  border-color: white;
  border-bottom-width: 1px;
  padding: 10px 5px;
  .user-email {
    &.selectable {
      filter: alpha(opacity=10);
      opacity: 0.5;
    }
    &.selected {
      filter: alpha(opacity=100);
      opacity: 1;
    }
  }
  .user-actions {
    display: flex;
    justify-content: flex-end;
    .user-action {
      width: 36px;
    }
  }
}
</style>