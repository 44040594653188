import { ActionTree } from 'vuex'
import queryApi from './api/api'
import { UserSettingsAction, UserSettingsMutation, UserSettingsState } from './types'

export const actions: ActionTree<UserSettingsState, {}> = {
  async [UserSettingsAction.loadCurrentUserSettings]({ commit }) {
    const data = await queryApi.currentUserFeatureSwitches()
    commit(UserSettingsMutation.setCurrentUserFeatureSwitches, data)
  },
  async [UserSettingsAction.loadMfaConfiguration]({ commit }) {
    const data = await queryApi.currentUserMfaConfiguration()
    commit(UserSettingsMutation.setEmailMfaEnabled, data.isEmailMfaEnabled)
  },
  async [UserSettingsAction.setEmailMfaEnabled]({ commit }, payload: boolean) {
    await queryApi.updateMfaConfiguration({ isEmailMfaEnabled: payload })
    commit(UserSettingsMutation.setEmailMfaEnabled, payload)
  },
}
