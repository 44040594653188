<template>
  <eco-labeled-control
    :context="context"
    :attributes="attributes"
    :direction="'column'"
  >
    <div class="eco-soc-configuration-control">
      <!-- visualization -->
      <div class="visualization">
        <div class="battery-plus-pole"></div>
        <div class="battery-body">
          <div class="battery-segment self-consumption-capacity" :title="selfConsumptionCapacityLabel" :style="selfConsumptionCapacityStyle"></div>
          <div class="battery-segment peak-shaving-capacity" :title="peakShavingCapacityLabel" :style="peakShavingCapacityStyle"></div>
          <div class="battery-segment load-management-capacity" :title="loadManagementCapacityLabel" :style="loadManagementCapacityStyle"></div>
          <div class="battery-segment off-grid-capacity" :title="offGridCapacityLabel" :style="offGridCapacityStyle"></div>
        </div>
      </div>
      <!-- inputs -->
      <div class="inputs">
        <div class="with-label">
          <div class="label">
            <div class="label-text">{{ selfConsumptionCapacityLabel }}</div>
          </div>
          <eco-number-input class="input"
            :current-value="currentSelfConsumptionCapacityValue"
            :pending-value="pendingSelfConsumptionCapacityValue"
            :min="0"
            :max="100"
            :step="1"
            unit="%"
            :readonly="true"
          />
        </div>
        <div class="with-label">
          <div class="label">
            <div class="label-text">{{ peakShavingCapacityLabel }}</div>
          </div>
          <eco-number-input class="input"
            :current-value="currentPeakShavingCapacityValue"
            :pending-value="pendingPeakShavingCapacityValue"
            :min="0"
            :max="maxPeakShavingCapacityValue"
            :step="1"
            unit="%"
            :readonly="readonly"
            @changed="onPeakShavingCapacityChanged"
          />
        </div>
        <div class="with-label">
          <div class="label">
            <div class="label-text">{{ loadManagementCapacityLabel }}</div>
          </div>
          <eco-number-input class="input"
            :current-value="currentLoadManagementCapacityValue"
            :pending-value="pendingLoadManagementCapacityValue"
            :min="0"
            :max="maxLoadManagementCapacityValue"
            :step="1"
            unit="%"
            :readonly="readonly"
            @changed="onLoadManagementCapacityChanged"
          />
        </div>
        <div class="with-label off-grid">
          <div class="label">
            <div class="label-text">{{ offGridCapacityLabel }}</div>
          </div>
          <eco-number-input class="input"
            :current-value="currentOffGridCapacityValue"
            :pending-value="pendingOffGridCapacityValue"
            :min="0"
            :max="maxOffGridCapacityValue"
            :step="1"
            unit="%"
            :readonly="readonly"
            @changed="onOffGridCapacityChanged"
          />
        </div>
      </div>
    </div>
  </eco-labeled-control>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ControlContextV2, StateOfChargeConfigurationControlAttributes } from './models'

import EcoLabeledControl from './shared/EcoLabeledControl.vue'
import EcoNumberInput from './shared/EcoNumberInput.vue'
 
@Component({
  components: {
    EcoLabeledControl,
    EcoNumberInput,
  },
})
export default class EcoStateOfChargeConfigurationControl extends Vue {
  @Prop() public id: string
  @Prop() public context: ControlContextV2
  @Prop() public attributes: StateOfChargeConfigurationControlAttributes
  @Prop() public state: object
  @Prop() public pendingState: object
  @Prop() public readonly: boolean

  public get currentSelfConsumptionCapacityValue()  {
    return this.state[this.attributes.selfConsumptionCapacity.state]
  }

  public get currentPeakShavingCapacityValue() {
    return this.state[this.attributes.peakShavingCapacity.state]
  }

  public get currentLoadManagementCapacityValue() {
    return this.state[this.attributes.loadManagementCapacity.state]
  }

  public get currentOffGridCapacityValue() {
    return this.state[this.attributes.offGridCapacity.state]
  }

  public get pendingSelfConsumptionCapacityValue()  {
    if (this.pendingPeakShavingCapacityValue || this.pendingLoadManagementCapacityValue || this.pendingOffGridCapacityValue) {
      const pendingPeakShavingCapacityValue = this.pendingPeakShavingCapacityValue ?? this.currentPeakShavingCapacityValue
      const pendingLoadManagementCapacityValue = this.pendingLoadManagementCapacityValue ?? this.currentLoadManagementCapacityValue
      const pendingOffgridCapacityValue = this.pendingOffGridCapacityValue ?? this.currentOffGridCapacityValue
      return 100 - (pendingLoadManagementCapacityValue + pendingPeakShavingCapacityValue + pendingOffgridCapacityValue)
    } else {
      return undefined
    }
  }

  public get pendingPeakShavingCapacityValue()  {
    return this.pendingState[this.attributes.peakShavingCapacity.state]
  }

  public get pendingLoadManagementCapacityValue()  {
    return this.pendingState[this.attributes.loadManagementCapacity.state]
  }

  public get pendingOffGridCapacityValue() {
    return this.pendingState[this.attributes.offGridCapacity.state]
  }

  public get maxPeakShavingCapacityValue() {
    return this.currentPeakShavingCapacityValue + this.currentSelfConsumptionCapacityValue
  }

  public get maxLoadManagementCapacityValue() {
    return this.currentLoadManagementCapacityValue + this.currentSelfConsumptionCapacityValue
  }

  public get maxOffGridCapacityValue()  {
    return this.currentOffGridCapacityValue + this.currentSelfConsumptionCapacityValue
  }

  public get selfConsumptionCapacityLabel()  {
    return this.context.resolveStringResource(this.attributes.selfConsumptionCapacity.label)
  }

  public get peakShavingCapacityLabel()  {
    return this.context.resolveStringResource(this.attributes.peakShavingCapacity.label)
  }

  public get loadManagementCapacityLabel()  {
    return this.context.resolveStringResource(this.attributes.loadManagementCapacity.label)
  }

  public get offGridCapacityLabel() {
    return this.context.resolveStringResource(this.attributes.offGridCapacity.label)
  }

  public get selfConsumptionCapacityStyle() {
    return {
      height: `${this.currentSelfConsumptionCapacityValue}%`,
    }
  }

  public get peakShavingCapacityStyle() {
    return {
      height: `${this.currentPeakShavingCapacityValue}%`,
    }
  }

  public get loadManagementCapacityStyle() {
    return {
      height: `${this.currentLoadManagementCapacityValue}%`,
    }
  }

  public get offGridCapacityStyle() {
    return {
      height: `${this.currentOffGridCapacityValue}%`,
    }
  }

  public onPeakShavingCapacityChanged(value: number) {
    this.context.executeCommand(this.id, {
      command: this.attributes.peakShavingCapacity.command,
      params: value,
      pendingState: {
        [this.attributes.peakShavingCapacity.state]: value,
      },
    })
  }

  public onLoadManagementCapacityChanged(value: number) {
    this.context.executeCommand(this.id, {
      command: this.attributes.loadManagementCapacity.command,
      params: value,
      pendingState: {
        [this.attributes.loadManagementCapacity.state]: value,
      },
    })
  }

  public onOffGridCapacityChanged(value: number) {
    this.context.executeCommand(this.id, {
      command: this.attributes.offGridCapacity.command,
      params: value,
      pendingState: {
        [this.attributes.offGridCapacity.state]: value,
      },
    })
  }
}
</script>

<style lang="scss" scoped>
.eco-soc-configuration-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .visualization {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 120px;
    .battery-plus-pole {
      height: 5%;
      width: 20px;
      background: white;
      border: solid 1px white;
    }
    .battery-body {
      height: 95%;
      display: flex;
      flex-direction: column;
      border: solid 1px white;
      border-radius: 5px;
      .battery-segment {
        width: 60px;
      }
      .self-consumption-capacity {
        background: linear-gradient(to right, green, darkgreen);
        border-radius: 5px 5px 0px 0px;
      }
      .peak-shaving-capacity {
        background: linear-gradient(to right, yellow,orange);
      }
      .load-management-capacity {
        background: linear-gradient(to right, blue, darkblue);
      }
      .off-grid-capacity {
        background: linear-gradient(to right, #fa0f36,#78022d);
        border-radius: 0px 0px 5px 5px;
      }
    }
  }
  .inputs {
    display: flex;
    flex-direction: column;
    .with-label {
      height: 35px;
      display: flex;
      justify-content: center;
      align-content: center;
      justify-items: center;
      align-content: center;
      .label {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 10px;
      }
      .off-grid {
        input {
          max-width: 50px;
        }
      }
    }
  }
} 

</style>

<style lang="css">
</style>
