import { MutationTree } from 'vuex'
import { groupedBy, remove, removeWithId, sortedByName, sortedBySortOrder, toMap, upsert, upsertWithId } from '../utils'
import { 
  ComponentFirmwareReleaseModel,
  ControlModelV2,
  DeviceModel,
  PendingControlCommand,
  PlcModel,
  ProjectModel,
  RoomModel,
  SceneModel,
  SetConsumptionProcessOwnerLockEnabledInput,
  SetControlAppDisplayLevelInput,
  SetControlColorGradientInput,
  SetDeviceConsumptionValidationEnabledInput,
  SetDeviceIgnoreInChartsInput,
  SetDisplayNameInput,
  SetEnergyStatusItemColorInput,
  SetMeasuringPointColorInput,
  StateChanged,
} from './models'
import { PlcOperationMutation, PlcOperationState } from './types'

export const mutations: MutationTree<PlcOperationState> = {
  [PlcOperationMutation.setProjects](state: PlcOperationState, projects: ProjectModel[]) {
    state.projects = sortedByName(projects) 
  },
  [PlcOperationMutation.setPlcs](state: PlcOperationState, plcs: PlcModel[]) {
    state.plcs = sortedByName(plcs) 
  },
  [PlcOperationMutation.setRooms](state: PlcOperationState, rooms: RoomModel[]) {
    state.rooms = sortedBySortOrder(rooms) 
    state.roomsLookup = toMap(rooms, room => room.id)
    state.roomsForPlcLookup = groupedBy(rooms, room => room.plcId)
  },
  [PlcOperationMutation.setDevices](state: PlcOperationState, devices: DeviceModel[]) {
    state.devices = sortedBySortOrder(devices)
    state.devicesLookup = toMap(devices, device => device.id)
    state.devicesForRoomLookup = groupedBy(devices, device => device.roomId)
  },
  [PlcOperationMutation.setControls](state: PlcOperationState, controls: ControlModelV2[]) {
    // merge existing control state (because of caching, the controls from the api could arrive later than state from the hub)
    controls.forEach(control => {
      const existingControl = state.controlsLookup.get(control.id)
      if (existingControl) {
        control.state = { ...existingControl.state, ...control.state }
      }
    })
    state.controls = sortedBySortOrder(controls)
    state.controlsLookup = toMap(controls, control => control.id)
    state.controlsForDeviceLookup = groupedBy(controls, control => control.deviceId)
  },
  [PlcOperationMutation.setControlsFilter](state: PlcOperationState, filter: (control: ControlModelV2) => boolean) {
    state.controlsFilter = filter
  },
  [PlcOperationMutation.setPlcId] (state: PlcOperationState, plcId: string) {
    state.plcId = plcId
  },
  [PlcOperationMutation.setProjectId] (state: PlcOperationState, projectId: string) {
    state.projectId = projectId
  },
  [PlcOperationMutation.setControlState](state: PlcOperationState, payload: StateChanged) {
    const control = state.controlsLookup.get(payload.controlId) as ControlModelV2
    if (control) {
      control.state = { ...control.state, ...payload.state }
    }
  },
  [PlcOperationMutation.setControlUpdateTimeout] (state: PlcOperationState, payload: boolean) {
    state.controlUpdateTimeout = payload
  }, 
  [PlcOperationMutation.setControlUpdateFailed] (state: PlcOperationState, payload: boolean) {
    state.controlUpdateFailed = payload
  }, 
  [PlcOperationMutation.setControlDisplayName](state: PlcOperationState, payload: SetDisplayNameInput) {
    const control = state.controlsLookup.get(payload.controlId)
    if (control) {
      control.displayName = payload.payload.displayName
    }
  },
  [PlcOperationMutation.setControlAppDisplayLevel](state: PlcOperationState, payload: SetControlAppDisplayLevelInput) {
    const control = state.controlsLookup.get(payload.controlId)
    if (control) {
      control.attributes = { ...control.attributes, ...payload.payload }
    }
  },
  [PlcOperationMutation.setControlColorGradient](state: PlcOperationState, payload: SetControlColorGradientInput) {
    const control = state.controlsLookup.get(payload.controlId)
    if (control) {
      control.attributes.gradient = payload.payload.colorGradient
    }
  },
  [PlcOperationMutation.upsertPendingControlCommand](state: PlcOperationState, payload: PendingControlCommand) {
    const pendingControlCommand = state.pendingControlCommands.find(c => c.controlId === payload.controlId)
    if(pendingControlCommand) {
      clearTimeout(pendingControlCommand.timeout)
    }
    upsertWithId(state.pendingControlCommands, payload, p => p.controlId)
  },
  [PlcOperationMutation.removePendingControlCommand](state, correlationId: string) {
    const pendingControlCommand = state.pendingControlCommands.find(c => c.correlationId === correlationId)
    if(pendingControlCommand) {
      clearTimeout(pendingControlCommand.timeout)
      removeWithId(state.pendingControlCommands, correlationId, p => p.correlationId)
    }
  },
  [PlcOperationMutation.setScenes] (state: PlcOperationState, scenes: SceneModel[]) {
    state.scenes = sortedByName(scenes)
  },
  [PlcOperationMutation.upsertScene](state: PlcOperationState, scene: SceneModel) {
    upsert(state.scenes, scene)
    state.scenes = sortedByName(state.scenes)
  },
  [PlcOperationMutation.removeScene](state: PlcOperationState, sceneId: string) {
    remove(state.scenes, sceneId)
  },
  [PlcOperationMutation.setFavorites](state, payload: string[]) {
    state.favorites = payload
  },
  [PlcOperationMutation.addFavorite](state, payload: string) {
    if (!state.favorites.includes(payload)) {
      state.favorites.push(payload)
    }
  },
  [PlcOperationMutation.removeFavorite](state, payload: string) {
    state.favorites = state.favorites.filter(id => id !== payload)
  },
  [PlcOperationMutation.setComponentFirmwareReleases](state, payload: ComponentFirmwareReleaseModel[]) {
    state.componentFirmwareReleases = payload
  },
  [PlcOperationMutation.setDeviceIgnoreInCharts](state: PlcOperationState, payload: SetDeviceIgnoreInChartsInput) {
    const device = state.devicesLookup.get(payload.deviceId)
    if (device) {
      device.isIgnoredInCharts = payload.ignoreInCharts
    }
  },
  [PlcOperationMutation.setDeviceConsumptionValidationEnabled](state: PlcOperationState, payload: SetDeviceConsumptionValidationEnabledInput) {
    const device = state.devicesLookup.get(payload.deviceId)
    if (device) {
      device.consumptionValidationEnabled = payload.consumptionValidationEnabled
    }
  },
  [PlcOperationMutation.setConsumptionProcessOwnerLockEnabled](state: PlcOperationState, payload: SetConsumptionProcessOwnerLockEnabledInput) {
    const device = state.devicesLookup.get(payload.deviceId)
    if (device) {
      device.consumptionProcessOwnerLockEnabled = payload.consumptionProcessOwnerLockEnabled
    }
  },
  [PlcOperationMutation.setMeasuringPointColor](state: PlcOperationState, payload: SetMeasuringPointColorInput) {
    const measuringPoint = state.devicesLookup.get(payload.deviceId)?.measuringPoints.find(m => m.id === payload.measuringPointId)
    if (measuringPoint) {
      measuringPoint.colorGradient = payload.colorGradient
    }
  },
  [PlcOperationMutation.setEnergyStatusItemColor](state: PlcOperationState, payload: SetEnergyStatusItemColorInput) {
    const energyStatusItem = state.devicesLookup.get(payload.deviceId)?.energyStatusItems.find(m => m.id === payload.energyStatusItemId)
    if (energyStatusItem) {
      energyStatusItem.colorGradient = payload.colorGradient
    }
  },
  [PlcOperationMutation.setConfirmedDevices](state, payload: string[]) {
    state.confirmedDeviceIds = payload
  },
  [PlcOperationMutation.addConfirmedDevice](state, payload: string) {
    if (!state.confirmedDeviceIds.includes(payload)) {
      state.confirmedDeviceIds.push(payload)
    }
  },
  [PlcOperationMutation.removeConfirmedDevice](state, payload: string) {
    state.confirmedDeviceIds = state.confirmedDeviceIds.filter(id => id !== payload)
  },
  [PlcOperationMutation.setActivePlcConfigurationReleaseId] (state, releaseId: string) {
    state.activePlcConfigurationReleaseId = releaseId
  },
}