import { AuthorizationsModelBase, Permission } from './models'

export const getPermissions = (authorizations: AuthorizationsModelBase[], id: string, userIdentifier: string): Permission[] => {
  const authorization = authorizations.find(a => a.id === id)
  if (!authorization) {
    return []
  }
  const permission = authorization!.permissions.find(p => p.userIdentifier === userIdentifier)
  return permission && permission.permissions || []
}

export const upsertPermissions = (authorizations: AuthorizationsModelBase[], id: string, userIdentifier: string, permissions: Permission[]) => {
  if (!authorizations.find(a => a.id === id)) {
    authorizations.push({
      id,
      permissions: [],
    })
  }
  const authorization = authorizations.find(a => a.id === id)!
  if (!authorization.permissions.find(p => p.userIdentifier === userIdentifier)) {
    authorization.permissions.push({
      userIdentifier,
      permissions: [],
    })
  }
  const permission = authorization.permissions.find(p => p.userIdentifier === userIdentifier)!
  permission.permissions = permissions
}
