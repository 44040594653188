import { AcceptedUserAgreementModel, UserAgreementModel } from './models'

export enum UserAgreementAction {
  loadCurrentUserAgreements = 'loadCurrentUserAgreements',
  loadAcceptedUserAgreements = 'loadAcceptedUserAgreements',
  acceptUserAgreement = 'acceptUserAgreement',
}

export enum UserAgreementMutation {
  setCurrentUserAgreements = 'setCurrentUserAgreements',
  setAcceptedUserAgreements = 'setAcceptedUserAgreements',
  addAcceptedUserAgreement = 'addAcceptedUserAgreement',
}

export enum UserAgreementGetter {
  currentUserAgreements = 'currentUserAgreements',
  acceptedUserAgreements = 'acceptedUserAgreements',
}

export interface UserAgreementState {
  currentUserAgreements: UserAgreementModel[]
  acceptedUserAgreements: AcceptedUserAgreementModel[]
}
