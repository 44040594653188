<template lang="html">
  <div class="base-toggle-button">
    <div 
      :style="gradientBackgroundStyle"
      class="plane"
      @click="emitButtonToggled">
      <div :class="dotClass"></div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'BaseToggleButton',
  props: {
    /** 
     * the current value
     */
    value: {
      type: Boolean,
      default: false,
    },
    /**
     * sets the pending value
     */
    pendingValue: {
      type: Boolean,
      default: null,
    },
    /** 
     * disabled button, cannot be toggled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /** 
     * background gradient start when active
     */
    activePrimaryColor: {
      type: String,
      default: '#68e138', // green
    },
    /** 
     * background gradient end when active
     */
    activeSecondaryColor: {
      type: String,
      default: '#015e25',
    },
    /** 
     * background gradient start when inactive
     */
    inactivePrimaryColor: {
      type: String,
      default: '#2d3442', // gray
    },
    /** 
     * background gradient end when inactive
     */
    inactiveSecondaryColor: {
      type: String,
      default: '#91a1b6',
    },
  },
  computed: {
    isPending() {
      return (this as any).pendingValue !== null
    },
    isChecked() {
      return (this as any).isPending ? (this as any).pendingValue : !!(this as any).value
    },
    gradientBackgroundStyle() {
      const active = !(this as any).disabled && (this as any).value
      const primaryColor = active ? (this as any).activePrimaryColor : (this as any).inactivePrimaryColor
      const secondaryColor = active ? (this as any).activeSecondaryColor : (this as any).inactiveSecondaryColor
      return {
        background: `-webkit-linear-gradient(90deg, ${primaryColor} 0%, ${secondaryColor} 100%)`,
      }
    },
    dotClass(): string {
      return (this as any).isChecked ? 'dot on' : 'dot off'
    },
  },
  methods: {
    emitButtonToggled(): void {
      if(!(this as any).disabled) {
        /**
         * Toggled event, contains the original value
         *
         * @event buttonToggled
         * @type {Boolean}
         */
        this.$emit('buttonToggled', (this as any).isChecked)
      }
    },
  },
})
</script>
<style lang="scss" scoped>
.base-toggle-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  .plane {
    width: 51px;
    height: 31px;
    position: relative;
    border-radius: 31px;
    cursor: pointer;
    transition: all .5s ease;
    &:active,
    &:focus {
      outline:0 !important;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      -webkit-tap-highlight-color: transparent; /* For some Androids */
    }
    .dot {
      width: 29px;
      height: 29px;
      border-radius: 29px;
      margin: 1px;
      position: absolute;
      transition: all .5s ease;
      &.off {
        left: 0px;
        background-color: #cbcbcb;
      }
      &.on {
        left: 20px;
        background-color: white;
      }
    }
  }
}
</style>

<docs>
A toggle button

Usage example
```js
new Vue({
  data: function() {
    return {
      value: false
    }
  },
  methods: {
    onButtonToggled(value) {
      console.log(value)
      this.value = !value
    }
  },
  template: `
    <base-toggle-button
     :value="value"
     @buttonToggled="onButtonToggled"
    />
  `
})
```

Advanced usage example with pending state until removed
```js
new Vue({
  data: function() {
    return {
      value: false,
      pendingValue: null,
    }
  },
  methods: {
    onButtonToggled(value) {
      console.log(value)
      this.pendingValue = !value
      setTimeout(() => {
        this.value = !value
        this.pendingValue = null
      }, 1000)
    }
  },
  template: `
    <base-toggle-button
     :value="value"
     @buttonToggled="onButtonToggled"
     :pending-value="pendingValue"/>
  `
})
```

Disabled
```jsx
  <base-toggle-button
    :value="true"
    :disabled="true"
  />
```

Custom gradient colors
```js
new Vue({
  data: function() {
    return {
      value: false
    }
  },
  methods: {
    onButtonToggled(value) {
      console.log(value)
      this.value = !value
    }
  },
  template: `
    <base-toggle-button
     :value="value"
     activePrimaryColor="pink"
     activeSecondaryColor="green"
     inactivePrimaryColor="blue"
     inactiveSecondaryColor="black"
     @buttonToggled="onButtonToggled"
    />
  `
})
```
</docs>
