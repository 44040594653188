<template>
  <div>
    <div v-if="isEcocoachEmployee" class="toggle">
      <label-component
          text="Test-Ansicht"
          descriptionContent="In der Test-Ansicht werden Funktionsblöcke mit Status 'In Test' angezeigt. Diese Ansicht ist nur für ecocoach Mitarbeiter sichtbar, Funktionsblöcke mit Status 'In Test' werden mit (!) vor dem Namen dargestellt"
      />
      <base-toggle-button
        :value="includeInTest"
        @buttonToggled="includeInTest = !includeInTest"
      />
    </div>
    <div class="filter">
      <string-filter-component
        :value="searchString"
        :placeholder="dictionary('functionblocks.filter.placeholder')"
        @changed="searchString = $event"
      />
      <label-component class="filterinfo"
        v-if="searchResults.length == 0"
        :text="dictionary('functionblocks.filter.noresults')"
      />
    </div>
    <tree-view>
      <tree-view-group
        v-for="({ category, functionBlocks }) in searchResults"
        :key="category.id"
        :expanded="isCategoryExpanded"
        :title="category.name"
        :icon="category.iconResourceId"
      >
        <tree-view-group-item
          v-for="functionBlock in functionBlocks"
          :description-popup-title="name(functionBlock)"
          :description-popup-text="info(functionBlock)"
          :key="functionBlock.id"
          :icon="functionBlock.iconResourceId"
          :name="displayName(functionBlock)"
          :disabled="isInteracted"
          @treeViewItemInteraction="createDevice(functionBlock.id)"/>
      </tree-view-group>
    </tree-view>
  </div>
</template>

<script lang="ts">
import TreeView from '@/components/ui/TreeView.vue'
import TreeViewGroup from '@/components/ui/TreeViewGroup.vue'
import TreeViewGroupItem from '@/components/ui/TreeViewGroupItem.vue'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { FunctionBlockDefinitionSearchParams, FunctionBlocksOfCategory, FunctionBlockTreeItemViewModel } from '../../../../../store/modules/functionBlockUi/models'

import { FunctionBlockState } from '../../../../../../../eco-domain-store-modules/src/systemConfiguration/models'

import { ResourceCategory, ResourceModel } from '../../../../../../../eco-domain-store-modules/src/resource/models'

import { ArchitectureType, PlcType, SourceLanguage } from '../../../../../../../eco-domain-store-modules/src/common'

import { PlcModel } from '@ecocoach/domain-store-modules/src/plc/models'

const App = namespace('app')
const FunctionBlockUi = namespace('functionBlockUi')
const Resource = namespace('resource')
const RoomUi = namespace('roomUi')
const DeviceUi = namespace('deviceUi')

@Component({
  components: {
    TreeView,
    TreeViewGroup,
    TreeViewGroupItem,
  },
})
export default class extends Vue {
    @App.Getter public isEcocoachEmployee: boolean
    @App.Getter public selectedPlc: PlcModel
    @FunctionBlockUi.Getter public functionBlocksSearchResult: (filter: FunctionBlockDefinitionSearchParams) => FunctionBlocksOfCategory[]
    @Resource.Getter public dictionary
    @Resource.Getter public resourcesOfCategory: (category: ResourceCategory) => ResourceModel[]
    @RoomUi.Getter public selectedRoomId: string
    @DeviceUi.Getter public isInteracted: boolean
    @DeviceUi.Getter public architectureType: ArchitectureType
    @DeviceUi.Action public createDevice: (functionBlockDefinitionId: string) => Promise<void>

    public searchString = ''
    public includeInTest = false

    public get searchResults() {
      return this.functionBlocksSearchResult({
        search: this.searchString,
        includeInTest: this.includeInTest,
        includeObsolete: false,
        architectureType: this.architectureType,
        sourceLanguage: this.selectedPlc.plcType === PlcType.ECO_BASIC_CONTROL_LIGHT ? SourceLanguage.CSharp : SourceLanguage.StructuredText,
      })
    }

    public get isCategoryExpanded(): boolean {
      return !!this.searchString
    }

    public displayName(functionBlock: FunctionBlockTreeItemViewModel): string {
      return (functionBlock.state === FunctionBlockState.IN_TEST)
        ? `(!) ${functionBlock.name}`
        : functionBlock.name
    }

    public name(functionBlock: FunctionBlockTreeItemViewModel) {
      return functionBlock.name
    }

    public info(functionBlock: FunctionBlockTreeItemViewModel) {
      return [
        `${functionBlock.description}`,
        `${this.dictionary('information.version')}: ${functionBlock.version}`,
        `${this.dictionary('navigation.releaseNotes')}:`,
        ...functionBlock.releaseNotesHistory,
      ].join('<br>')
    }
}
</script>

<style lang="scss" type="text/scss" scoped>
  .toggle {
		display:flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
    flex: 1;
    padding: 12px 36px 0px 0px;
	}
  .filter {
    padding: 12px 36px 0px 0px;
    margin-left: -12px;
	}
  .filterinfo {
    padding: 12px 36px 0px 12px;
	}
</style>