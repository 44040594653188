import { actions } from '@/store/modules/authorizationUi/actions'
import { getters } from '@/store/modules/authorizationUi/getters'
import { mutations } from '@/store/modules/authorizationUi/mutations'
import { state } from '@/store/modules/authorizationUi/state'
import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { AuthorizationUiAction, AuthorizationUiGetter, AuthorizationUiMutation, AuthorizationUiState } from './types'

const namespaced: boolean = true
export const authorizationUi: Module<AuthorizationUiState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action: AuthorizationUiAction, 
  mutation: AuthorizationUiMutation,
  getter: AuthorizationUiGetter,
}

export const domain = 'authorizationUi'

export const vuexModule = {
  [domain]: authorizationUi,
}