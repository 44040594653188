<template>
  <modal-dialog 
    :show="functionDefinition"
    header="Set obsolete" 
    button1-text='Yes'
    button2-text="Cancel"
    :button1-disabled="!!actionInProgress"
    :button2-disabled="!!actionInProgress"
    @button1Pressed="onConfirmed"
    @button2Pressed="onClose"
  >
    <v-layout column>
      <v-flex class="fb-info">
        Function definition: {{name}}
        <br>
        Version: {{version}}
      </v-flex>
      <v-flex>
        <form-field text="Successor (optional)">
          <autocomplete-select 
            :options="successorFunctionBlocksOptions"
            option-value="id"
            option-label="label"
            :selected-value="successorFunctionBlockId"
            @selected="successorFunctionBlockId = $event"
          />
        </form-field>
      </v-flex>
      <v-flex>
        This action is irreversible! Are you sure?
      </v-flex>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { SetFunctionBlockObsoleteInput } from '../../../../../eco-domain-store-modules/src/systemConfiguration/models'
import { DropdownOption } from '../../../../../shared-components/src'
import { FunctionBlockTreeItemViewModel } from '../../../store/modules/functionBlockUi/models'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')

@Component({
  components: {
  },
})
export default class SetFunctionDefinitionObsoleteDialog extends Vue {
  @Prop() public functionDefinition: FunctionBlockTreeItemViewModel
  @FunctionDefinitionEditorUi.Getter public actionInProgress: string
  @FunctionDefinitionEditorUi.Getter public successorFunctionBlocksOptions: DropdownOption[]
  @FunctionDefinitionEditorUi.Action public setFunctionDefinitionObsolete: (payload: SetFunctionBlockObsoleteInput) => Promise<void>

  public successorFunctionBlockId = ''

  public mounted() {
    this.successorFunctionBlockId = ''
  }

  public get name() {
    return this.functionDefinition?.name
  }

  public get version() {
    return this.functionDefinition?.version
  }

  public async onConfirmed() {
    await this.setFunctionDefinitionObsolete({
      id: this.functionDefinition.id,
      ...(this.successorFunctionBlockId && { successorFunctionBlockId: this.successorFunctionBlockId}),
    })
    this.onClose()
  }

  @Emit('closed') 
  public onClose() {
    return
  }
}
</script>

<style lang="scss" type="text/scss" scoped>
.fb-info {
  margin-bottom: 20px;
}
</style>