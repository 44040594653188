import { backendEnvironment } from '@/environment'
import { BackendEnvironmentTypes } from '@ecocoach/domain-store-modules/src/services/api.service'
import EnvironmentService from '@ecocoach/domain-store-modules/src/services/environment.service'

export default function (store) {
  if (backendEnvironment() !== BackendEnvironmentTypes.PRODUCTION || EnvironmentService.isRunningLocally) {
    const timestampedHeading = ({ type, prefix }) => {
      const d = new Date()
      return `%c${prefix} ${type} @ ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}:${d.getMilliseconds()}`
    }

    store.subscribe(({ type, payload }, state) => {
      window.console.groupCollapsed(timestampedHeading({ type, prefix: 'mutation' }), 'font-weight: bold;')
      window.console.log(`%cpayload`, 'color: #03A9F4; font-weight: bold;', payload)
      window.console.log('%cstate', 'color: #4CAF50; font-weight: bold;', state)
      window.console.groupEnd()
    })

    store.subscribeAction(({ type, payload }, state) => {
      window.console.groupCollapsed(timestampedHeading({ type, prefix: 'action' }), 'font-weight: bold;')
      window.console.log(`%cpayload`, 'color: #03A9F4; font-weight: bold;', payload)
      window.console.log('%cstate', 'color: #4CAF50; font-weight: bold;', state)
      window.console.groupEnd()
    })
  }
}