<template>
  <div class="room-form">
    <room-form-edit v-if="isEditingInProgress"/>
    <room-form-create v-else/>
  </div>
</template>

<script lang="ts">
import RoomFormCreate from '@/components/views/ecoSetupTool/steps/defineRooms/RoomFormCreate.vue'
import RoomFormEdit from '@/components/views/ecoSetupTool/steps/defineRooms/RoomFormEdit.vue'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const RoomUi = namespace('roomUi')

@Component({
  components: {
    RoomFormEdit,
    RoomFormCreate,
  },
})
export default class RoomForm extends Vue {
  @RoomUi.Getter public isEditingInProgress: boolean
  @RoomUi.Mutation public resetRoomInput: () => void

  public mounted() {
    this.resetRoomInput()
  }
}
</script>
<style lang="scss" scoped>
.room-form {
  margin-top: 10px;
}
</style>