<template>
  <v-menu bottom right @click.native.stop attach
    :transition="'scale-transition'"
  >
    <v-layout row align-center slot="activator" class="icon-selector">
      <svg-icon :icon="selected" :width="iconSize" :height="iconSize"/>
      <div v-if="showLabel" class="label">{{ 'icon.choose' | translate }}</div>
    </v-layout>
    <v-card dark class="icon-list">
      <v-btn flat large icon 
        v-for="icon in icons" 
        :key="icon.id"
        @click="onIconSelected(icon.id)"
        class="icon-button"
        :class="{ selected: selected === icon.id }" 
      >
        <svg-icon :icon="icon.id" width="30" height="30"/>
      </v-btn>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { ResourceModel } from '@ecocoach/domain-store-modules/src/resource/models'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import SvgIcon from './SvgIcon.vue'

@Component({
  components: {
    SvgIcon,
  },
})
export default class IconChooser extends Vue {
  @Prop() public icons: ResourceModel[]
  @Prop() public selected: string
  @Prop({ default: true }) public showLabel: boolean
  @Prop({ default: 30 }) public iconSize: number

  @Emit('iconSelected')
  public onIconSelected(iconId: string): string {
    return iconId
  }
}
</script>
<style lang="scss" scoped>
.icon-selector {
  .label {
    margin-left: 15px;
    text-transform: uppercase;
  }
}
.icon-list {
  background: radial-gradient(#1a3856, #080e19);
  width: 360px;
  .icon-button {
    &.selected {
      background-color: #fa0f36 !important;
    }
  }
}
</style>