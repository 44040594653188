<template>
  <v-layout column class="clamps">
    <v-flex xs12>
      <master-clamp-line
        :clamp-lines="clampLines"
        :plc="selectedPlc"
      />
    </v-flex>
    <draggable v-model="slaveClampLines"
      class="masterClamps"
      group="slaveClampLines"
      animation="200"
      handle=".handle"
      @end="sortSolutionHardwareInstances"
    >
      <v-flex xs12
        v-for="slaveLine in slaveClampLines"
        :key="slaveLine.couplerClamp.id"
      >
        <slave-clamp-line
          :clamp-line="slaveLine"
        />
      </v-flex>
    </draggable>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import MasterClampLine from './MasterClampLine.vue'
import SlaveClampLine from './SlaveClampLine.vue'

import Draggable from 'vuedraggable'

import { SolutionHardwareClampLines, SolutionHardwareSlaveClampLine } from '../../../../../store/modules/solutionHardwareUi/models'

import { PlcModel } from '@ecocoach/domain-store-modules/src/plc/models'

const App = namespace('app')
const SolutionHardwareUi = namespace('solutionHardwareUi')

@Component({
  components: {
    Draggable,
    MasterClampLine,
    SlaveClampLine,
  },
})
export default class Clamps extends Vue {
  @SolutionHardwareUi.Getter public clampLines: SolutionHardwareClampLines
  @SolutionHardwareUi.Action public sortSolutionHardwareInstances: () => Promise<void>
  @App.Getter public selectedPlc: PlcModel

  public get slaveClampLines(): SolutionHardwareSlaveClampLine[] {
    return this.clampLines.slaveClampLines
  }

  public set slaveClampLines(value: SolutionHardwareSlaveClampLine[]) {
    this.clampLines.slaveClampLines = value
  }
}
</script>

<style lang="scss" scoped>
.clamps {
  margin-right: 60px;
}
</style>