import hexRgb from 'hex-rgb'

const gradients = [
  {
    id: 'gradient-1',
    primaryColor: '#79032c',
    secondaryColor: '#fd0036',
  },
  {
    id: 'gradient-2',
    primaryColor: '#721839',
    secondaryColor: '#fb287c',
  },
  {
    id: 'gradient-3',
    primaryColor: '#591a27',
    secondaryColor: '#be0029',
  },
  {
    id: 'gradient-4',
    primaryColor: '#f24c4b',
    secondaryColor: '#f3c753',
  },
  {
    id: 'gradient-5',
    primaryColor: '#4a04ca',
    secondaryColor: '#35b4ff',
  },
  {
    id: 'gradient-6',
    primaryColor: '#2b2d65',
    secondaryColor: '#d490f2',
  },
  {
    id: 'gradient-7',
    primaryColor: '#2561c2',
    secondaryColor: '#47fdf3',
  },
  {
    id: 'gradient-8',
    primaryColor: '#023369',
    secondaryColor: '#0e71eb',
  },
  {
    id: 'gradient-9',
    primaryColor: '#028e52',
    secondaryColor: '#bdf737',
  },
  {
    id: 'gradient-10',
    primaryColor: '#078131',
    secondaryColor: '#94fe6f',
  },
  {
    id: 'gradient-11',
    primaryColor: '#015e25',
    secondaryColor: '#68e138',
  },
  {
    id: 'gradient-12',
    primaryColor: '#b36a02',
    secondaryColor: '#fcfe00',
  },
  {
    id: 'gradient-13',
    primaryColor: '#2d3442',
    secondaryColor: '#91a1b6',
  },
  {
    id: 'gradient-14',
    primaryColor: '#0e71eb',
    secondaryColor: '#591a27',
  },
  {
    id: 'gradient-15',
    primaryColor: '#9f3801',
    secondaryColor: '#ffc000',
  },
  {
    id: 'gradient-16',
    primaryColor: '#ffa751',
    secondaryColor: '#ffe259',
  },
]

class Gradients {
  public stops = (id: string): string[] => {
    return [
      this.primaryColor(id),
      this.secondaryColor(id),
    ]
  }
  public primaryColor = (id: string): string => {
    const gradient = gradients.find(g => g.id === id) || this.defaultGradient()
    return gradient.primaryColor
  }
  public secondaryColor = (id: string): string => {
    const gradient = gradients.find(g => g.id === id) || this.defaultGradient()
    return gradient.secondaryColor
  }
  public primaryColorRgbFunction = (id: string): string => {
    const rgba = hexRgb(this.primaryColor(id))
    return `rgb(${rgba.red},${rgba.green},${rgba.blue})`
  }
  public secondaryColorRgbFunction = (id: string): string => {
    const rgba = hexRgb(this.secondaryColor(id))
    return `rgb(${rgba.red},${rgba.green},${rgba.blue})`
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public primaryColorRgbaFunction = (id: string, alpha: number): string => {
    const rgba = hexRgb(this.primaryColor(id))
    return `rgba(${rgba.red},${rgba.green},${rgba.blue})`
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public secondaryColorRgbaFunction = (id: string, alpha: number): string => {
    const rgba = hexRgb(this.secondaryColor(id))
    return `rgba(${rgba.red},${rgba.green},${rgba.blue},${rgba.alpha})`
  }
  public list = (): Array<{ id: string, primaryColor: string, secondaryColor: string }> => {
    return gradients
  }
  public style = (id: string, allowEmpty: boolean) => {
    const gradient = gradients.find(g => g.id === id) || (allowEmpty ? undefined : this.defaultGradient())
    return gradient 
      ? { background: `linear-gradient(-135deg, ${gradient.primaryColor} 0%, ${gradient.secondaryColor} 100%` }
      : { background: 'transparent', border: '1px gray solid' }
  }
  private defaultGradient = () => gradients.find(g => g.id === 'gradient-13')!
}

export default new Gradients()