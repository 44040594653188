import { GetterTree } from 'vuex'
import { 
  FunctionBlockCategoryModel,
  FunctionBlockModel,
  GlobalVariableModel,
  SolutionHardwareDefinitionCategoryModel,
  SolutionHardwareDefinitionModel,
} from './models'
import { SystemConfigurationGetter, SystemConfigurationState } from './types'

export const getters: GetterTree<SystemConfigurationState, {}> = {
  [SystemConfigurationGetter.functionBlocks]({ functionBlocks }): FunctionBlockModel[] {
    return functionBlocks
  },
  [SystemConfigurationGetter.functionBlockById]({ functionBlocks }) {
    return (id: string): FunctionBlockModel => functionBlocks.find(item => item.id === id)!
  },
  [SystemConfigurationGetter.functionBlockCategories]({ functionBlockCategories }): FunctionBlockCategoryModel[] {
    return functionBlockCategories
  },
  [SystemConfigurationGetter.functionBlockCategoryById]({ functionBlockCategories }) {
    return (id: string): FunctionBlockCategoryModel => functionBlockCategories.find(item => item.id === id)!
  },
  [SystemConfigurationGetter.solutionHardwareDefinitions]({ solutionHardwareDefinitions }): SolutionHardwareDefinitionModel[] {
    return solutionHardwareDefinitions
  },
  [SystemConfigurationGetter.solutionHardwareDefinitionById]({ solutionHardwareDefinitions }) {
    return (id: string): SolutionHardwareDefinitionModel => solutionHardwareDefinitions.find(item => item.id === id)!
  },
  [SystemConfigurationGetter.solutionHardwareDefinitionCategories]({ solutionHardwareDefinitionCategories }): SolutionHardwareDefinitionCategoryModel[] {
    return solutionHardwareDefinitionCategories
  },
  [SystemConfigurationGetter.solutionHardwareDefinitionCategoryById]({ solutionHardwareDefinitionCategories }) {
    return (id: string): SolutionHardwareDefinitionCategoryModel => solutionHardwareDefinitionCategories.find(item => item.id === id)!
  },
  [SystemConfigurationGetter.globalVariables]({ globalVariables }): GlobalVariableModel[] {
    return globalVariables
  },
  [SystemConfigurationGetter.globalVariableById]({ globalVariables }) {
    return (id: string): GlobalVariableModel => globalVariables.find(item => item.id === id)!
  },
}
