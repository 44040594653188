<template>
  <div class="consumption-accounts">
    <wizard-step-header 
      :title="'navigation.ecoOneClick.administration' | translate" 
      :sub-title="'oneclick.account.subtitle' | translate"
    />
    <div class="consumption-selections">
      <form-field :text="'consumption.project' | translate" class="form-field">
        <autocomplete-select 
          :options="adminProjects"
          option-value="id"
          option-label="label"
          :selected-value="selectedProjectId"
          @selected="selectAdminProject"
        />
      </form-field>
      <form-field :text="'consumption.targetsystem' | translate" class="form-field">
        <base-single-select
          :options="adminTargetSystems"
          option-value="id"
          option-label="label"
          :selected-value="adminSelectedTargetSystem"
          @selected="selectAdminTargetSystem"
        />
      </form-field>
    </div>
    <minergie-configuration v-if="isMinergie"/>
    <egonline-configuration v-if="isEgonline"/>
    <div class="one-click-configuration">
      <div class="accounts area">
        <account-list />
      </div>
      <div class="meters area">
        <meter-list />
      </div>
      <div class="users area" v-if="showUsers">
        <user-list />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AccountList from '@/components/views/ecoOneClick/administration/AccountList.vue'
import EgonlineConfiguration from '@/components/views/ecoOneClick/administration/EgonlineConfiguration.vue'
import MeterList from '@/components/views/ecoOneClick/administration/MeterList.vue'
import MinergieConfiguration from '@/components/views/ecoOneClick/administration/MinergieConfiguration.vue'
import UserList from '@/components/views/ecoOneClick/administration/UserList.vue'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { OneClickTargetSystem } from '../../../../../../eco-domain-store-modules/src/consumption/models'
import { DropdownOption } from '../../../../../../shared-components/src'

const ConsumptionUi = namespace('consumptionUi')

@Component({
  components: {
    AccountList,
    EgonlineConfiguration,
    MeterList,
    MinergieConfiguration,
    UserList,
  },
})
export default class ConsumptionAccounts extends Vue {
  @ConsumptionUi.Getter public isInteracted: boolean
  @ConsumptionUi.Getter public adminProjects: DropdownOption[]
  @ConsumptionUi.Getter public selectedProjectId: string
  @ConsumptionUi.Getter public adminTargetSystems: DropdownOption[]
  @ConsumptionUi.Getter public adminSelectedTargetSystem: OneClickTargetSystem

  @ConsumptionUi.Action public initializeAdmin: () => Promise<void>
  @ConsumptionUi.Action public selectAdminProject: (projectId: string) => Promise<void>
  @ConsumptionUi.Action public selectAdminTargetSystem: (targetSystem: OneClickTargetSystem) => Promise<void>

  public async mounted() {
    await this.initializeAdmin()
  }

  public get isMinergie() {
    return this.adminSelectedTargetSystem === OneClickTargetSystem.Minergie
  }

  public get isEgonline() {
    return this.adminSelectedTargetSystem === OneClickTargetSystem.Egonline
  }

  public get showUsers() {
    return !this.isMinergie
  }
}
</script>
<style lang="scss" scoped>
header {
  padding: 30px 0 0 25px;
  height: 100px;
}
.consumption-accounts {  
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  .consumption-selections { 
    flex: 1;
    display: flex;
    flex-direction: row;
    margin: 10px 20px;
    .form-field {
      min-width: 300px;
    }
  }
  .one-click-configuration {
    display: flex;
    width: 100%;
    padding-left: 20px;
    .area {
      padding: 2px;
      margin: 0 10px;
      flex: 1;
    }
  }
}
.account-list {
  margin-bottom: 40px;
}
</style>
