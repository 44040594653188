<template>
  <div>
    <template-list
      :selection="selectedTemplateId"
      @selectedTemplate="selectTemplate"
    />
    <div class="next-button-wrapper">
      <base-text-button v-if="selectedTemplateId"
        :enabled="!isInteracted"
        @buttonClicked="onCreateCopy"
      >{{'releases.choose' | translate}}</base-text-button>
    </div>
    <apply-template-operation-data-dialog v-if="showApplyTemplateOperationDataDialog"
      @close="onCloseApplyTemplateOperationDataDialog"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import ApplyTemplateOperationDataDialog from './ApplyTemplateOperationDataDialog.vue'
import TemplateList from './TemplateList.vue'

import { ArchitectureType } from '../../../../../../../eco-domain-store-modules/src/common'
import { ReleaseModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'

const Wizard = namespace('wizard')
const App = namespace('app')
const ReleaseUi = namespace('releaseUi')
const PlcConfiguration = namespace('plcConfiguration')

@Component({
  components: {
    ApplyTemplateOperationDataDialog,
    TemplateList,
  },
})
export default class TemplateOptionContent extends Vue {
  @App.Getter public isEcocoachEmployee: boolean
  @App.Getter public userIdentifier: string
  @Wizard.Action public goToStep
  @Wizard.Getter public wizardSteps
  @Wizard.Mutation public closeReleaseDialog
  @ReleaseUi.Action public copyReleaseTemplate: (payload: { releaseId: string, applyTemplateOperationData: boolean }) => Promise<void>
  @ReleaseUi.Action public upgradeRelease: (useInTestDefinitions: boolean) => Promise<void>
  @ReleaseUi.Mutation public setInteracted
  @ReleaseUi.Getter public isInteracted
  @ReleaseUi.Getter public currentReleaseShouldBeUpgraded: boolean
  @ReleaseUi.Getter public currentNewRelease: ReleaseModel
  @ReleaseUi.Mutation public showReleaseUpgradeConfirmationDialog: () => void
  @PlcConfiguration.Getter public releaseTemplates: ReleaseModel[]

  public selectedTemplateId = ''
  public showApplyTemplateOperationDataDialog = false

  public selectTemplate(id: string) {
    this.selectedTemplateId = id
  }

  public async onCreateCopy() {
    if (this.releaseTemplates.find(t => t.id === this.selectedTemplateId)?.architectureType === ArchitectureType.ObjectOriented) {
      this.showApplyTemplateOperationDataDialog = true
    } else {
      await this.useTemplate(false)
    }
  }

  public async useTemplate(applyTemplateOperationData: boolean) {
    await this.copyReleaseTemplate({
      releaseId: this.selectedTemplateId,
      applyTemplateOperationData,
    })
    if (this.shouldShowReleaseUpgradeConfirmationDialog) {
      this.showReleaseUpgradeConfirmationDialog()
      return
    }
    if (!this.isEcocoachEmployee) {
      await this.upgradeReleaseSilentlyIfRequired()
    }
    this.closeReleaseDialog()
    const step = this.wizardSteps.find(s => s.name === 'defineRooms')
    this.goToStep({ step, router: this.$router })
  }

  public get shouldShowReleaseUpgradeConfirmationDialog() {
    return this.currentNewRelease.architectureType === ArchitectureType.ObjectOriented
      ? this.isEcocoachEmployee
      : (this.currentReleaseShouldBeUpgraded || this.isEcocoachEmployee)
  }

  public async upgradeReleaseSilentlyIfRequired() {
    if (!this.isEcocoachEmployee && this.currentNewRelease.architectureType === ArchitectureType.ObjectOriented) {
      await this.upgradeRelease(false)
    }
  }

  public async onCloseApplyTemplateOperationDataDialog(applyTemplateOperationData: boolean) {
    await this.useTemplate(applyTemplateOperationData)
  }
}
</script>
<style lang="scss" scoped>
.next-button-wrapper {
  padding-top: 5px;
  display: flex;
  justify-content: flex-end;
  height: 53px;
}
.v-btn {
  border: solid 1px white;
}
</style>