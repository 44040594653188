<template>
  <eco-labeled-control
    :context="context"
    :attributes="attributes"
  >
    <base-toggle-button
      :value="currentValue"
      :pending-value="pendingValue"
      :disabled="readonly"
      @buttonToggled="onToggle"
    />
  </eco-labeled-control>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import BaseToggleButton from '../../base/BaseToggleButton.vue'
import { ConsumptionProcessToggleControlAttributes, ControlContextV2 } from './models'
import EcoLabeledControl from './shared/EcoLabeledControl.vue'
  
@Component({
  components: {
    BaseToggleButton,
    EcoLabeledControl,
  },
})
export default class EcoConsumptionProcessToggleControl extends Vue {
  @Prop() public id: string
  @Prop() public context: ControlContextV2
  @Prop() public attributes: ConsumptionProcessToggleControlAttributes
  @Prop() public state: object
  @Prop() public pendingState: object
  @Prop() public readonly: boolean

  public onToggle(value: boolean) {
    this.context.executeCommand(this.id, {
      command: this.attributes.toggle.command,
      params: !value,
      pendingState: {
        [this.attributes.toggle.state]: !value,
      },
    })
  }

  public get currentValue() {
    return this.state[this.attributes.toggle.state]
  }

  public get pendingValue() {
    return this.pendingState[this.attributes.toggle.state]
  }
}
</script>

<style lang="scss" scoped>
</style>

<style lang="css">
</style>
