import { actions } from '@/store/modules/functionBlockUi/actions'
import { getters } from '@/store/modules/functionBlockUi/getters'
import { mutations } from '@/store/modules/functionBlockUi/mutations'
import { state } from '@/store/modules/functionBlockUi/state'
import { FunctionBlockUiAction, FunctionBlockUiGetter, FunctionBlockUiMutation, FunctionBlockUiState } from '@/store/modules/functionBlockUi/types'
import { RootState } from  '@/store/types'
import { Module } from 'vuex'

const namespaced: boolean = true
export const functionBlockUi: Module<FunctionBlockUiState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}

export const types = {
  action: FunctionBlockUiAction,
  mutation: FunctionBlockUiMutation,
  getter: FunctionBlockUiGetter,
}

export const domain = 'functionBlockUi'

export const vuexModule = {
  [domain]: functionBlockUi,
}