import { ActionTree } from 'vuex'
import api from './api'
import { PlcConnectionAction, PlcConnectionMutation, PlcConnectionState} from './types'

export const actions: ActionTree<PlcConnectionState, {}> = {
  async [PlcConnectionAction.loadPlcOperationStateHistory]({ commit }, plcId: string): Promise<void> {
    const data = await api.loadPlcOperationStateHistory(plcId)
    commit(PlcConnectionMutation.setPlcOperationStateHistory, data)
  },
}
