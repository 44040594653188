import { PlcOperationState, PlcType } from '../common'

export interface PlcModel {
  id: string
  projectId: string
  name: string
  projectName: string
  plcType: PlcType
  currentUserAuthorization: string
  serialNumber: string
  plcOperationState: PlcOperationState
  ecoCloudConnectorVersion: string
  threadHealthStates: ThreadHealthState[]
  mainThreadState: string
  openCriticalAlarmsCount: number
  openInformationAlarmsCount: number
  twinCatVersion: string
  totalMemoryInBytes: number
  totalDiskSpaceInBytes: number
  timeStamp: string
}

export interface PlcOperationStateChanged {
  plcId: string
  timeStamp: string
  plcOperationState: PlcOperationState
  ecoCloudConnectorVersion: string
  threadHealthStates: ThreadHealthState[]
  mainThreadState: string
  openCriticalAlarmsCount: number
  openInformationAlarmsCount: number
  serialNumber: string
  twinCatVersion: string
  totalMemoryInBytes: number
  totalDiskSpaceInBytes: number
}

export interface ThreadHealthState {
  threadName: string
  isStarted: boolean
  isInitialized: boolean
  totalNumberOfExecutionsWithException: number
  lastException: string
}

export interface MultiplePlcStateChanged {
  plcOperationStateList: PlcOperationStateChanged[]
}

export const plcStateHubId = 'plcStateHub'