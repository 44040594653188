<template>
  <modal-dialog :show="show"
    :header="`common.button.collectSupportData` | translate"
    :button1-text="`OK`"
    @button1Pressed="onClose"
  >
    <v-layout column ref="container">
      <div class="support-data-line" v-for="entry in entries" :key="entry.label">{{ entry.label }}: {{ entry.value }}</div>
      <ul>
        <li class="support-data-line" v-for="fb in supportData.functionBlockDefinitions" :key="fb.internalName">{{fb.internalName }}: v{{ fb.version }}</li>
      </ul>
      <base-text-button @buttonClicked="copyToClipboard">{{ 'common.button.copydetailstoclipboard' | translate }}</base-text-button>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import convert from 'convert-units'
import { roundToDecimals } from '../../../../../../../eco-domain-store-modules/src/utils'

import { PlcModel } from '@ecocoach/domain-store-modules/src/plc/models'
import { PlcSupportData } from '@/store/modules/selectPlcUi/models'

const Resource = namespace('resource')

@Component({
  components: {
  },
})
export default class PlcSupportDataDialog extends Vue {
  @Prop() public show: boolean
  @Prop() public plc: PlcModel
  @Prop() public supportData: PlcSupportData
  @Resource.Getter public dictionary

  @Emit('closed')
  public onClose() {
    return
  }

  public get entries() {
    return [
      { label: this.dictionary('select.plc.name') , value: this.plc?.name || 'n/a' },
      { label: this.dictionary('select.plc.project') , value: this.plc?.projectName || 'n/a' },
      { label: this.dictionary('select.plc.id') , value: this.plc?.id || 'n/a' },
      { label: this.dictionary('select.plc.projectId') , value: this.plc?.projectId || 'n/a' },
      { label: this.dictionary('select.plc.type') , value: this.plc?.plcType || 'n/a' },
      { label: this.dictionary('plcSystemInformation.serialNumber') , value: this.plc?.serialNumber || 'n/a' },
      { label: this.dictionary('plcSystemInformation.twinCatVersion') , value: this.plc?.twinCatVersion || 'n/a' },
      { label: this.dictionary('plcSystemInformation.totalMemory') , value: (this.plc?.totalMemoryInBytes > 0) ? this.formatMemory(this.plc?.totalMemoryInBytes): 'n/a' },
      { label: this.dictionary('plcSystemInformation.totalDiskspace') , value: (this.plc?.totalDiskSpaceInBytes > 0) ? this.formatMemory(this.plc?.totalDiskSpaceInBytes): 'n/a' },
      { label: this.dictionary('select.plc.version') , value: this.plc?.ecoCloudConnectorVersion || 'n/a' },
      { label: this.dictionary('select.plc.architecturetype') , value: this.supportData.architectureType || 'n/a' },
      { label: this.dictionary('select.plc.functionBlockDefinitions') , value: '' },
    ]
  }

  public formatMemory(bytes: number) {
    const best = convert(bytes).from('B').toBest()
    return `${roundToDecimals(best.val, 1)} ${best.unit}`
  }

  public copyToClipboard() {
    if (this.$copyText) {
      const plcLines = this.entries.map(m => `${m.label}: ${m.value}`)
      const fbLines = this.supportData.functionBlockDefinitions.map(fb => `- ${fb.internalName}: v${fb.version}`)
      this.$copyText([...plcLines, ...fbLines].join('\n'), this.$refs.container)
    }
  }
}
</script>
<style lang="scss" scoped>
.support-data-line {
  margin-bottom: 5px;
}
.v-btn {
  border: 1px solid white;
} 
</style>