<template>
  <div>
    <form-field text="Visibility condition">
      <checkbox-with-label
        :value="visibilityConditionSelected"
        text="Define visibility condition"
        :disabled="visibilityConditionDisabled"
        @checked="setControlDefinitionVisibilityCondition(!$event)"
      />
    </form-field>
    <form-field text="Condition value" v-if="visibilityState" class="visibility-condition-value">
      <autocomplete-select v-if="isBooleanVisibilityConditionEndpoint"
        :options="['', 'True', 'False']"
        :selected-value="booleanVisibilityConditionValue"
        :disabled="conditionSettingsDisabled"
        @selected="setControlDefinitionVisibilityConditionValue($event === 'True')"
      />
      <text-input v-else
        :value="numericVisibilityConditionValue"
        :disabled="conditionSettingsDisabled"
        @changed="setControlDefinitionVisibilityConditionValue(parseInt($event))"
      />
    </form-field>
    <state-detail v-if="visibilityState"
      :name="'visibility'"
      :endpoint="visibilityState.endpoint"
      :to-subscribe="visibilityState.toSubscribe"
      :poll-interval="visibilityState.pollInterval"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import StateDetail from './StateDetail.vue'

import {  ControlDefinitionViewModelV2, FunctionDefinitionViewModel } from '../../../store/modules/functionDefinitionEditorUi/models'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')

@Component({
  components: {
    StateDetail,
  },
})
export default class VisibilityConditionDetail extends Vue {
  @FunctionDefinitionEditorUi.Getter public functionDefinition: FunctionDefinitionViewModel
  @FunctionDefinitionEditorUi.Getter public controlDefinitionInput: ControlDefinitionViewModelV2
  @FunctionDefinitionEditorUi.Action public setControlDefinitionVisibilityCondition: (payload: boolean) => Promise<void>
  @FunctionDefinitionEditorUi.Action public setControlDefinitionVisibilityConditionValue: (payload: boolean | number) => Promise<void>

  public get visibilityConditionSelected() {
    return !!this.visibilityState
  }

  public get visibilityConditionDisabled() {
    return this.controlDefinitionInput.isExisting
  }

  public get visibilityState() {
    return this.controlDefinitionInput.states.visibility
  }

  public get isBooleanVisibilityConditionEndpoint() {
    const visibilityEndpoint = this.controlDefinitionInput.states.visibility?.endpoint
    return this.functionDefinition.endpoints.find(e => e.internalName === visibilityEndpoint)?.dataType === 'BOOL'
  }

  public get visibilityConditionSettingsDisabled() {
    return !this.controlDefinitionInput.states.visibility?.endpoint
  }

  public get booleanVisibilityConditionValue() {
    if (this.controlDefinitionInput.attributes.visibilityCondition?.value === true) {
      return 'True'
    } else if (this.controlDefinitionInput.attributes.visibilityCondition?.value === false) {
      return 'False'
    } else {
      return ''
    }
  }
  public get numericVisibilityConditionValue() {
    return this.controlDefinitionInput.attributes.visibilityCondition?.value ?? null
  }
}
</script>

<style lang="scss" scoped>
  .visibility-condition-value {
    margin-bottom: 10px;
  }
  .field .text-input {
    padding-left: 10px;
    padding-right: 10px;
  }
</style>

<style lang="css">
</style>