import { PlcOperationState, PlcType } from '@ecocoach/domain-store-modules/src/common'
import { ComponentFirmwareReleaseModel, ComponentFirmwareState, ComponentFirmwareType } from '@ecocoach/domain-store-modules/src/plcOperation/models'
import { compare, validate } from 'compare-versions'
import { PlcStatus } from './models'

export const toPlcStatus = (plcOperationState: PlcOperationState | undefined): PlcStatus => {
  switch (plcOperationState) {
  case PlcOperationState.OnlineReady:
    return PlcStatus.Online
  case PlcOperationState.OnlinePartiallyReady:
    return PlcStatus.PartiallyOnline
  case PlcOperationState.Offline:
  case PlcOperationState.Disconnected:
    return PlcStatus.Offline
  case PlcOperationState.Updating:
    return PlcStatus.Updating
  default:
    return PlcStatus.Unknown
  }
}

export const toComponentFirmwareType = (plcType: PlcType): ComponentFirmwareType | undefined => {
  switch (plcType) {
  case PlcType.ECO_BASIC_CONTROL_SMALL: return ComponentFirmwareType.EcoCloudConnectorSmall
  case PlcType.ECO_BASIC_CONTROL_MEDIUM: return ComponentFirmwareType.EcoCloudConnectorMedium
  case PlcType.ECO_BASIC_CONTROL_LARGE: return ComponentFirmwareType.EcoCloudConnectorLarge
  case PlcType.ECO_BASIC_CONTROL_LIGHT: return ComponentFirmwareType.EcoCloudConnectorLight
  default: return undefined
  }
}

export const activeEccRelease = (allReleases: ComponentFirmwareReleaseModel[], plcType: PlcType): ComponentFirmwareReleaseModel | undefined => {
  return allReleases.find(r => r.state === ComponentFirmwareState.Active && r.type === toComponentFirmwareType(plcType))
}

export const latestInTestEccRelease = (allReleases: ComponentFirmwareReleaseModel[], plcType: PlcType): ComponentFirmwareReleaseModel | undefined => {
  return allReleases.filter(r => 
    r.state === ComponentFirmwareState.InTest && 
    r.type === toComponentFirmwareType(plcType) &&
    validate(r.version))
    .sort((a, b) => compare(a.version, b.version, '>') ? -1 : 1)[0]
}