<template>
  <div class="autocomplete-select">
    <autocomplete-input :class="{ multiple: multiple }"
      :options="options"
      :option-value="optionValue"
      :option-label="optionLabel"
      :selected-value="selectedValue"
      :multiple="multiple"
      :disabled="disabled"
      :pending="pending"
      :placeholdertext="placeholdertext"
      @selected="emitSelected($event)"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import AutocompleteInput from './AutocompleteInput.vue'

export default Vue.extend({
  name: 'AutocompleteSelect',
  components: {
    AutocompleteInput,
  },
  props: {
    /**
     * array of strings or objects to be used by the autocomplete drop down menu
     */
    options: {
      type: Array,
      default: [],
    },
    /** 
     * the selected value, an array in case of multiple=true
     */
    selectedValue: {
      type: [String, Array],
      default: '',
    },
    /** 
     * optional, needed only in case options is an array of objects. 
     * the property name of the value string inside the options object
     */
    optionValue: {
      type: String,
      default: '',
    },
    /** 
     * optional, needed only in case options is an array of objects. 
     * property name of the displayed string inside the options object
     */
    optionLabel: {
      type: String,
      default: '',
    },
    /** 
     * multiselect
     */
    multiple: {
      type: Boolean,
      default: false,
    },
    /** 
     * disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * pending
     */
    pending: {
      type: Boolean,
      default: false,
    },
    /** 
     * optional placeholder text, displayed in case nothing is selected
     */
    placeholdertext: {
      type: String,
      default: '',
    },
  },
  methods: {
    emitSelected(value: string | string[]): void {
      /**
       * Selected event.
       *
       * @event selected
       * string or an array in case of multiple=true
       */
      this.$emit('selected', value)
    },
  },
})
</script>
<style lang="scss">
.autocomplete-select {
  .autocomplete-input {
    margin: 0px;
    padding: 10px;
    padding-top: 0px;
  }
  .autocomplete-input input {
    font-family: $font-family-primary;
    border-width: $default-border-width;
    border-style: solid;
    border-color: $fore-color-primary;
    padding: $default-padding !important;
    color: $fore-color-primary !important;
  }
  .autocomplete-input.multiple input {
    font-family: $font-family-primary;
    border-width: 0;
    border-style: solid;
    border-color: $fore-color-primary;
    padding: $default-padding !important;
    color: $fore-color-primary !important;
  }
  .autocomplete-input ::placeholder {
    color: $fore-color-disabled !important;
  }
  .v-icon {
    padding-top: 0px;
  }
}
</style>

<docs>
An autocomplete

Usage of the autocomplete select with string array options
```js
new Vue({
  data: function() {
    return {
      options: [ "foo", "bar", "baz" ],
      selectedValue: 'option1',
    }
  },
  methods: {
    onSelected(value) {
      console.log(value)
      selectedValue = value
    }
  },
  template: `<v-app style="height: 48px;background:transparent">
    <autocomplete-select 
      :options="options"
      :selected-value="selectedValue"
      @selected="onSelected"/>
  </v-app>`
})
```

Usage of the autocomplete input with object options
```js
new Vue({
  data: function() {
    return {
      options: [
        {id: "id1", label: "foo"},
        {id: "id2", label: "bar"}
      ],
      selectedValue: 'id1',
    }
  },
  methods: {
    onSelected(value) {
      console.log(value)
      this.selectedValue = value
    }
  },
  template: `<v-app style="height: 48px;background:transparent">
    <autocomplete-select 
      :options="options"
      :option-value="'id'"
      :option-label="'label'"
      :selected-value="selectedValue"
      @selected="onSelected"/>
  </v-app>`
})
```

Disabled
```jsx
<v-app style="height: 48px;background:transparent">
  <autocomplete-select
    :options="['option1']"
    selected-value="option1"
    disabled
  />
</v-app>
```

Placeholder text
```jsx
<v-app style="height: 48px;background:transparent">
  <autocomplete-select
    :options="['option1', 'option2']"
    placeholdertext="Select something please!"
  />
</v-app>
```
</docs>
