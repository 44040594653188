<template>
  <modal-dialog 
    :show="show"
    :header="dialogHeader"
    button1-text="OK"
    @button1Pressed="onOk"
  >
    <v-layout column>
      <v-flex>
        <form-field :text="displayNameHeader">
          <text-input
            :value="displayName"
            @changed="onDisplayNameChanged"
          />
        </form-field>
        <control-component-v-2 class="control-component"
          :context="context"
          :control="control"
          :potential-descendent-controls="potentialDescendentControls"
        />
      </v-flex>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

import ModalDialog from '../../../dialogs/ModalDialog.vue'
import FormField from '../../../patterns/FormField.vue'
import TextInput from '../../../patterns/TextInput.vue'
import ControlComponentV2 from '../../ControlComponentV2.vue'
import { ArrayContainerControlAttributes, ControlContextV2, ControlViewModelV2 } from '../models'

@Component({
  components: {
    ControlComponentV2,
    FormField,
    ModalDialog,
    TextInput,
  },
})
export default class EcoEditArrayEntryDialog extends Vue {
  @Prop() public show: boolean
  @Prop() public context: ControlContextV2
  @Prop() public control: ControlViewModelV2
  @Prop() public parentControl: ControlViewModelV2
  @Prop() public potentialDescendentControls: ControlViewModelV2[]

  public get parentControlAttributes() {
    return this.parentControl.attributes as ArrayContainerControlAttributes
  }

  public get dialogHeader(): string {
    return this.context.resolveStringResource(this.parentControlAttributes.childDialogHeaderLabel)
  }

  public get displayNameHeader(): string {
    return this.context.resolveStringResource(this.parentControlAttributes.childDialogDisplayNameInputLabel)
  }

  public get displayName(): string {
    return this.control && this.control.displayName || ''
  }

  public onDisplayNameChanged(value: string) {
    this.context.setDisplayName(this.control.id, value)
  }

  @Emit('closed') 
  public onOk () {
    return true
  }
}
</script>
<style lang="scss" scoped>
.field {
  height: 70px;
}
.field .text-input {
  padding-left: 10px;
}
.control-component {
  margin-left: 10px;
}
</style>