<template>
  <modal-dialog 
    :show="show"
    header="Results of uploads" 
    button1-text='Ok'
    @button1Pressed="onOk"
  >
    <v-layout column>
      <v-layout row align-start
        v-for="(item, index) in uploadResults"
        :key="index"
      >
        <v-flex xs2>
          <v-icon :color="color(item.success)" class="actionIcon">{{item.success ? 'check_circle' : 'error'}}</v-icon>
          <span>{{item.success ? 'Success' : 'Failure'}}</span>
        </v-flex>
        <v-flex xs2>
          <v-icon class="actionIcon">{{item.dryRun ? 'bug_report' : 'cloud_upload'}}</v-icon>
          <span>{{item.dryRun ? 'Dry-run' : 'Upload'}}</span>
        </v-flex>
        <v-flex xs4 class="text">
          <span>{{`${name(item.uploadInput)}, Version ${version(item.uploadInput)}`}}</span>
        </v-flex>
        <v-flex xs2>
          <upload-result-details
            button-text="Input"
            :content="item.uploadInput"
          />
        </v-flex>
        <v-flex xs2>
          <upload-result-details
            button-text="Output"
            :content="item.uploadOutput"
          />
        </v-flex>
        <v-flex xs2 v-if="canDownloadRelease" class="download-button">
          <v-tooltip bottom>  
            <v-btn slot="activator" icon small :disabled="!downloadEnabled(item)" @click.stop="downloadRelease(item.uploadOutput.testBuildResult.releaseId)">
              <v-icon>download</v-icon>
            </v-btn>
            <span>{{ 'common.button.download' | translate }}</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import UploadResultDetails from './UploadResultDetails.vue'

import { UploadNewDefinitionsInput, UploadNewDefinitionsInputV2 } from '../../../../../eco-domain-store-modules/src/systemConfiguration/models'
import { UploadDefinitionResult } from '../../../store/modules/functionDefinitionEditorUi/models'

import { FeatureSwitches } from '../../../../../eco-domain-store-modules/src/userSettings/models'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')
const Resource = namespace('resource')
const UserSettings = namespace('userSettings')

@Component({
  components: {
    UploadResultDetails,
  },
})
export default class UploadResultsDialog extends Vue {
  @Prop({type: Boolean}) public show: boolean
  @FunctionDefinitionEditorUi.Getter public uploadResults: UploadDefinitionResult[]
  @FunctionDefinitionEditorUi.Action public downloadRelease: (id: string) => Promise<void>
  @Resource.Getter public resolveStringResource: (key: string) => string
  @UserSettings.Getter public isFeatureOn: (featureId: string) => boolean

  @Emit('closed')
  public onOk() {
    return true
  }

  public name(input: UploadNewDefinitionsInput | UploadNewDefinitionsInputV2) {
    const definition = (input as any).functionDefinitions || (input as any).definitions
    return this.resolveStringResource(definition[0].nameResourceId)
  }

  public version(input: UploadNewDefinitionsInput | UploadNewDefinitionsInputV2) {
    const definition = (input as any).functionDefinitions || (input as any).definitions
    return definition[0].version
  }

  public color(value: boolean) {
    return value ? 'green' : 'red'
  }

  public get canDownloadRelease() {
    return this.isFeatureOn(FeatureSwitches.downloadProjectFilesFromUranus)
  }

  public downloadEnabled(result: UploadDefinitionResult) {
    return !!result.uploadOutput.testBuildResult?.releaseId
  }
}
</script>

<style lang="scss" type="text/scss" scoped>
.text {
  word-break: break-word;
}
.download-button {
  margin-top: -6px;
}
</style>