import { actions } from '@/store/modules/logToolUi/actions'
import { getters } from '@/store/modules/logToolUi/getters'
import { mutations } from '@/store/modules/logToolUi/mutations'
import { state } from '@/store/modules/logToolUi/state'
import { LogToolUiAction, LogToolUiGetter, LogToolUiMutation, LogToolUiState } from '@/store/modules/logToolUi/types'
import { RootState } from  '@/store/types'
import { Module } from 'vuex'

const namespaced: boolean = true
export const logToolUi: Module<LogToolUiState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}

export const types = {
  action: LogToolUiAction,
  mutation: LogToolUiMutation,
  getter: LogToolUiGetter,
}

export const domain = 'logToolUi'

export const vuexModule = {
  [domain]: logToolUi,
}