import { GetterTree } from 'vuex'
import { getPermissions } from './helpers'
import { AlarmDefinitionAuthorizationsModel, DeviceAuthorizationsModel, PartnerModel, Permission, PlcAuthorizationsModel, ProjectAuthorizationsModel } from './models'
import { AuthorizationGetter, AuthorizationState } from './types'

export const getters: GetterTree<AuthorizationState, {}> = {
  [AuthorizationGetter.partners]({ partners }): PartnerModel[] {
    return partners
  },
  [AuthorizationGetter.projectAuthorizations]({ projectAuthorizations }): ProjectAuthorizationsModel {
    return projectAuthorizations
  },
  [AuthorizationGetter.consumptionAuthorizations]({ consumptionAuthorizations }): ProjectAuthorizationsModel {
    return consumptionAuthorizations
  },
  [AuthorizationGetter.plcAuthorizations]({ plcAuthorizations }): PlcAuthorizationsModel[] {
    return plcAuthorizations
  },
  [AuthorizationGetter.deviceAuthorizations]({ deviceAuthorizations }): DeviceAuthorizationsModel[] {
    return deviceAuthorizations
  },
  [AuthorizationGetter.alarmDefinitionAuthorizations]({ alarmDefinitionAuthorizations }): AlarmDefinitionAuthorizationsModel[] {
    return alarmDefinitionAuthorizations
  },
  [AuthorizationGetter.projectPermissionsForUser]({ projectAuthorizations }) {
    return (id: string, userIdentifier: string): Permission[] => {
      return getPermissions([projectAuthorizations], id, userIdentifier)
    }
  },
  [AuthorizationGetter.consumptionPermissionsForUser]({ consumptionAuthorizations }) {
    return (id: string, userIdentifier: string): Permission[] => {
      return getPermissions([consumptionAuthorizations], id, userIdentifier)
    }
  },
  [AuthorizationGetter.plcPermissionsForUser]({ plcAuthorizations }) {
    return (id: string, userIdentifier: string): Permission[] => {
      return getPermissions(plcAuthorizations, id, userIdentifier)
    }
  },
  [AuthorizationGetter.devicePermissionsForUser]({ deviceAuthorizations }) {
    return (id: string, userIdentifier: string): Permission[] => {
      return getPermissions(deviceAuthorizations, id, userIdentifier)
    }
  },
  [AuthorizationGetter.alarmDefinitionPermissionsForUser]({ alarmDefinitionAuthorizations }) {
    return (id: string, userIdentifier: string): Permission[] => {
      return getPermissions(alarmDefinitionAuthorizations, id, userIdentifier)
    }
  },
  [AuthorizationGetter.appRegistrationNameForId]({ appRegistrationNameLookup }) {
    return (appId: string): string | null => appRegistrationNameLookup.get(appId) ?? null
  },
}
