import { ArrayWithLookup } from '../helpers/arrayWithLookup'
import { ResourceModel } from './models'

export interface ResourceState {
  appStrings: ArrayWithLookup<ResourceModel>
  fbStrings: ArrayWithLookup<ResourceModel>
  appIcons: ArrayWithLookup<ResourceModel>
  deviceIcons: ArrayWithLookup<ResourceModel>
  solutionHardwareIcons: ArrayWithLookup<ResourceModel>
  roomIcons: ArrayWithLookup<ResourceModel>
  sceneIcons: ArrayWithLookup<ResourceModel>
  chartIcons: ArrayWithLookup<ResourceModel>
  plcImages: ArrayWithLookup<ResourceModel>
  terminalImages: ArrayWithLookup<ResourceModel>
  moduleImages: ArrayWithLookup<ResourceModel>
  systemConfigurationStrings: ArrayWithLookup<ResourceModel>
}

export enum ResourceAction {
  loadResources = 'loadResources',
  createResource = 'createResource',
}

export enum ResourceMutation {
  upsertResources = 'upsertResources',
}

export enum ResourceGetter {
  resourcesOfCategory = 'resourcesOfCategory',
  resolveStringResource = 'resolveStringResource',
  resolveIconResource = 'resolveIconResource',
  resolveImageResource = 'resolveImageResource',

  // kept for backwards compatibility
  svgIcon = 'svgIcon',
  image = 'image',
  dictionary = 'dictionary',
}