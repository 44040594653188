import { AlarmDefinitionAuthorizationsModel, DeviceAuthorizationsModel, PartnerModel, PlcAuthorizationsModel, ProjectAuthorizationsModel, ProjectModel, RoomModel } from './models'

export enum AuthorizationAction {
  loadPartners = 'loadPartners',
  loadProjects = 'loadProjects',
  loadProject = 'loadProject',
  updateProjectAuthorization = 'updateProjectAuthorization',
  updateConsumptionAuthorization = 'updateConsumptionAuthorization',
  updatePlcAuthorization = 'updatePlcAuthorization',
  updateDeviceAuthorizations = 'updateDeviceAuthorizations',
  updateAlarmDefinitionAuthorizations = 'updateAlarmDefinitionAuthorizations',
  resolveAppRegistrationNames = 'resolveAppRegistrationNames',
}

export enum AuthorizationMutation {
  setPartners = 'setPartners',
  setProjects = 'setProjects',
  setConsumptionProjects = 'setConsumptionProjects',
  setProjectAuthorizations = 'setProjectAuthorizations',
  setConsumptionAuthorizations = 'setConsumptionAuthorizations',
  setPlcAuthorizations = 'setPlcAuthorizations',
  setDeviceAuthorizations = 'setDeviceAuthorizations',
  setAlarmDefinitionAuthorizations = 'setAlarmDefinitionAuthorizations',
  setRooms = 'setRooms',
  upsertProjectAuthorization = 'upsertProjectAuthorization',
  upsertConsumptionAuthorization = 'upsertConsumptionAuthorization',
  upsertPlcAuthorization = 'upsertPlcAuthorization',
  upsertDeviceAuthorizations = 'upsertDeviceAuthorizations',
  upsertAlarmDefinitionAuthorizations = 'upsertAlarmDefinitionAuthorizations',
  setAppRegistrationNames = 'setAppRegistrationNames',
}

export enum AuthorizationGetter {
  partners = 'partners',
  projectAuthorizations = 'projectAuthorizations',
  consumptionAuthorizations = 'consumptionAuthorizations',
  plcAuthorizations = 'plcAuthorizations',
  deviceAuthorizations = 'deviceAuthorizations',
  alarmDefinitionAuthorizations = 'alarmDefinitionAuthorizations',
  projectPermissionsForUser = 'projectPermissionsForUser',
  consumptionPermissionsForUser = 'consumptionPermissionsForUser',
  plcPermissionsForUser = 'plcPermissionsForUser',
  devicePermissionsForUser = 'devicePermissionsForUser',
  alarmDefinitionPermissionsForUser = 'alarmDefinitionPermissionsForUser',
  appRegistrationNameForId = 'appRegistrationNameForId',
}

export interface AuthorizationState {
  partners: PartnerModel[]
  projects: ProjectModel[]
  consumptionProjects: ProjectModel[]
  projectAuthorizations: ProjectAuthorizationsModel
  consumptionAuthorizations: ProjectAuthorizationsModel
  plcAuthorizations: PlcAuthorizationsModel[]
  deviceAuthorizations: DeviceAuthorizationsModel[]
  alarmDefinitionAuthorizations: AlarmDefinitionAuthorizationsModel[]
  rooms: RoomModel[]
  appRegistrationNameLookup: Map<string, string>
}
