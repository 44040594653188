<template>
  <modal-dialog v-if="dialog.show"
    :show="dialog.show"
    :header="`${dialog.isCreating ? 'alarmdefinition.dialog.create' : 'alarmdefinition.dialog.edit'}` | translate"
    :button1-text="`${dialog.isCreating ? 'common.button.create' : 'common.button.save'}` | translate"
    :button2-text="'common.button.cancel' | translate"
    :button1-disabled="isInteracted || !canConfirm"
    :button2-disabled="isInteracted"
    @button1Pressed="onConfirm"
    @button2Pressed="cancelAlarmDefinition"
  >
    <v-layout column>
      <v-flex>
        <form-field :text="'alarmdefinition.name' | translate">
          <text-input
            :value="dialog.name"
            :disabled="isInteracted"
            @input="setProperty('name', $event.trim())"
          />
        </form-field>

        <!-- ecocoach employees -->
        <v-tabs class="tabs" v-if="isEcocoachEmployee"> 
          <v-tab>{{'alarmdefinition.controlName' | translate}}</v-tab>
          <v-tab>{{'alarmdefinition.endpoint' | translate}}</v-tab>
          <v-tab-item>
            <div class="alarm-config">
              <form-field class="alarm-control" :text="'alarmdefinition.controlName' | translate">
                <autocomplete-select 
                  :options="dialog.controls"
                  option-value="id"
                  option-label="label"
                  :selected-value="dialog.option.controlId"
                  :disabled="isInteracted || !dialog.isCreating"
                  @selected="setProperty('option.controlId', $event)"
                />
              </form-field>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="alarm-config">
              <form-field class="alarm-endpoint" :text="'alarmdefinition.endpoint' | translate">
                <text-input
                  :value="dialog.option.qualifiedEndpointPath"
                  :disabled="isInteracted || !dialog.isCreating"
                  @input="setProperty('option.qualifiedEndpointPath', $event.trim())"
                />
              </form-field>
              <checkbox-with-label
                :value="dialog.option.isNumeric"
                :text="'alarmdefinition.numeric' | translate"
                :disabled="isInteracted || !dialog.option.qualifiedEndpointPath  || !dialog.isCreating"
                @checked="setProperty('option.isNumeric', !$event)"
              />
              <form-field :text="'alarmdefinition.unit' | translate">
                <text-input
                  :value="dialog.option.unit"
                  :disabled="isInteracted || !dialog.option.qualifiedEndpointPath || !dialog.option.isNumeric || !dialog.isCreating"
                  @input="setProperty('option.unit', $event.trim())"
                />
              </form-field>
            </div>
          </v-tab-item>
        </v-tabs>
        <!-- non ecocoach employees -->
        <form-field v-else class="alarm-control" :text="'alarmdefinition.controlName' | translate">
          <autocomplete-select 
            :options="dialog.controls"
            option-value="id"
            option-label="label"
            :selected-value="dialog.option.controlId"
            :disabled="isInteracted || !dialog.isCreating"
            @selected="setProperty('option.controlId', $event)"
          />
        </form-field>
        <!-- numeric controls -->
        <div v-if="dialog.option.isNumeric" class="condition-numeric">
          <form-field :text="'alarmdefinition.condition' | translate">
            <div class="condition-numeric-form">
              <autocomplete-select 
                :options="dialog.operators"
                option-value="id"
                option-label="label"
                :selected-value="dialog.operator"
                :disabled="isInteracted || !dialog.option.qualifiedEndpointPath"
                @selected="setProperty('operator', $event)"
              />
              <text-input type="number" pattern="\d*"
                :value="dialog.value"
                :disabled="isInteracted || !dialog.option.qualifiedEndpointPath"
                @input="setProperty('value', Number($event))"
              />
            </div>
          </form-field>
          <form-field :text="'alarmdefinition.hysteresis' | translate">
            <text-input type="number" pattern="\d*"
              :value="dialog.hysteresis"
              :disabled="isInteracted || !dialog.option.qualifiedEndpointPath"
              @input="setProperty('hysteresis', Number($event))"
            />
          </form-field>
        </div>
        <!-- boolean controls -->
        <div v-else>
          <form-field :text="'alarmdefinition.condition' | translate">
            <autocomplete-select 
              :options="dialog.values"
              option-value="id"
              option-label="label"
              :selected-value="dialog.value"
              :disabled="isInteracted || !dialog.option.qualifiedEndpointPath"
              @selected="setProperty('value', $event)"
            />
          </form-field>
        </div>
        <form-field :text="'alarmdefinition.message' | translate">
          <text-input
            :value="dialog.message"
            :disabled="isInteracted"
            @input="setProperty('message', $event.trim())"
          />
        </form-field>
        <form-field :text="'alarmdefinition.level' | translate">
          <autocomplete-select
            :options="levelOptions"
            option-value="id"
            option-label="label"
            :selected-value="dialog.level"
            :disabled="isInteracted"
            @selected="setProperty('level', $event)"
          />
        </form-field>
        <checkbox-with-label v-if="dialog.isCreating"
          :value="selfAuthorize"
          :text="'alarmdefinition.selfauthorize' | translate"
          :disabled="isInteracted"
          @checked="selfAuthorize = !$event"
        />
      </v-flex>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { DropdownOption } from '../../../../../../../shared-components/src'

import { AlarmDefinitionEditDialogViewModel } from '../../../../../store/modules/manageAlarmsUi/models'

const App = namespace('app')
const ManageAlarmsUi = namespace('manageAlarmsUi')

@Component({
  components: {
  },
})
export default class EditAlarmDefinitionDialog extends Vue {
  @App.Getter public isEcocoachEmployee: boolean
  @ManageAlarmsUi.Getter public isInteracted: boolean
  @ManageAlarmsUi.Getter public levelOptions: DropdownOption[]
  @ManageAlarmsUi.Getter public dialog: AlarmDefinitionEditDialogViewModel
  @ManageAlarmsUi.Action public updateAlarmDefinitionProperty: (payload: { property: string, value: any }) => Promise<void>
  @ManageAlarmsUi.Action public cancelAlarmDefinition: () => Promise<void>
  @ManageAlarmsUi.Action public createAlarmDefinition: (selfAuthorize: boolean) => Promise<void>
  @ManageAlarmsUi.Action public updateAlarmDefinition: () => Promise<void>

  public selfAuthorize = true

  public setProperty(property: string, value: any) {
    this.updateAlarmDefinitionProperty({
      property,
      value,
    })
  }

  public get canConfirm() {
    return !!this.dialog.name && !!this.dialog.option.qualifiedEndpointPath && !!this.dialog.operator && !!this.dialog.message
  }

  public onConfirm() {
    if (this.dialog.isCreating) {
      this.createAlarmDefinition(this.selfAuthorize)
    } else {
      this.updateAlarmDefinition()
    }
  }
}
</script>
<style lang="scss" scoped>
.condition-numeric {
  display: flex;
  .condition-numeric-form {
    display: flex;
    .text-input {
      height: 32px;
    }
  }
}
.field {
  height: 70px;
  .text-input {
    padding-left: 10px;
    padding-right: 10px;
  }
  .label {
    padding-left: 10px;
  }
}
.checkboxWithLabel {
  margin-left: -5px;
}
</style>
<style lang="scss">
.theme--dark.v-tabs__bar {
  background-color: transparent !important;
  margin-bottom: 10px;
}
</style>