import { Module } from 'vuex'
import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'
import { AlarmDefinitionAction, AlarmDefinitionMutation, AlarmDefinitionState } from './types'

const namespaced: boolean = true
export const alarmDefinition: Module<AlarmDefinitionState,any> = {
  namespaced,
  state,
  actions,
  mutations,
}

export const types = {
  action:  AlarmDefinitionAction,
  mutation: AlarmDefinitionMutation,
}

export const domain = 'alarmDefinition'

export const vuexModule = {
  [domain]: alarmDefinition,
}