import { RootState } from '@/store/types'
import { ProjectModel as AuthProjectModel } from '@ecocoach/domain-store-modules/src/authorization/models'
import { AuthorizationMutation } from '@ecocoach/domain-store-modules/src/authorization/types'
import { PlcType } from '@ecocoach/domain-store-modules/src/common'
import { ProjectModel as ConsumptionProjectModel } from '@ecocoach/domain-store-modules/src/consumption/models'
import { ConsumptionMutation } from '@ecocoach/domain-store-modules/src/consumption/types'
import { PlcModel as SetupToolPlcModel } from '@ecocoach/domain-store-modules/src/plc/models'
import { PlcMutation } from '@ecocoach/domain-store-modules/src/plc/types'
import { PlcConfigurationAction } from '@ecocoach/domain-store-modules/src/plcConfiguration/types'
import { PlcModel, ProjectModel } from '@ecocoach/domain-store-modules/src/project/models'
import { ProjectAction, ProjectMutation } from '@ecocoach/domain-store-modules/src/project/types'
import { ActionTree } from 'vuex'
import { ProjectManagementUiAction, ProjectManagementUiMutation, ProjectManagementUiState } from './types'

export const actions: ActionTree<ProjectManagementUiState, RootState> = {
  async [ProjectManagementUiAction.createProject] ({ commit, dispatch, rootState }, payload: { name: string }): Promise<void> {
    try {
      commit(ProjectManagementUiMutation.setInteracted, true)
      const input = {
        partnerId: rootState.authorization.partners[0].id,
        project: { name: payload.name } as ProjectModel,
      }
      const projectsBefore = [...rootState.project.projects]
      await dispatch(`project/${ProjectAction.createProject}`, input, { root: true })
      const newProject = rootState.project.projects.filter(p => !projectsBefore.includes(p))[0]
      // update authorization store to make auth tool visible
      const newAuthProject = {
        id: newProject?.id,
        name: newProject?.name,
      } as AuthProjectModel
      commit(`authorization/${AuthorizationMutation.setProjects}`, [...rootState.authorization.projects, newAuthProject], { root: true })
      // update consumption store to make one click visible
      const newConsumptionProject = {
        id: newProject?.id,
        name: newProject?.name,
        currentUserAuthorization: 'Authorize',
        targetSystems: [],
      } as ConsumptionProjectModel
      commit(`consumption/${ConsumptionMutation.setProjects}`, [...rootState.consumption.projects, newConsumptionProject], { root: true })
    } finally {
      commit(ProjectManagementUiMutation.setInteracted, false)
    }
  },
  async [ProjectManagementUiAction.updateProject] ({ commit, dispatch }, payload: ProjectModel): Promise<void> {
    try {
      commit(ProjectManagementUiMutation.setInteracted, true)
      dispatch(`project/${ProjectAction.updateProject}`, payload, { root: true })
    } finally {
      commit(ProjectManagementUiMutation.setInteracted, false)
    }
  },
  async [ProjectManagementUiAction.deleteProject] ({ commit, dispatch }, payload: string): Promise<void> {
    try {
      commit(ProjectManagementUiMutation.setInteracted, true)
      dispatch(`project/${ProjectAction.deleteProject}`, payload, { root: true })
    } finally {
      commit(ProjectManagementUiMutation.setInteracted, false)
    }
  },
  async [ProjectManagementUiAction.createPlc] ({ commit, dispatch, rootState }, payload: PlcModel): Promise<void> {
    try {
      commit(ProjectManagementUiMutation.setInteracted, true)
      const project = rootState.project.projects.find(p => p.id === payload.projectId)
      const plcsBefore = [...project?.plcs ?? []]
      await dispatch(`project/${ProjectAction.createPlc}`, payload, { root: true })
      const newPlc = (project?.plcs ?? []).filter(p => !plcsBefore.includes(p))[0]
      // update plc store to make setup tool visible
      const newSetupToolPlc = {
        id: newPlc?.id,
        projectId: newPlc?.projectId,
        name: newPlc?.name,
        projectName: project?.name ?? '',
        plcType: newPlc?.plcType,
        currentUserAuthorization: 'FullAccess',
      } as SetupToolPlcModel
      commit(`plc/${PlcMutation.setPlcs}`, [...rootState.plc.plcs, newSetupToolPlc], { root: true })
    } finally {
      commit(ProjectManagementUiMutation.setInteracted, false)
    }
  },
  async [ProjectManagementUiAction.updatePlc] ({ commit, dispatch }, payload: PlcModel): Promise<void> {
    try {
      commit(ProjectManagementUiMutation.setInteracted, true)
      dispatch(`project/${ProjectAction.updatePlc}`, payload, { root: true })
    } finally {
      commit(ProjectManagementUiMutation.setInteracted, false)
    }
  },
  async [ProjectManagementUiAction.deletePlc] ({ commit, dispatch }, payload: string): Promise<void> {
    try {
      commit(ProjectManagementUiMutation.setInteracted, true)
      dispatch(`project/${ProjectAction.deletePlc}`, payload, { root: true })
    } finally {
      commit(ProjectManagementUiMutation.setInteracted, false)
    }
  },
  async [ProjectManagementUiAction.setPlcType] ({ commit, dispatch, rootState }, payload: { plcId: string, plcType: PlcType }): Promise<void> {
    try {
      commit(ProjectManagementUiMutation.setInteracted, true)
      // update via plcConfiguration store/api
      dispatch(`plcConfiguration/${PlcConfigurationAction.setPlcType}`, payload, { root: true })
      // update plcType also in project store
      const existingPlc = rootState.project.projects.find(p => p.plcs.some(plc => plc.id === payload.plcId))?.plcs
        .find(plc => plc.id === payload.plcId)
      const input = {
        ...existingPlc,
        plcType: payload.plcType,
      } as PlcModel
      commit(`project/${ProjectMutation.upsertPlc}`, input, { root: true })
    } finally {
      commit(ProjectManagementUiMutation.setInteracted, false)
    }
  },
}
