import { StatusCode } from '../services/api.service'

const nonDetailExceptionPayloadProperties = [
  'exceptionType',
  'exceptionId',
  'message',
]

const getPayloadPropertyValue = (errorContext: { statusCode?: number, payload?: any }, property: string): string | undefined => {
  return errorContext.payload && errorContext.payload[property]
}

export interface ApiErrorConverterResult {
  message: string
  details: string[]
}

export interface ApiErrorConverterOptions {
  resolveStringResource: (resourceKey: string) => string
}

export class ApiErrorConverter {
  private resolveStringResource: (resourceKey: string) => string

  constructor(options: ApiErrorConverterOptions) {
    this.resolveStringResource = options.resolveStringResource
  }

  public convert = (errorContext: { statusCode?: number, payload?: any }): ApiErrorConverterResult => {
    const exceptionType = getPayloadPropertyValue(errorContext, 'exceptionType')

    // for legacy exceptions or unknown errors use the status code
    if (!exceptionType) {
      return this.createResultFromStatusCode(errorContext)
    }

    // use the exception type to lookup an error message, as fallback use the status code
    return this.createResultFromExceptionType(errorContext, exceptionType)
  }

  private createResultFromStatusCode = (errorContext): ApiErrorConverterResult => {
    const statusCode = errorContext.statusCode
    const fallbackMessage = getPayloadPropertyValue(errorContext, 'message')
    const message = this.resolveStatusCodeMessage(errorContext.statusCode)
    const details = (statusCode === StatusCode.BadRequest && fallbackMessage) ? [fallbackMessage] : []
    return { message, details }
  }

  private createResultFromExceptionType = (errorContext, exceptionType: string): ApiErrorConverterResult => {
    const payloadDetailProperties = (errorContext.payload && Object.keys(errorContext.payload) || [])
      .filter(prop => !nonDetailExceptionPayloadProperties.includes(prop))
    const message = this.resolveExceptionTypeMessage(exceptionType, errorContext.statusCode)
    const details = this.createExceptionDetails(errorContext, exceptionType, payloadDetailProperties)
    return { message, details }
  }

  private resolveStatusCodeMessage = (statusCode: number): string => {
    if (!statusCode) {
      return this.resolveStringResource('error.network')
    }
    switch (statusCode) {
    case StatusCode.BadRequest: return this.resolveStringResource('error.bad.request')
    case StatusCode.Forbidden: return this.resolveStringResource('error.forbidden')
    case StatusCode.NotFound: return this.resolveStringResource('error.not.found')
    case StatusCode.Conflict: return this.resolveStringResource('error.conflict')
    case StatusCode.InternalServerError: return this.resolveStringResource('error.internal.server')
    default: return this.resolveStringResource('error.unexpected')
    }
  }

  private resolveExceptionTypeMessage = (exceptionType: string, statusCode: number): string => {
    const resourceStringForExceptionType = `error.${exceptionType.toLowerCase()}`
    const resolvedResource = this.resolveStringResource(resourceStringForExceptionType)
    return resolvedResource !== resourceStringForExceptionType ? resolvedResource : this.resolveStatusCodeMessage(statusCode)
  }

  private createExceptionDetails = (errorContext, exceptionType: string, properties: string[]): string[] => {
    const details: string[] = []
    properties.forEach(property => {
      const resourceStringForProperty = `error.${exceptionType.toLowerCase()}.${property.toLowerCase()}`
      const resolvedResource = this.resolveStringResource(resourceStringForProperty)
      const label = resolvedResource !== resourceStringForProperty ? resolvedResource : undefined
      const value = getPayloadPropertyValue(errorContext, property)
      if (label && value !== undefined) {
        details.push(`${label}: ${value}`)
      }
    })
    return details
  }
}
