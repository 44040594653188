<template>
  <v-flex align-center>
    <device-control v-for="control in controls"
      :key="control.id"
      :control="control"
    />
    <v-flex class="notAvailable" v-if="controls.length === 0">{{ 'parameter.missed' | translate}} </v-flex>
  </v-flex>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import DeviceControl from './DeviceControl.vue'

import { ControlModelV2 } from '../../../../../../../eco-domain-store-modules/src/plcOperation/models'

@Component({
  components: {
    DeviceControl,
  },
})
export default class DeviceControls extends Vue {
  @Prop({type: Array, required: true }) public controls: ControlModelV2[]
}
</script>
<style lang="scss" scoped>
</style>