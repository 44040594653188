<template>
  <modal-dialog v-if="show"
    :show="show"
    :header="isCreate ? 'Create control definition' : 'Edit control definition'"
    :button1-text="isCreate ? 'Create' : 'Save'"
    button2-text="Cancel"
    @button1Pressed="onConfirmed"
    @button2Pressed="onCancelled"
    width=1200
  >
    <v-layout row wrap>
      <!-- left part: common attributes  -->
      <v-flex xs3>
        <form-field text="ID" v-if="!isCreate">
          <div class="label">
            {{controlDefinitionInput.id}}
          </div>
        </form-field>
        <form-field text="Type">
          <autocomplete-select 
            :options="controlDefinitionTypeOptionsV2"
            option-value="id"
            option-label="label"
            :disabled="controlDefinitionInput.isExisting"
            :selected-value="controlDefinitionInput.type"
            @selected="initializeControlDefinitionTypeV2($event)"
          />
        </form-field>
        <form-field text="Appearance">
          <autocomplete-select 
            :options="controlDefinitionAppearanceOptions(controlDefinitionInput.type)"
            option-value="id"
            option-label="label"
            :selected-value="controlDefinitionInput.attributes.appearance"
            @selected="setAttribute('appearance', $event)"
          />
        </form-field>
        <div class="control-preview-header">Preview (readonly)</div>
        <div class="control previewbox">
          <preview-control :control-definition="controlDefinitionInput" :readonly="true" />
        </div>
        <form-field text="Label">
          <string-resource-select
            :options="filteredSystemConfigurationResources('ControlDefinitions_', '_Name', false)"
            :selected-value="controlDefinitionInput.attributes.label"
            category="SystemConfiguration"
            prefix="ControlDefinitions_"
            postfix="_Name"
            default-value=""
            @selected="onLabelResourceIdSelected"
            @created="onLabelResourceCreated"
          />
        </form-field>
        <form-field text="Tooltip (optional)">
          <string-resource-select
            :options="filteredSystemConfigurationResources('ControlDefinitions_', '_Description', true)"
            :selected-value="controlDefinitionInput.attributes.tooltip"
            category="SystemConfiguration"
            prefix="ControlDefinitions_"
            postfix="_Description"
            default-value=""
            @selected="setAttribute('tooltip', $event)"
            @created="onTooltipResourceCreated"
          />
        </form-field>
        <form-field text="ecoMobileApp display level">
          <autocomplete-select 
            :options="controlDefinitionAppDisplayLevelOptionsV2"
            option-value="id"
            option-label="label"
            :selected-value="controlDefinitionInput.attributes.appDisplayLevel"
            @selected="setAttribute('appDisplayLevel', $event)"
          />
        </form-field>
        <form-field text="ecoSetupTool parametrization category">
          <autocomplete-select 
            :options="controlDefinitionConfigurationToolOptions"
            :selected-value="controlDefinitionInput.attributes.configurationToolCategory"
            @selected="setAttribute('configurationToolCategory', $event)"
          />
        </form-field>
        <form-field text="Ecocoach only">
          <checkbox-with-label
            :value="controlDefinitionInput.attributes.ecocoachOnly"
            text="Visible only for ecocoach employees"
            @checked="setAttribute('ecocoachOnly', !$event)"
          />
        </form-field>
        <form-field text="Obsolete">
          <checkbox-with-label
            :value="controlDefinitionInput.obsolete"
            text="Set control definition obsolete"
            :disabled="!controlDefinitionInput.isExisting"
            @checked="setControlDefinitionObsolete(!$event)"
          />
        </form-field>
        <form-field text="Tags" v-if="sourceLanguage === 'CSharp'">
          <autocomplete-select 
            :multiple="true"
            :options="controlDefinitionControlTagOptions"
            option-value="id"
            option-label="label"
            :selected-value="controlDefinitionInput.attributes.tags"
            placeholdertext="Select tags"
            @selected="setAttribute('tags', $event)"
          />
        </form-field>        
      </v-flex>
      <!-- center part: type specific attributes  -->
      <v-flex xs3>
        <form-field text="True icon (material icon name)" v-if="showAttribute('iconTrue')">
          <material-icon-input
            :value="controlDefinitionInput.attributes.iconTrue"
            @changed="setAttribute('iconTrue', $event)"
          />
        </form-field>
        <form-field text="False icon (material icon name)" v-if="showAttribute('iconFalse')">
          <material-icon-input
            :value="controlDefinitionInput.attributes.iconFalse"
            @changed="setAttribute('iconFalse', $event)"
          />
        </form-field>
        <form-field text="Unit" v-if="showAttribute('unit')">
          <autocomplete-select 
            :options="controlDefinitionUnitOptions"
            :selected-value="controlDefinitionInput.attributes.unit"
            @selected="setAttribute('unit', $event)"
          />
        </form-field>        
        <form-field text="Decimals" v-if="showAttribute('decimals')">
          <text-input
            :value="controlDefinitionInput.attributes.decimals"
            @changed="setAttribute('decimals', $event)"
          />
        </form-field>
        <form-field text="Invalid value" v-if="showAttribute('invalidValue')">
          <text-input
            :value="controlDefinitionInput.attributes.invalidValue"
            @changed="setAttribute('invalidValue', $event)"
          />
        </form-field>
        <form-field text="Invalid display value" v-if="showAttribute('invalidDisplayValue')">
          <text-input
            :value="controlDefinitionInput.attributes.invalidDisplayValue"
            @changed="setAttribute('invalidDisplayValue', $event)"
          />
        </form-field>
        <form-field text="Minimum value" v-if="showAttribute('minValue')">
          <text-input
            :value="controlDefinitionInput.attributes.minValue"
            @changed="setAttribute('minValue', $event)"
          />
        </form-field>
        <form-field text="Maximum value" v-if="showAttribute('maxValue')">
          <text-input
            :value="controlDefinitionInput.attributes.maxValue"
            @changed="setAttribute('maxValue', $event)"
          />
        </form-field>
        <form-field text="Interval" v-if="showAttribute('interval')">
          <text-input
            :value="controlDefinitionInput.attributes.interval"
            @changed="setAttribute('interval', $event)"
          />
        </form-field>
        <form-field text="Minimum value label (optional)" v-if="showAttribute('minValueLabel')">
          <string-resource-select
            :options="filteredSystemConfigurationResources('ControlDefinitions_', '_MinimumValueLabel', true)"
            :selected-value="controlDefinitionInput.attributes.minValueLabel"
            category="SystemConfiguration"
            prefix="ControlDefinitions_"
            postfix="_MinimumValueLabel"
            default-value=""
            @selected="setAttribute('minValueLabel', $event)"
            @created="onMinValueLabelResourceCreated"
          />
        </form-field>
        <form-field text="Maximum value label (optional)" v-if="showAttribute('maxValueLabel')">
          <string-resource-select
            :options="filteredSystemConfigurationResources('ControlDefinitions_', '_MaximumValueLabel', true)"
            :selected-value="controlDefinitionInput.attributes.maxValueLabel"
            category="SystemConfiguration"
            prefix="ControlDefinitions_"
            postfix="_MaximumValueLabel"
            default-value=""
            @selected="setAttribute('maxValueLabel', $event)"
            @created="onMaxValueLabelResourceCreated"
          />
        </form-field>
        <form-field text="Color gradient" v-if="showAttribute('gradient')">
          <color-select 
            :options="gradients"
            option-value="id"
            option-label="id"
            option-color="primaryColor"
            :selected-value="controlDefinitionInput.attributes.gradient"
            @selected="setAttribute('gradient', $event)"
          />
        </form-field>
        <form-field text="Button text" v-if="showAttribute('action')">
          <string-resource-select
            :options="filteredSystemConfigurationResources('ControlDefinitions_', '_Name', false)"
            :selected-value="controlDefinitionInput.attributes.action.text"
            category="SystemConfiguration"
            prefix="ControlDefinitions_"
            postfix="_Name"
            default-value=""
            @selected="setAttribute('action', { ...controlDefinitionInput.attributes.action, text: $event })"
            @created="onActionTextResourceCreated"
          />
        </form-field>
        <form-field text="Confirmation text (optional)" v-if="showAttribute('confirmationText')">
          <string-resource-select
            :options="filteredSystemConfigurationResources('ControlDefinitions_', '_ConfirmationText', true)"
            :selected-value="controlDefinitionInput.attributes.confirmationText"
            category="SystemConfiguration"
            prefix="ControlDefinitions_"
            postfix="_ConfirmationText"
            default-value=""
            @selected="setAttribute('confirmationText', $event)"
            @created="onConfirmationTextResourceCreated"
          />
        </form-field>
        <form-field text="First icon (material icon name)" v-if="showAttribute('actions')">
          <material-icon-input
            :value="controlDefinitionInput.attributes.actions[0].icon"
            @changed="setAttribute('actions', [{...controlDefinitionInput.attributes.actions[0], icon: $event }, controlDefinitionInput.attributes.actions[1] ])"
          />
        </form-field>
        <form-field text="Second icon (material icon name)" v-if="showAttribute('actions')">
          <material-icon-input
            :value="controlDefinitionInput.attributes.actions[1].icon"
            @changed="setAttribute('actions', [ controlDefinitionInput.attributes.actions[0], {...controlDefinitionInput.attributes.actions[1], icon: $event }])"
          />
        </form-field>
        <form-field text="First icon (material icon name)" v-if="showAttribute('toggles')">
          <material-icon-input
            :value="controlDefinitionInput.attributes.toggles[0].icon"
            @changed="setAttribute('toggles', [{...controlDefinitionInput.attributes.toggles[0], icon: $event }, controlDefinitionInput.attributes.toggles[1] ])"
          />
        </form-field>
        <form-field text="Second icon (material icon name) " v-if="showAttribute('toggles')">
          <material-icon-input
            :value="controlDefinitionInput.attributes.toggles[1].icon"
            @changed="setAttribute('toggles', [ controlDefinitionInput.attributes.toggles[0], {...controlDefinitionInput.attributes.toggles[1], icon: $event }])"
          />
        </form-field>
        <form-field text="Dropdown options" v-if="showAttribute('options') && controlDefinitionInput.type !== 'Links'">
          <icon-button @buttonClicked="showAddOptionDialog = true"
            type="materialIcon" 
            materialIconName="add" 
            width=20 
            height=20
            class="addbutton"
          />
          <div v-if="controlDefinitionInput.attributes.options.length == 0" class="noitems">No options</div>
          <ul>
            <li v-for="option in controlDefinitionInput.attributes.options" :key="option.id">
              <div class="itemslist">
                <div class="truncate">{{`${option.id}: ${resolveStringResource(option.label)}`}}</div>
                <icon-button
                  @buttonClicked="deleteOption(option)"
                  type="materialIcon" 
                  materialIconName="delete" 
                  width=20 
                  height=20
                  class="deletebutton"
                />
              </div>
            </li>
          </ul>
        </form-field>
        <modal-dialog :show="showAddOptionDialog"
          header="Add option"
          button1-text="Add"
          button2-text="Cancel"
          @button1Pressed="addOption"
          @button2Pressed="showAddOptionDialog = false"
        >
          <form-field text="Option value">
            <text-input
              :value="addOptionId"
              @changed="addOptionId = $event"
            />
          </form-field>
          <form-field text="Option label">
            <string-resource-select
              :options="filteredSystemConfigurationResources('DropdownValueDefinitions_', '_Name', false)"
              :selected-value="addOptionLabel"
              category="SystemConfiguration"
              prefix="DropdownValueDefinitions_"
              postfix="_Name"
              default-value=""
              @selected="addOptionLabel = $event"
              @created="onOptionLabelResourceCreated"
            />
          </form-field>
        </modal-dialog>
        <form-field text="Target instance" v-if="showAttribute('sourceInterfaceId')">
          <autocomplete-select 
            :options="controlDefinitionTargetInstanceOptions"
            option-value="id"
            option-label="label"
            :selected-value="controlDefinitionSelectedTargetInstanceId"
            :disabled="controlDefinitionInput.isExisting"
            @selected="setControlDefinitionTargetInstance"
          />
        </form-field>
        <form-field text="Source interface" v-if="showAttribute('sourceInterfaceId')">
          <autocomplete-select 
            :options="controlDefinitionSourceInterfaceOptions"
            option-value="id"
            option-label="label"
            :selected-value="controlDefinitionInput.attributes.sourceInterfaceId"
            :disabled="controlDefinitionInput.isExisting"
            @selected="setAttribute('sourceInterfaceId', $event)"
          />
        </form-field>
        <form-field text="Link type (EMS topology only)" v-if="showAttribute('sourceInterfaceId')">
          <autocomplete-select 
            :options="controlDefinitionLinkTypeOptions"
            option-value="id"
            option-label="label"
            :selected-value="controlDefinitionInput.attributes.linkType"
            :disabled="controlDefinitionInput.isExisting && !!controlDefinitionInput.attributes.linkType"
            @selected="setAttribute('linkType', $event)"
          />
        </form-field>
        <form-field text="Messages Enum" v-if="showAttribute('messagesEnum')">
          <autocomplete-select 
            :options="controlDefinitionMessagesEnumOptions"
            option-value="id"
            option-label="label"
            :selected-value="controlDefinitionInput.attributes.messagesEnum"
            @selected="setAttribute('messagesEnum', $event)"
          />
        </form-field>
        <form-field text="Max string length" v-if="showAttribute('maxLength')">
          <text-input
            :value="controlDefinitionInput.attributes.maxLength"
            @changed="setAttribute('maxLength', $event)"
          />
        </form-field>
        <form-field text="Max number of selections" v-if="showAttribute('maxNumSelections')">
          <text-input
            :value="controlDefinitionInput.attributes.maxNumSelections"
            :disabled="controlDefinitionInput.isExisting"
            @changed="setAttribute('maxNumSelections', $event)"
          />
        </form-field>
        <form-field text="Time type" v-if="showAttribute('timeType')">
          <autocomplete-select 
            :options="controlDefinitionTimeTypeOptions"
            option-value="id"
            option-label="label"
            :selected-value="controlDefinitionInput.attributes.timeType"
            @selected="setAttribute('timeType', $event)"
          />
        </form-field>
        <form-field text="Self consumption capacity label" v-if="showAttribute('selfConsumptionCapacity')">
          <string-resource-select
            :options="filteredSystemConfigurationResources('ControlDefinitions_', '_StateOfChargeLabel', false)"
            :selected-value="controlDefinitionInput.attributes.selfConsumptionCapacity.label"
            category="SystemConfiguration"
            prefix="ControlDefinitions_"
            postfix="_StateOfChargeLabel"
            default-value=""
            @selected="setAttribute('selfConsumptionCapacity', {...controlDefinitionInput.attributes.selfConsumptionCapacity, label: $event })"
            @created="onStateOfChargeLabelResourceCreated($event, 'selfConsumptionCapacity')"
          />
        </form-field>
        <form-field text="Peak shaving capacity label" v-if="showAttribute('peakShavingCapacity')">
          <string-resource-select
            :options="filteredSystemConfigurationResources('ControlDefinitions_', '_StateOfChargeLabel', false)"
            :selected-value="controlDefinitionInput.attributes.peakShavingCapacity.label"
            category="SystemConfiguration"
            prefix="ControlDefinitions_"
            postfix="_StateOfChargeLabel"
            default-value=""
            @selected="setAttribute('peakShavingCapacity', {...controlDefinitionInput.attributes.peakShavingCapacity, label: $event })"
            @created="onStateOfChargeLabelResourceCreated($event, 'peakShavingCapacity')"
          />
        </form-field>
        <form-field text="Load management capacity label" v-if="showAttribute('loadManagementCapacity')">
          <string-resource-select
            :options="filteredSystemConfigurationResources('ControlDefinitions_', '_StateOfChargeLabel', false)"
            :selected-value="controlDefinitionInput.attributes.loadManagementCapacity.label"
            category="SystemConfiguration"
            prefix="ControlDefinitions_"
            postfix="_StateOfChargeLabel"
            default-value=""
            @selected="setAttribute('loadManagementCapacity', {...controlDefinitionInput.attributes.loadManagementCapacity, label: $event })"
            @created="onStateOfChargeLabelResourceCreated($event, 'loadManagementCapacity')"
          />
        </form-field>
        <form-field text="Off-grid capacity label" v-if="showAttribute('offGridCapacity')">
          <string-resource-select
            :options="filteredSystemConfigurationResources('ControlDefinitions_', '_StateOfChargeLabel', false)"
            :selected-value="controlDefinitionInput.attributes.offGridCapacity.label"
            category="SystemConfiguration"
            prefix="ControlDefinitions_"
            postfix="_StateOfChargeLabel"
            default-value=""
            @selected="setAttribute('offGridCapacity', {...controlDefinitionInput.attributes.offGridCapacity, label: $event })"
            @created="onStateOfChargeLabelResourceCreated($event, 'offGridCapacity')"
          />
        </form-field>
      </v-flex>
      <!-- right part: commands, states, visibility condition  -->
      <v-flex xs3>
        <!-- commands -->
        <div v-for="(value, key) in controlDefinitionInput.commands" :key="key">
          <command-detail
            :name="key"
            :type="value.type"
            :endpoint="value.endpoint"
            :cloud-persistent="value.cloudPersistent"
            :template-attached="value.templateAttached"
            :auditable="value.auditable"
            :audit-resource-id="value.auditResourceId"
          />
        </div>
        <!-- states -->
        <div v-for="[key, value] in states" :key="key">
          <state-detail
            :name="key"
            :endpoint="value.endpoint"
            :to-subscribe="value.toSubscribe"
            :poll-interval="value.pollInterval"
          />
        </div>
        <!-- Visibility condition -->
        <visibility-condition-detail/>
      </v-flex>
      <v-flex xs3>
        <pre>{{formattedText}}</pre>
      </v-flex>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import CommandDetail from './CommandDetail.vue'
import MaterialIconInput from './MaterialIconInput.vue'
import PreviewControl from './PreviewControl.vue'
import StateDetail from './StateDetail.vue'
import StringResourceSelect from './StringResourceSelect.vue'
import VisibilityConditionDetail from './VisibilityConditionDetail.vue'

import { ConfigurationToolCategories } from '../../../../../eco-domain-store-modules/src/plcOperation/models'

import { ControlDefinitionViewModelV2 } from '../../../store/modules/functionDefinitionEditorUi/models'

import { CreateResourceInput } from '../../../../../eco-domain-store-modules/src/resource/models'

import { DropdownOption, Gradients } from '../../../../../shared-components/src'

import { ControlTypeV2, SourceLanguage } from '../../../../../eco-domain-store-modules/src/common'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')
const Resource = namespace('resource')

const toNumber = (value: any) => {
  const numericValue: number = Number(value)
  return numericValue
}

@Component({
  components: {
    CommandDetail,
    MaterialIconInput,
    PreviewControl,
    StateDetail,
    StringResourceSelect,
    VisibilityConditionDetail,
  },
})
export default class ControlDefinitionDetailsDialogV2 extends Vue {
  @Prop({type: Boolean}) public show: boolean
  @Prop({type: Boolean, default: false}) public isCreate: boolean
  @Resource.Getter public resolveStringResource: (key: string) => string
  @FunctionDefinitionEditorUi.Action public showError: (error: string) => Promise<void>
  @FunctionDefinitionEditorUi.Getter public controlDefinitionInput: ControlDefinitionViewModelV2
  @FunctionDefinitionEditorUi.Getter public controlDefinitionDefaults: (type: ControlTypeV2) => ControlDefinitionViewModelV2
  @FunctionDefinitionEditorUi.Getter public controlDefinitionTypeOptionsV2: DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionAppearanceOptions: (type: ControlTypeV2) => DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionTargetInstanceOptions: DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionSelectedTargetInstanceId: string
  @FunctionDefinitionEditorUi.Getter public controlDefinitionSourceInterfaceOptions: DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionLinkTypeOptions: DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionMessagesEnumOptions: DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionTimeTypeOptions: DropdownOption[]  
  @FunctionDefinitionEditorUi.Getter public controlDefinitionAppDisplayLevelOptionsV2: DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionConfigurationToolOptions: ConfigurationToolCategories[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionUnitOptions: string[]
  @FunctionDefinitionEditorUi.Getter public filteredSystemConfigurationResources:
    (prefix: string, postfix: string, allowEmpty: boolean) => DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionControlTagOptions: DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public sourceLanguage: SourceLanguage
  @FunctionDefinitionEditorUi.Mutation public setControlDefinitionObsolete: (payload: boolean) => void
  @FunctionDefinitionEditorUi.Mutation public setControlDefinitionAttribute: (p: { key: string, value: any }) => void
  @FunctionDefinitionEditorUi.Action public initializeControlDefinitionTypeV2: (type: ControlTypeV2) => Promise<void>
  @FunctionDefinitionEditorUi.Action public setControlDefinitionTargetInstance: (targetInstanceEndpointPath: string) => Promise<void>
  @FunctionDefinitionEditorUi.Action public createResource: (payload: CreateResourceInput) => Promise<void>

  public showAddOptionDialog = false
  public addOptionId = 0
  public addOptionLabel = ''

  @Emit('closed') 
  public onCancelled () {
    return true
  }

  @Emit('confirmed') 
  public onConfirmed() {
    return this.controlDefinitionInput.id
  }

  public get formattedText() {
    return JSON.stringify(this.controlDefinitionInput, null, 2)
  }

  public showAttribute(attribute: string) {
    return Object.keys(this.controlDefinitionDefaults(this.controlDefinitionInput.type).attributes).includes(attribute)
  }

  public get states() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return Object.entries(this.controlDefinitionInput.states).filter(([key, _]) => key !== 'visibility')
  }

  public setAttribute(attribute: string, value: any) {
    const numericAttributeNames = ['minValue', 'maxValue', 'interval', 'decimals', 'invalidValue', 'maxLength', 'maxNumSelections', 'pollInterval']
    const convertedValue = numericAttributeNames.includes(attribute) ? toNumber(value) : value
    this.setControlDefinitionAttribute({ key: attribute, value: convertedValue })
  }

  public get gradients() {
    return [ { id: '', primaryColor: '' }, ...Gradients.list()]
  }

  public onLabelResourceIdSelected(value: string) {
    this.setAttribute('label', value)
    const descriptionResourceId = value.replace('_Name', '_Description')
    if (this.filteredSystemConfigurationResources('ControlDefinitions_', '_Description', false).find(_ => _.id === descriptionResourceId)) {
      this.setAttribute('tooltip', descriptionResourceId)
    }
  }

  public onLabelResourceCreated(input: CreateResourceInput) {
    this.createResource(input)
    this.onLabelResourceIdSelected(input.id)
  }

  public onTooltipResourceCreated(input: CreateResourceInput) {
    this.createResource(input)
    this.setAttribute('tooltip', input.id)
  }

  public onMinValueLabelResourceCreated(input: CreateResourceInput) {
    this.createResource(input)
    this.setAttribute('minValueLabel', input.id)
  }

  public onMaxValueLabelResourceCreated(input: CreateResourceInput) {
    this.createResource(input)
    this.setAttribute('maxValueLabel', input.id)
  }

  public onActionTextResourceCreated(input: CreateResourceInput) {
    this.createResource(input)
    this.setAttribute('action', { ...this.controlDefinitionInput.attributes.action, text: input.id })
  }

  public onConfirmationTextResourceCreated(input: CreateResourceInput) {
    this.createResource(input)
    this.setAttribute('confirmationText', input.id)
  }

  public onStateOfChargeLabelResourceCreated(input: CreateResourceInput, attribute: string) {
    this.createResource(input)
    this.setAttribute(attribute, {...this.controlDefinitionInput.attributes[attribute], label: input.id })
  }

  public onOptionLabelResourceCreated(input: CreateResourceInput) {
    this.createResource(input)
    this.addOptionLabel = input.id
  }

  public addOption() {
    this.setAttribute('options', [...this.controlDefinitionInput.attributes.options, { 
      id: (Number)(this.addOptionId),
      label: this.addOptionLabel,
    }])
  }

  public deleteOption(option: DropdownOption) {
    this.setAttribute('options', [...this.controlDefinitionInput.attributes.options].filter(_ => _.id !== option.id))
  }
}
</script>
<style lang="scss" scoped>
  .field {
    height: 70px;
  }
  .field .text-input {
    padding-left: 10px;
  }
  .field .label {
    padding-left: 10px;
    overflow: hidden;
    max-height: 40px;
  }
  .field {
    .iconSelect {
      margin-top: -18px;
      margin-left: 5px;
    }
  }
  .control-preview-header {
    padding-left: 10px;
  }
  .noitems {
    margin-left: 10px;
  }
  .itemslist {
    display: flex;
  }
  .truncate {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .deletebutton {
    margin: 0;
    height: 20px;
  }
  .control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 10px;
    &.selected {
      border-color: $fore-color-primary;
    }
  }
</style>