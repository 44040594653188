<template>
  <eco-labeled-control class="eco-text-input-control"
    :context="context"
    :attributes="attributes"
    :direction="'column'"
  >
    <text-input class="text-input"
      :value="pendingValue !== undefined ? pendingValue : currentValue"
      :pending="pendingValue !== undefined"
      :disabled="readonly"
      @changed="onChange"
    />
  </eco-labeled-control>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import TextInput from '../../patterns/TextInput.vue'
import { ControlContextV2, TextInputControlAttributes } from './models'
import EcoLabeledControl from './shared/EcoLabeledControl.vue'

@Component({
  components: {
    EcoLabeledControl,
    TextInput,
  },
})
export default class EcoTextInputControl extends Vue {
  @Prop() public id: string
  @Prop() public context: ControlContextV2
  @Prop() public attributes: TextInputControlAttributes
  @Prop() public state: object
  @Prop() public pendingState: object
  @Prop() public readonly: boolean

  public onChange(value: string) {
    this.context.executeCommand(this.id, {
      command: this.attributes.command,
      params: value,
      pendingState: {
        [this.attributes.state]: value,
      },
    })
  }

  public get currentValue() {
    return this.state[this.attributes.state]
  }

  public get pendingValue() {
    return this.pendingState[this.attributes.state]
  }
}
</script>

<style lang="scss" scoped>
</style>

<style lang="css">
.eco-text-input-control input {
  border-radius: 5px;
}
</style>
