import { render, staticRenderFns } from "./RoomForm.vue?vue&type=template&id=146a8b4d&scoped=true"
import script from "./RoomForm.vue?vue&type=script&lang=ts"
export * from "./RoomForm.vue?vue&type=script&lang=ts"
import style0 from "./RoomForm.vue?vue&type=style&index=0&id=146a8b4d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.9.1_prettier@2.8.8_vue-template-compiler@2.6.6_webpack@5.90.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "146a8b4d",
  null
  
)

export default component.exports