import { get, set, Store } from 'idb-keyval'

export interface CacheOptions<T> {
  action: string
  parameters?: Array<string|boolean>
  load: () => Promise<T>
  commit: (data: T) => void
}

export interface CacheServiceOptions {
  userIdentifier: string
  environment: string
  version: number
}

class CacheService {
  private store: Store

  constructor () {
    // hack for ios 14.6
    // https://forum.ionicframework.com/t/indexeddb-in-latest-ios/210306/15
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const db = window.indexedDB
  }

  public init = (options: CacheServiceOptions) => {
    this.store = new Store(`eco-cache-v${options.version}-${options.userIdentifier}-${options.environment}`)
  }
  
  public load = async <T>(options: CacheOptions<T>) => {
    const key = this.makeCacheKey(options)
    const cachedData = await this.readCache<T>(key)
    if (cachedData) {
      options.commit(cachedData)
      options.load().then(apiData => { 
        options.commit(apiData)
        this.writeCache(key, apiData)
      })
    } else {
      const apiData = await options.load()
      options.commit(apiData)
      this.writeCache(key, apiData)
    }
  }

  private makeCacheKey = <T>(cacheOptions: CacheOptions<T>) => {
    return `${cacheOptions.action}${cacheOptions.parameters?.length ? `-${cacheOptions.parameters.join('-')}` : ''}`
  }

  private readCache = async <T>(key: string): Promise<T|null> => {
    if (!this.store) {
      return null
    }

    try {
      const data = await get<T>(key, this.store)
      return data || null
    } catch (error) {
      return null
    }
  }

  private writeCache = async <T>(key: string, data: T) => {
    if (!this.store) {
      return
    }

    try {
      await set(key, data, this.store)
    } catch (error) {
      //
    }
  }
}

export default new CacheService()
