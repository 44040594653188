import { ArchitectureType, SourceLanguage } from '../../common'
import ApiService from '../../services/api.service'
import { 
  Endpoint,
  EndpointType,
  FunctionBlockState,
  ParseFunctionBlockDefinitionXmlOutputV2,
  ParseFunctionBlockXmlOutput,
  UploadNewDefinitionsInput,
  UploadNewDefinitionsInputV2,
  UploadNewDefinitionsOutput,
  UploadNewDefinitionsOutputV2,
} from '../models'

export default {
  async setFunctionBlockObsolete(id: string, successorFunctionBlockId: string | undefined): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().functionBlockCommandApi}/FunctionBlock/${id}/setObsolete`, {
      successorFunctionBlockId,
    })
  },
  async setFunctionBlockActive(id: string): Promise<string[]> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().functionBlockCommandApi}/FunctionBlock/${id}/activate`, {})
    return response.data.obsoleteIds
  },
  async setControlDefinitionObsolete(id: string): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().functionBlockCommandApi}/ControldDefinition/${id}/setObsolete`, {})
  },
  async parseFunctionBlockXml(functionBlockXml: string): Promise<ParseFunctionBlockDefinitionXmlOutputV2> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().functionBlockCommandApi}/FunctionBlock/parseFunctionBlockXml`, {
      functionBlockXml,
    })
    const data: ParseFunctionBlockXmlOutput = response.data
    // convert to v2
    return {
      internalName: data.internalName,
      endpoints: data.endpoints.map(e => {
        const toEndpointType = (endpoint: Endpoint) => {
          if (endpoint.isInput) {
            return EndpointType.Input
          }
          if (endpoint.isOutput) { 
            return EndpointType.Output
          }
          if (endpoint.isMethod) {
            return EndpointType.Method
          }
          if (endpoint.isProperty) { 
            return EndpointType.Property
          }
          return EndpointType.Unknown
        }
        return {
          internalName: e.internalName,
          dataType: e.dataType,
          isMappableHardwareInput: e.isMappableHardwareInput,
          isMappableHardwareOutput: e.isMappableHardwareOutput,
          endpointType: toEndpointType(e),
          isReadOnlyProperty: e.isReadOnlyProperty,
          isWriteOnlyProperty: e.isWriteOnlyProperty,
          isEnum: e.isEnum,
        }
      }),
      matchingControlDefinitions: [],
      targetInstances: [],
      sourceInstances: [],
      messagesEnumInternalName: '',
      messagesEnumValues: [],
      matchingButtonControlDefinitions: data.matchingButtonControlDefinitions,
      matchingColorPickerControlDefinitions: data.matchingColorPickerControlDefinitions,
      matchingMonitoringControlDefinitions: data.matchingMonitoringControlDefinitions,
      matchingParameterControlDefinitions:data.matchingParameterControlDefinitions,
      matchingSwitchControlDefinitions: data.matchingSwitchControlDefinitions,
      matchingTextControlDefinitions: data.matchingTextControlDefinitions,
    }
  },
  async uploadNewDefinitions(input: UploadNewDefinitionsInput, language: string): Promise<UploadNewDefinitionsOutput> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().functionBlockCommandApi}/FunctionBlock/uploadNewDefinitions?resourceLanguage=${language}`, input)
    const data: UploadNewDefinitionsOutput = response.data
    data.functionDefinitionOutputs.forEach(fb => {
      fb.functionBlockOutput.architectureType = ArchitectureType.Classic
      fb.functionBlockOutput.sourceLanguage = SourceLanguage.StructuredText
      fb.functionBlockOutput.state = FunctionBlockState.IN_TEST
    })
    return response.data
  },
  // command.systemconfiguration
  async parseFunctionBlockDefinitionXml(functionBlockDefinitionXml: string): Promise<ParseFunctionBlockDefinitionXmlOutputV2> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().systemConfigurationCommandApi}/Definitions/parseFunctionBlockDefinitionXml`, {
      functionBlockDefinitionXml,
    })
    return response.data
  },
  async uploadDefinitions(input: UploadNewDefinitionsInputV2, language: string): Promise<UploadNewDefinitionsOutputV2> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().systemConfigurationCommandApi}/Definitions/uploadDefinitions?resourceLanguage=${language}`, input)
    const data: UploadNewDefinitionsOutputV2 = response.data
    // this endpoint always returns 200 StatusCode, also in validation error case, see https://dev.azure.com/ecocoachsmarthome/Ecocoach/_git/IntegrationCloud/pullrequest/5817
    // manually throw the error here in the way axios would throw it
    if (data.processingErrors.length) {
      throw Object.assign(new Error('Upload error'), { response })
    }
    data.functionBlockDefinitions.forEach(fb => { 
      fb.architectureType = ArchitectureType.ObjectOriented
      fb.sourceLanguage = SourceLanguage.StructuredText
      fb.state = FunctionBlockState.IN_TEST
    })
    return data
  },
  async getFunctionBlockPackageDefinition(functionBlockPackageInfoId: string): Promise<ParseFunctionBlockDefinitionXmlOutputV2> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().systemConfigurationCommandApi}/Definitions/getFunctionBlockPackageDefinition`, {
      functionBlockPackageInfoId,
    })
    return response.data
  },
  async uploadCSharpDefinitions(input: UploadNewDefinitionsInputV2, language: string): Promise<UploadNewDefinitionsOutputV2> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().systemConfigurationCommandApi}/Definitions/uploadCSharpDefinitions?resourceLanguage=${language}`, input)
    const data: UploadNewDefinitionsOutputV2 = response.data
    data.functionBlockDefinitions.forEach(fb => { 
      fb.architectureType = ArchitectureType.ObjectOriented
      fb.sourceLanguage = SourceLanguage.CSharp
      fb.state = FunctionBlockState.IN_TEST
    })
    return data
  },
  async setFunctionBlockDefinitionObsolete(id: string, successorFunctionBlockDefinitionId: string | undefined): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().systemConfigurationCommandApi}/Definitions/${id}/setObsolete`, {
      successorFunctionBlockDefinitionId,
    })
  },
  async setFunctionBlockDefinitionActive(id: string): Promise<string[]> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().systemConfigurationCommandApi}/Definitions/${id}/activate`, {})
    return response.data.obsoleteIds
  },
}