<template>
  <v-menu
    v-model="show"
    :close-on-content-click="false"
    persistent
    width="300"
  >
   <template slot="activator">
      <v-btn small outline class="open-button">
        <v-icon>info</v-icon>
        {{buttonText}}
      </v-btn>
    </template>
    <v-card 
      class="cardbody"
      color="#1a3856"
      dark
    >
      <div class="jsonContent">
        <pre>{{formattedText}}</pre>
      </div>
      <div class="actions">
        <v-card-actions class="justify-center">
          <v-btn outline @click="close">Close</v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class UploadResultDetails extends Vue {
  @Prop({type: String}) public buttonText: string
  @Prop({type: Object}) public content: any

  public show: boolean = false

  public get formattedText() {
    return JSON.stringify(this.content, null, 2)
  }

  public close() {
    this.show = false
  }
}
</script>
<style lang="scss" scoped>
.open-button {
  margin-top: 0px;
}
.jsonContent {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
}
</style>