<template>
    <v-flex>
        <v-flex class="popup-opener" @click.stop="togglePopover">
          <base-material-icon
            :iconName="icon"
            :title="tooltip || header"
            :color="color"
          />
        </v-flex>
        <v-flex class="menu1" style="visibility: collapse; width:0px; height: 0px;" xs12>
            <v-menu v-model="popupOpen"
                class="menu"
                :close-on-content-click="true"
                :transition="'scale-transition'"
                max-width="500"
                offset-x>
                <v-btn class="hidden" ref="choose" slot="activator" color="indigo" dark></v-btn>
                <v-card dark class="special">
                    <v-card-title primary-title>
                        <div>
                            <h3 class="mb-0">{{ this.header }}</h3>
                        </div>
                    </v-card-title>
                    <hr>
                    <v-card-text>
                        <slot/>
                    </v-card-text>
                </v-card>
            </v-menu>
        </v-flex>
    </v-flex>
</template>

<script lang="ts">
import Vue from 'vue'
import BaseMaterialIcon from '../base/BaseMaterialIcon.vue'

export default Vue.extend({
  name: 'Popover',
  components: {
      BaseMaterialIcon,
  },
  data() {
      return {
          popupOpen: false,
      }
  },
  props: {
    /**
     * the popover header
     */
    header: {
      type: String,
      default: '',
    },
    /** 
     * the popover icon
     */
    icon: {
      type: String,
      default: 'info',
    },
    /** 
     * the popover icon color
     */
    color: {
      type: String,
      default: 'white',
    },
    /** 
     * the popover icon tooltip
     */
    tooltip: {
      type: String,
      default: '',
    },
  },
  methods: {
    togglePopover(): void {
      this.popupOpen = !this.popupOpen
    },
  },
})
</script>

<style lang="scss" scoped>
button.hidden {
    display: inline-block !important;
    height: 0px !important;
    width: 0px !important;
    min-height: 0px !important;
    min-width: 0px !important;
    max-height: 0px !important;
    max-width: 0px !important;
    overflow: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
}
.popup-opener {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    cursor: pointer;
    span {
        text-transform: uppercase;
    }
}
.special {
    background: radial-gradient(#1a3856, #080e19) !important;
}
.text {
    word-break: break-word;
    // width: 100%;
    // padding: 10px 10px;
}
.menu1 {

}
</style>

<docs>
A popover, content in the slot

Usage example
```jsx
<v-app style="height: 32px; background:transparent;">
  <popover
    header="The popover title"
    icon="info"
    color="white"
  >
    <div>some content</div>
  </popover>
</v-app>
```
</docs>
