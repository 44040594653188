<template>
  <v-layout column wrap>
    <v-flex xs12>
      <v-layout row wrap>
        <wizard-step-header 
          :title="dictionary('wizard.hardware.define')"
          :sub-title="selectedPlc.name" 
        />
      </v-layout>
    </v-flex>
    <v-flex xs12>
      <release-information-panel class="release-copy-spacing" />
    </v-flex>
    <v-flex xs12>
      <v-layout row>
        <v-flex md3 shrink class="shTree">
          <div class="filter">
            <string-filter-component
              :value="searchString"
              :placeholder="dictionary('functionblocks.filter.placeholder')"
              @changed="searchString = $event"
            />
            <label-component class="filterinfo"
              v-if="this.searchResults.length == 0"
              :text="dictionary('functionblocks.filter.noresults')"
            />
          </div>
          <tree-view>
            <tree-view-group
              v-for="({ category, definitions }) in searchResults"
              :key="category.id"
              :expanded="isCategoryExpanded"
              :icon="category.iconResourceId"
              :title="category.name"
              :data-eco-name-resource-id="category.nameResourceId"
              :data-eco-icon-resource-id="category.iconResourceId"
            >
              <tree-view-group-item
                v-for="definition in definitions"
                :key="definition.id"
                :icon="category.iconResourceId"
                :name="definition.name"
                :disabled="isInteracted"
                :description-popup-title="definition.name"
                :description-popup-text="`Version: ${definition.version}<br>${definition.description}`"
                :data-eco-name-resource-id="definition.nameResourceId"
                :data-eco-description-resource-id="definition.descriptionResourceId"
                @treeViewItemInteraction="createSolutionHardwareInstance(definition.id)"/>
            </tree-view-group>
          </tree-view>
        </v-flex>
        <v-flex md9 grow class="shCards">
          <clamps/>
          <modules/>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import TreeView from '@/components/ui/TreeView.vue'
import TreeViewGroup from '@/components/ui/TreeViewGroup.vue'
import TreeViewGroupItem from '@/components/ui/TreeViewGroupItem.vue'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Clamps from './Clamps.vue'
import Modules from './Modules.vue'

import { SolutionHardwareDefinitionsOfCategory } from '../../../../../store/modules/solutionHardwareUi/models'
import ReleaseInformationPanel from '../manageRelease/ReleaseInformationPanel.vue'

import { PlcModel } from '@ecocoach/domain-store-modules/src/plc/models'

const App = namespace('app')
const Resource = namespace('resource')
const SolutionHardwareUi = namespace('solutionHardwareUi')

@Component({
  components: {
    Clamps,
    Modules,
    TreeView,
    TreeViewGroup,
    TreeViewGroupItem,
    ReleaseInformationPanel,
  },
})
export default class DeviceDevices extends Vue {
  @Resource.Getter public dictionary
  @App.Getter public selectedPlc: PlcModel
  @SolutionHardwareUi.Getter public solutionHardwareDefinitionsSearchResult
  @SolutionHardwareUi.Getter public isInteracted: boolean
  @SolutionHardwareUi.Action public createSolutionHardwareInstance: (id: string) => Promise<void>
  @SolutionHardwareUi.Action public computeClampLinesAndModules: () => Promise<void>

  public searchString = ''

  public mounted() {
    this.computeClampLinesAndModules()
  }

  public get searchResults(): SolutionHardwareDefinitionsOfCategory[] {
    return this.solutionHardwareDefinitionsSearchResult({
      search: this.searchString, 
      includeInTest: true,
      includeObsolete: false,
    })
  }

  public get isCategoryExpanded(): boolean {
    return this.searchString !== ''
  }
}
</script>

<style lang="scss" scoped>
.release-copy-spacing {
  margin-bottom: 75px;
}
.toggle {
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 12px 36px 0px 0px;
}
.filter {
  padding: 12px 36px 0px 0px;
  margin-left: -12px;
}
.filterinfo {
  padding: 12px 36px 0px 12px;
}
.shTree {
  min-width: 400px;
  max-width: 600px;  
  margin-bottom: 75px;
}
.shCards {
  flex: 1;
  margin-top: -61px;
  margin-bottom: 75px;
}
</style>