<template>
  <card header="Default alarm definitions">
    <template v-slot:actions>
      <card-action
        icon="add"
        tooltip="Create a new default alarm definition"
        @click="onCreate"
      />
    </template>
    <div>
      <v-data-table v-if="models.length" :headers="headers" :items="models" item-key="id"
        class="default-alarm-definitions-table"
        :rows-per-page-items="[5]"
      >
        <template v-slot:items="props">
          <tr :key="props.item.id">
            <td :title="props.item.level">
              <div>
                <icon-with-badge :material-icon="levelIcon(props.item)" :badge="levelBadge(props.item)" :badge-color="levelBadgeColor(props.item)" />
              </div>
            </td>
            <td :title="props.item.nameResourceId | translate">{{ props.item.nameResourceId | translate }}</td>
            <td :title="props.item.endpointPath">{{ props.item.endpointPath }}</td>
            <td>{{ props.item.operator }} {{ props.item.value }}</td>
            <td :title="props.item.messageResourceId | translate">{{ props.item.messageResourceId | translate }}</td>
            <td>
              <div class="default-alarm-definitions-table-actions">
                <v-tooltip bottom class="default-alarm-definitions-table-action">
                  <v-btn slot="activator" @click="onEdit(props.item)" icon flat>
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <span>{{ 'common.button.edit' | translate }}</span>
                </v-tooltip>
                <v-tooltip bottom class="default-alarm-definitions-table-action">
                  <v-btn slot="activator" @click="onDelete(props.item.id)" icon flat>
                    <v-icon small>delete</v-icon>
                  </v-btn>
                  <span>{{ 'common.button.delete' | translate }}</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div v-else>
        None defined 
      </div>
      <edit-default-alarm-definition-dialog 
        :show="showDialog"
        :model="modelToEdit" 
        @closed="showDialog = false"
      />
    </div>
  </card>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Card from '../../ui/Card.vue'
import CardAction from '../../ui/CardAction.vue'
import IconWithBadge from '../../ui/IconWithBadge.vue'

import EditDefaultAlarmDefinitionDialog from './EditDefaultAlarmDefinitionDialog.vue'

import { FunctionDefinitionViewModel } from '../../../store/modules/functionDefinitionEditorUi/models'

import { AlarmLevel } from '../../../../../eco-domain-store-modules/src/common'
import { DefaultAlarmDefinition } from '../../../../../eco-domain-store-modules/src/systemConfiguration/models'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')

@Component({
  components: {
    Card,
    CardAction,
    EditDefaultAlarmDefinitionDialog,
    IconWithBadge,
  },
})
export default class DefaultAlarmDefinitionsDetail extends Vue {
  @FunctionDefinitionEditorUi.Getter public functionDefinition: FunctionDefinitionViewModel
  @FunctionDefinitionEditorUi.Getter public actionInProgress: string
  @FunctionDefinitionEditorUi.Mutation public removeFunctionDefinitionDefaultAlarmDefinition: (payload: string) => void

  public showDialog = false
  public modelToEdit: DefaultAlarmDefinition | null = null

  public get models() {
    return this.functionDefinition.defaultAlarmDefinitions
  }

  public get headers() {
    return [
      {
        text: 'Level',
        value: 'level',
        align: 'left',
        width: '10%',
      },
      {
        text: 'Alarm definition',
        value: 'nameResourceId',
        align: 'left',
        width: '20%',
      },
      {
        text: 'Endpoint path',
        value: 'endpointPath',
        align: 'left',
        width: '20%',
      },
      {
        text: 'Condition',
        value: 'readIntervalInSeconds',
        align: 'left',
        sortable: false,
        width: '15%',
      },
      {
        text: 'Message text',
        value: 'messageResourceId',
        align: 'left',
        width: '25%',
      },
      {
        text: '',
        sortable: false,
        width: '10%',
      },    
    ]
  }

  public levelIcon(model: DefaultAlarmDefinition) {
    switch (model.level) {
    case AlarmLevel.Critical: return 'error'
    case AlarmLevel.Information: return 'information'
    default: return 'error'
    }
  }

  public levelBadgeColor(model: DefaultAlarmDefinition) {
    switch (model.level) {
    case AlarmLevel.Critical: return 'red'
    case AlarmLevel.Information: return 'blue'
    default: return 'red'
    }
  }

  public levelBadge(model: DefaultAlarmDefinition) {
    switch (model.level) {
    case AlarmLevel.Critical: return '!'
    case AlarmLevel.Information: return 'i'
    default: return '!'
    }
  }

  public onCreate() {
    this.modelToEdit = null
    this.showDialog = true
  }

  public onEdit(model: DefaultAlarmDefinition) {
    this.modelToEdit = model
    this.showDialog = true
  }

  public onDelete(id: string) {
    this.removeFunctionDefinitionDefaultAlarmDefinition(id)
  }
}
</script>
<style lang="scss" scoped>
  th, td {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .cardbody {
    margin-left: -10px;
  }
  .popUpButton {
    flex: 0;
    padding-left: 10px;
  }
  .field {
    height: 70px;
  }
  .field .label {
    padding-left: 10px;
    overflow: hidden;
    max-height: 40px;
  }
  thead tr {
    border-bottom: 1px solid white!important;
  }
  .default-alarm-definitions-table {
    .default-alarm-definitions-table-actions {
      display: flex;
      justify-content: flex-end;
      .default-alarm-definitions-table-action {
        width: 36px;
      }
    }
  }
</style>
<style lang="css">
.default-alarm-definitions-table .theme--dark.v-table {
  background-color: transparent !important;
  table-layout: fixed;
  width: 100%;
}
.default-alarm-definitions-table .v-datatable__actions {
  background-color: transparent !important;
}
</style>