import { NoteUiMutation, NoteUiState } from '@/store/modules/noteUi/types'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<NoteUiState> = {
  [NoteUiMutation.setInteracted](state, payload: boolean) {
    state.interacted = payload
  },
  [NoteUiMutation.setLoading](state, payload: boolean) {
    state.loading = payload
  },
}