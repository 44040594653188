import { ActionTree } from 'vuex'
import commandApi from './api/command'
import queryApi from './api/query'
import { Permission, ProjectAuthorizationsModel, SetAlarmDefinitionAuthorizationsPayload, SetConsumptionAuthorizationPayload, SetDeviceAuthorizationsPayload, SetPlcAuthorizationPayload, SetProjectAuthorizationPayload } from './models'
import { permissionConverter } from './permissionConverter'
import { AuthorizationAction, AuthorizationMutation, AuthorizationState } from './types'

export const actions: ActionTree<AuthorizationState, {}> = {
  async [AuthorizationAction.loadPartners]({ commit }) {
    const data = await queryApi.partners()
    commit(AuthorizationMutation.setPartners, data)
  },
  async [AuthorizationAction.loadProjects]({ commit }) {
    const [projects, consumptionProjects] = await Promise.all([
      queryApi.projects(),
      queryApi.consumptionProjects(),
    ])
    commit(AuthorizationMutation.setProjects, projects)
    commit(AuthorizationMutation.setConsumptionProjects, consumptionProjects)
  },
  async [AuthorizationAction.loadProject]({ commit, state }, projectId: string) {
    const hasProjectAuthorizePermission = permissionConverter.stringToPermissions(state.projects.find(p => p.id === projectId)?.currentUserAuthorization ?? '').includes(Permission.AUTHORIZE)
    const hasConsumptionAuthorizePermission = state.consumptionProjects.find(p => p.id === projectId)
    const [
      projectAuthorizations,
      consumptionAuthorizations,
      plcAuthorizations,
      deviceAuthorizationsAndRooms,
      alarmDefinitionAuthorizations,
    ] = await Promise.all([
      hasProjectAuthorizePermission ? queryApi.projectAuthorizations(projectId) : Promise.resolve({ id: '', permissions: []} as ProjectAuthorizationsModel), 
      hasConsumptionAuthorizePermission ? queryApi.consumptionAuthorizations(projectId) : Promise.resolve({ id: '', permissions: []} as ProjectAuthorizationsModel), 
      queryApi.plcAuthorizations(projectId),
      queryApi.deviceAuthorizations(projectId),
      queryApi.alarmDefinitionAuthorizations(projectId),
    ])
    commit(AuthorizationMutation.setProjectAuthorizations, projectAuthorizations)
    commit(AuthorizationMutation.setConsumptionAuthorizations, consumptionAuthorizations)
    commit(AuthorizationMutation.setPlcAuthorizations, plcAuthorizations)
    commit(AuthorizationMutation.setDeviceAuthorizations, deviceAuthorizationsAndRooms.devices)
    commit(AuthorizationMutation.setAlarmDefinitionAuthorizations, alarmDefinitionAuthorizations)
    commit(AuthorizationMutation.setRooms, deviceAuthorizationsAndRooms.rooms)
  },
  async [AuthorizationAction.updateProjectAuthorization]({ commit }, payload: SetProjectAuthorizationPayload) {
    await commandApi.setProjectAuthorization(payload.projectId, payload.userIdentifier, payload.permissions)
    commit(AuthorizationMutation.upsertProjectAuthorization, payload)
  },
  async [AuthorizationAction.updateConsumptionAuthorization]({ commit }, payload: SetConsumptionAuthorizationPayload) {
    await commandApi.setConsumptionAuthorization(payload.projectId, payload.userIdentifier, payload.permissions)
    commit(AuthorizationMutation.upsertConsumptionAuthorization, payload)
  },
  async [AuthorizationAction.updatePlcAuthorization]({ commit }, payload: SetPlcAuthorizationPayload) {
    await commandApi.setPlcAuthorization(payload.plcId, payload.userIdentifier, payload.permissions)
    commit(AuthorizationMutation.upsertPlcAuthorization, payload)
  },
  async [AuthorizationAction.updateDeviceAuthorizations]({ commit }, payload: SetDeviceAuthorizationsPayload) {
    await commandApi.setDeviceAuthorizations(payload.deviceIds, payload.userIdentifier, payload.permissions)
    commit(AuthorizationMutation.upsertDeviceAuthorizations, payload)
  },
  async [AuthorizationAction.updateAlarmDefinitionAuthorizations]({ commit }, payload: SetAlarmDefinitionAuthorizationsPayload): Promise<void> {
    await commandApi.setAlarmDefinitionAuthorizations(payload.alarmDefinitionIds, payload.userIdentifier, payload.permissions)
    commit(AuthorizationMutation.upsertAlarmDefinitionAuthorizations, payload)
  },
  async [AuthorizationAction.resolveAppRegistrationNames]({ commit, state }, payload: string[]) {
    const data = await queryApi.resolveAppRegistrationNames(payload)
    const mergedData = new Map([...Array.from(state.appRegistrationNameLookup.entries()), ...Array.from(data.entries())])
    commit(AuthorizationMutation.setAppRegistrationNames, mergedData)
  },
}
