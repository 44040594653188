import { UpgradeRequiredReason, ValidationIssue } from './models'

export interface LoadProgramUiState {
  interacted: boolean
  upgradeRequiredReason: UpgradeRequiredReason
  validationIssues: ValidationIssue[]
}

export enum LoadProgramUiAction {
  setProperties = 'setProperties',
  completeRelease = 'completeRelease',
  deployRelease = 'deployRelease',
}

export enum LoadProgramUiMutation {
  setInteracted = 'setInteracted',
  setUpgradeRequiredReason = 'setUpgradeRequiredReason',
  setValidationIssues = 'setValidationIssues',
}

export enum LoadProgramUiGetter {
  currentStep = 'currentStep',
  isInteracted = 'isInteracted',
  upgradeRequiredReason = 'upgradeRequiredReason',
  deploymentFinished = 'deploymentFinished',
  validationIssues = 'validationIssues',
}
