<template>
    <v-flex xs12 row wrap align-center class="string-add-component">
        <input
            type="text"
            class="base-text-box"
            v-model="currentString"
            :disabled="pending || disabled"
            :placeholder="placeholder"
            @keyup.enter="handleStringConfirmation">
        <base-spinner v-show="pending" />
        <div class="add-string-button">
            <icon-button 
                @buttonClicked="handleStringConfirmation"
                type="materialIcon"
                materialIconName="add"
                :height="25"
                :width="25"
                :enabled="!pending && !disabled"/>
        </div>
    </v-flex>
</template>
<script lang="ts">
import Vue from 'vue'

import BaseSpinner from '../base/BaseSpinner.vue'
import IconButton, { IconButtonEvents } from './IconButton.vue'

export default Vue.extend({
    name: 'StringAddComponent',
    components: {
        BaseSpinner,
        IconButton,
    },
    props: {
        /** 
         * intermediate pending state, can be used while a slow action triggered by the toggleing is in progress
         */
        pending: {
            type: Boolean,
            default: false,
        },
        /** 
         * disabled
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /** 
         * optional placeholder text, displayed in case nothing is input
         */
        placeholder: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            currentString: '',
        }
    },
    methods: {
        /***
         * description: emits the current value of the text as payload for a textChanged-Event
         */
        emitStringConfirmed() {
            /**
             * string confirmed event
             *
             * @event stringAddConfirmed
             */
            this.$emit('stringAddConfirmed', this.currentString)
        },
        /**
         * resets the textbox
         */
        resetCurrentString() {
            this.currentString = ''
        },
        /**
         * emits the string and resets the textbox
         * resets the string 
         */
        handleStringConfirmation() {
            this.emitStringConfirmed()
            this.resetCurrentString()
        },
    },
})
</script>
<style lang="scss">
.string-add-component {
    margin: $margin-default;
    display: flex;
    flex-direction: row;
    margin: 0px;
    padding: 10px 10px;
    .base-text-box {
        flex-grow: 1;
    }
    .base-spinner {
        min-width: 80px;
    }
    .add-string-button {
        width: 30px;
    }
    input {
        font-family: $font-family-primary;
        height: 100%;
        width: 100%;
        border-width: $default-border-width;
        border-style: solid;
        border-color: $fore-color-primary;
        padding: $default-padding;
        color: $fore-color-primary;
        background: transparent;
        outline: none;
        &.disabled {
          color: $fore-color-disabled;
        }
    }
    ::placeholder {
      color: $fore-color-disabled;
    }
}
</style>

<docs>
A string add component

The default string add component
```jsx
<string-add-component />
```

Usage example with events
```js
new Vue({
  methods: {
    log(text) {
      console.log(text)
    }
  },
  template: `<string-add-component 
    :placeholder="'email adresse hinzufügen'"
    @stringAddConfirmed="log"/>`
})
```

Interacted/pending state
```jsx
<string-add-component :pending="true" />
```

Disabled
```jsx
<string-add-component :disabled="true" />
```

Placeholder text
```jsx
<string-add-component :placeholder="'email adresse hinzufügen'" />
```
</docs>