<template>
  <modal-dialog :show="show"
    :header="'Create log configuration'"
    :button1-text="'common.button.create' | translate"
    :button2-text="'common.button.cancel' | translate"
    :button1-disabled="isInteracted || !logSource"
    :button2-disabled="isInteracted"
    @button1Pressed="onCreate"
    @button2Pressed="onClose"
  >
    <form-field :text="'Log source'">
      <autocomplete-select 
        :options="logSourceOptions"
        option-value="id"
        option-label="label"
        :selected-value="logSource"
        :disabled="isInteracted"
        @selected="logSource = $event"
      />
    </form-field>
    <form-field :text="'Log level'">
      <autocomplete-select 
        :options="logLevelOptions"
        option-value="id"
        option-label="label"
        :selected-value="logLevel"
        :disabled="isInteracted || isLoading"
        @selected="logLevel = $event"
      />
    </form-field>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { DropdownOption } from '@ecocoach/shared-components/src'

import { LogDestination, LoggerConfigurationModel, LogLevel } from '../../../../../store/modules/logToolUi/models'

const LogToolUi = namespace('logToolUi')

@Component({
  components: {
  },
})
export default class CreateLogConfigDialog extends Vue {
  @Prop() public show: boolean
  @Prop() public destination: LogDestination
  @LogToolUi.Getter public isInteracted: boolean
  @LogToolUi.Getter public logLevelOptions: DropdownOption[]
  @LogToolUi.Getter public logSourceOptions: DropdownOption[]
  @LogToolUi.Action public setLogLevel: (payload: LoggerConfigurationModel) => Promise<void>

  public logSource = ''
  public logLevel = LogLevel.Debug

  public async onCreate() {
    await this.setLogLevel({
      logDestination: this.destination,
      logLevel: this.logLevel,
      logSource: this.logSource,
    })
    this.onClose()
  }

  @Emit('closed')
  public onClose() {
    return
  }
}
</script>
<style lang="scss" scoped>
.field {
  height: 70px;
  .text-input {
    padding-left: 10px;
    padding-right: 10px;
  }
  .label {
    padding-left: 10px;
  }
}
</style>