<template>
  <div>
    <v-data-table :headers="headers" :items="notesForPlc" item-key="id"
      class="notes-table"
      :rows-per-page-items="[100]"
      :loading="isLoading"
    >
      <template v-slot:items="props">
        <tr :key="props.item.id">
          <td>
            <div class="note-content" v-html="content(props.item)"></div>
            <div class="note-author" v-html="author(props.item)"></div>
          </td>
          <td>
            <div class="notes-table-actions">
              <v-tooltip bottom class="notes-table-action">
                <v-btn slot="activator" :disabled="isInteracted" @click="onEditNote(props.item)" icon flat>
                  <v-icon small>edit</v-icon>
                </v-btn>
                <span>{{ 'common.button.edit' | translate }}</span>
              </v-tooltip>
              <v-tooltip bottom class="notes-table-action">
                <v-btn slot="activator" :disabled="isInteracted" @click="onDeleteNote(props.item)" icon flat>
                  <v-icon small>delete</v-icon>
                </v-btn>
                <span>{{ 'common.button.delete' | translate }}</span>
              </v-tooltip>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="note-actions">
      <base-text-button
        :enabled="!isInteracted && !isLoading"
        @buttonClicked="createNote = true">
        {{'common.button.create' | translate}}
      </base-text-button>
    </div>
    <edit-note-dialog 
      :show="noteToEdit || createNote"
      :note="noteToEdit" 
      @closed="onClose"
    />
    <confirm-dialog
      :show="noteToDelete"
      :header="'common.button.delete' | translate"
      :text="'common.dialog.confirm' | translate"
      :confirm-button-text="'OK'"
      :cancel-button-text="'common.button.cancel' | translate"
      @confirmed="onDeleteNoteConfirmed"
      @cancelled="noteToDelete = null"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import EditNoteDialog from './EditNoteDialog.vue'

import { NoteViewModel } from '../../../../store/modules/noteUi/models'

const Resource = namespace('resource')
const NoteUi = namespace('noteUi')

@Component({
  components: {
    EditNoteDialog,
  },
})
export default class NotesTable extends Vue {
  @NoteUi.Getter public isInteracted: boolean
  @NoteUi.Getter public isLoading: boolean
  @NoteUi.Getter public notesForPlc: NoteViewModel[]
  @NoteUi.Action public deleteNoteForPlc: (id: string) => Promise<void>
  @Resource.Getter public resolveStringResource: (id: string) => string

  public createNote = false
  public noteToEdit: NoteViewModel | null = null
  public noteToDelete: NoteViewModel | null = null

  public get headers() {
    return [
      {
        text: this.resolveStringResource('manage.plc.notes'),
        value: 'text',
        align: 'left',
        sortable: false,
      },
      {
        text: '',
        sortable: false,
      },
    ]
  }

  public content(note: NoteViewModel) {
    const assureFullLink = (url: string) => url.includes('//') ? url : `//${url}`
    // eslint-disable-next-line no-useless-escape
    const urlMatcher = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm
    const body = note.text.replace(urlMatcher, url => `<b><a href="${assureFullLink(url)}" target="_blank" style="color: #fa0f36;">${url}</a></b>`)
    return `<span style="white-space: pre;">${body}</span>`
  }

  public author(note: NoteViewModel) {
    const created = `<b>${note.createdBy}</b> ${note.createdAt}`
    const modified = note.modifiedBy ? `<br><i>${this.resolveStringResource('common.button.modified')}: <b>${note.modifiedBy}</b> ${note.modifiedAt}</i>` : ''
    return `${created}${modified}`
  }
  public onEditNote(note: NoteViewModel) {
    this.noteToEdit = note
  }

  public onDeleteNote(note: NoteViewModel) {
    this.noteToDelete = note
  }

  public onClose() {
    this.noteToEdit = null
    this.createNote = false
  }

  public async onDeleteNoteConfirmed() {
    await this.deleteNoteForPlc(this.noteToDelete!.id)
    this.noteToDelete = null
  }
}
</script>
<style lang="scss" scoped>
th, td {
  overflow: hidden;
  text-overflow: ellipsis;
}
thead tr {
  border-bottom: 1px solid white!important;
}
.notes-table {
  .note-content {
    padding: 5px 0px;
  }
  .notes-table-actions {
    display: flex;
    justify-content: flex-end;
    .notes-table-action {
      width: 36px;
    }
  }
}
.note-actions {
  padding-bottom: 120px;
  display: flex;
  justify-content: flex-end;
  height: 53px;
  .v-btn {
    border: solid 1px white;
  }
}
</style>
<style lang="css">
.notes-table .theme--dark.v-table {
  background-color: transparent !important;
  table-layout: fixed;
  width: 100%;
}
.notes-table .v-datatable__actions {
  background-color: transparent !important;
}
</style>