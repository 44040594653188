import { PlcConnectionHistoryUiState } from './types'

export const state: PlcConnectionHistoryUiState = {
  interacted: false,
  selectedPlcId: '',
  filterSettings: {
    statuses: [],
    date: '',
  },
}
