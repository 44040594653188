<template>
    <i 
        class="icon material-icons base-material-icon"
        :style="iconStyle">
        {{ iconName }}
    </i>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
    name: 'BaseMaterialIcon',
    props: {
        /**
         * the icon as base64 for string (complete with type)
         */
        iconName: {
          type: String,
          default: 'add',
        },
        /** 
         * determines the height (in pixel) of the icon
         */
        height: {
          type: Number,
          default: 25,
        },
        /** 
         * the icon color
         */
        color: {
        type: String,
        default: 'white',
        },
    },
    computed: {
      iconStyle(): any {
        return {
            fontSize: this.height + 'px',
            color: this.color,
        }
      },
    },
})
</script>
<style lang="scss" scoped>
    .base-material-icon {
        color: white;
        &.disabled {
            opacity: 0.4;
        }
    }
</style>

<docs>
An icon from a material design icon name

The default icon
```jsx
<base-material-icon />
```

A custom icon
```jsx
<base-material-icon :iconName="'delete'" />
```

Custom size 50px
```jsx
<base-material-icon
    :iconName="'check'"
    :height="50" />
```
</docs>
