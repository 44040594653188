<template>
  <v-layout column wrap>
    <!-- show edit (and grey out new and templates) -->
    <edit-without-releases v-if="hasNewWithoutCompleted" />

    <!-- show old releases list and templates option -->
    <old-releases v-else-if="hasOnlyCompleted" />

    <!-- show edit (and grey out old releases) -->
    <edit-with-releases v-else-if="hasNewWithCompleted" />

    <!-- show new and templates option -->
    <create-new-release v-else-if="needsNewRelease" />

    <div v-else>
      {{ 'releases.problem' | translate }}
    </div>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import CreateNewRelease from './CreateNewRelease.vue'
import EditWithoutReleases from './EditWithoutReleases.vue'
import EditWithReleases from './EditWithReleases.vue'
import OldReleases from './OldReleases.vue'

const ReleaseUi = namespace('releaseUi')
const PlcConfiguration = namespace('plcConfiguration')

@Component({
  components: {
    CreateNewRelease,
    EditWithReleases,
    EditWithoutReleases,
    OldReleases,
  },
})
export default class ManageRelease extends Vue { 
  @ReleaseUi.Getter public needsNewRelease
  @ReleaseUi.Getter public hasNewWithoutCompleted
  @ReleaseUi.Getter public hasNewWithCompleted
  @ReleaseUi.Getter public hasOnlyCompleted
  @PlcConfiguration.Action public loadReleaseTemplates

  public mounted() {
    this.loadReleaseTemplates()
  }
}
</script>
<style lang="scss" scoped>

</style>