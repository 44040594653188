<template>
  <modal-dialog  v-if="releaseUpgradeConfirmationDialogVisible"
    :show="true"
    :header="'release.upgrade' | translate"
    :button1-text="button1Text | translate"
    :button2-text="button2Text | translate"
    :button1-disabled="isInteracted"
    :button2-disabled="isInteracted"
    @button1Pressed="onUpgradeReleaseConfirmed"
    @button2Pressed="onUpgradeReleaseCancelled"
  >
    <v-layout column>
      <v-flex class="confirmation-message" v-if="!forceUpgrade">
        {{ confirmationMessage | translate }}
      </v-flex>
      <v-flex>
        <checkbox-with-label v-if="isEcocoachEmployee"
          :value="useInTestDefinitions"
          :text="'releases.upgrade.useintestdefinitions' | translate"
          :disabled="isInteracted"
          @checked="useInTestDefinitions = !$event"
        />
      </v-flex>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { ArchitectureType } from '../../../../../../../eco-domain-store-modules/src/common'
import { ReleaseModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'

const ReleaseUi = namespace('releaseUi')
const Wizard = namespace('wizard')
const App = namespace('app')

@Component
export default class UpgradeReleaseConfirmationDialog extends Vue {
  @ReleaseUi.Getter public releaseUpgradeConfirmationDialogVisible: boolean
  @ReleaseUi.Getter public isInteracted: boolean
  @ReleaseUi.Getter public currentReleaseShouldBeUpgraded: boolean
  @ReleaseUi.Getter public currentReleaseHasInTestDevices: boolean
  @ReleaseUi.Getter public currentRelease: ReleaseModel
  @ReleaseUi.Mutation public hideShowReleaseUpgradeConfirmationDialog: () => void
  @ReleaseUi.Action public upgradeRelease: (useInTestDefinitions: boolean) => Promise<void>
  @Wizard.Action public goToStep
  @Wizard.Getter public wizardSteps
  @Wizard.Mutation public closeReleaseDialog: () => void
  @App.Getter public isEcocoachEmployee: boolean

  public useInTestDefinitions = false

  @Watch('releaseUpgradeConfirmationDialogVisible')
  public setDefaults() {
    this.useInTestDefinitions = this.currentReleaseHasInTestDevices && this.isEcocoachEmployee
  }

  public get confirmationMessage() {
    return this.currentReleaseShouldBeUpgraded ? 'release.upgrade.confirmation' : 'release.upgrade.confirmation.useintest'
  }

  public get forceUpgrade() {
    return this.currentRelease.architectureType === ArchitectureType.ObjectOriented
  }

  public get button1Text() {
    return this.forceUpgrade ? 'OK' : 'common.button.yes'
  }

  public get button2Text() {
    return this.forceUpgrade ? 'common.button.cancel' : 'common.button.no'
  }

  public async onUpgradeReleaseConfirmed() {
    await this.upgradeRelease(this.useInTestDefinitions)
    this.hideShowReleaseUpgradeConfirmationDialog()
    this.navigateToRooms()
  }

  public onUpgradeReleaseCancelled() {
    this.hideShowReleaseUpgradeConfirmationDialog()
    this.navigateToRooms()
  }

  private navigateToRooms() {
    this.closeReleaseDialog()
    const step = this.wizardSteps.find(s => s.name === 'defineRooms')
    this.goToStep({ step, router: this.$router })
  }
}
</script>
<style lang="scss" scoped>
.confirmation-message {
  margin-left: 10px;
}
.checkboxWithLabel {
  margin-left: -5px;
}
</style>
