<template>
  <v-flex xs12 row wrap align-center class="autocomplete-input" :class="{ 'pending': pending }">
    <v-autocomplete
      :items="options"
      :item-value="optionValue"
      :item-text="optionLabel"
      :value="selectedValue"
      :multiple="multiple"
      :placeholder="placeholdertext"
      :disabled="disabled"
      :pending="pending"
      hide-no-data
      @change="emitSelected($event)"
      solo
      :menu-props="{ contentClass: 'autocomplete-input-menu' }"
    />
  </v-flex>
</template>


<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'AutocompleteInput',
  props: {
    /**
     * array of strings or objects to be used by the autocomplete drop down menu
     */
    options: {
      type: Array,
      default: [],
    },
    /** 
     * the selected value, an array in case of multiple=true
     */
    selectedValue: {
      type: [String, Array],
      default: '',
    },
    /** 
     * optional, needed only in case options is an array of objects. 
     * the property name of the value string inside the options object
     */
    optionValue: {
      type: String,
      default: '',
    },
    /** 
     * optional, needed only in case options is an array of objects. 
     * property name of the displayed string inside the options object
     */
    optionLabel: {
      type: String,
      default: '',
    },
    /** 
     * multiselect
     */
    multiple: {
      type: Boolean,
      default: false,
    },
    /** 
     * disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * pending
     */
    pending: {
      type: Boolean,
      default: false,
    },
    /** 
     * optional placeholder text, displayed in case nothing is selected
     */
    placeholdertext: {
      type: String,
      default: '',
    },
  },
  methods: {
    emitSelected(value: string | string[]): void {
      /**
       * Selected event.
       * string or an array in case of multiple=true
       * @event selected
       */
      this.$emit('selected', value)
    },
  },
})
</script>
<style lang="scss">
.autocomplete-input > .v-text-field.v-text-field--solo .v-input__control {
  min-height: 30px;
}
.autocomplete-input > .v-text-field--solo>.v-input__control>.v-input__slot {
  background-color: transparent !important;
  box-shadow: none !important;
}
.autocomplete-input > .v-input > .v-input__control > .v-text-field__details {
  display: none;
}
.autocomplete-input input {
  height: 100%;
  width: 100%;
  color: inherit !important;
  background: transparent;
  outline: none;
  caret-color: $fore-color-primary !important;
  &.disabled {
    color: $fore-color-disabled;
  }
  padding-bottom: 0px !important; 
}
.v-input_slot {
  margin: 0;
}
.autocomplete-input .primary--text input{
  caret-color: $fore-color-primary !important;
}
.autocomplete-input .theme--light.v-input--is-disabled input {
  color: $fore-color-disabled !important;
}
.autocomplete-input.pending > .v-input input {
  border-color: $fore-color-disabled;
}
.autocomplete-input > .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
  color: $fore-color-primary;
  padding: 0px;
}
.autocomplete-input .v-icon {
  cursor: pointer;
  color: $fore-color-primary !important;
  padding-top: 5px;
  opacity: 1 !important;
}
.autocomplete-input ::placeholder {
  color: inherit !important;
}
.autocomplete-input .v-input {
  font-size: inherit;
}
.autocomplete-input-menu .v-list {
  background: $bg-primary !important;
}
.autocomplete-input-menu .v-list .v-list__tile--active.primary--text {
  color: $fore-color-primary !important;
  font-weight: bold;
  font-size: inherit;
}
.autocomplete-input-menu .v-list .v-list__tile {
  color: $fore-color-primary !important;
  font-size: inherit;
}
.autocomplete-input-menu .v-list__tile__title {
  padding-right: 10px;
  overflow-x: visible;
  text-overflow: clip;
}
</style>

<docs>
An autocomplete

Usage of the autocomplete input with string array options
```js
new Vue({
  data: function() {
    return {
      options: [ "foo", "bar", "baz" ],
      selectedValue: 'option1',
    }
  },
  methods: {
    onSelected(value) {
      console.log(value)
      selectedValue = value
    }
  },
  template: `<v-app style="height: 48px;background:transparent">
    <autocomplete-input 
      :options="options"
      :selected-value="selectedValue"
      @selected="onSelected"/>
  </v-app>`
})
```

Usage of the autocomplete input with object options
```js
new Vue({
  data: function() {
    return {
      options: [
        {id: "id1", label: "foo"},
        {id: "id2", label: "bar"}
      ],
      selectedValue: 'id1',
    }
  },
  methods: {
    onSelected(value) {
      console.log(value)
      this.selectedValue = value
    }
  },
  template: `<v-app style="height: 48px;background:transparent">
    <autocomplete-input 
      :options="options"
      :option-value="'id'"
      :option-label="'label'"
      :selected-value="selectedValue"
      @selected="onSelected"/>
  </v-app>`
})
```

Disabled
```jsx
<v-app style="height: 48px;background:transparent">
  <autocomplete-input
    :options="['option1']"
    selected-value="option1"
    disabled
  />
</v-app>
```

Placeholder text
```jsx
<v-app style="height: 48px;background:transparent">
  <autocomplete-input
    :options="['option1', 'option2']"
    placeholdertext="Select something please!"
  />
</v-app>
```
</docs>
