import { ActionTree, Commit } from 'vuex'
import { ArchitectureType, PlcType } from '../common'
import ApiService from '../services/api.service'
import HubService from '../services/hub.service'
import commandApi from './api/command'
import queryApi from './api/query'
import { 
  AutoMappingModel, 
  BuildState, 
  CompleteReleasePayload, 
  DeploymentState, 
  DeployReleasePayload, 
  DeviceMappingModel, 
  DeviceModel, 
  ReleaseDetails, 
  ReleaseModel, 
  ReleaseState,
  ReleaseStateChanged,
  RoomModel,
  SolutionHardwareInstanceModel,
} from './models'
import { PlcConfigurationAction, PlcConfigurationMutation, PlcConfigurationState } from './types'

export const configurationReleaseStateHubId = 'configurationReleaseStateHub'

const createNewRelease = (id: string, plcId: string, plcType: PlcType, userIdentifier: string, architectureType: ArchitectureType): ReleaseModel => {
  return {
    id,
    plcId: plcId,
    state: ReleaseState.NEW,
    plcType,
    createdBy: userIdentifier,
    createdAt: new Date().toISOString(),
    lastModifiedBy: userIdentifier,
    lastModifiedAt: new Date().toISOString(),
    deploymentState: DeploymentState.NOT_STARTED,
    deploymentStateInfo: null,
    deploymentProgress: 0,
    deployedAt: '',
    deployedBy: null,
    buildState: BuildState.NOT_STARTED,
    buildStateInfo: null,
    builtAt: '',
    builtBy: null,
    isTemplate:true,
    name: null,
    description: null,
    architectureType,
  }
}

const commitNewRelease = (commit: Commit, release: ReleaseModel, releaseDetails: ReleaseDetails) => {
  commit(PlcConfigurationMutation.addRelease, release)
  commit(PlcConfigurationMutation.setDevices, releaseDetails.devices)
  commit(PlcConfigurationMutation.setSolutionHardwareInstances, releaseDetails.solutionHardwareInstances)
  commit(PlcConfigurationMutation.setDeviceMappings, releaseDetails.deviceMappings)
  commit(PlcConfigurationMutation.setAutoMappings, releaseDetails.autoDeviceMappings)
  commit(PlcConfigurationMutation.setRooms, releaseDetails.rooms)
  commit(PlcConfigurationMutation.setLoadedRelease, {
    plcId: release.plcId,
    releaseId: release.id,
    architectureType: release.architectureType,
  })
}

export const actions: ActionTree<PlcConfigurationState, {}> = {
  async [PlcConfigurationAction.setPlcType]({ state, commit }, payload: { plcId: string, plcType: PlcType }) {
    const { updatedReleaseIds} = await commandApi.setPlcType(payload.plcId, payload.plcType)
    updatedReleaseIds.forEach(updatedReleaseId => {
      const existingRelease = state.releases.find(r => r.id === updatedReleaseId)!
      const updatedRelease: ReleaseModel = {
        ...existingRelease,
        plcType: payload.plcType,
      }
      commit(PlcConfigurationMutation.updateRelease, updatedRelease)
    })
  },
  async [PlcConfigurationAction.loadReleaseTemplates]({ commit }) {
    const releaseTemplates = await queryApi.releaseTemplates()
    commit(PlcConfigurationMutation.setReleaseTemplates, releaseTemplates)
  },
  async [PlcConfigurationAction.loadReleases]({ commit }, payload: { plcId: string }) {
    const release = await queryApi.releases(payload.plcId)
    commit(PlcConfigurationMutation.setReleases, release)
  },
  async [PlcConfigurationAction.createRelease]({ commit }, payload: { plcId: string, userIdentifier: string, architectureType: ArchitectureType }) {
    const { id, plcType, details, architectureType } = await commandApi.createRelease(payload.plcId, payload.architectureType)
    const newRelease = createNewRelease(id, payload.plcId, plcType, payload.userIdentifier, architectureType)
    commitNewRelease(commit, newRelease, details)
  },
  async [PlcConfigurationAction.importRelease]({ commit }, payload: { plcId: string, userIdentifier: string, file: File }): Promise<void> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = async (e: any) => {
        try {
          const data: string = e.target.result
          const json = JSON.parse(data)
          const { id, plcType, architectureType, details } = await commandApi.importRelease(payload.plcId, json)
          const newRelease = createNewRelease(id, payload.plcId, plcType, payload.userIdentifier, architectureType)
          commit(PlcConfigurationMutation.addRelease, newRelease)
          commitNewRelease(commit, newRelease, details)
        } finally {
          resolve()
        }
      }
      reader.onerror = (error) => {
        reject(error)
      }
      reader.readAsText(payload.file)
    })
  },
  async [PlcConfigurationAction.copyRelease]({ state, commit }, payload: { plcId: string, releaseId: string, applyTemplateOperationData: boolean, userIdentifier: string }) {
    const release = [...state.releases, ...state.releaseTemplates].find(r => r.id === payload.releaseId)!
    const { id, plcType, architectureType, details } = await commandApi.copyRelease(payload.plcId, release.id, payload.applyTemplateOperationData, release.architectureType)
    const newRelease = createNewRelease(id, payload.plcId, plcType, payload.userIdentifier, architectureType)
    commitNewRelease(commit, newRelease, details)
  },
  async [PlcConfigurationAction.updateRelease]({ state, commit }, payload: ReleaseModel) {
    await commandApi.updateRelease(payload.plcId, payload.id, {
      isTemplate: payload.isTemplate,
      name: payload.name,
      description: payload.description,
      updateTemplateOperationData: !!payload.updateTemplateOperationData,
    })
    const existingRelease = state.releases.find(release => release.id === payload.id)!
    const upatedRelease: ReleaseModel = {
      ...existingRelease,
      ...payload,
    }
    commit(PlcConfigurationMutation.updateRelease, upatedRelease)
  },
  async [PlcConfigurationAction.deleteRelease]({ state, commit }, payload: string) {
    const release = state.releases.find(r => r.id === payload)!
    await commandApi.deleteRelease(release.plcId, release.id)
    commit(PlcConfigurationMutation.removeRelease, release.id)
  },
  async [PlcConfigurationAction.completeRelease]({ state, commit }, payload: CompleteReleasePayload) {
    const release = state.releases.find(r => r.id === payload.releaseId)!
    await commandApi.completeRelease(release.plcId, release.id, payload.archive)
    const updatedRelease: ReleaseModel = {
      ...release,
      state: ReleaseState.COMPLETED,
      buildState: BuildState.STARTING,
      // set props that UI uses directly (no need to refresh page)
      builtAt: payload.builtAt,
      builtBy: payload.userIdentifier,
    }
    commit(PlcConfigurationMutation.updateRelease, updatedRelease)
  },
  async [PlcConfigurationAction.deployRelease]({ state, commit }, payload: DeployReleasePayload) {
    const release = state.releases.find(r => r.id === payload.releaseId)!
    await commandApi.deployRelease(release.plcId, release.id, payload.forceDeployment)
    const updatedRelease: ReleaseModel = {
      ...release,
      deploymentState: DeploymentState.UPDATING,
      // set props that UI uses directly (no need to refresh page)
      deployedAt: payload.deployedAt,
      deployedBy: payload.userIdentifier,
    }
    commit(PlcConfigurationMutation.updateRelease, updatedRelease)
  },
  async [PlcConfigurationAction.downloadRelease] (_, releaseId: string): Promise<void> {
    await queryApi.downloadProjectFiles(releaseId)
  },
  async [PlcConfigurationAction.exportRelease](_, releaseId: string) {
    await queryApi.export(releaseId)
  },
  async [PlcConfigurationAction.loadReleaseDetails]({ state, commit }, payload: { releaseId: string, language: string }) {
    const release = state.releases.find(r => r.id === payload.releaseId)!
    const releaseDetails = await queryApi.release(release.plcId, release.id, payload.language)
    commit(PlcConfigurationMutation.setDevices, releaseDetails.devices)
    commit(PlcConfigurationMutation.setSolutionHardwareInstances, releaseDetails.solutionHardwareInstances)
    commit(PlcConfigurationMutation.setDeviceMappings, releaseDetails.deviceMappings)
    commit(PlcConfigurationMutation.setAutoMappings, releaseDetails.autoDeviceMappings)
    commit(PlcConfigurationMutation.setRooms, releaseDetails.rooms)
    commit(PlcConfigurationMutation.setLoadedRelease, {
      plcId: release.plcId,
      releaseId: release.id,
      architectureType: release.architectureType,
    })
  },
  async [PlcConfigurationAction.upgradeRelease]({ state, commit }, payload: { releaseId: string, useInTestDefinitions: boolean, language: string }) {
    const release = state.releases.find(r => r.id === payload.releaseId)!
    const upgradeReleaseDetails = await commandApi.upgradeRelease(release.plcId, release.id, payload.useInTestDefinitions, payload.language)
    const devices = state.devices
      .filter(d => !upgradeReleaseDetails.removedDevices.includes(d.id))
      .concat(upgradeReleaseDetails.addedDevices)
    const solutionHardwareInstances = state.solutionHardwareInstances
      .filter(sh => !upgradeReleaseDetails.removedSolutionHardwareInstances.includes(sh.id))
      .concat(upgradeReleaseDetails.addedSolutionHardwareInstances)
    const deviceMappings = state.deviceMappings
      .filter(m => !upgradeReleaseDetails.removedDeviceMappings.includes(m.id))
      .concat(upgradeReleaseDetails.addedDeviceMappings)
    const autoDeviceMappings = state.autoMappings
      .filter(m => !upgradeReleaseDetails.removedDeviceAutoMappings.includes(m.id))
      .concat(upgradeReleaseDetails.addedDeviceAutoMappings)
    // sortOrders are returned as separate arrays, add them to the models
    upgradeReleaseDetails.deviceSortOrder.forEach((id, index) => {
      devices.find(d => d.id === id)!.sortOrder = index
    })
    upgradeReleaseDetails.solutionHardwareInstanceSortOrder.forEach((id, index) => {
      solutionHardwareInstances.find(s => s.id === id)!.sortOrder = index
    })
    commit(PlcConfigurationMutation.setDevices, devices)
    commit(PlcConfigurationMutation.setSolutionHardwareInstances, solutionHardwareInstances)
    commit(PlcConfigurationMutation.setDeviceMappings, deviceMappings)
    commit(PlcConfigurationMutation.setAutoMappings, autoDeviceMappings)
    commit(PlcConfigurationMutation.setDeviceUpgradeInfos, upgradeReleaseDetails.deviceUpgradeInfos ?? [])
  },
  async [PlcConfigurationAction.createRoom]({ state, commit }, payload: RoomModel) {
    const { id } = await commandApi.createRoom(state.plcId, state.releaseId, {
      name: payload.name,
      icon: payload.icon,
    })
    const newRoom: RoomModel = {
      ...payload,
      id,
    }
    commit(PlcConfigurationMutation.addRoom, newRoom)
  },
  async [PlcConfigurationAction.updateRoom]({ state, commit }, payload: RoomModel) {
    await commandApi.updateRoom(state.plcId, state.releaseId, payload.id, {
      name: payload.name,
      icon: payload.icon,
    })
    const existingRoom = state.rooms.find(room => room.id === payload.id)!
    const updatedRoom: RoomModel = {
      ...existingRoom,
      ...payload,
    }
    commit(PlcConfigurationMutation.updateRoom, updatedRoom)
  },
  async [PlcConfigurationAction.deleteRoom]({ state, commit }, payload: string) {
    await commandApi.deleteRoom(state.plcId, state.releaseId, payload)
    commit(PlcConfigurationMutation.removeRoom, payload)
    const devicesIdsToRemove = state.devices.filter(device => device.roomId === payload).map(device => device.id)
    devicesIdsToRemove.forEach(id => commit(PlcConfigurationMutation.removeDevice, id))
    state.deviceMappings.filter(mapping => devicesIdsToRemove.includes(mapping.deviceId))
      .forEach(mapping => commit(PlcConfigurationMutation.removeDeviceMapping, mapping.id))
    state.autoMappings.filter(autoMapping => devicesIdsToRemove.includes(autoMapping.deviceId))
      .forEach(autoMapping => commit(PlcConfigurationMutation.removeAutoMapping, autoMapping.id))
  },
  async [PlcConfigurationAction.sortRooms]({ state, commit }, payload: string[]) {
    await commandApi.sortRooms(state.plcId, state.releaseId, payload)
    commit(PlcConfigurationMutation.sortRooms, payload)
  },
  async [PlcConfigurationAction.createDevice]({ state, commit }, payload: { input: DeviceModel, language: string }) {
    const { id } = await commandApi.createDevice(state.plcId, state.releaseId, {
      roomId: payload.input.roomId,
      functionBlockDefinitionId: payload.input.functionBlockDefinitionId,
      name: payload.input.name,
      icon: payload.input.icon,
    }, payload.language)
    const newDevice: DeviceModel = {
      ...payload.input,
      id,
    }
    commit(PlcConfigurationMutation.addDevice, newDevice)
  },
  async [PlcConfigurationAction.updateDevice]({ state, commit }, payload: DeviceModel) {
    await commandApi.updateDevice(state.plcId, state.releaseId, payload.id, {
      name: payload.name,
      icon: payload.icon,
    })
    const existingDevice = state.devices.find(device => device.id === payload.id)!
    const updatedDevice: DeviceModel = {
      ...existingDevice,
      ...payload,
    }
    commit(PlcConfigurationMutation.updateDevice, updatedDevice)
  },
  async [PlcConfigurationAction.deleteDevice]({ state, commit }, payload: string) {
    const { removedDeviceMappings, removedAutoMappings } = await commandApi.deleteDevice(state.plcId, state.releaseId, payload)
    commit(PlcConfigurationMutation.removeDevice, payload)
    removedDeviceMappings.forEach(id => commit(PlcConfigurationMutation.removeDeviceMapping, id))
    removedAutoMappings.forEach(id => commit(PlcConfigurationMutation.removeAutoMapping, id))
  },
  async [PlcConfigurationAction.sortDevices]({ state, commit }, payload: string[]) {
    await commandApi.sortDevices(state.plcId, state.releaseId, payload)
    commit(PlcConfigurationMutation.sortDevices, payload)
  },
  async [PlcConfigurationAction.createSolutionHardwareInstance]({ state, commit }, payload: SolutionHardwareInstanceModel) {
    const { id } = await commandApi.createSolutionHardwareInstance(state.plcId, state.releaseId, payload.solutionHardwareDefinitionId)
    const newSolutionHardwareInstance: SolutionHardwareInstanceModel = {
      ...payload,
      id,
    }
    commit(PlcConfigurationMutation.addSolutionHardwareInstance, newSolutionHardwareInstance)
  },
  async [PlcConfigurationAction.updateSolutionHardwareInstance]({ state, commit }, payload: SolutionHardwareInstanceModel) {
    await commandApi.updateSolutionHardwareInstance(state.plcId, state.releaseId, payload.id, {
      lastOctet: payload.lastOctet || 0,
    })
    const existingSolutionHardwareInstance = state.solutionHardwareInstances.find(solutionHardwareInstance => solutionHardwareInstance.id === payload.id)!
    const updatedSolutionHardwareInstance: SolutionHardwareInstanceModel = {
      ...existingSolutionHardwareInstance,
      ...payload,
    }
    commit(PlcConfigurationMutation.updateSolutionHardwareInstance, updatedSolutionHardwareInstance)
  },
  async [PlcConfigurationAction.deleteSolutionHardwareInstance]({ state, commit }, payload: string) {
    const { removedDeviceMappings, removedAutoMappings } = await commandApi.deleteSolutionHardwareInstance(state.plcId, state.releaseId, payload)
    commit(PlcConfigurationMutation.removeSolutionHardwareInstance, payload)
    removedDeviceMappings.forEach(id => commit(PlcConfigurationMutation.removeDeviceMapping, id))
    removedAutoMappings.forEach(id => commit(PlcConfigurationMutation.removeAutoMapping, id))
  },
  async [PlcConfigurationAction.sortSolutionHardwareInstances]({ state, commit }, payload: string[]) {
    await commandApi.sortSolutionHardwareInstances(state.plcId, state.releaseId, payload)
    commit(PlcConfigurationMutation.sortSolutionHardwareInstances, payload)
  },
  async [PlcConfigurationAction.createDeviceMapping]({ state, commit }, payload: DeviceMappingModel) {
    const { id } = await commandApi.createDeviceMapping(state.plcId, state.releaseId, {
      deviceId: payload.deviceId,
      solutionHardwareInstanceId: payload.solutionHardwareInstanceId,
      deviceEndpointId: payload.deviceEndpointId,
      solutionHardwareEndpointId: payload.solutionHardwareEndpointId,
    })
    const newDeviceMapping: DeviceMappingModel = {
      ...payload,
      id,
    }
    commit(PlcConfigurationMutation.addDeviceMapping, newDeviceMapping)
  },
  async [PlcConfigurationAction.deleteDeviceMapping]({ state, commit }, payload: string) {
    await commandApi.deleteDeviceMapping(state.plcId, state.releaseId, payload)
    commit(PlcConfigurationMutation.removeDeviceMapping, payload)
  },
  async [PlcConfigurationAction.createAutoMapping]({ state, commit }, payload: AutoMappingModel) {
    const { id } = await commandApi.createAutoMapping(state.plcId, state.releaseId, {
      deviceId: payload.deviceId,
      solutionHardwareInstanceId: payload.solutionHardwareInstanceId,
      deviceSlotInternalName: payload.deviceSlotInternalName,
      solutionHardwareSlotInternalName: payload.solutionHardwareSlotInternalName,
      instanceNumber: payload.instanceNumber,
    })
    const newAutoMapping: AutoMappingModel = {
      ...payload,
      id,
    }
    commit(PlcConfigurationMutation.addAutoMapping, newAutoMapping)
  },
  async [PlcConfigurationAction.deleteAutoMapping]({ state, commit }, payload: string) {
    await commandApi.deleteAutoMapping(state.plcId, state.releaseId, payload)
    commit(PlcConfigurationMutation.removeAutoMapping, payload)

  },
  async [PlcConfigurationAction.connectToConfigurationReleaseStateHub]({ commit, state }): Promise<void> {
    const hubId = configurationReleaseStateHubId
    await HubService.connect({
      hubId,
      hubUrl: `${ApiService.backendEnvironmentConfiguration().releaseStateHub}`,
      methodCallbacks: [{
        method: 'releaseStateChanged',
        callback: (payload: ReleaseStateChanged) => {
          const release = state.releases.find(r => r.id === payload.id)
          if (release) {
            commit(PlcConfigurationMutation.updateRelease, {
              ...release,
              buildState: payload.buildState,
              buildStateInfo: payload.buildStateInfo,
              deploymentState: payload.deploymentState,
              deploymentStateInfo: payload.deploymentStateInfo,
              deploymentProgress: payload.deploymentProgress,
            } as ReleaseModel)
          }
        },
      }],
    })
  },
  async [PlcConfigurationAction.startConfigurationReleaseStateHubForPlc](_, plcId: string): Promise<void> {
    HubService.invoke({
      hubId: configurationReleaseStateHubId,
      method: 'StartForPlc',
      args: [plcId],
    })
  },
  async [PlcConfigurationAction.stopConfigurationReleaseStateHubForPlc](_, plcId: string): Promise<void> {
    HubService.invoke({
      hubId: configurationReleaseStateHubId,
      method: 'StopForPlc',
      args: [plcId],
    })
  },
}
