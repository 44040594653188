<template>
  <v-layout>
    <draggable v-model="solutionHardwareModules"
      class="layout row wrap"
      group="modules"
      animation="200"
      @end="sortSolutionHardwareInstances"
    >
      <module 
        v-for="module in solutionHardwareModules"
        :key="module.id"
        :module="module"
      />
    </draggable>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Draggable from 'vuedraggable'

import Module from './Module.vue'

import { SolutionHardwareInstanceViewModel } from '../../../../../store/modules/solutionHardwareUi/models'

const SolutionHardwareUi = namespace('solutionHardwareUi')

@Component({
  components: {
    Module,
    Draggable,
  },
})
export default class Modules extends Vue {
  @SolutionHardwareUi.Getter public isInteracted: boolean
  @SolutionHardwareUi.Getter public modules: SolutionHardwareInstanceViewModel[]
  @SolutionHardwareUi.Mutation public setModules: (modules: SolutionHardwareInstanceViewModel[]) => void
  @SolutionHardwareUi.Action public sortSolutionHardwareInstances: () => Promise<void>
  
  public get solutionHardwareModules(): SolutionHardwareInstanceViewModel[] {
    return this.modules
  }

  public set solutionHardwareModules(value: SolutionHardwareInstanceViewModel[]) {
    this.setModules(value)
  }
}
</script>

<style lang="scss" scoped>

</style>