import ApiService from '../../services/api.service'
import { AccountModel, EgonlineConfigurationModel, MeterAssignmentModel, MinergieConfigurationModel, UserAssignmentModel } from '../models'

export default {
  async createAccountEcocoach(projectId: string, account: AccountModel): Promise<{ id: string }> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Ecocoach/${projectId}/account`, {
      ...account,
      projectId,
    } as AccountModel)
    return {
      id: response.data.id,
    }
  },
  async modifyAccountEcocoach(projectId: string, account: AccountModel): Promise<void> {
    await ApiService.put(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Ecocoach/${projectId}/account/${account.id}`, account)
  },
  async deleteAccountEcocoach(projectId: string, id: string): Promise<void> {
    await ApiService.delete(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Ecocoach/${projectId}/account/${id}`, {})
  },
  async assignMeterEcocoach(projectId: string, assignment: MeterAssignmentModel): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Ecocoach/${projectId}/meter/assign`, assignment)
  },
  async unassignMeterEcocoach(projectId: string, assignment: MeterAssignmentModel): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Ecocoach/${projectId}/meter/unassign`, assignment)
  },
  async assignUserEcocoach(projectId: string, assignment: UserAssignmentModel): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Ecocoach/${projectId}/user/assign`, assignment)
  },
  async unassignUserEcocoach(projectId: string, assignment: UserAssignmentModel): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Ecocoach/${projectId}/user/unassign`, assignment)
  },
  async linkProjectMinergie(projectId: string, minergieObjectId: string): Promise<MinergieConfigurationModel> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().consumptionQueryApi}/Minergie/${projectId}/link/${minergieObjectId}`, {})
    return response.data
  },
  async unlinkProjectMinergie(projectId: string): Promise<MinergieConfigurationModel> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().consumptionQueryApi}/Minergie/${projectId}/unlink`, {})
    return response.data
  },
  async verifyProjectMinergie(projectId: string): Promise<MinergieConfigurationModel> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().consumptionQueryApi}/Minergie/${projectId}/verify`, {})
    return response.data
  },
  async createAccountMinergie(projectId: string, account: AccountModel): Promise<{ id: string }> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Minergie/${projectId}/Account`, {
      ...account,
      projectId,
    } as AccountModel)
    return {
      id: response.data.id,
    }
  },
  async modifyAccountMinergie(projectId: string, account: AccountModel): Promise<void> {
    await ApiService.put(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Minergie/${projectId}/Account/${account.id}`, account)
  },
  async deleteAccountMinergie(projectId: string, id: string): Promise<void> {
    await ApiService.delete(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Minergie/${projectId}/Account/${id}`, { data: {} })
  },
  async assignMeterMinergie(projectId: string, assignment: MeterAssignmentModel): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Minergie/${projectId}/Meter/assign`, assignment)
  },
  async unassignMeterMinergie(projectId: string, assignment: MeterAssignmentModel): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Minergie/${projectId}/Meter/unassign`, assignment)
  },
  async linkProjectEgonline(projectId: string, egonlineProjectId: string): Promise<EgonlineConfigurationModel> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Egonline/${projectId}/link/${egonlineProjectId}`, {})
    return response.data
  },
  async unlinkProjectEgonline(projectId: string): Promise<EgonlineConfigurationModel> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Egonline/${projectId}/unlink`, {})
    return response.data
  },
  async createAccountEgonline(projectId: string, account: AccountModel): Promise<{ id: string }> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Egonline/${projectId}/account`, {
      ...account,
      projectId,
    } as AccountModel)
    return {
      id: response.data.id,
    }
  },
  async modifyAccountEgonline(projectId: string, account: AccountModel): Promise<void> {
    await ApiService.put(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Egonline/${projectId}/account/${account.id}`, account)
  },
  async deleteAccountEgonline(projectId: string, id: string): Promise<void> {
    await ApiService.delete(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Egonline/${projectId}/account/${id}`, { data: {} })
  },
  async assignMeterEgonline(projectId: string, assignment: MeterAssignmentModel): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Egonline/${projectId}/meter/assign`, assignment)
  },
  async unassignMeterEgonline(projectId: string, assignment: MeterAssignmentModel): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Egonline/${projectId}/meter/unassign`, assignment)
  },
  async assignEgonlineUser(projectId: string, assignment: UserAssignmentModel): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Egonline/${projectId}/Account/user/assign`, assignment)
  },
  async unassignEgonlineUser(projectId: string, assignment: UserAssignmentModel): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/Egonline/${projectId}/Account/user/unassign`, assignment)
  },
}
