import { MappingUiMutation, MappingUiState } from '@/store/modules/mappingUi/types'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<MappingUiState> = {
  [MappingUiMutation.addSolutionHardwareToCurrentManualMapping]({ currentManualMapping }, 
    payload: { type: 'input' | 'output', id: string, endpointId: string }) {
    currentManualMapping.type = payload.type,
    currentManualMapping.solutionHardwareInstanceId = payload.id
    currentManualMapping.solutionHardwareEndpointId = payload.endpointId
  },
  [MappingUiMutation.addDeviceToCurrentManualMapping]({ currentManualMapping }, 
    payload: { type: 'input' | 'output', id: string, endpointId: string }) {
    currentManualMapping.type = payload.type,
    currentManualMapping.deviceId = payload.id
    currentManualMapping.deviceEndpointId = payload.endpointId
  },
  [MappingUiMutation.addSolutionHardwareToCurrentAutoMapping]({ currentAutoMapping }, payload: { interface: string, id: string, slot: string }) {
    currentAutoMapping.interfaceType = payload.interface
    currentAutoMapping.solutionHardwareInstanceId = payload.id
    currentAutoMapping.solutionHardwareSlotInternalName = payload.slot
  },
  [MappingUiMutation.addDeviceToCurrentAutoMapping]({ currentAutoMapping }, payload: { interface: string, id: string, slot: string }) {
    currentAutoMapping.interfaceType = payload.interface
    currentAutoMapping.deviceId = payload.id
    currentAutoMapping.deviceSlotInternalName = payload.slot
  },
  [MappingUiMutation.addInstanceNumberToCurrentAutoMapping]({ currentAutoMapping }, payload: { instanceNumber: number }) {
    currentAutoMapping.instanceNumber = payload.instanceNumber
  },
  [MappingUiMutation.resetCurrentManualMapping](state) {
    state.currentManualMapping = {
      type: '',
      solutionHardwareInstanceId: '',
      solutionHardwareEndpointId: '',
      deviceId: '',
      deviceEndpointId: '',
    }
  },
  [MappingUiMutation.resetCurrentAutoMapping](state) {
    state.currentAutoMapping = {
      interfaceType: '',
      solutionHardwareInstanceId: '',
      solutionHardwareSlotInternalName: '',
      deviceId: '',
      deviceSlotInternalName: '',
      instanceNumber: -1,
    }
  },
  [MappingUiMutation.setInteracted](state, payload: boolean) {
    state.interacted = payload
  },
}
