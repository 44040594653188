export interface MappingUiState {
  interacted: boolean
  currentManualMapping: {
    type: '' | 'input' | 'output',
    deviceId: string,
    deviceEndpointId: string,
    solutionHardwareInstanceId: string,
    solutionHardwareEndpointId: string,
  }
  currentAutoMapping: {
    interfaceType: string,
    deviceId: string,
    deviceSlotInternalName: string,
    solutionHardwareInstanceId: string,
    solutionHardwareSlotInternalName: string,
    instanceNumber: number,
  }
}

export enum MappingUiAction {
  addSolutionHardwareToCurrentManualMapping = 'addSolutionHardwareToCurrentManualMapping',
  addDeviceToCurrentManualMapping = 'addDeviceToCurrentManualMapping',
  removeManualMapping = 'removeManualMapping',
  addSolutionHardwareToCurrentAutoMapping = 'addSolutionHardwareToCurrentAutoMapping',
  addDeviceToCurrentAutoMapping = 'addDeviceToCurrentAutoMapping',
  addInstanceNumberToCurrentAutoMapping = 'addInstanceNumberToCurrentAutoMapping',
  removeAutoMapping = 'removeAutoMapping',
}

export enum MappingUiMutation {
  addSolutionHardwareToCurrentManualMapping = 'addSolutionHardwareToCurrentManualMapping',
  addDeviceToCurrentManualMapping = 'addDeviceToCurrentManualMapping',
  addSolutionHardwareToCurrentAutoMapping = 'addSolutionHardwareToCurrentAutoMapping',
  addDeviceToCurrentAutoMapping = 'addDeviceToCurrentAutoMapping',
  addInstanceNumberToCurrentAutoMapping = 'addInstanceNumberToCurrentAutoMapping',
  resetCurrentManualMapping = 'resetCurrentManualMapping',
  resetCurrentAutoMapping = 'resetCurrentAutoMapping',
  setInteracted = 'setInteracted',
}

export enum MappingUiGetter {
  solutionHardwareInputs = 'solutionHardwareInputs',
  solutionHardwareOutputs = 'solutionHardwareOutputs',
  mappableDevicesOfRoom = 'mappableDevicesOfRoom',
  isManualMappingStarted = 'isManualMappingStarted',
  isAutoMappingStarted = 'isAutoMappingStarted',
  doesCurrentAutoMappingNeedInstanceNumber = 'doesCurrentAutoMappingNeedInstanceNumber',
  currentAutoMappingSolutionHardwareSlot = 'currentAutoMappingSolutionHardwareSlot',
  autoMappingsOfCurrentSolutionHardwareSlot = 'autoMappingsOfCurrentSolutionHardwareSlot',
  isCurrentManualMappingComplete = 'isCurrentManualMappingComplete',
  isCurrentAutoMappingComplete = 'isCurrentAutoMappingComplete',
  existingManualMappingsForSolutionHardwareEndpoint = 'existingManualMappingsForSolutionHardwareEndpoint',
  existingManualMappingsForDeviceEndpoint = 'existingManualMappingsForDeviceEndpoint',
  existingAutoMappingsForSolutionHardwareInstanceSlot = 'existingAutoMappingsForSolutionHardwareInstanceSlot',
  existingAutoMappingsForDeviceSlot = 'existingAutoMappingsForDeviceSlot',
  doesSolutionHardwareEndpointAcceptAdditionalManualMappings = 'doesSolutionHardwareEndpointAcceptAdditionalManualMappings',
  doesDeviceEndpointAcceptAdditionalManualMappings = 'doesDeviceEndpointAcceptAdditionalManualMappings',
  doesSolutionHardwareSlotAcceptAdditionalAutoMappings = 'doesSolutionHardwareSlotAcceptAdditionalAutoMappings',
  doesDeviceSlotAcceptAdditionalAutoMappings = 'doesDeviceSlotAcceptAdditionalAutoMappings',
  isCurrentlyManualMappingSolutionHardwareEndpoint = 'isCurrentlyManualMappingSolutionHardwareEndpoint',
  isCurrentlyManualMappingDeviceEndpoint = 'isCurrentlyManualMappingDeviceEndpoint',
  isCurrentlyAutoMappingSolutionHardwareSlot = 'isCurrentlyAutoMappingSolutionHardwareSlot',
  isCurrentlyAutoMappingDeviceAutoMappingSlot = 'isCurrentlyAutoMappingDeviceAutoMappingSlot',
  isMatchingSolutionHardwareManualMappingEndpoint = 'isMatchingSolutionHardwareManualMappingEndpoint',
  isMatchingDeviceManualMappingEndpoint = 'isMatchingDeviceManualMappingEndpoint',
  isMatchingSolutionHardwareAutoMappingSlot = 'isMatchingSolutionHardwareAutoMappingSlot',
  isMatchingDeviceAutoMappingSlot = 'isMatchingDeviceAutoMappingSlot',
  solutionHardwareIndex = 'solutionHardwareIndex',
  manualMappingSolutionHardwareEndpointDescription = 'manualMappingSolutionHardwareEndpointDescription',
  manualMappingDeviceEndpointDescription = 'manualMappingDeviceEndpointDescription',
  autoMappingSlotName = 'autoMappingSlotName',
  autoMappingSlotDescription = 'autoMappingSlotDescription',

  isInteracted = 'isInteracted',
}