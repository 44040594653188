<template>
  <div class="mapping-device-output" :class="{
    mapped: isMapped,
  }">
    <div class="header">
      <div class="indicator"></div>
      <div class="description">
        <div>
          <h3 :title="name">{{ name }}</h3>
          <p :title="`${description} (${endpoint.internalName})`">{{ description }}</p>
        </div>
      </div>
      <div class="action">
        <mapping-action-button
          v-if="addMappingActionVisible"
          :is-currently-mapping="isCurrentlyMapping"
          :disabled="!addMappingActionEnabled"
          @click="onMapped"
        />
      </div>
    </div>
    <div class="mappings">
      <div class="mapping"
        v-for="mapping in mappings"
        :key="mapping.id">
        <div class="description">
          <h3 :title="mappedSolutionHardwareEndpointName(mapping)">{{ mappedSolutionHardwareEndpointName(mapping) }}</h3>
          <p :title="mappedSolutionHardwareEndpointDescription(mapping)">{{ mappedSolutionHardwareEndpointDescription(mapping) }}</p>
        </div>
        <div class="action">
          <mapping-action-button
            :is-mapped="true"
            :is-currently-mapping="isCurrentlyMapping"
            :disabled="isAutoMappingStarted || isManualMappingStarted"
            @click="onUnmapped(mapping)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import MappingActionButton from '@/components/views/ecoSetupTool/steps/mapHardware/MappingActionButton.vue'

import { DeviceMappingModel, DeviceModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'

import { FunctionBlockEndpointModel } from '../../../../../../../eco-domain-store-modules/src/systemConfiguration/models'

const MappingUi = namespace('mappingUi')
const Resource = namespace('resource')

@Component({
  components: {
    MappingActionButton,
  },
})
export default class ManualMappingDeviceOutputEndpoint extends Vue {
  @Prop() public device: DeviceModel
  @Prop() public endpoint: FunctionBlockEndpointModel
  @Prop() public mappings: DeviceMappingModel[]

  @MappingUi.Getter public isManualMappingStarted
  @MappingUi.Getter public isAutoMappingStarted
  @MappingUi.Getter public isCurrentlyManualMappingDeviceEndpoint
  @MappingUi.Getter public doesDeviceEndpointAcceptAdditionalManualMappings
  @MappingUi.Getter public isMatchingDeviceManualMappingEndpoint
  @MappingUi.Getter public manualMappingDeviceEndpointDescription
  @MappingUi.Mutation public resetCurrentManualMapping
  @MappingUi.Action public addDeviceToCurrentManualMapping
  @MappingUi.Action public removeManualMapping
  @Resource.Getter public dictionary

  public get name() {
    return this.endpoint.name || this.dictionary(this.endpoint.nameResourceId)
  }

  public get description() {
    return this.endpoint.description || this.dictionary(this.endpoint.descriptionResourceId)
  }

  public get isMapped() {
    return this.mappings.length > 0
  }

  public get addMappingActionVisible(): boolean {
    return this.doesDeviceEndpointAcceptAdditionalManualMappings(
      this.device.id,
      this.endpoint)
  }

  public get addMappingActionEnabled(): boolean {
    if (this.isAutoMappingStarted) {
      return false
    }
    if (!this.isManualMappingStarted || this.isCurrentlyMapping) {
      return true
    }
    return this.isMatchingDeviceManualMappingEndpoint(
      this.device.id,
      this.endpoint)
  }

  public get isCurrentlyMapping(): boolean {
    return this.isCurrentlyManualMappingDeviceEndpoint(
      this.device.id,
      this.endpoint.id)
  }

  public mappedSolutionHardwareEndpointName(mapping: DeviceMappingModel) {
    return this.manualMappingDeviceEndpointDescription(mapping).name
  }

  public mappedSolutionHardwareEndpointDescription(mapping: DeviceMappingModel) {
    const data = this.manualMappingDeviceEndpointDescription(mapping)
    return `${data.index}|${data.description}`
  }

  public onMapped() {
    if (!this.isCurrentlyMapping) {
      this.addDeviceToCurrentManualMapping({
        type: 'output',
        id: this.device.id,
        endpointId: this.endpoint.id,
      })
    } else {
      this.resetCurrentManualMapping()
    }
  }
  
  public onUnmapped(mapping: DeviceMappingModel) {
    this.removeManualMapping(mapping)
  }
}
</script>

<style lang="scss" scoped>
.mapping-device-output {
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(to bottom, #1b344b, #0e131b);
  border: 1px inset #c2043e;
  margin-bottom: 10px;
  &.mapped {
    border: 1px inset #238d37;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .action {
      min-width: 41px;
      flex: 0;
    }
    .description {
      flex: 1;
      flex-basis: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .indicator {
      flex: 0;
      background-color: #c2043e;
      height: 10px;
      min-width: 30px;
      border-radius: 2px;
      margin-top: 10px;
      margin-right: 8px;
      margin-left: 8px;
    }
  }
  .mappings {
    .mapping {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .description {
        flex: 1;
        padding-left: 46px;
      }
      .action {
        flex: 0;
      }
    }
  }
}
</style>
