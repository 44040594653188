<template>
    <header>
        <div class="step__title">
            <h1>{{ title }}</h1>
            <h2>{{ subTitle }}</h2>
        </div>
    </header>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'WizardStepHeader',
  props: {
    /** 
     * the wizard step title
     */
    title: {
      type: String,
      default: '',
    },
    /** 
     * the wizard step sub-title
     */
    subTitle: {
      type: String,
      default: '',
    },
  },
})
</script>

<style lang="scss" scoped>
    header {
        height: 100px;
        margin-right: 35px;
        display: flex;
        align-items: center;
        justify-content: left;
        .step__title {
            margin-left: 0;
            h1 {
                text-transform: uppercase;
                text-align: left;
                color: #fff;
                font-size: 2em;
                font-weight: 200;
                font-family: Titillium Web,sans-serif;
            }
            h2 {
                color: #fff;
                font-size: 1.5em;
                font-weight: 100;
                text-transform: uppercase;
                font-style: italic;
                font-family: Titillium Web,sans-serif;
                padding-bottom: 16px;
            }
        }
    }
</style>

<docs>
A wizard step header

Usage example
```jsx
<wizard-step-header
  :title="'step title'"
  :sub-title="'step sub title'"
/>
```
</docs>