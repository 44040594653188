<template>
  <card header="ecoSetupTool Preview (readonly)">
    <template v-slot:actions>
    </template>
    <v-layout column class="efde-preview">
      <v-flex xs12 class="category">
        <expandable-card :title="'parameter.state' | translate">
          <p v-if="setupToolStatusControlDefinitions.length === 0">{{ 'parameter.missed' | translate }}</p>
          <div v-for="controlDefinition in setupToolStatusControlDefinitions"
            :key="controlDefinition.id"
            class="control"
          >
            <preview-control :control-definition="controlDefinition" :readonly="true" />
          </div>
        </expandable-card>
      </v-flex>
      <v-flex xs12 class="category">
        <expandable-card :title="'parameter.parameter' | translate">
          <p v-if="setupToolParameterControlDefinitions.length === 0">{{ 'parameter.missed' | translate }}</p>
          <div v-for="controlDefinition in setupToolParameterControlDefinitions"
            :key="controlDefinition.id"
            class="control"
          >
            <preview-control :control-definition="controlDefinition" :readonly="true" />
          </div>
        </expandable-card>
      </v-flex>
      <v-flex xs12 class="category">
        <expandable-card :title="'parameter.functiontest' | translate">
          <p v-if="setupToolFunctionTestControlDefinitions.length === 0">{{ 'parameter.missed' | translate }}</p>
          <div v-for="controlDefinition in setupToolFunctionTestControlDefinitions"
            :key="controlDefinition.id"
            class="control"
          >
            <preview-control :control-definition="controlDefinition" :readonly="true" />
          </div>
        </expandable-card>
      </v-flex>
    </v-layout>
  </card>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Card from '../../ui/Card.vue'
import CardAction from '../../ui/CardAction.vue'

import ExpandableCard from '../../ui/ExpandableCard.vue'

import { ControlDefinitionViewModel, ControlDefinitionViewModelV2, FunctionDefinitionViewModel } from '../../../store/modules/functionDefinitionEditorUi/models'

import PreviewControl from './PreviewControl.vue'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')

@Component({
  components: {
    Card,
    CardAction,
    ExpandableCard,
    PreviewControl,
  },
})
export default class ConfigurationPreviewDetails extends Vue {
  @FunctionDefinitionEditorUi.Getter public functionDefinition: FunctionDefinitionViewModel
  @FunctionDefinitionEditorUi.Getter public setupToolStatusControlDefinitions: Array<ControlDefinitionViewModel | ControlDefinitionViewModelV2>
  @FunctionDefinitionEditorUi.Getter public setupToolParameterControlDefinitions: Array<ControlDefinitionViewModel | ControlDefinitionViewModelV2>
  @FunctionDefinitionEditorUi.Getter public setupToolFunctionTestControlDefinitions: Array<ControlDefinitionViewModel | ControlDefinitionViewModelV2>
}
</script>
<style lang="scss">
.efde-preview {
  .category {
    padding: 0px 10px;
    .v-expansion-panel__body {
      padding: 10px;
    }
  }
}
</style>