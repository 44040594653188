import { RootState } from '@/store/types'
import { AppDisplayLevels, ConfigurationToolCategories, ControlModelV2, DeviceModel, EnergyDisplayLevels, RoomModel } from '@ecocoach/domain-store-modules/src/plcOperation/models'
import { PlcOperationGetter } from '@ecocoach/domain-store-modules/src/plcOperation/types'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { DropdownOption } from '@ecocoach/shared-components/src'
import { GetterTree } from 'vuex'
import { ParametrizationUiGetter, ParametrizationUiState } from './types'
import { ControlTypeV2 } from '@ecocoach/domain-store-modules/src/common'

const getVisibleControlForDeviceAndConfigurationToolCategory = (controls: ControlModelV2[], deviceId: string, category: ConfigurationToolCategories) => {
  return getVisibleControlsSorted(controls).filter(control => control.deviceId == deviceId && control.attributes?.configurationToolCategory === category)
}

const getVisibleControlsSorted = (controls: ControlModelV2[]) => {
  const childFilter = (c: ControlModelV2) => !c.parentControlId
  const visibilityConditionFilter = (c: ControlModelV2) => !c?.attributes?.visibilityCondition ||
    c.attributes.visibilityCondition.value === c.state![c.attributes.visibilityCondition.state]
  return controls
    .filter(control => childFilter(control) && visibilityConditionFilter(control))
    .sort((a, b) => (a.sortOrder - b.sortOrder))
}

export const getters: GetterTree<ParametrizationUiState, RootState> = {
  [ParametrizationUiGetter.rooms](_, __, rootState): RoomModel[] {
    const plcId = rootState.app.selectedPlcId
    return rootState.plcOperation.rooms.filter(r => r.plcId === plcId)
  },
  [ParametrizationUiGetter.selectedRoomId]({ selectedRoomId }): string {
    return selectedRoomId
  },
  [ParametrizationUiGetter.devicesOfRoom] (state, __, rootState): DeviceModel[] {
    const roomId = state.selectedRoomId
    return rootState.plcOperation.devices.filter(d => d.roomId === roomId)
  },
  [ParametrizationUiGetter.selectedDeviceId] ({ selectedDeviceId }): string {
    return selectedDeviceId
  },
  [ParametrizationUiGetter.isConfirmedDevice] (_, __, rootState) {
    return (deviceId: string): boolean => {
      return rootState.plcOperation.confirmedDeviceIds.includes(deviceId)
    }
  },
  [ParametrizationUiGetter.hasMessage] (_, __, ___, rootGetters) {
    return (deviceId: string): boolean => {
      const controls = rootGetters[`plcOperation/${PlcOperationGetter.filteredControls}`]
      return getVisibleControlsSorted(controls).some(c => c.deviceId === deviceId && c.type === ControlTypeV2.DeviceMessages && c.state![c.attributes.state]?.length > 0)
    }
  },
  [ParametrizationUiGetter.hasOpenAlarm] (_, __, rootState) {
    return (deviceId: string): boolean => {
      const alarmDefinitionIdsOfDevice = rootState.alarmDefinition.alarmDefinitions.filter(a => a.option.deviceId === deviceId).map(a => a.id)
      return rootState.alarm.customPlcAlarms.some(a => a.isOpen && alarmDefinitionIdsOfDevice.includes(a.alarmDefinitionId))
    }
  },
  [ParametrizationUiGetter.statusControlsOfSelectedDevice] ({ selectedDeviceId }, __, ___, rootGetters) {
    const controls = rootGetters[`plcOperation/${PlcOperationGetter.filteredControls}`]
    return getVisibleControlForDeviceAndConfigurationToolCategory(controls, selectedDeviceId, ConfigurationToolCategories.STATE)
  },
  [ParametrizationUiGetter.parameterControlsOfSelectedDevice] ({ selectedDeviceId }, __, ___, rootGetters) {
    const controls = rootGetters[`plcOperation/${PlcOperationGetter.filteredControls}`]
    return getVisibleControlForDeviceAndConfigurationToolCategory(controls, selectedDeviceId, ConfigurationToolCategories.PARAMETER)
  },
  [ParametrizationUiGetter.functionTestControlsOfSelectedDevice] ({ selectedDeviceId }, __, ___, rootGetters) {
    const controls = rootGetters[`plcOperation/${PlcOperationGetter.filteredControls}`]
    return getVisibleControlForDeviceAndConfigurationToolCategory(controls, selectedDeviceId, ConfigurationToolCategories.FUNCTION_TEST)
  },
  [ParametrizationUiGetter.chartConfigurationControlsOfSelectedDevice] ({ selectedDeviceId }, __, ___, rootGetters) {
    const controls = rootGetters[`plcOperation/${PlcOperationGetter.filteredControls}`]
    return getVisibleControlsSorted(controls).filter(control => control.deviceId === selectedDeviceId && 
      (control.attributes?.isUsedInCharts || control.attributes?.energyDisplayLevel == EnergyDisplayLevels.CONSUMER || control.attributes?.energyDisplayLevel == EnergyDisplayLevels.PRODUCER))
  },
  [ParametrizationUiGetter.isInteracted] ({ interacted }): boolean {
    return interacted
  },
  [ParametrizationUiGetter.nameOfActiveRelease] (_, __, rootState): string {
    if(rootState.plcOperation.activePlcConfigurationReleaseId) {
      const plcConfigurationRelease = rootState.plcConfiguration.releases
        .find(configurationRelease => configurationRelease.id === rootState.plcOperation.activePlcConfigurationReleaseId)
      if(plcConfigurationRelease) {
        return plcConfigurationRelease.name ? plcConfigurationRelease.name : '' 
      }
    }
    return ''
  },
  [ParametrizationUiGetter.descriptionOfActiveRelease] (_, __, rootState): string {
    if(rootState.plcOperation.activePlcConfigurationReleaseId) {
      const plcConfigurationRelease = rootState.plcConfiguration.releases
        .find(configurationRelease => configurationRelease.id === rootState.plcOperation.activePlcConfigurationReleaseId)
      if(plcConfigurationRelease) {
        return plcConfigurationRelease.description ? plcConfigurationRelease.description : '' 
      }
    }
    return ''
  },
  [ParametrizationUiGetter.appDisplayLevelOptions] (_, __, ___, rootGetters): DropdownOption[] {
    const resolveStringResource = rootGetters[`resource/${ResourceGetter.resolveStringResource}`]
    return [
      { id: AppDisplayLevels.NOT_DISPLAYED, label: resolveStringResource('appDisplayLevel.hidden') },
      { id: AppDisplayLevels.DISPLAYED_ON_FIRST_LEVEL, label: resolveStringResource('appDisplayLevel.allLevels') },
      { id: AppDisplayLevels.DISPLAYED_ON_THIRD_LEVEL, label: resolveStringResource('appDisplayLevel.deviceLevelOnly') },
    ]
  },
}