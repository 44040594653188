<template>
  <option-selector
    :title="'releases.template.title' | translate"
    :description="'releases.template.guide' | translate"
    :active="active"
    activeImage="UseTemplateChecked.svg"
    inactiveImage="UseTemplate.svg"
    @selected="onSelected"
  />
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import OptionSelector from './OptionSelector.vue'

@Component({
  components: {
    OptionSelector,
  },
})
export default class TemplateOptionSelector extends Vue {
  @Prop() public active: boolean

  @Emit('selected')
  public onSelected() {
    return
  }
}
</script>
