<template>
  <div class="projecManagementContainer">
    <table border="0" class="plcTable">
      <tr class="headerRow">
        <th valign="middle" class="descriptionCol">
          <div ref="readOnlyName" v-if="!isEditing">
            {{ project.name }}
            <v-chip v-if="project.isKioskModeEnabled" small :color="'blue'">{{ 'common.kioskmodeenabled' | translate }}</v-chip>
            <v-tooltip v-if="project.isKioskModeEnabled" bottom>
                <v-btn slot="activator" icon ripple :disabled="isInteracted" @click.stop="copyKioskUrl">
                  <v-icon>content_copy</v-icon>
                </v-btn>
                <span>{{'common.copykioskmodeurl' | translate}}</span>
            </v-tooltip>
          </div>
          <v-layout>
            <text-input
              v-if="isEditing"
              :value="projectNameInput"
              @input="projectNameInput = $event"
            />
            <checkbox-with-label v-if="isEditing"
              :text="'common.enablekioskmode' | translate"
              :value="isKioskModeEnabledInput"
              @checked="isKioskModeEnabledInput = !$event"
            />
          </v-layout>
        </th>
        <th :title="isEditing ? dictionary('common.button.save') : dictionary('common.name.edit')" valign="middle" class="actionIcon">
          <v-icon 
            v-if="!isEditing && !isInteracted"
            @click.stop="startEditing"
          >
            edit
          </v-icon>
          <v-icon 
            v-else-if="!isInteracted"
            @click.stop="onSave">
            save
          </v-icon>
        </th>
        <th :title="dictionary('common.button.delete')" valign="middle" class="actionIcon">
          <div class="deletionContainer">
            <div v-if="pending">
              <BaseSpinner />
            </div>
            <v-icon
              v-if="!isEditing"
              @click.stop="onDelete">
              delete_outline
            </v-icon>
          </div>
        </th>
        <th :title="dictionary('toggle')" valign="middle" class="actionIcon">
          <v-icon
            v-if="!isInteracted"
            @click.stop="expanded = !expanded">
              {{ expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
          </v-icon>
        </th>
      </tr>
      <tr v-if="expanded">
        <td colspan="4" style="padding: 10px;" class="deviceContainer">
          <plc-component
                v-for="plc in plcs"
                :key="plc.id"
                :projectId="project.id"
                :plc="plc"/>
          <add-plc-component :projectId="project.id" />
        </td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import BaseSpinner from '@/components/ui/BaseSpinner.vue'
import AddPlcComponent from '@/components/views/ecoPartnerTool/AddPlcComponent.vue'
import PlcComponent from '@/components/views/ecoPartnerTool/PlcComponent.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { backendEnvironment } from '@/environment'
import { PlcModel, ProjectModel } from '@ecocoach/domain-store-modules/src/project/models'

const ProjectManagementUi = namespace('projectManagementUi')
const Resource = namespace('resource')
const App = namespace('app')

@Component({
  components: {
    AddPlcComponent,
    BaseSpinner,
    PlcComponent,
  },
})
export default class ProjectComponent extends Vue {
  @Prop({type: Object, required: true }) public project: ProjectModel
  @Prop({type: Array, required: true }) public plcs: PlcModel[]
  @ProjectManagementUi.Action public updateProject: (project: ProjectModel) => Promise<void>
  @ProjectManagementUi.Action public deleteProject: (id: string) => Promise<void>
  @ProjectManagementUi.Getter public isInteracted: boolean
  @Resource.Getter public dictionary
  @App.Getter public selectedLanguage: string

  public pending = false
  public isEditing = false
  public projectNameInput = ''
  public isKioskModeEnabledInput = false
  public expanded = false
  
  public startEditing(){
    this.projectNameInput = this.project.name,
    this.isKioskModeEnabledInput = this.project.isKioskModeEnabled
    this.isEditing = true
  }

  public copyKioskUrl() {
    const queryString = `?projectId=${this.project.id}&language=${this.selectedLanguage}`
    const environment = backendEnvironment()
    this.$copyText(`https://${environment}kioskapp.ecocoa.ch/${queryString}`)
  }

  public onSave() {
    if (this.projectNameInput.trim() === '') {
      this.$store.commit('wizard/setToastContent', this.dictionary('error.project.name.empty'), {root: true})
      this.$store.commit('wizard/showToast', {root: true})
      return
    }
    this.pending = true
    this.updateProject({
      ...this.project,
      name: this.projectNameInput.trim(),
      isKioskModeEnabled: this.isKioskModeEnabledInput,
    } as ProjectModel).finally(() => this.pending = false)
    this.isEditing = false
  }

  public onDelete() {
    if (this.plcs.length > 0) {
      this.$store.commit('wizard/setToastContent', this.dictionary('partner.delete.project.prevent'), {root: true})
      this.$store.commit('wizard/showToast', {root: true})
      return
    }
    this.pending = true
    this.deleteProject(this.project.id).finally(() => this.pending = false)
  }
}
</script>
<style lang="scss" scoped>
.plcTable {
  width: 100%;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-spacing: 0px;
  text-align: right;
  th {
    padding: 10px 10px;
    font-weight: normal;
    &.descriptionCol {
      font-weight: bold;
      text-align: left;
      width: 80%;
      input {
        width: 100%;
        border-color: white;
        border-width: 1px;
        border-style: solid;
        padding-left: 5px;
        padding-right: 5px;
        overflow: hidden;
      }
    }
    &.actionIcon {
      font-weight: bold;
      width: 5%;
      cursor: pointer;
      .material-icons {
        max-width: 24px;
        color: white;
      }
    }
    .deletionContainer {
      display: flex;
      flex-direction: row;
    }
  }
  tr {
    
    .deviceContainer {
      background: linear-gradient(to bottom, #0f3a64, #0f3a64); 
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #0f3a64), color-stop(0.6, #0f3a64));
    }
  }
  .headerRow {
    background: linear-gradient(to bottom, #0f3a64, #0f3a64); 
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #0f3a64), color-stop(0.6, #0f3a64));
  }
  .authDiv {
    display: flex;
    flex-direction: row;
    margin-bottom: -5px;
    > div {
      margin-left: 5px;
      cursor: pointer;
    }
  }
}
</style>