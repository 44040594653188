import { RootState } from '@/store/types'
import { ReleaseModel } from '@ecocoach/domain-store-modules/src/plcConfiguration/models'
import { ActionTree } from 'vuex'
import { ArchitectureType } from '../../../../../eco-domain-store-modules/src/common'
import { PlcConfigurationAction, PlcConfigurationMutation } from '../../../../../eco-domain-store-modules/src/plcConfiguration/types'
import { AppGetter } from '../app/types'
import { ReleaseProperties } from './models'
import { ReleaseUiAction, ReleaseUiMutation, ReleaseUiState } from './types'

export const actions: ActionTree<ReleaseUiState, RootState> = {
  async [ReleaseUiAction.setProperties]({ commit, dispatch, rootState }, payload: { releaseId: string, input: ReleaseProperties }): Promise<void> {
    try {
      commit(ReleaseUiMutation.setInteracted, true)
      const release = rootState.plcConfiguration.releases.find(r => r.id === payload.releaseId)!
      const input: ReleaseModel = {
        ...release,
        ...payload.input,
      }
      await dispatch(`plcConfiguration/${PlcConfigurationAction.updateRelease}`, input, { root: true })
    } finally {
      commit(ReleaseUiMutation.setInteracted, false)
    }
  },
  async [ReleaseUiAction.createRelease]({ commit, dispatch, rootGetters }, architectureType: ArchitectureType): Promise<void> {
    try {
      commit(ReleaseUiMutation.setInteracted, true)
      const payload = { 
        plcId: rootGetters[`app/${AppGetter.selectedPlcId}`],
        userIdentifier: rootGetters[`app/${AppGetter.userIdentifier}`],
        architectureType,
      }
      await dispatch(`plcConfiguration/${PlcConfigurationAction.createRelease}`, payload, { root: true })
    } finally {
      commit(ReleaseUiMutation.setInteracted, false)
    }
  },
  async [ReleaseUiAction.importRelease]({ commit, dispatch, rootGetters }, file: File): Promise<void> {
    try {
      commit(ReleaseUiMutation.setInteracted, true)
      const payload = { 
        plcId: rootGetters[`app/${AppGetter.selectedPlcId}`],
        userIdentifier: rootGetters[`app/${AppGetter.userIdentifier}`],
        file,
      }
      await dispatch(`plcConfiguration/${PlcConfigurationAction.importRelease}`, payload, { root: true })
    } finally {
      commit(ReleaseUiMutation.setInteracted, false)
    }
  },
  async [ReleaseUiAction.copyRelease]({ commit, dispatch, rootGetters }, releaseId: string): Promise<void> {
    try {
      commit(ReleaseUiMutation.setInteracted, true)
      const payload = {
        plcId: rootGetters[`app/${AppGetter.selectedPlcId}`],
        releaseId,
        applyTemplateOperationData: false,
        userIdentifier: rootGetters[`app/${AppGetter.userIdentifier}`],
      }
      await dispatch(`plcConfiguration/${PlcConfigurationAction.copyRelease}`, payload, { root: true })
    } finally {
      commit(ReleaseUiMutation.setInteracted, false)
    }
  },
  async [ReleaseUiAction.copyReleaseTemplate]({ commit, dispatch, rootGetters }, input: { releaseId: string, applyTemplateOperationData: boolean })
  : Promise<void> {
    try {
      commit(ReleaseUiMutation.setInteracted, true)
      const payload = {
        plcId: rootGetters[`app/${AppGetter.selectedPlcId}`],
        releaseId: input.releaseId, 
        applyTemplateOperationData: input.applyTemplateOperationData,
        userIdentifier: rootGetters[`app/${AppGetter.userIdentifier}`],
      }
      await dispatch(`plcConfiguration/${PlcConfigurationAction.copyRelease}`, payload, { root: true })
    } finally {
      commit(ReleaseUiMutation.setInteracted, false)
    }
  },
  async [ReleaseUiAction.deleteRelease]({ commit, dispatch }, releaseId: string): Promise<void> {
    try {
      commit(ReleaseUiMutation.setInteracted, true)
      await dispatch(`plcConfiguration/${PlcConfigurationAction.deleteRelease}`, releaseId, { root: true })
    } finally {
      commit(ReleaseUiMutation.setInteracted, false)
    }
  },
  async [ReleaseUiAction.upgradeRelease]({ commit, dispatch, rootState }, useInTestDefinitions: boolean): Promise<void> {
    try {
      commit(ReleaseUiMutation.setInteracted, true)
      const releaseId = rootState.plcConfiguration.releaseId
      const language = rootState.app.selectedLanguage
      await dispatch(`plcConfiguration/${PlcConfigurationAction.upgradeRelease}`, { releaseId, useInTestDefinitions, language }, { root: true })
    } finally {
      commit(ReleaseUiMutation.setInteracted, false)
    }
  },
  async [ReleaseUiAction.loadReleaseDetails]({ commit, dispatch, rootState }, releaseId: string): Promise<void> {
    try {
      commit(ReleaseUiMutation.setInteracted, true)
      const payload = {
        releaseId, 
        language: rootState.app.selectedLanguage,
      }
      await dispatch(`plcConfiguration/${PlcConfigurationAction.loadReleaseDetails}`, payload, { root: true })
    } finally {
      commit(ReleaseUiMutation.setInteracted, false)
    }
  },
  async [ReleaseUiAction.downloadRelease]({ commit, dispatch }, releaseId: string): Promise<void> {
    try {
      commit(ReleaseUiMutation.setInteracted, true)
      await dispatch(`plcConfiguration/${PlcConfigurationAction.downloadRelease}`, releaseId, { root: true })
    } finally {
      commit(ReleaseUiMutation.setInteracted, false)
    }
  },
  async [ReleaseUiAction.exportRelease]({ commit, dispatch }, releaseId: string): Promise<void> {
    try {
      commit(ReleaseUiMutation.setInteracted, true)
      await dispatch(`plcConfiguration/${PlcConfigurationAction.exportRelease}`, releaseId, { root: true })
    } finally {
      commit(ReleaseUiMutation.setInteracted, false)
    }
  },
  async [ReleaseUiAction.clearReleaseUpgradeLogEntries]({ commit }): Promise<void> {
    try {
      commit(ReleaseUiMutation.setInteracted, true)
      commit(`plcConfiguration/${PlcConfigurationMutation.setDeviceUpgradeInfos}`, [], { root: true })
    } finally {
      commit(ReleaseUiMutation.setInteracted, false)
    }
  },
}
