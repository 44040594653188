var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"plc-connection-table",attrs:{"headers":_vm.headers,"items":_vm.plcOperationStateHistoryItems,"pagination":_vm.pagination,"item-key":"id","custom-filter":_vm.filteredPlcOperationStateHistoryItems,"search":'',"rows-per-page-items":[10]},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',{staticClass:"table-header-row"},_vm._l((props.headers),function(header){return _c('th',{key:header.value,class:[
          'column', 
          header.align === 'center' ? 'text-xs-center' : 'text-xs-left',
          header.sortable !== false ? 'sortable' : '',
          _vm.pagination.descending ? 'desc' : 'asc', 
          header.value === _vm.pagination.sortBy ? 'active' : ''
        ],style:({ 
          display: header.hidden === true ? 'none' : 'table-cell',
          width: header.width,
        })},[_c('div',{staticClass:"table-header",on:{"click":function($event){return _vm.changeSort(header)}}},[_vm._v(" "+_vm._s(_vm._f("translate")(header.text))+" "),(header.sortable !== false)?_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_upward")]):_vm._e()],1),(header.type === 'multiselect')?_c('table-filter-multi-select',{attrs:{"options":header.options,"values":header.selectedValues,"placeholder":_vm._f("translate")(header.placeholder)},on:{"selected":header.selected,"cleared":header.cleared}}):_vm._e(),(header.type === 'date')?_c('table-filter-date',{attrs:{"value":header.filter,"placeholder":_vm._f("translate")(header.placeholder),"language":header.language},on:{"changed":header.changed}}):_vm._e()],1)}),0)]}},{key:"items",fn:function(props){return [_c('tr',{key:props.item.id},[_c('td',{attrs:{"title":props.item.order}}),_c('td',[_c('plc-connection-history-table-status',{attrs:{"model":props.item}})],1),_c('td',[_vm._v(_vm._s(props.item.dateTimeFormatted))])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }