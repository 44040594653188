import { actions } from '@/store/modules/alarmLogUi/actions'
import { getters } from '@/store/modules/alarmLogUi/getters'
import { mutations } from '@/store/modules/alarmLogUi/mutations'
import { state } from '@/store/modules/alarmLogUi/state'
import { AlarmLogUiAction, AlarmLogUiGetter, AlarmLogUiMutation, AlarmLogUiState } from '@/store/modules/alarmLogUi/types'
import { RootState } from  '@/store/types'
import { Module } from 'vuex'

const namespaced: boolean = true
export const alarmLogUi: Module<AlarmLogUiState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}

export const types = {
  action: AlarmLogUiAction,
  mutation: AlarmLogUiMutation,
  getter: AlarmLogUiGetter,
}

export const domain = 'alarmLogUi'

export const vuexModule = {
  [domain]: alarmLogUi,
}