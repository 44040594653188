<template>
  <div class="clampWithActions">
    <v-tooltip bottom v-if="clamp.obsolete">
      <v-btn slot="activator" icon flat small class="actionButton">
        <v-icon>warning</v-icon>
      </v-btn>
      <span>{{'common.button.obsolete'| translate}}</span>
    </v-tooltip>
    <description-popover
      class="popUpButton"
      :header="clamp.name"
      :content="`Version: ${clamp.version}<br>${clamp.description}`"
    />
    <v-tooltip bottom>
      <v-btn @click="onDelete" slot="activator" icon flat small class="actionButton">
        <v-icon>delete_outline</v-icon>
      </v-btn>
      <span>{{'common.button.delete'| translate}}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <img
        slot="activator"
        class="clamp"
        :src="image(clamp.imageResourceId)"
        :alt="clamp.imageResourceId"
        :data-eco-image-resource-id="clamp.imageResourceId"
      />
      <span>{{ clamp.name }}</span>
    </v-tooltip>
    <confirm-dialog
      :show="showDeleteConfirmationDialog"
      :header="'common.button.delete' | translate"
      :text="'error.clamp.has.mappings' | translate"
      :confirm-button-text="'common.button.delete' | translate"
      :cancel-button-text="'common.button.cancel' | translate"
      :disabled="isInteracted"
      @confirmed="onDeleteConfirmed"
      @cancelled="showDeleteConfirmationDialog = false"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { SolutionHardwareInstanceViewModel } from '../../../../../store/modules/solutionHardwareUi/models'

const SolutionHardwareUi = namespace('solutionHardwareUi')
const Resource = namespace('resource')

@Component({
  components: {
  },
})
export default class Clamp extends Vue {
  @Prop() public clamp: SolutionHardwareInstanceViewModel
  @Resource.Getter public image
  @SolutionHardwareUi.Getter public isInteracted: boolean
  @SolutionHardwareUi.Getter public isMappedSolutionHardwareInstance: (id: string) => boolean
  @SolutionHardwareUi.Action public deleteSolutionHardwareInstance: (id: string) => Promise<void>

  public showDeleteConfirmationDialog = false

  public onDelete() {
    if (!this.isMappedSolutionHardwareInstance(this.clamp.id)) {
      this.deleteSolutionHardwareInstance(this.clamp.id)
    } else {
      this.showDeleteConfirmationDialog = true
    }
  }

  public onDeleteConfirmed() {
    this.deleteSolutionHardwareInstance(this.clamp.id)
    this.showDeleteConfirmationDialog = false
  }
}
</script>

<style lang="scss" scoped>
.clampWithActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
img.clamp {
  height: 315px;
  color: white;
  cursor: grab;
}
.actionButton {
  padding: 0px;
  margin: 4px;
}
</style>