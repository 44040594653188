<template>
  <eco-labeled-control :context="contextV2" :attributes="attributes" class="color-control">
    <color-gradient-picker 
      :color-gradient="gradient"
      :disabled="isInteracted"
      @selected="onGradientSelected"
    />
  </eco-labeled-control>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import ColorGradientPicker from '@/components/ui/ColorGradientPicker.vue'
import { DeviceModel, EnergyStatusItemModel, SetEnergyStatusItemColorInput } from '../../../../../../../eco-domain-store-modules/src/plcOperation/models'
import { EcoLabeledControl } from '@ecocoach/shared-components/src'
import { BaseControlAttributes, ControlContextV2 } from '@ecocoach/shared-components/src/components/deviceControls/v2/models'

const Resource = namespace('resource')
const ParametrizationUi = namespace('parametrizationUi')

@Component({
  components: {
    ColorGradientPicker,
    EcoLabeledControl,
  },
})
export default class EnergyStatusItemColor extends Vue {
  @Prop() public device: DeviceModel
  @Prop() public energyStatusItem: EnergyStatusItemModel
  @Resource.Getter public dictionary
  @ParametrizationUi.Getter public isInteracted: boolean
  @ParametrizationUi.Action public setEnergyStatusItemColor: (payload: SetEnergyStatusItemColorInput) => Promise<void>

  public get contextV2(): ControlContextV2 {
    return {
      resolveStringResource: (resourceKey: string): string => {
        return this.dictionary(resourceKey)
      },
    } as ControlContextV2
  }

  public get attributes() {
    return {
      label: this.device.energyStatusItems.length > 1
        ? `${this.dictionary('parameter.statusviewcolor')}: ${this.dictionary(`EnergyStatusItemType_${this.energyStatusItem.type}`)}`
        : 'parameter.statusviewcolor',
      tooltip: 'common.colorinstatusview.label',
    } as BaseControlAttributes
  }

  public get gradient() {
    return this.energyStatusItem.colorGradient ?? ''
  }

  public onGradientSelected(colorGradient: string) {
    this.setEnergyStatusItemColor({
      deviceId: this.device.id,
      energyStatusItemId: this.energyStatusItem.id,
      colorGradient,
    } as SetEnergyStatusItemColorInput)
  }
}
</script>
<style lang="scss" scoped>
.color-control {
  padding-bottom: 15px;
}
</style>
