<template>
    <expandable-card
      :icon="device.icon"
      :title="device.name"
      :description-popup-title="fbName"
      :description-popup-text="fbTooltip"
      :open="expanded"
      class="expandable-card"
    >
        <v-layout class="cardBody" style="padding: 0 12px 12px 12px;">
            <v-flex v-if="functionBlock.autoMappingSlots">
                <auto-mapping-device-slot 
                    v-for="slot in functionBlock.autoMappingSlots"
                    :key="slot.internalName"
                    :auto-mapping-slot="slot"
                    :device="device"
                    :function-block="functionBlock"
                    :data-eco-name-resource-id="slot.nameResourceId"
                    :data-eco-description-resource-id="slot.descriptionResourceId"
                />
            </v-flex>
        </v-layout>
        <v-layout :class="['cardBody', isAutoMappingStarted ? 'disabled' : '']" style="padding: 0 12px 12px 12px;" row>
            <v-flex xs6>
                <v-layout row wrap>
                    <v-flex style="padding: 0px 12px 0px 0px"
                        v-for="(input) in functionBlock.hardwareMappableEndpoints.filter(point => point.isMappableHardwareInput)"
                        :key="input.id"
                        :class="[
                            { xs6: functionBlock.hardwareMappableEndpoints.filter(point => point.isMappableHardwareInput).length > 4 },
                            { xs12: functionBlock.hardwareMappableEndpoints.filter(point => point.isMappableHardwareInput).length <= 4 }
                        ]">
                        <manual-mapping-device-input-endpoint
                          :device="device"
                          :endpoint="input"
                          :mappings="existingManualMappingsForDeviceEndpoint(device.id, input.id)"
                          :data-eco-name-resource-id="input.nameResourceId"
                          :data-eco-description-resource-id="input.descriptionResourceId"
                        />
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs6>
                <v-layout row wrap>
                    <v-flex style="padding: 0px 0px 0px 12px;"
                        v-for="(output) in functionBlock.hardwareMappableEndpoints.filter(point => point.isMappableHardwareOutput)"
                        :key="output.id"
                        :class="[
                            { xs6: functionBlock.hardwareMappableEndpoints.filter(point => point.isMappableHardwareOutput).length > 4 },
                            { xs12: functionBlock.hardwareMappableEndpoints.filter(point => point.isMappableHardwareOutput).length <= 4 }
                        ]">
                        <manual-mapping-device-output-endpoint
                          :device="device"
                          :endpoint="output"
                          :mappings="existingManualMappingsForDeviceEndpoint(device.id, output.id)"
                          :data-eco-name-resource-id="output.nameResourceId"
                          :data-eco-description-resource-id="output.descriptionResourceId"
                        />
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </expandable-card>
</template>

<script lang="ts">
import ExpandableCard from '../../../../ui/ExpandableCard.vue'

import AutoMappingDeviceSlot from '@/components/views/ecoSetupTool/steps/mapHardware/AutoMappingDeviceSlot.vue'
import ManualMappingDeviceInputEndpoint from '@/components/views/ecoSetupTool/steps/mapHardware/ManualMappingDeviceInputEndpoint.vue'
import ManualMappingDeviceOutputEndpoint from '@/components/views/ecoSetupTool/steps/mapHardware/ManualMappingDeviceOutputEndpoint.vue'

import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { DeviceModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'

import { FunctionBlockModel } from '../../../../../../../eco-domain-store-modules/src/systemConfiguration/models'

const { Getter } = namespace('resource')
const MappingUi = namespace('mappingUi')

@Component({
  components: {
    AutoMappingDeviceSlot,
    ExpandableCard,
    ManualMappingDeviceInputEndpoint,
    ManualMappingDeviceOutputEndpoint,
  },
})
export default class DeviceCard extends Vue {
    @Prop() public device: DeviceModel
    @Prop() public functionBlock: FunctionBlockModel
    @Prop({ default: true }) public expanded: boolean
    @Getter public dictionary
    @MappingUi.Getter public existingManualMappingsForDeviceEndpoint
    @MappingUi.Getter public isAutoMappingStarted

    public get fbName() {
      return this.functionBlock.name || this.dictionary(this.functionBlock.name)
    }

    public get fbTooltip() {
      return `Version: ${this.functionBlock.version}<br>${this.functionBlock.description || this.dictionary(this.functionBlock.descriptionResourceId)}`
    }
}
</script>

<style lang="scss" scoped>
.expandable-card {
    margin-bottom: 20px;
}
</style>
