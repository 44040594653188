<template>
    <input
        type="text"
        class="text-field"
        :value="this.value"
        :placeholder="this.placeholder"
        @input="changesHandler($event.target.value)">
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TextField extends Vue {
  @Prop({type: String}) public value: string
  @Prop({type: String}) public placeholder: string
  @Prop({type: Function}) public changesHandler: (input : string) => void
}
</script>

<style lang="scss" scoped>
  input.text-field {
    height: 40px;
    line-height: 40px;
    border: 0px;
    font-size: 16px;
    font-family: Titillium Web,sans-serif;
    padding: 0 20px;
    font-weight: 700;
    margin: 25px 0px 0px 0px;
    background-color: #fff;
    color: #000;
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 700;
      color: #222;
      font-family: Titillium Web,sans-serif;
    }
  }
</style>