import { AlarmDefinitionModel } from '@ecocoach/domain-store-modules/src/alarmDefinition/models'
import { DropdownOption } from '@ecocoach/shared-components/src'

export interface AlarmDefinitionViewModel extends AlarmDefinitionModel {
  displayName: string
  displayMessage: string
  alarmConditionText: string
  controlName: string
}

export interface AlarmDefinitionTableFilterSettings {
  levels: string[]
  name: string
  controlName: string
  deviceName: string
  roomName: string
  message: string
}

export interface AlarmDefinitionEditDialogViewModel extends AlarmDefinitionModel {
  show: boolean
  isCreating: boolean
  controls: DropdownOption[]
  operators: DropdownOption[]
  values: DropdownOption[]
}

export const AlarmDefinitionsTableFilterSettingsStorageKey = 'alarmDefinitionsFilterSettings'
