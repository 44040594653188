export interface AlarmUiState {
  dialogOpened: boolean,
}

export enum AlarmUiAction {
  closeAlarmDialog = 'closeAlarmDialog',
  openAlarmDialog = 'openAlarmDialog',
}

export enum AlarmUiMutation {
  closeDialog = 'closeDialog',
  openDialog = 'openDialog',
}

export enum AlarmUiGetter {
  isDisturbed = 'isDisturbed',
  hasServiceAlarm = 'hasServiceAlarm',
  messageAlarmsText = 'messageAlarmsText',
  isDialogOpen = 'isDialogOpen',
  isAlarmTriggered = 'isAlarmTriggered',
  serviceAlarmTime = 'serviceAlarmTime',
  messageAlarmTime = 'messageAlarmTime',
  showPlcStateAlarm = 'showPlcStateAlarm',
  isPlcOffline = 'isPlcOffline',
  plcNotOffline = 'plcNotOffline',
  plcOfflineTimeStamp = 'plcOfflineTimeStamp',
}