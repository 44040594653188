import ApiService from '../services/api.service'
import { EnergyStatusDevice } from './models'

export default {
  async loadEnergyData(projectId: string, language: string): Promise<{ data: EnergyStatusDevice[], projectName: string}> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationQueryApi}/Projects/${projectId}/energyStatusData?resourceLanguage=${language}`)
    response.data.value.forEach(device => device.items.forEach(item => item.value = null))
    return {
      data: response.data.value,
      projectName: '',
    }
  },
  async loadKioskEnergyData(projectId: string, language: string): Promise<{ data: EnergyStatusDevice[], projectName: string}> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationQueryApi}/Kiosk/${projectId}/energyStatusData?resourceLanguage=${language}`)
    response.data.value.forEach(device => device.items.forEach(item => item.value = null))
    return {
      data: response.data.value,
      projectName: response.data.projectName,
    }
  },
}
