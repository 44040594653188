import { RootState } from '@/store/types'
import { PlcConnectionAction } from '@ecocoach/domain-store-modules/src/plcConnection/types'
import AppDataStorageService from '@ecocoach/domain-store-modules/src/services/appdatastorage.service'
import { intersectArrays } from '@ecocoach/domain-store-modules/src/utils'
import { DropdownOption } from '@ecocoach/shared-components/src'
import { ActionTree } from 'vuex'
import { PlcConnectionHistoryTableFilterSettings, PlcConnectionHistoryTableFilterSettingsStorageKey } from './models'
import { PlcConnectionHistoryUiAction, PlcConnectionHistoryUiGetter, PlcConnectionHistoryUiMutation, PlcConnectionHistoryUiState } from './types'

export const actions: ActionTree<PlcConnectionHistoryUiState, RootState> = {
  async [PlcConnectionHistoryUiAction.openPlcConnectionHistoryForPlc] ({ commit, dispatch }, plcId: string): Promise<void> {
    try {
      commit(PlcConnectionHistoryUiMutation.setInteracted, true)
      await dispatch(`plcConnection/${PlcConnectionAction.loadPlcOperationStateHistory}`, plcId, { root: true })
      commit(PlcConnectionHistoryUiMutation.setSelectedPlcId, plcId)
    } finally {
      commit(PlcConnectionHistoryUiMutation.setInteracted, false)
    }
  },
  async [PlcConnectionHistoryUiAction.closePlcConnectionHistory] ({ commit }): Promise<void> {
    commit(PlcConnectionHistoryUiMutation.setSelectedPlcId, '')
  },
  async [PlcConnectionHistoryUiAction.loadFilterSettings] ({ state, getters }): Promise<void> {
    const loadedSettings = await AppDataStorageService
      .getObject<PlcConnectionHistoryTableFilterSettings>(PlcConnectionHistoryTableFilterSettingsStorageKey)
    const validStatuses = (getters[PlcConnectionHistoryUiGetter.statuses] as DropdownOption[]).map(o => o.id as string)
    state.filterSettings.statuses = intersectArrays(validStatuses, loadedSettings?.statuses ?? [])
    state.filterSettings.date = loadedSettings?.date ?? ''
  },
  async [PlcConnectionHistoryUiAction.updateFilterSettings] ({ state }, payload: { property: string, value: any }): Promise<void> {
    state.filterSettings[payload.property] = payload.value
    await AppDataStorageService.setObject(PlcConnectionHistoryTableFilterSettingsStorageKey, state.filterSettings)
  },
}
