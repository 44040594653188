<template>
  <div class="action">
    <v-btn icon flat dark style="margin: 0; margin-right: 5px;" 
      :disabled="disabled" 
      @click="handleSlotClick">
      <v-icon v-if="isCurrentlyMapping" flat icon dark>gps_fixed</v-icon>
      <v-icon v-else-if="isMapped" flat icon dark>gps_off</v-icon>
      <v-icon v-else flat icon dark>gps_not_fixed</v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MappingActionButton extends Vue {
  @Prop({ default: false }) public isMapped: boolean
  @Prop({ default: false }) public isCurrentlyMapping: boolean
  @Prop({ default: false }) public disabled: boolean

  public handleSlotClick() {
    if (!this.disabled) {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.action {
  color: white;
}
</style>