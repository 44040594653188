<template>
  <v-flex xs12 row wrap align-center class="file-select">
    <label 
      :for="'file-upload' + id"
      class="base-text-box"
      :class="{ disabled: disabled }"
    >
      <span v-if="currentFile">{{currentFile.name}}</span>
      <span class="placeholder" v-else>{{placeholder}}</span>
    </label>
    <div class="upload-button">
      <v-btn 
        @click="openFileDialog" 
        flat 
        icon 
        class="icon-button" 
        :disabled="disabled" 
        :class="{ disabled: disabled }"
      >
        <base-material-icon iconName="cloud_upload"/>
      </v-btn>
    </div>
    <input 
      :id="'file-upload' + id"
      type="file" 
      ref="input"
      :accept="fileType"
      @click="openFileDialog" 
      @change="emitFileChanged"
      :disabled="disabled">
  </v-flex>
</template>
<script lang="ts">
import Vue from 'vue'

import BaseMaterialIcon from '../base/BaseMaterialIcon.vue'

export default Vue.extend({
  name: 'FileSelect',
  components: {
    BaseMaterialIcon,
  },
  props: {
    /** 
     * id, provide it in case of several inputs in the same page
     */
    id: {
      type: String,
      default: '',
    },
    /** 
     * initial value of the input
     */
    value: {
      type: File,
      default: undefined,
    },
    /** 
     * optional value for the accept attribute
     * examples: 
     *  - a specific extension: '.xml'
     *  - several extension: '.doc, .docx'
     *  - image files: 'image/*'
     */
    fileType: {
      type: String,
      default: '',
    },
    /** 
     * disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /** 
     * optional placeholder text, displayed in case nothing is input
     */
    placeholder: {
      type: String,
      default: 'Select a file',
    },
  },
  data() {
    return {
      currentFile: (this as any).value as File,
    }
  },
  methods: {
    emitFileChanged(e) {
      (this as any).currentFile = e.target.files[0]
      /**
       * changed event
       *
       * @event changed
       * @type {File}
       */
      this.$emit('changed', (this as any).currentFile)
    },
    openFileDialog() {
      const input:any = this.$refs.input
      input.value = null
      input.click()
    },
  },
})
</script>
<style lang="scss">
input[type="file"] {
    display: none;
}
.file-select {
    margin: $margin-default;
    display: flex;
    flex-direction: row;
    margin: 0px;
    padding: 10px 10px;
    .v-btn {
      margin: 0px;
    }
    .base-text-box {
        flex-grow: 1;
    }
    .upload-button {
      width: 30px;
        .icon-button {
          color: $fore-color-primary;
          font-family: $font-family-primary;
          outline: none;
          &.disabled {
            opacity: 0.4;
          }
        }
    }
    label {
        font-family: $font-family-primary;
        height: 100%;
        width: 100%;
        border-width: $default-border-width;
        border-style: solid;
        border-color: $fore-color-primary;
        padding: $default-padding;
        color: $fore-color-primary;
        background: transparent;
        outline: none;
        &.disabled {
          color: $fore-color-disabled;
        }
      .placeholder {
        color: $fore-color-disabled;
      }
    }
}
</style>

<docs>
A file picker

Usage example
```js
new Vue({
  methods: {
    onChange(file) {
      console.log(file)
    }
  },
  template: `<file-select 
    id="1"
    file-type=".doc, .docx"
    placeholder="Select a word document"
    @changed="onChange"/>`
})
```

Disabled
```jsx
<file-select id="2" placeholder="Disabled, cannot select" disabled />
```
</docs>