<template>
  <div class="device-icon-block">
    <available-io
      :isInput="true"
      :pinAmount="amountOfHardwareMappableInputEndpointsById(device.functionBlockDefinitionId)" 
    />
    <icon-chooser-popover v-if="allowChangeIcon"
      :disabled="disabled"
      :icons="deviceIcons"
      :show-label="false"
      :selected="device.icon"
      :icon-size="50"
      @iconSelected="updateIcon"
    />          
    <svg-icon v-else :icon="device.icon" width="50" height="50"/>
    <available-io
      :isOutput="true"
      :pinAmount="amountOfHardwareMappableOutputEndpointsById(device.functionBlockDefinitionId)"
    />
  </div>
</template>

<script lang="ts">
import IconChooserPopover from '@/components/ui/IconChooserPopover.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AvailableIo from './AvailableIo.vue'

import SvgIcon from '../../../../ui/SvgIcon.vue'

import { DeviceModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'
import { ResourceCategory, ResourceModel } from '../../../../../../../eco-domain-store-modules/src/resource/models'

const FunctionBlockUi = namespace('functionBlockUi')
const Resource = namespace('resource')
const DeviceUi = namespace('deviceUi')

@Component({
  components: {
    AvailableIo,
    IconChooserPopover,
    SvgIcon,
  },
})
export default class DeviceWithEndpointIndicators extends Vue {
  @Prop({ type: Boolean, default: false }) public allowChangeIcon: boolean
  @Prop({ type: Object, required: true }) public device: DeviceModel
  @Prop({ type: Boolean, default: false }) public disabled: boolean
  
  @FunctionBlockUi.Getter public amountOfHardwareMappableInputEndpointsById
  @FunctionBlockUi.Getter public amountOfHardwareMappableOutputEndpointsById
  @Resource.Getter public resourcesOfCategory: (category: ResourceCategory) => ResourceModel[]
  @DeviceUi.Mutation public setDeviceIcon: (payload: { id: string, icon: string}) => void

  public get deviceIcons() {
    return this.resourcesOfCategory(ResourceCategory.DEVICE_ICONS)
  }

  public updateIcon(value: string) {
    this.setDeviceIcon({
      id: this.device.id,
      icon: value,
    })
  }
}
</script>

<style lang="scss" scoped>
.device-icon-block {
    padding: 0px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 70px;
}
</style>
