import { AuditLogUiMutation, AuditLogUiState } from '@/store/modules/auditLogUi/types'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<AuditLogUiState> = {
  [AuditLogUiMutation.setInteracted](state, payload: boolean) {
    state.interacted = payload
  },
  [AuditLogUiMutation.setSelectedPlcId](state, payload: string) {
    state.selectedPlcId = payload
  },
}
