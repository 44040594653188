import { GetterTree } from 'vuex'
import { stateForResourceCategory } from './helpers'
import { ResourceCategory, ResourceModel } from './models'
import { ResourceGetter, ResourceState } from './types'

export const getters: GetterTree<ResourceState, {}> = {
  [ResourceGetter.resourcesOfCategory] (state: ResourceState) {
    return (category: ResourceCategory): ResourceModel[] => stateForResourceCategory(state, category).getAll()
  },
  [ResourceGetter.resolveStringResource] (state: ResourceState) {
    return (id: string): string => {
      const resource = state.appStrings.get(id) || state.systemConfigurationStrings.get(id) || state.fbStrings.get(id)
      return resource?.value || id
    }
  },
  [ResourceGetter.resolveIconResource] (state: ResourceState) {
    return (id: string) => {
      const resource = state.appIcons.get(id) 
        || state.deviceIcons.get(id)
        || state.solutionHardwareIcons.get(id)
        || state.roomIcons.get(id)
        || state.sceneIcons.get(id)
        || state.chartIcons.get(id)
        || state.appIcons.get('default.white')
      return resource?.value ? `data:image/svg+xml;base64,${resource.value}` : ''
    }
  },
  [ResourceGetter.resolveImageResource] (state: ResourceState) {
    return (id: string) => {
      const resource = state.plcImages.get(id) 
      || state.terminalImages.get(id)
      || state.moduleImages.get(id)
      return resource?.value ? `data:image/png;base64,${resource.value}` : ''
    }
  },
  [ResourceGetter.image] (_, localGetters) {
    return (id: string) => localGetters[ResourceGetter.resolveImageResource](id)
  },
  [ResourceGetter.svgIcon] (_, localGetters) {
    return (id: string) => localGetters[ResourceGetter.resolveIconResource](id)
  },
  [ResourceGetter.dictionary] (_, localGetters) {
    return (id: string) => localGetters[ResourceGetter.resolveStringResource](id)
  },
}
