<template>
  <div class="manualMappingItem">
    <div class="box" :class="isMapped ? 'active' : ''" :title="index">
      <span>{{index}}</span>
    </div>
    <div class="box" :class="isMapped ? 'active' : ''" :title="name">
      <span>{{name}}</span>
    </div>
    <div class="label" :title="description">
      <span>{{description}}</span>
    </div>
    <div class="action">
      <mapping-action-button
        :is-mapped="isMapped"
        :is-currently-mapping="isCurrentlyMapping"
        :disabled="disabled"
        @click="$emit('click')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import MappingActionButton from '@/components/views/ecoSetupTool/steps/mapHardware/MappingActionButton.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component ({
  components: {
    MappingActionButton,
  },
})
export default class ManualMappingSolutionHardwareItem extends Vue {
  @Prop() public index: string
  @Prop() public name: string
  @Prop() public description: string
  @Prop({ default: false}) public isMapped: boolean
  @Prop({ default: false}) public isCurrentlyMapping: boolean
  @Prop({ default: false}) public disabled: boolean
}
</script>

<style lang="scss" scoped>
.manualMappingItem {
  display:flex;
  align-items: center;
  height: 39px;
  background-color: #0b1621;
  .box {
    flex: 0;
    min-width: 70px;
    margin: 4px 0px 4px 4px;
    height: 32px;
    background-color: #0b2035;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active {
      background-color: #58aa31;
    }
  }
  .label {
    flex: 1;
    flex-basis: auto;
    max-width: 400px;
    padding: 0px 10px;
    height: 32px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .action {
    flex: 0;
  }
}
</style>