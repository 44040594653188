import { RoomModel } from '@ecocoach/domain-store-modules/src/plcConfiguration/models'

export interface RoomUiState {
  selectedRoomId: string
  roomInput: RoomModel
  interacted: boolean
}

export enum RoomUiAction {
  createRoom = 'createRoom',
  editRoom = 'editRoom',
  saveRoom = 'saveRoom',
  cancelRoom = 'cancelRoom',
  deleteRoom = 'deleteRoom',
  sortRooms = 'sortRooms',
  initializeRoomSelection = 'initializeRoomSelection',
}

export enum RoomUiMutation {
  setSelectedRoomId = 'setSelectedRoomId',
  setRoomInputProperty = 'setRoomInputProperty',
  resetRoomInput = 'resetRoomInput',
  setInteracted = 'setInteracted',
}

export enum RoomUiGetter {
  rooms = 'rooms',
  selectedRoomId = 'selectedRoomId',
  getRoomInputProperty = 'getRoomInputProperty',
  isEditingInProgress = 'isEditingInProgress',
  isInteracted = 'isInteracted',
}