<template>
  <eco-labeled-control :context="contextV2" :attributes="attributes" class="consumption-proccess-owner-lock-control">
    <v-tooltip bottom>
      <base-checkbox slot="activator" 
        :value="device.consumptionProcessOwnerLockEnabled"
        :disabled="isInteracted"
        @buttonToggled="onChecked"
      />
      <span>{{ 'parameter.consumptionprocessownerlock' | translate }}</span>
    </v-tooltip>
  </eco-labeled-control>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { DeviceModel, SetConsumptionProcessOwnerLockEnabledInput } from '../../../../../../../eco-domain-store-modules/src/plcOperation/models'

import { EcoLabeledControl } from '@ecocoach/shared-components/src'
import { BaseControlAttributes, ControlContextV2 } from '@ecocoach/shared-components/src/components/deviceControls/v2/models'

const Resource = namespace('resource')
const ParametrizationUi = namespace('parametrizationUi')

@Component({
  components: {
    EcoLabeledControl,
  },
})
export default class ConsumptionProcessOwnerLockEnabled extends Vue {
  @Prop() public device: DeviceModel
  @Resource.Getter public dictionary
  @ParametrizationUi.Getter public isInteracted: boolean
  @ParametrizationUi.Action public setConsumptionProcessOwnerLockEnabled: (input: SetConsumptionProcessOwnerLockEnabledInput) => Promise<void>

  public get contextV2(): ControlContextV2 {
    return {
      resolveStringResource: (resourceKey: string): string => {
        return this.dictionary(resourceKey)
      },
    } as ControlContextV2
  }

  public get attributes() {
    return {
      label: 'parameter.consumptionprocessownerlock',
      tooltip: 'common.consumptionprocessownerlock.label',
    } as BaseControlAttributes
  }

  public onChecked(enabled: string) {
    this.setConsumptionProcessOwnerLockEnabled({
      deviceId: this.device.id,
      consumptionProcessOwnerLockEnabled: !enabled,
    } as SetConsumptionProcessOwnerLockEnabledInput)
  }
}
</script>
<style lang="scss" scoped>
.consumption-proccess-owner-lock-control {
  padding-bottom: 15px;
}
</style>
