<template>
    <div class="ipSelect">
        <base-single-select
          :options="hexValueRange"
          :selected-value="this.nextValue.first"
            placeholdertext="-"
          :option-value="firstChar"
          :option-label="firstChar"
          @selected="firstCharValueChanged"
          class="project-selection"
        />
        <base-single-select
          :options="hexValueRange"
          :selected-value="this.nextValue.last"
           placeholdertext="-"
          :option-value="lastChar"
          :option-label="lastChar"
          @selected="lastCharValueChanged"
          class="project-selection"
        />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

const hexBase = 16

function convertToHex(num: number) {
  return ((num).toString(hexBase).length === 1 ?
    '0' + (num).toString(hexBase) : (num).toString(hexBase)).toUpperCase()
}

function parseFromHex(hex: string) {
  return parseInt(hex, hexBase)
}

@Component
export default class HexSwitch extends Vue {
    @Prop(Number) public lastOctet: number | null

    public nextValue: { first: string, last: string } = {
      first: '',
      last: '',
    }

    get hexValueRange () {
      return ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F']
    }

    get firstChar () {
      return convertToHex(this.lastOctet || 0).charAt(0)
    }

    get lastChar () {
      return convertToHex(this.lastOctet || 0).charAt(1)
    }

    get hasNextValue () {
      return this.nextValue.first !== null && this.nextValue.last !== null
    }

    @Watch('lastOctet')
    private onOctetChanged() {
      this.convertOctetToHexAndSplit()
    }

    private convertOctetToHexAndSplit () {
      this.nextValue.first = this.firstChar
      this.nextValue.last = this.lastChar
    }

    private mounted () {
      this.convertOctetToHexAndSplit()
    }

    private firstCharValueChanged(newValue) {
      this.nextValue.first = newValue
      this.valueChanged()
    }

    private lastCharValueChanged(newValue) {
      this.nextValue.last = newValue
      this.valueChanged()
    }

    private valueChanged() {
      if (this.hasNextValue) {
        this.$emit('IpChanged', parseFromHex([
          this.nextValue.first,
          this.nextValue.last,
        ].join('')))
      }
    }
}
</script>
<style lang="scss" scoped>
.ipSelect {
  display: flex;
  width: 100%;
  margin: 5px 0;
}
</style>
