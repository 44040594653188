<template>
    <v-layout column>
        <v-flex xs12>
            <v-card class="deviceCard" style="min-width: 400px;">
                <v-card-title primary-title>
                  <v-layout row>
                    <v-flex shrink>
                      <h3 :title="device.name">{{ device.name }}</h3>
                    </v-flex>
                    <v-flex grow></v-flex>
                    <v-flex shrink>
                      <description-popover
                        class="infopopover"
                        :header="fbName"
                        :content="fbTooltip"
                      />
                      </v-flex>
                  </v-layout>
                </v-card-title>
                <layout column class="categories">
                  <v-flex xs12 class="category">
                    <expandable-card :title="'parameter.state' | translate">
                      <p v-if="statusControlsOfSelectedDevice.length === 0">{{ 'parameter.missed' | translate }}</p>
                      <div v-else>
                        <device-controls
                          :device="device"
                          :controls="statusControlsOfSelectedDevice"
                        />
                      </div>
                    </expandable-card>
                  </v-flex>
                  <v-flex xs12 class="category">
                    <expandable-card :title="'parameter.parameter' | translate" class="expandable-card">
                      <p v-if="parameterControlsOfSelectedDevice.length === 0">{{ 'parameter.missed' | translate }}</p>
                      <div v-else>
                        <device-controls
                          :device="device"
                          :controls="parameterControlsOfSelectedDevice"
                        />
                      </div>
                    </expandable-card>
                  </v-flex>
                  <v-flex xs12 class="category">
                    <expandable-card :title="'parameter.functiontest' | translate" class="expandable-card">
                      <p v-if="functionTestControlsOfSelectedDevice.length === 0">{{ 'parameter.missed' | translate }}</p>
                      <div v-else>
                        <device-controls
                          :device="device"
                          :controls="functionTestControlsOfSelectedDevice"
                        />
                      </div>
                      <base-text-button
                        :enabled="!isConfirmedDevice(device.id)"
                        @buttonClicked="confirmDevice(device.id)">
                        {{ 'parameter.implemented' | translate}}
                      </base-text-button>
                    </expandable-card>                
                  </v-flex>
                  <v-flex xs12 class="category" v-if="chartConfigurationControlsOfSelectedDevice.length">
                    <expandable-card :title="'parameter.chartconfig' | translate" class="expandable-card">
                      <div>
                        <chart-configuration-control v-for="control in chartConfigurationControlsOfSelectedDevice" :key="control.id"
                          :control="control"
                        />
                      </div>
                    </expandable-card>                
                  </v-flex>
                  <v-flex xs12 class="category" v-if="showDeviceParameters">
                    <expandable-card :title="'parameter.deviceconfig' | translate" class="expandable-card">
                      <div v-if="device.canSetIgnoreInCharts">
                        <ignore-in-charts :device="device"/>
                      </div>
                      <div v-for="energyStatusItem in deviceEnergyStatuItems" :key="energyStatusItem.id">
                        <energy-status-item-color :device="device" :energy-status-item="energyStatusItem"/>
                      </div>
                      <div v-for="measuringPoint in deviceMeasuringPoints" :key="measuringPoint.id">
                        <measuring-point-color :device="device" :measuring-point="measuringPoint"/>
                      </div>
                      <div v-if="device.canSetConsumptionValidation">
                        <consumption-validation-enabled :device="device"/>
                        <consumption-process-owner-lock-enabled v-if="device.consumptionValidationEnabled" :device="device"/>
                      </div>
                    </expandable-card>                
                  </v-flex>
              </layout>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import ExpandableCard from '../../../../ui/ExpandableCard.vue'

import ChartConfigurationControl from './ChartConfigurationControl.vue'
import ConsumptionProcessOwnerLockEnabled from './ConsumptionProcessOwnerLockEnabled.vue'
import ConsumptionValidationEnabled from './ConsumptionValidationEnabled.vue'
import EnergyStatusItemColor from './EnergyStatusItemColor.vue'
import DeviceControls from './DeviceControls.vue'
import IgnoreInCharts from './IgnoreInCharts.vue'
import MeasuringPointColor from './MeasuringPointColor.vue'

import { ControlModelV2, DeviceModel } from '../../../../../../../eco-domain-store-modules/src/plcOperation/models'

import { FunctionBlockModel } from '../../../../../../../eco-domain-store-modules/src/systemConfiguration/models'
import { sortedBy } from '@ecocoach/domain-store-modules/src/utils'

const SystemConfiguration = namespace('systemConfiguration')
const ParametrizationUi = namespace('parametrizationUi')
const PlcOperation = namespace('plcOperation')
const Resource = namespace('resource')

@Component({
  components: {
    ChartConfigurationControl,
    ConsumptionProcessOwnerLockEnabled,
    ConsumptionValidationEnabled,
    DeviceControls,
    EnergyStatusItemColor,
    ExpandableCard,
    IgnoreInCharts,
    MeasuringPointColor,
  },
})
export default class ConfigurableDevice extends Vue {
  @Prop() public device: DeviceModel
  @SystemConfiguration.Getter public functionBlockById: (id: string) => FunctionBlockModel  
  @ParametrizationUi.Getter public isConfirmedDevice: (id: string) => boolean
  @ParametrizationUi.Getter public statusControlsOfSelectedDevice: ControlModelV2[]  
  @ParametrizationUi.Getter public parameterControlsOfSelectedDevice: ControlModelV2[]
  @ParametrizationUi.Getter public functionTestControlsOfSelectedDevice: ControlModelV2[]
  @ParametrizationUi.Getter public chartConfigurationControlsOfSelectedDevice: ControlModelV2[]
  @ParametrizationUi.Action public confirmDevice: (id: string) => Promise<void>

  @PlcOperation.Getter public hasControlUpdateFailed: boolean
  @PlcOperation.Getter public hasControlUpdateTimeout: boolean
  
  @Resource.Getter public dictionary

  public get fbName() {
    const fb = this.functionBlockById(this.device.functionBlockDefinitionId)
    return fb?.name || this.dictionary(fb?.nameResourceId)
  }

  public get fbTooltip() {
    const fb = this.functionBlockById(this.device.functionBlockDefinitionId)
    return `Version: ${fb?.version}<br>${fb?.description || this.dictionary(fb?.descriptionResourceId)}`
  }

  public get showDeviceParameters() {
    return !!this.device.measuringPoints?.length || !!this.device.energyStatusItems?.length || this.device.canSetConsumptionValidation || this.device.canSetIgnoreInCharts
  }

  public get deviceEnergyStatuItems() {
    return sortedBy(this.device.energyStatusItems, m => m.type)
  }

  public get deviceMeasuringPoints() {
    return sortedBy(this.device.measuringPoints, m => m.tags[0])
  }

  @Watch('hasControlUpdateFailed')
  private showFailedError(value) {
    if(value) { // changes from false to true
      this.$store.commit('wizard/setToastContent', this.dictionary('control.update.failed'), {root: true})
      this.$store.commit('wizard/showToast', {root: true})
    }
  }

  @Watch('hasControlUpdateTimeout')
  private showTimeoutError(value) {
    if(value) { // changes from false to true
      this.$store.commit('wizard/setToastContent', this.dictionary('control.update.failed'), {root: true})
      this.$store.commit('wizard/showToast', {root: true})
    }
  }
}
</script>
<style lang="scss" scoped>
.deviceCard {
  margin-bottom: 75px;
}
.v-btn {
  border: 1px solid white;
  font-weight: bold;
  margin: 6px 0!important;
}
</style>
<style lang="scss">
.deviceCard {
  padding-bottom: 5px;
  .v-card__title {
    color: white !important;
  }
  background-color: #1a3856 !important;
  .categories {
    .category {
      padding: 5px 10px;
      .v-expansion-panel__body {
        padding: 10px;
      }
    }
  }
}

</style>
