import { AlarmLevel, AlarmOperator, ArchitectureType, ControlTypeV2, EnergyStatusItemType, SourceLanguage } from '../../common'

export interface FunctionBlockModel {
  id: string
  version: string
  hardwareMappableEndpoints: FunctionBlockEndpointModel[]
  internalName: string
  name: string
  nameResourceId: string
  descriptionResourceId: string
  description: string
  releaseNotesResourceId: string
  releaseNotes: string
  releaseNotesHistory: FunctionBlockReleaseNotesModel[]
  releaseNotesHistoryResourceIds: string[]
  iconResourceId: string
  state: FunctionBlockState
  categoryId: string
  additionalTwinCatLibraryDependency: string
  autoMappingSlots: AutoMappingSlotModel[]
  successorFunctionBlockId: string | null
  architectureType: ArchitectureType
  sourceLanguage: SourceLanguage
  cycleTimeInMilliseconds: number
}

export interface FunctionBlockEndpointModel {
  id: string
  internalName: string
  functionBlockId: string
  dataType: string
  baseDataType: string
  nameResourceId: string
  name: string
  descriptionResourceId: string
  description: string
  maxStringLength: number
  isMappableHardwareInput: boolean
  isMappableHardwareOutput: boolean
  isInput: boolean
  isOutput: boolean
}

export interface FunctionBlockReleaseNotesModel {
  releaseNotesResourceId: string
  releaseNotes: string
}

export enum FunctionBlockState {
  NEW = 'New',
  ACTIVE = 'Active',
  IN_TEST = 'InTest',
  OBSOLETE = 'Obsolete',
}

export interface AutoMappingSlotModel {
  internalName: string
  interfaceType: string
  nameResourceId: string
  name: string
  descriptionResourceId: string
  description: string
  minCount: number
  maxCount: number
}

export interface FunctionBlockCategoryModel {
  id: string
  nameResourceId: string
  name: string
  iconResourceId: string
}

export interface SolutionHardwareDefinitionModel {
  id: string
  interfaceType: SolutionHardwareInterfaceType
  state: SolutionHardwareDefinitionState
  solutionHardwareDefinitionCategory: string
  nameResourceId: string
  name: string
  descriptionResourceId: string
  description: string
  sortOrder: number
  imageResourceId: string
  internalName: string
  version: string
  hardwareEndpoints: SolutionHardwareDefinitionEndpointModel[]
  autoMappingSlots: AutoMappingSlotModel[]
}

export enum SolutionHardwareInterfaceType {
  UNKNOWN = 'Unknown',
  KBUS = 'KBus',
  ETHERNET = 'Ethernet',
  EBUS = 'EBus',
  EBUS_EXTENSION = 'EBusExtension',
}

export enum SolutionHardwareDefinitionState {
  NEW = 'New',
  ACTIVE = 'Active',
  IN_TEST = 'InTest',
  OBSOLETE = 'Obsolete',
}

export interface SolutionHardwareDefinitionEndpointModel {
  id: string
  nameResourceId: string
  name: string
  descriptionResourceId: string
  description: string
  isOutput: boolean
  dataSize: number
  dataOffset: number
  dataType: string
  lastOctet: number
}

export interface SolutionHardwareDefinitionCategoryModel {
  id: string
  nameResourceId: string
  name: string
  iconResourceId: string
}

export interface GlobalVariableModel {
  id: string
  name: string
  isSoftwareMappingCategory: boolean
}

export interface FunctionDefinition {
  id: string
  internalName: string
  functionBlockXml: string
  version: string
  nameResourceId: string
  descriptionResourceId: string
  iconResourceId: string
  categoryId: string
  twinCatLibraryDependency: TwinCatLibraryDependency // v1
  cycleTimeInMilliseconds: number
  controlDefinitionIds: string[] // v1
  endpoints: Endpoint[]
  autoMappingSlots: any[]
  endpointResources: Array<{
    endpointPath: string,
    nameResourceId: string,
    descriptionResourceId: string,
  }>
  defaultValues: Array<{
    controlDefinitionId: string
    defaultValue: any
  }>
}

export interface FunctionBlockDefinitionV2 {
  id: string
  internalName: string
  xml?: string
  functionBlockPackageInfoId?: string
  version: string
  nameResourceId: string
  descriptionResourceId: string
  iconResourceId: string
  functionBlockDefinitionCategoryId: string
  twinCatLibraryDependency: TwinCatLibraryDependency
  cycleTimeInMilliseconds: number
  messagesEnumInternalName: string
  controlDefinitionMappings: Array<{
    controlDefinitionId: string
    defaultValues: {
      [command: string]: any
    }
  }>
  endpoints: EndpointV2[]
  autoMappingSlots: any[]
  endpointResources: Array<{
    endpointPath: string,
    nameResourceId: string,
    descriptionResourceId: string,
  }>
  instanceResources: Array<{
    endpointPath,
    resourceId,
  }>
  measuringPoints: MeasuringPoint[]
  energyStatusItems: EnergyStatusItem[]
  defaultAlarmDefinitions: DefaultAlarmDefinition[]
  architectureType: ArchitectureType
  sourceLanguage: SourceLanguage
  licenseTags: string[]
}

export enum MeasuringPointTag {
  // meters
  ConsumptionMeter = 'ConsumptionMeter',
  DynamicConsumptionMeter = 'DynamicConsumptionMeter',

  // EMS external energy flows
  EnergyConsumed = 'EnergyConsumed',
  EnergyProduced = 'EnergyProduced',
  EnergyExportedGrid = 'EnergyExportedGrid',
  EnergyImportedGrid = 'EnergyImportedGrid',
  EnergyChargedBuffer = 'EnergyChargedBuffer',
  EnergyDischargedBuffer = 'EnergyDischargedBuffer',

  // EMS internal energy flows
  EnergyProducersToConsumers = 'EnergyProducersToConsumers',
  EnergyProducersToGrid = 'EnergyProducersToGrid',
  EnergyProducersToBuffers = 'EnergyProducersToBuffers',
  EnergyGridToConsumers = 'EnergyGridToConsumers',
  EnergyGridToBuffers = 'EnergyGridToBuffers',
  EnergyBuffersToConsumers = 'EnergyBuffersToConsumers',
  EnergyBuffersToGrid = 'EnergyBuffersToGrid',

  // other media
  HeatImported = 'HeatImported',
  ColdWaterImported = 'ColdWaterImported',
  HotWaterImported = 'HotWaterImported',

  // other
  BufferLevel = 'BufferLevel',
  Temperature = 'Temperature',
}

export interface MeasuringPoint {
  id: string
  endpointPath: string
  unit: string
  readIntervalInSeconds: number
  allowedForBilling: boolean
  tags: MeasuringPointTag[]
  resourceId: string
  colorGradient: string
}

export interface EnergyStatusItem {
  id: string
  endpointPath: string
  unit: string
  type: EnergyStatusItemType
  toSubscribe: boolean
  pollInterval: number
  colorGradient: string
}

export interface DefaultAlarmDefinition {
  id: string
  nameResourceId: string
  messageResourceId: string
  operator: AlarmOperator
  value: number | boolean
  unit: string
  hysteresis: number
  isNumeric: boolean
  endpointPath: string
  level: AlarmLevel
}

export enum EndpointType {
  Unknown = 'Unknown', 
  Input = 'Input', 
  Output = 'Output', 
  LocalVariable = 'LocalVariable', 
  Method = 'Method', 
  Property = 'Property',
}

export interface Endpoint {
  internalName: string
  dataType: string
  isInput: boolean
  isOutput: boolean
  isMappableHardwareInput: boolean
  isMappableHardwareOutput: boolean
  isMethod: boolean
  isProperty: boolean
  isReadOnlyProperty: boolean
  isWriteOnlyProperty: boolean
  isEnum: boolean
}

export enum TwinCatLibraryDependency {
  NONE = 'None',
  FTP = 'Ftp',
  MOD_BUS_RTU = 'ModbusRtu',
  MOD_BUS_TCP = 'ModbusTcp',
  SERIAL = 'Serial',
  TCP_IP = 'TcpIp',
  ETHERCAT = 'EtherCat',
}

export interface ControlDefinitionModelV2 {
  id: string
  type: ControlTypeV2
  attributes: {
    [attribute: string]: any
  }
  commands: {
    [command: string]: {
      type: string
      endpoint: string
      cloudPersistent: boolean
      templateAttached: boolean
      auditable: boolean
      auditResourceId: string
    },
  }
  states: {
    [state: string]: {
      endpoint: string
      toSubscribe: boolean
      pollInterval: number
    },
  }
}

export enum ControlTag {
  // keep in sync with the tag values in the ecc light project
  EccLight = 'EccLight',
  LinkedEnergyManager = 'LinkedEnergyManager',
  LinkedParentEnergyManager = 'LinkedParentEnergyManager',
  LinkedGrid = 'LinkedGrid',
  LinkedElectricityMeter = 'LinkedElectricityMeter',
  LinkedModbusRtuMaster = 'LinkedModbusRtuMaster',
  LinkedObservableEnergyConsumers = 'LinkedObservableEnergyConsumers',
  LinkedObservableEnergySuppliers = 'LinkedObservableEnergySuppliers',
  LinkedHouseDataProvider = 'LinkedHouseDataProvider',
  Enabled = 'Enabled',
  DeviceMessages = 'DeviceMessages',
  IpAddress = 'IpAddress',
  ModbusAddress = 'ModbusAddress',
  Fuse = 'Fuse',
  DefaultPriority = 'DefaultPriority',
  DefaultOperatingMode = 'DefaultOperatingMode',
  UnbalancedLoad = 'UnbalancedLoad',
  LimitUnbalancedLoad = 'LimitUnbalancedLoad',
  PhaseOrder = 'PhaseOrder',
  ChargingEnabled = 'ChargingEnabled',
  MixedModeLimitCurrent = 'MixedModeLimitCurrent',
  CurrentOperatingMode = 'CurrentOperatingMode',
  CarConnected = 'CarConnected',
  ChargingInProgress = 'ChargingInProgress',
  BoosterModeActive = 'BoosterModeActive',
  BoosterModeCriterion = 'BoosterModeCriterion',
  BoosterModeTimespan = 'BoosterModeTimespan',
  BoosterModeEnergy = 'BoosterModeEnergy',
  BoosterModeStartTime = 'BoosterModeStartTime',  
}

export enum LinkType {
  LinkedEnergyManager = "LinkedEnergyManager",
  LinkedParentEnergyManager = "LinkedParentEnergyManager",
  LinkedGrid = "LinkedGrid",
}

export enum LicenseTag {
  WeidmullerChargingStation = 'WeidmullerChargingStation',
  WeidmullerSubDistribution = 'WeidmullerSubDistribution',
}

export interface EndpointV2 {
  internalName: string
  dataType: string
  isMappableHardwareInput: boolean
  isMappableHardwareOutput: boolean
  endpointType: EndpointType
  isReadOnlyProperty: boolean
  isWriteOnlyProperty: boolean
  isEnum: boolean
}

export interface ParseFunctionBlockXmlOutput {
  internalName: string
  endpoints: Endpoint[]
  matchingButtonControlDefinitions: any[]
  matchingColorPickerControlDefinitions: any[]
  matchingMonitoringControlDefinitions: any[]
  matchingParameterControlDefinitions: any[]
  matchingSwitchControlDefinitions: any[]
  matchingTextControlDefinitions: any[]
}

export interface ParseFunctionBlockDefinitionXmlOutputV2 {
  internalName: string
  endpoints: EndpointV2[]
  matchingControlDefinitions: ControlDefinitionModelV2[]
  targetInstances: Array<{
    endpointPath: string
    sourceInterfaces: Array<{
      sourceInterfaceId: string
      internalName: string,
    }>,
  }>
  sourceInstances: Array<{
    endpointPath: string
  }>
  messagesEnumInternalName: string
  messagesEnumValues: Array<{
    name: string
    value: string
  }>
  // v1
  matchingButtonControlDefinitions: any[]
  matchingColorPickerControlDefinitions: any[]
  matchingMonitoringControlDefinitions: any[]
  matchingParameterControlDefinitions: any[]
  matchingSwitchControlDefinitions: any[]
  matchingTextControlDefinitions: any[]
}

export interface UploadNewDefinitionsInput {
  functionDefinitions: Array<{
    id: string
    functionBlockXml: string
    version: string
    iconResourceId: string
    nameResourceId: string
    descriptionResourceId: string
    releaseNotesResourceId: string
    categoryId: string
    twinCatLibraryDependency: TwinCatLibraryDependency
    controlDefinitionIds: string[]
    autoMappingSlots: any[]
    endpointResources: Array<{
      endpointPath: string
      nameResourceId: string
      descriptionResourceId: string
    }>
    defaultValues: Array<{
      controlDefinitionId: string
      defaultValue: any
    }>
    predecessorFunctionBlockIds: string[]
  }>
  buttonControlDefinitions: any[]
  colorPickerControlDefinitions: any[]
  monitoringControlDefinitions: any[]
  parameterControlDefinitions: any[]
  switchControlDefinitions: any[]
  textControlDefinitions: any[]
  modifiedButtonControlDefinitions: any[]
  modifiedColorPickerControlDefinitions: any[]
  modifiedMonitoringControlDefinitions: any[]
  modifiedParameterControlDefinitions: any[]
  modifiedSwitchControlDefinitions: any[]
  modifiedTextControlDefinitions: any[]
  validationBuildOnly: boolean
  preserveProjectFilesInUranus?: boolean
}

export interface UploadNewDefinitionsInputV2 {
  definitions: Array<{
    xml?: string // structured text fbs
    functionBlockPackageInfoId?: string // csharp fbs
    version: string
    functionBlockDefinitionCategoryId: string
    nameResourceId: string
    descriptionResourceId: string
    iconResourceId: string
    releaseNotesResourceId: string
    controlDefinitionMappings: Array<{
      controlDefinitionId: string
      defaultValues: {
        [command: string]: any
      }
    }>
    cycleTimeInMilliseconds: number
    autoMappingSlots: any[]
    predecessorFunctionBlockIds: string[]
    endpointResources: Array<{
      endpointPath: string
      nameResourceId: string
      descriptionResourceId: string
    }>
    instanceResources: Array<{
      endpointPath: string
      resourceId: string
    }>
    measuringPoints: MeasuringPoint[]
    energyStatusItems: Array<Omit<EnergyStatusItem, 'id'>>
    defaultAlarmDefinitions: DefaultAlarmDefinition[]
    licenseTags: string[]
  }>
  controlDefinitionsToUpsert: ControlDefinitionModelV2[]
  controlDefinitionsToDelete: string[]
  controlDefinitionsToSetObsolete: string[]
  validationBuildOnly: boolean,
  preserveProjectFilesInUranus?: boolean
}

export interface UploadNewDefinitionsOutput {
  functionDefinitionOutputs: Array<{
    functionBlockOutput: FunctionBlockModel,
    setTestStateOutput: {
      obsoleteIds: string[],
    },
  }>
  processingErrors: Array<{
    inputObject: any,
    exception: {
      message: string,
      stackTrace: string
    },
  }>
  testBuildResult: {
    releaseId: string,
    isSuccessful: boolean,
    buildStateChangedEvents: Array<{
      newState: string,
      statusInfo: string,
      releaseId: string
      plcId: string
    }>,
    exceptionMessage: string,
  }
}

export interface UploadNewDefinitionsOutputV2 {
  processingErrors: Array<{
    inputObject?: any,
    exception?: {
      message: string,
      stackTrace: string
    },
  }>
  testBuildResult: {
    releaseId: string,
    isSuccessful: boolean,
    buildStateChangedEvents: Array<{
      newState: string,
      statusInfo: string,
      releaseId: string
      plcId: string
    }>,
    exceptionMessage?: string,
  }
  functionBlockDefinitions: FunctionBlockModel[]
  obsoleteFunctionBlockDefinitionIds: string[]
}

export interface SetFunctionBlockObsoleteInput {
  id: string
  successorFunctionBlockId?: string
}

export interface SetFunctionBlockReleaseNotesInput {
  id: string
  releaseNotes: string
}

export interface FunctionBlockPackageInfoModel {
  id: string
  internalName: string
  packageName: string
  packageVersion: string
}
