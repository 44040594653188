import { Module } from 'vuex'
import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'
import { PlcConnectionAction, PlcConnectionMutation, PlcConnectionState } from './types'

const namespaced: boolean = true
export const plcConnection: Module<PlcConnectionState,any> = {
  namespaced,
  state,
  actions,
  mutations,
}

export const types = {
  action:  PlcConnectionAction,
  mutation: PlcConnectionMutation,
}

export const domain = 'plcConnection'

export const vuexModule = {
  [domain]: plcConnection,
}