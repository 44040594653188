<template>
  <div class="color-gradient-picker">
    <v-tooltip bottom>
      <div slot="activator" @click="showGradientSelector = true" class="gradient-picker" :style="iconStyle" v-ripple/>
      <span>{{ 'parameter.chartcolor' | translate }}</span>
    </v-tooltip>
    <color-gradient-picker-dialog 
      :show="showGradientSelector"
      :gradients="gradients"
      :selected-gradient="colorGradient"
      :disabled="disabled"
      @gradientSelected="onGradientSelected"
      @closed="showGradientSelector = false"
    />
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import { Gradients } from '@ecocoach/shared-components/src'

import ColorGradientPickerDialog from './ColorGradientPickerDialog.vue'

@Component({
  components: {
    ColorGradientPickerDialog,
  },
})
export default class ColorGradientPicker extends Vue {
  @Prop() public colorGradient: string
  @Prop() public disabled: boolean

  public showGradientSelector = false

  public get iconStyle() {
    return Gradients.style(this.colorGradient, true)
  }

  public get gradients(): string[] {
    return ['', ...Gradients.list().map(gradient => gradient.id)]
  }

  @Emit('closed')
  public onOk() {
    return true
  }

  @Emit('selected')
  public onGradientSelected(gradient: string) {
    return gradient
  }

  public gradientStyle(gradient: string) {
    return Gradients.style(gradient, true)
  }
}
</script>
<style lang='scss' scoped>
.gradient-picker {
  cursor: pointer;
  height: 25px;
  width: 25px;
  border-radius: 5px;
}
.v-btn {
  border: 1px solid white;
}
</style>
