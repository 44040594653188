<template>
  <eco-labeled-control class="eco-links-control"
    :context="context"
    :attributes="attributes"
  >
    <autocomplete-select
      :multiple="isMultiple"
      :options="options"
      :option-value="'id'"
      :option-label="'label'"
      :selected-value="selectedValue"
      :pending="pendingValues !== undefined"
      :disabled="readonly"
      @selected="onSelected"
    />
  </eco-labeled-control>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DropdownOption } from '../../../models'
import AutocompleteSelect from '../../patterns/AutocompleteSelect.vue'
import { ControlContextV2, LinksControlAttributes } from './models'
import EcoLabeledControl from './shared/EcoLabeledControl.vue'

@Component({
  components: {
    AutocompleteSelect,
    EcoLabeledControl,
  },
})
export default class EcoLinksControl extends Vue {
  @Prop() public id: string
  @Prop() public context: ControlContextV2
  @Prop() public attributes: LinksControlAttributes
  @Prop() public state: object
  @Prop() public pendingState: object
  @Prop() public readonly: boolean

  public onSelected(value) {
    if (this.isMultiple) {
      const params = value
      this.context.executeCommand(this.id, {
        command: this.attributes.command,
        params,
        pendingState: {
          [this.attributes.state]: params,
        },
      })
    } else {
      const params = !!value ? [value] : []
      this.context.executeCommand(this.id, {
        command: this.attributes.command,
        params,
        pendingState: {
          [this.attributes.state]: params,
        },
      })
    }
  }

  public get isMultiple() {
    return this.attributes.maxNumSelections > 1
  }

  public get options() {
    if (this.isMultiple) {
      return this.attributes.options
    } else {
      return [{ id: '', label: '-' } as DropdownOption].concat(this.attributes.options)
    }
  }

  public get selectedValue() {
    if (this.isMultiple) {
      return this.pendingValues !== undefined ? this.pendingValues : this.currentValues
    } else {
      return this.pendingValues !== undefined ? (this.pendingValues && this.pendingValues[0]) : (this.currentValues && this.currentValues[0])
    }
  }

  public get currentValues() {
    return this.state[this.attributes.state]
  }

  public get pendingValues() {
    return this.pendingState[this.attributes.state]
  }
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
.eco-links-control .autocomplete-select input {
  border-radius: 5px;
}
.eco-links-control .autocomplete-input {
  padding-right: 0px;
}
.eco-links-control .autocomplete-input .v-input__control {
  margin-bottom: -15px;
}
.eco-links-control .autocomplete-select .v-select__selections {
  border-radius: 5px;
  border-width: $default-border-width;
  border-style: solid;
  border-color: $fore-color-primary;
  padding: 0px 5px;
}
.eco-links-control .autocomplete-input.pending .v-select__selections {
  border-color: $fore-color-disabled;
}
</style>
