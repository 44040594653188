<template>
  <div class="option-selector option-button" :class="active ? 'selected' : ''">
    <div class="title">{{ title }}</div>
    <div @click.stop="onSelected" class="image">
      <img v-if="active" width="180" :src="imageUrl(activeImage)" />
      <img v-else width="180" :src="imageUrl(inactiveImage)" />
    </div>
    <div v-if="active" class="description">{{ description }}</div>
    <slot/>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class OptionSelector extends Vue {
  @Prop() public title: string
  @Prop() public description: string
  @Prop() public active: boolean
  @Prop() public activeImage: string
  @Prop() public inactiveImage: string

  @Emit('selected') 
  public onSelected() {
    return
  }

  public imageUrl(imageName: string) {
    if (!imageName) {
      return
    }
    return require('@/../public/img/icons/' + imageName)
  }
}
</script>
<style lang="scss" scoped>
.option-selector {
  display: flex;
  flex-direction: column;
  width: 200px;
  text-align: center;
  margin-right: 50px;
  margin-left: 50px;
}
.option-button {
  opacity: 1;
  &:not(:hover) {
    opacity: 0.5;
  }
  &.selected {
    opacity: 1;
  }
}
.image {
  cursor: pointer;
  height: 180px;
  width: 180px;
  padding: 0 10px;
  text-align: center;
  margin-bottom: 10px;
}
.title {
  margin-bottom: 10px;
}
.description {
  margin-bottom: 15px;
}

</style>