import { actions } from '@/store/modules/noteUi/actions'
import { getters } from '@/store/modules/noteUi/getters'
import { mutations } from '@/store/modules/noteUi/mutations'
import { state } from '@/store/modules/noteUi/state'
import { NoteUiAction, NoteUiGetter, NoteUiMutation, NoteUiState } from '@/store/modules/noteUi/types'
import { RootState } from  '@/store/types'
import { Module } from 'vuex'

const namespaced: boolean = true
export const noteUi: Module<NoteUiState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}

export const types = {
  action: NoteUiAction,
  mutation: NoteUiMutation,
  getter: NoteUiGetter,
}

export const domain = 'noteUi'

export const vuexModule = {
  [domain]: noteUi,
}