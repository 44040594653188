import { AuthenticationOptions } from '@ecocoach/domain-store-modules/src/authentication/models'
import { BackendEnvironmentTypes } from '@ecocoach/domain-store-modules/src/services/api.service'

const B2CTENANTS = {
  [BackendEnvironmentTypes.PRODUCTION]: 'ecointegrationcloud',
  [BackendEnvironmentTypes.STAGING]: 'stagingauhtentication',
  [BackendEnvironmentTypes.DEVELOPMENT]: 'ecocoachdevelopmentauth',
}
const CLIENT_IDS = {
  [BackendEnvironmentTypes.PRODUCTION]: 'b608fcb2-ef87-441f-8b7f-c3249dd3fc3b',
  [BackendEnvironmentTypes.STAGING]: '76dd9fa2-d3b2-46c2-8e1c-845d2b60ffd4',
  [BackendEnvironmentTypes.DEVELOPMENT]: '59a4130e-8642-4a38-96d5-fb9dfee3a169',
}

export function backendEnvironment(): BackendEnvironmentTypes {
  if (process.env.VUE_APP_ENVIRONMENT) {
    // when run locally with serve:production|staging|develoment
    return process.env.VUE_APP_ENVIRONMENT as BackendEnvironmentTypes
  }
  if (window.location.origin.includes(BackendEnvironmentTypes.DEVELOPMENT)) {
    return BackendEnvironmentTypes.DEVELOPMENT
  }
  if (window.location.origin.includes(BackendEnvironmentTypes.STAGING)) {
    return BackendEnvironmentTypes.STAGING
  }
  return BackendEnvironmentTypes.PRODUCTION
}

export function authenticationRedirectUrl() {
  return window.location.origin
}

export function authenticationOptions(environment: BackendEnvironmentTypes) {
  if (environment === BackendEnvironmentTypes.PRODUCTION) {
    return {
      clientId: CLIENT_IDS[BackendEnvironmentTypes.PRODUCTION],
      b2cTenant: B2CTENANTS[BackendEnvironmentTypes.PRODUCTION],
      redirectUrl: authenticationRedirectUrl(),
      useRefreshToken: false,
    } as AuthenticationOptions
  }
  if (environment === BackendEnvironmentTypes.STAGING) {
    return {
      clientId: CLIENT_IDS[BackendEnvironmentTypes.STAGING],
      b2cTenant: B2CTENANTS[BackendEnvironmentTypes.STAGING],
      redirectUrl: authenticationRedirectUrl(),
      useRefreshToken: true,
      tokenEndpointProxyUrl: 'https://ecocoachb2cproxy.azurewebsites.net/staging',
    } as AuthenticationOptions
  }
  if (environment === BackendEnvironmentTypes.DEVELOPMENT) {
    return {
      clientId: CLIENT_IDS[BackendEnvironmentTypes.DEVELOPMENT],
      b2cTenant: B2CTENANTS[BackendEnvironmentTypes.DEVELOPMENT],
      redirectUrl: authenticationRedirectUrl(),
      useRefreshToken: true,
      tokenEndpointProxyUrl: 'https://ecocoachb2cproxy.azurewebsites.net/development',
    } as AuthenticationOptions
  }
}
