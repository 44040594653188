export interface ProjectModel {
  id: string
  name: string
  targetSystems: OneClickTargetSystem[]
  currentUserAuthorization: string
}

export enum OneClickTargetSystem {
  Ecocoach = 'Ecocoach',
  Minergie = 'Minergie',
  Egonline = 'Egonline',
}

export interface LoadInput {
  projectId: string
  targetSystem: OneClickTargetSystem
}

export interface AccountModel {
  id: string
  name: string
  customId: string
}

export interface UserModel {
  userIdentifier: string
  consumptionAccountId: string
}

export interface MeterModel {
  meterId: string
  consumptionAccountId: string
  name: string
}

export interface MeterAssignmentModel {
  meterId: string
  consumptionAccountId: string
}

export interface UserAssignmentModel {
  dynamicMeterUserIdentifier: string
  consumptionAccountId: string
}

export interface MinergieConfigurationModel {
  minergieObjectId: string
  isLinked: boolean
  isVerified: boolean
}

export interface EgonlineConfigurationModel {
  egonlineProjectId: string
  isLinked: boolean
}

export interface ConsumptionReportInput {
  projectId: string
  aggregationInterval: string
  dateFrom?: string
  dateTo?: string
  consumptionAccountId?: string
  resourceLanguage?: string
}

export enum ConsumptionReportAggregationInterval {
  FIFTEEN_MINUTES = 'fifteenMinutes',
  HOURLY = 'hourly',
  DAILY = 'daily',
  YEARLY = 'yearly',
  MONTHLY = 'monthly',
}

export interface MinergieVisualizationDataInput {
  projectId: string
  accountId: string
  interval: MinergieVisualizationInterval
  language: string
}

export enum MinergieVisualizationInterval {
  YEARLY = 'yearly',
  MONTHLY = 'monthly',
}

export interface MinergieVisualizationData {
  series: MinergieVisualizationSeries[]
  xAxis: XAxisDefinition
  interval: MinergieVisualizationInterval
  accountId: string
  unit: string
}

export interface MinergieVisualizationSeries {
  name: string
  data: Array<number | null>
  average: number | null
}

export interface XAxisDefinition {
  categories: VisualizationCategory[]
}

export interface VisualizationCategory {
  name: string
  average: number | null
}
