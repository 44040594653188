import { modules as moduleFactory }  from '@/store/modules'    // storeModules aendern
import { RootState } from '@/store/types'
import { modules } from '@ecocoach/domain-store-modules/src'
import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import actionLogger from './plugins/actionLogger'

Vue.use(Vuex)

export const localModules = moduleFactory()
const store = new Store<RootState>({
  modules: { 
    ...localModules,
    ...modules.alarmModule,
    ...modules.alarmDefinitionModule,
    ...modules.auditLogModule,
    ...modules.authenticationModule,
    ...modules.authorizationModule,
    ...modules.consumptionModule,
    ...modules.noteModule,
    ...modules.plcModule,
    ...modules.plcConfigurationModule,
    ...modules.plcConnectionModule,
    ...modules.plcOperationModule,
    ...modules.projectModule,
    ...modules.resourceModule,
    ...modules.systemConfigurationModule,
    ...modules.userAgreementModule,
    ...modules.userSettingsModule,
  },
  plugins: [actionLogger],
})

export default store