import { actions } from '@/store/modules/releaseUi/actions'
import { getters } from '@/store/modules/releaseUi/getters'
import { mutations } from '@/store/modules/releaseUi/mutations'
import { state } from '@/store/modules/releaseUi/state'
import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { ReleaseUiAction, ReleaseUiGetter, ReleaseUiMutation, ReleaseUiState } from './types'

const namespaced: boolean = true
export const releaseUi: Module<ReleaseUiState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action: ReleaseUiAction, 
  mutation: ReleaseUiMutation,
  getter: ReleaseUiGetter,
}

export const domain = 'releaseUi'

export const vuexModule = {
  [domain]: releaseUi,
}