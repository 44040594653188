<template lang="html">
    <div class="uiToggle">
      <div
        :class="{ 'active': value, 'inactive': !value }"
        :style="gradientBackgroundStyle"
        class = "plane"
        @click="emitButtonToggled()"
      >
      <div :class="dotClass">
          <span class="icon-bar"/>
          <span class="icon-bar"/>
          <span class="icon-bar"/>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
import BaseToggleButton from '../base/BaseToggleButton.vue'

export default Vue.extend({
    name: 'LockToggleButton',
    mixins: [BaseToggleButton],
    props: {
      activePrimaryColor: {
        type: String,
        default: '#68e138',
      },
      activeSecondaryColor: {
        type: String,
        default: '#015e25',
      },
    },
})
</script>
<style lang="scss" scoped>
.uiToggle {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .plane {
      width: 51px;
      height: 31px;
      position: relative;
      border-radius: 4px;
      cursor: pointer;
      box-sizing: padding-box;
      transition: all .5s ease;
      border: 1px solid rgba(7,55,100,1);
      &.active {
        border: 1px solid rgba(7,55,100,0);
      }
      .dot {
        width: 26px;
        height: 25px;
        border-radius: 3px;
        margin: 2px;
        padding: 3px 7px;
        box-sizing: border-box;
        position: absolute;
        transform: rotate(90deg);
        transition: all .5s ease;
        background: -webkit-linear-gradient(90deg, rgb(216,216,216) 0%, rgb(252,252,252) 100%);
        box-shadow: 1px 0px 3px 1px rgba(0,1,0,0.5);
        &.off {
          left: 0px;
          background-color: #cbcbcb;
        }
        &.on {
          left: 20px;
          background-color: white;
        }
        .icon-bar{
          display: block;
          border-radius: 1px;
          height: 2px;
          box-sizing: border-box;
          width: 12px;
          background: rgb(160,160,163);
          margin:3px 0;
        }
      }
    }
  }
</style>

<docs>
A lock toggle button

Usage example
```jsx
new Vue({
  data: function() {
    return {
      value: false
    }
  },
  methods: {
    onSelected(value) {
      console.log('firstToggleState', value)
      this.value = !value
    }
  },
  template: `<v-app style="height: 48px;background:transparent">
   <lock-toggle-button
      :value="value"
      @buttonToggled="onSelected"
      />
  </v-app>`
})
```

Interacted/pending state
```jsx
new Vue({
  data: function() {
    return {
      value: false
    }
  },
  methods: {
    onSelected(value) {
      console.log('firstToggleState', value)
      this.value = !value
    }
  },
  template: `<v-app style="height: 48px;background:transparent">
   <lock-toggle-button
      :label-text="'alarm with info'"
      :information-text="'this could be some error info'"
      :current-value="value"
      :pending-value="!value"
      @buttonToggled="onSelected"
      />
  </v-app>`
})
```

Advanced usage example with pending state until removed
```jsx
new Vue({
  data: function() {
    return {
      currentValue: false,
      pendingValue: null,
    }
  },
  methods: {
    onButtonToggled(value) {
      console.log(value)
      this.pendingValue = !value
      setTimeout(() => {
        this.currentValue = !value
        this.pendingValue = null
      }, 2000)
    }
  },
  template: `
    <lock-toggle-button
     :value="currentValue"
     @buttonToggled="onButtonToggled"
     :pending-value="pendingValue"/>
  `
})
```

Disabled
```jsx
  <lock-toggle-button
    :value="true"
    :disabled="true"
  />
```

Custom gradient colors
```js
new Vue({
  data: function() {
    return {
      value: false
    }
  },
  methods: {
    onButtonToggled(value) {
      console.log(value)
      this.value = !value
    }
  },
  template: `
    <lock-toggle-button
     :value="value"
     activePrimaryColor="pink"
     activeSecondaryColor="green"
     inactivePrimaryColor="blue"
     inactiveSecondaryColor="black"
     @buttonToggled="onButtonToggled"
    />
  `
})
```
</docs>
