import { ControlDefinitionViewModel, ControlDefinitionViewModelV2 } from '@/store/modules/functionDefinitionEditorUi/models'
import { ControlTypeV2 } from '@ecocoach/domain-store-modules/src/common'
import { ControlKinds } from '@ecocoach/domain-store-modules/src/plcOperation/models'

export const stateForControlDefinitionV2 = (controlDefinition: ControlDefinitionViewModelV2, defaultValues: { [command: string]: any })
  : { [command: string]: any } => {
  switch (controlDefinition.type) {
  case ControlTypeV2.Toggle: return {
    value: defaultValues.toggleCommand ?? false,
  }
  case ControlTypeV2.Toggles: return {
    firstValue: defaultValues.firstToggleCommand ?? false,
    secondValue: defaultValues.secondToggleCommand ?? false,
  }
  case ControlTypeV2.ConsumptionProcessToggle: return {
    value: defaultValues.consumptionProcessToggleCommand ?? false,
  }
  case ControlTypeV2.NumericInput: return {
    value: defaultValues.setValueCommand,
  }
  case ControlTypeV2.TextInput: return {
    value: defaultValues.setTextCommand,
  }
  case ControlTypeV2.EnumInput: return {
    value: defaultValues.setEnumCommand,
  }
  case ControlTypeV2.StateOfChargeConfiguration: return {
    // tslint:disable-next-line:no-magic-numbers max-line-length
    selfConsumptionCapacity: 100 - (defaultValues.peakShavingCapacityCommand + defaultValues.loadManagementCapacityCommand + defaultValues.offGridCapacityCommand),
    peakShavingCapacity: defaultValues.peakShavingCapacityCommand,
    loadManagementCapacity: defaultValues.loadManagementCapacityCommand,
    offGridCapacity: defaultValues.offGridCapacityCommand,
  }
  default: return {}
  }
}

export const stateForMappedControlDefinitionV2 = (controlDefinition: ControlDefinitionViewModel, defaultValues: { [command: string]: any })
: { [command: string]: any } => {
  switch (controlDefinition.kind) {
  case ControlKinds.SWITCH_CONTROL:
    return {
      state: defaultValues?.defaultValue?.toState === 'on' ?? false,
    }
  case ControlKinds.PARAMETER_CONTROL:
    return {
      state: defaultValues?.defaultValue?.value,
    }
  case ControlKinds.COLOR_PICKER_CONTROL:
    return {
      state: defaultValues?.defaultValue?.hue,
    }
  case ControlKinds.TEXT_CONTROL:
    return {
      state: defaultValues?.defaultValue?.value,
    }
  default: return {}
  }
}
