import { actions } from '@/store/modules/alarmUi/actions'
import { getters } from '@/store/modules/alarmUi/getters'
import { mutations } from '@/store/modules/alarmUi/mutations'
import { state } from '@/store/modules/alarmUi/state'
import { AlarmUiAction, AlarmUiGetter, AlarmUiMutation, AlarmUiState } from '@/store/modules/alarmUi/types'
import { RootState } from '@/store/types'
import { Module } from 'vuex'

const namespaced: boolean = true
export const alarmUi: Module<AlarmUiState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action: AlarmUiAction, 
  mutation: AlarmUiMutation,
  getter: AlarmUiGetter,
}

export const domain = 'alarmUi'

export const vuexModule = {
  [domain]: alarmUi,
}