import { AlarmLogUiState } from '@/store/modules/alarmLogUi/types'

export const state: AlarmLogUiState = {
  interacted: false,
  selectedPlcId: '',
  filterSettings: {
    states: [],
    deviceName: '',
    name: '',
    message: '',
    openedDate: '',
    closedDate: '',
  },
}
