import camelCase from 'camelcase'
import moment from 'moment'
import transformObjKeys from 'transform-obj-keys'
import upperCamelCase from 'uppercamelcase'

// returns a new object with keys recursively converted to UpperCamelCase
export const toUpperCaseKeys = o => transformObjKeys(o, upperCamelCase, { deep: true })

// returns a new object with keys recursively converted to lowerCamelCase
export const toLowerCaseKeys = o => transformObjKeys(o, camelCase, { deep: true })

// updates item in the array by id or inserts it if the id is not found
export const upsert = <T extends { id: string }>(array: T[], itemToAddOrUpdate: T) => {
  const index = array.findIndex(element => element.id === itemToAddOrUpdate.id)
  if (index >= 0) {
    array.splice(index, 1, itemToAddOrUpdate)
  } else {
    array.push(itemToAddOrUpdate)
  }
}

// updates items in the array by id or inserts them if the ids are not found
export const upsertMany = <T extends { id: string }>(array: T[], itemsToAddOrUpdate: T[]): T[] => {
  const arrayCopy = deepCopy(array)
  itemsToAddOrUpdate.forEach(item => upsert(arrayCopy, item))
  return arrayCopy
}

// updates items in the array by id selector or inserts them if the ids are not found
export const upsertManyWithId = <T>(array: T[], itemsToAddOrUpdate: T[], idSelector: (element: T) => string): T[] => {
  const arrayCopy = deepCopy(array)
  itemsToAddOrUpdate.forEach(item => upsertWithId(arrayCopy, item, idSelector))
  return arrayCopy
}

// updates item in the array by id selector or inserts it if the id is not found
export const upsertWithId = <T>(array: T[], itemToAddOrUpdate: T, idSelector: (element: T) => string) => {
  const index = array.findIndex(element => idSelector(element) === idSelector(itemToAddOrUpdate))
  if (index >= 0) {
    array.splice(index, 1, itemToAddOrUpdate)
  } else {
    array.push(itemToAddOrUpdate)
  }
}

// updates item in the array by selector or inserts it if nothing matches the selector
export const upsertWithSelector = <T>(array: T[], itemToAddOrUpdate: T, selector: (element: T) => boolean) => {
  const index = array.findIndex(element => selector(element))
  if (index >= 0) {
    array.splice(index, 1, itemToAddOrUpdate)
  } else {
    array.push(itemToAddOrUpdate)
  }
}

// removes an item in the array by id if it is found
export const remove = <T extends { id: string }>(array: T[], idToRemove: string) => {
  const index = array.findIndex(element => element.id === idToRemove)
  if (index >= 0) {
    array.splice(index, 1)
  }
}

// removes items from the array by id if it they are found
export const removeMany = <T extends { id: string }>(array: T[], idsToRemove: string[]) => {
  return array.filter(element => !idsToRemove.includes(element.id))
}

// removes an item in the array by id selector if it is found
export const removeWithId = <T>(array: T[], idToRemove: string, idSelector: (element: T) => string) => {
  const index = array.findIndex(element => idSelector(element) === idToRemove)
  if (index >= 0) {
    array.splice(index, 1)
  }
}

// removes items from the array by id if it they are found
export const removeManyWithId = <T>(array: T[], idsToRemove: string[], idSelector: (element: T) => string) => {
  return array.filter(element => !idsToRemove.includes(idSelector(element)))
}

// removes all items in the array for which the selector is true
export const removeWithSelector = <T>(array: T[], selectorToRemove: (element: T) => boolean) => {
  return array.filter(element => !selectorToRemove(element))
}

// sorts an array be the sortOrder property of the items
export const sortedBySortOrder = <T extends { sortOrder: number }>(array: T[]): T[] => {
  return [...array].sort((a, b) => a.sortOrder - b.sortOrder)
}

// sorts an array be the name property of the items
export const sortedByName = <T extends { name: string }>(array: T[]): T[] => {
  return sortedBy(array, element => element.name)
}

// sorts an array be the given string property of the items
export const sortedBy = <T>(array: T[], selector: (element: T) => string, descending = false): T[] => {
  return descending
    ? [...array].sort((a, b) => selector(b).localeCompare(selector(a)))
    : [...array].sort((a, b) => selector(a).localeCompare(selector(b)))
}

// renames a key of an object
export const renameObjectKey = (o: object, oldKey: string, newKey: string) => {
  delete Object.assign(o, { [newKey]: o[oldKey] })[oldKey]
}

// setTimeout returning a promise
export const delay = (ms: number) => new Promise<void>(resolve => setTimeout(resolve, ms))

export const deepCopy = <T>(obj: T): T => {
  if (typeof obj === 'object') {
    return JSON.parse(JSON.stringify(obj))
  }
  return obj
}

export const deepCompare = (first: any, second: any): boolean => {
  if (typeof first === 'object' || typeof second === 'object') {
    return JSON.stringify(first) === JSON.stringify(second)
  } else {
    return first === second
  }
}

// checks if the candidate string includes the substring to match by doing a case insensitive include match
export const matchSubstringCaseInsensitive = (candidate: string, substringToMatch: string): boolean => {
  return candidate?.toLowerCase().includes(substringToMatch?.toLowerCase() ?? '')
}

export const nextAvailableSortOrder = <T extends { sortOrder: number }>(array: T[]): number => {
  return array.length > 0 ? Math.max(...array.map(i => i.sortOrder)) + 1 : 0
}

export const toMap = <T>(array: T[], keySelector: (item: T) => string): Map<string, T> => {
  return new Map(array.map((i): [string, T] => [keySelector(i), i]))
}

export const addToMap = <T>(map: Map<string, T>, key: string, value: T): Map<string, T> => {
  return new Map(map).set(key, value)
}

export const removeFromMap = <T>(map: Map<string, T>, key: string): Map<string, T> => {
  const newMap = new Map(map)
  map.delete(key)
  return newMap
}

export const groupedBy = <T>(array: T[], keySelector: (item: T) => string): Map<string, T[]> => {
  const map = new Map<string, T[]>()
  array.forEach(item => {
    const key = keySelector(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

export const intersectArrays = (first: string[], second: string[]) => {
  const secondSet = new Set(second)
  return Array.from(new Set(first)).filter(x => secondSet.has(x))
}

export function filterDate(dateTime: string, filter: string) {
  if (!dateTime) {
    return false
  }
  const operator = filter[0]
  const dateTimeReference = filter.substring(1)
  if (!operator || !dateTimeReference) {
    return true
  }

  switch (operator) {
  case '>':
    return moment.utc(dateTime).isAfter(dateTimeReference)
  case '<':
    return moment.utc(dateTime).isBefore(dateTimeReference)
  case '=':
    return moment.utc(dateTime).startOf('day').isSame(moment.utc(dateTimeReference))
  default:
    return true
  }
}

export function replaceStringTemplate(template:string, data: object) {
  return template.replace(/(?:{(.+?)})/g, (match, property) => data[property] !== null ? data[property] ?? match : 'n/a')
}

export const roundToDecimals = (value: number, decimals: number) => {
  return (value !== null && !Number.isInteger(value)) ? value.toFixed(decimals) : value
}