<template>
  <v-flex class="base-single-select" :class="{ 'pending': pending }">
    <v-select
      :key="optionValue+'_'+optionLabel"
      :items="options"
      :item-value="optionValue"
      :item-text="optionLabel"
      :value="selectedValue"
      :placeholder="placeholdertext"
      :disabled="disabled"
      @change="emitSelected($event)"
      solo
      :menu-props="{ contentClass: 'base-single-select-menu' }"
    />
  </v-flex>
</template>


<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'BaseSingleSelect',
  props: {
    /**
     * array of strings or objects to be used by the drop down menu
     */
    options: {
      type: Array,
      default: [],
    },
    /**
     * the selected value
     */
    selectedValue: {
      type: [Number, String],
      default: '',
    },
    /**
     * optional, needed only in case options is an array of objects.
     * the property name of the value string inside the options object
     */
    optionValue: {
      type: String,
      default: '',
    },
    /**
     * optional, needed only in case options is an array of objects.
     * property name of the displayed string inside the options object
     */
    optionLabel: {
      type: String,
      default: '',
    },
    /**
     * disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * pending
     */
    pending: {
      type: Boolean,
      default: false,
    },
    /**
     * optional placeholder text, displayed in case nothing is selected
     */
    placeholdertext: {
      type: String,
      default: '',
    },
  },
  methods: {
    emitSelected(value: string): void {
      /**
       * Selected event.
       *
       * @event selected
       * @type {string}
       */
      this.$emit('selected', value)
    },
  },
})
</script>
<style lang="scss">
.base-single-select {
  margin: 0px;
  padding: 10px;
  margin-bottom: -9px;
  padding-right: 0px;
}
.base-single-select > .v-text-field.v-text-field--solo .v-input__control {
  min-height: 30px;
}
.base-single-select > .v-text-field--solo>.v-input__control>.v-input__slot {
  background-color: transparent !important;
  box-shadow: none !important;
}
.base-single-select > .v-input > .v-input__control > .v-text-field__details {
  display: none;
}
.base-single-select > .v-select .v-select__selections {
  padding: 0px 5px;
  border-width: $default-border-width;
  border-style: solid;
  border-color: $fore-color-primary;
  border-radius: 5px;
  color: $fore-color-primary !important;
  font-family: $font-family-primary;
  &.disabled {
    color: $fore-color-disabled !important;
  }
}
.base-single-select.pending > .v-select .v-select__selections {
  border-color: $fore-color-disabled;
}
.base-single-select .v-select__selection--disabled {
    color: $fore-color-disabled !important;
}
.base-single-select > .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
  color: $fore-color-primary;
  padding: 0px;
}
.base-single-select .v-input {
  font-size: inherit;
}
.base-single-select .v-icon {
  color: $fore-color-primary !important;
}
.base-single-select ::placeholder {
  color: $fore-color-disabled !important;
}
.base-single-select-menu .v-list {
  background: $bg-primary !important;
}
.base-single-select-menu .v-list .v-list__tile--active.primary--text {
  color: $fore-color-primary !important;
  font-weight: bold;
  font-size: inherit;
}
.base-single-select-menu .v-list .v-list__tile {
  color: $fore-color-primary !important;
  font-size: inherit;
}

</style>

<docs>
A simple drop down

Usage with string array options
```js
new Vue({
  data: function() {
    return {
      options: [ "option1", "option2" ],
      selectedValue: 'option1',
    }
  },
  methods: {
    onSelected(value) {
      console.log(value)
      selectedValue = value
    }
  },
  template: `<v-app style="height: 48px;background:transparent">
    <base-single-select
      :options="options"
      :selected-value="selectedValue"
      @selected="onSelected"/>
  </v-app>`
})
```

Usage with object options
```js
new Vue({
  data: function() {
    return {
      options: [
        {id: "id1", label: "label1"},
        {id: "id2", label: "label2"},
        {id: "id3", label: "label3"},
        {id: "id4", label: "label4"},
        {id: "id5", label: "label5"},
        {id: "id6", label: "label6"},
        {id: "id7", label: "label7"},
        {id: "id8", label: "label8"},
        {id: "id9", label: "label9"}
      ],
      selectedValue: 'id1',
    }
  },
  methods: {
    onSelected(value) {
      console.log(value)
      this.selectedValue = value
    }
  },
  template: `<v-app style="height: 48px;background:transparent">
    <base-single-select
      :options="options"
      :option-value="'id'"
      :option-label="'label'"
      :selected-value="selectedValue"
      @selected="onSelected"/>
  </v-app>`
})
```

Diabled
```jsx
<v-app style="height: 48px;background:transparent">
  <base-single-select
    :options="['option1']"
    selected-value="option1"
    disabled
  />
</v-app>
```

Placeholder text
```jsx
<v-app style="height: 48px;background:transparent">
  <base-single-select
    :options="['option1', 'option2']"
    placeholdertext="Select something please!"
  />
</v-app>
```
</docs>
