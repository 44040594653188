import { MutationTree } from 'vuex'
import { 
  FunctionBlockCategoryModel, 
  FunctionBlockModel, 
  FunctionBlockPackageInfoModel, 
  FunctionBlockState,
  GlobalVariableModel,
  SetFunctionBlockObsoleteInput,
  SetFunctionBlockReleaseNotesInput,
  SolutionHardwareDefinitionCategoryModel,
  SolutionHardwareDefinitionModel,
} from './models'
import { SystemConfigurationMutation, SystemConfigurationState } from './types'

export const mutations: MutationTree<SystemConfigurationState> = {
  [SystemConfigurationMutation.setFunctionBlocks](state, payload: FunctionBlockModel[]) {
    state.functionBlocks = payload
  },
  [SystemConfigurationMutation.setFunctionBlockCategories](state, payload: FunctionBlockCategoryModel[]) {
    state.functionBlockCategories = payload
  },
  [SystemConfigurationMutation.setSolutionHardwareDefinitions](state, payload: SolutionHardwareDefinitionModel[]) {
    state.solutionHardwareDefinitions = payload
  },
  [SystemConfigurationMutation.setSolutionHardwareDefinitionCategories](state, payload: SolutionHardwareDefinitionCategoryModel[]) {
    state.solutionHardwareDefinitionCategories = payload
  },
  [SystemConfigurationMutation.setGlobalVariables](state, payload: GlobalVariableModel[]) {
    state.globalVariables = payload
  },
  [SystemConfigurationMutation.setPackageInfo](state, payload: FunctionBlockPackageInfoModel[]) {
    state.packageInfo = payload
  },
  [SystemConfigurationMutation.setFunctionBlockActive](state, payload: string) {
    state.functionBlocks.find(fb => fb.id === payload)!.state = FunctionBlockState.ACTIVE
  },
  [SystemConfigurationMutation.setFunctionBlockObsolete](state, payload: SetFunctionBlockObsoleteInput) {
    const functionBlock = state.functionBlocks.find(fb => fb.id === payload.id)!
    functionBlock.state = FunctionBlockState.OBSOLETE
    if (payload.successorFunctionBlockId) {
      functionBlock.successorFunctionBlockId = payload.successorFunctionBlockId
    }
  },
  [SystemConfigurationMutation.setFunctionBlockReleaseNotes](state, payload: SetFunctionBlockReleaseNotesInput) {
    state.functionBlocks.find(fb => fb.id === payload.id)!.releaseNotes = payload.releaseNotes
  },
}
