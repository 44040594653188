import { RootState } from '@/store/types'
import { ArchitectureType } from '@ecocoach/domain-store-modules/src/common'
import { FunctionBlockModel, FunctionBlockState, TwinCatLibraryDependency } from '@ecocoach/domain-store-modules/src/systemConfiguration/models'
import { SystemConfigurationGetter } from '@ecocoach/domain-store-modules/src/systemConfiguration/types'
import { GetterTree } from 'vuex'
import { ResourceGetter } from '../../../../../eco-domain-store-modules/src/resource/types'
import { DeviceViewModel } from './models'
import { DeviceUiGetter, DeviceUiState } from './types'

export const getters: GetterTree<DeviceUiState, RootState> = {
  [DeviceUiGetter.isInteracted] ({ interacted }): boolean {
    return interacted
  },
  [DeviceUiGetter.devicesOfRoom] (state, __, rootState, rootGetters): DeviceViewModel[] {
    const roomId = rootState.roomUi.selectedRoomId
    return rootState.plcConfiguration.devices
      .filter(d => d.roomId === roomId)
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map(d => {
        const isNameChanged = state.modifiedDeviceNames.has(d.id) && state.modifiedDeviceNames.get(d.id) !== d.name
        const isIconChanged = state.modifiedDeviceIcons.has(d.id) && state.modifiedDeviceIcons.get(d.id) !== d.icon
        const functionBlock: FunctionBlockModel = 
          rootGetters[`systemConfiguration/${SystemConfigurationGetter.functionBlockById}`](d.functionBlockDefinitionId)
        return {
          id: d.id,
          name: isNameChanged ? state.modifiedDeviceNames.get(d.id) : d.name,
          icon: isIconChanged ? state.modifiedDeviceIcons.get(d.id) : d.icon,
          functionBlockDefinitionId: functionBlock.id,
          functionBlockName: functionBlock.name || 
            rootGetters[`resource/${ResourceGetter.dictionary}`](functionBlock.nameResourceId),
          functionBlockDescription: functionBlock.description || 
            rootGetters[`resource/${ResourceGetter.dictionary}`](functionBlock.descriptionResourceId),
          version: functionBlock.version,
          license: functionBlock.additionalTwinCatLibraryDependency !== TwinCatLibraryDependency.NONE
            ? functionBlock.additionalTwinCatLibraryDependency  : '',
          obsolete: functionBlock.state === FunctionBlockState.OBSOLETE,
          dirty: isNameChanged || isIconChanged,
        } as DeviceViewModel
      })
  },
  [DeviceUiGetter.isMappedDevice] (_, __, rootState) {
    return (deviceId: string): boolean => {
      const hasDeviceMappings = rootState.plcConfiguration.deviceMappings.some(m => m.deviceId === deviceId)
      const hasAutoMappings = rootState.plcConfiguration.autoMappings.some(m => m.deviceId === deviceId)
      return hasDeviceMappings || hasAutoMappings
    }
  },
  [DeviceUiGetter.architectureType] (_, __, rootState): ArchitectureType {
    return rootState.plcConfiguration.architectureType
  },
}
