<template>
  <div class="control-component-wrapper">
    <v-tooltip bottom v-if="canChangeAppVisibility">
      <icon-button v-if="canChangeAppVisibility" class="app-display-level"
        slot="activator" 
        @buttonClicked="showCustomAppDisplayLevelDialog = true"
        type="materialIcon"
        :materialIconName="appVisibilityIcon"
        :height="20"
        :width="20"
        :enabled="canChangeAppVisibility"
        :style="appVisibilityStyle"
      />
      <span>{{ appVisibilityTooltip | translate }}</span>
    </v-tooltip>
    <control-component-v-2 class="control-component"
      :context="contextV2"
      :control="controlViewModelV2"
      :potential-descendent-controls="controlViewModelsV2"
      :data-eco-name-resource-id="control.nameResourceId"
      :data-eco-description-resource-id="control.descriptionResourceId"
    />
    <custom-app-display-level-dialog v-if="showCustomAppDisplayLevelDialog"
      :show="showCustomAppDisplayLevelDialog"
      :control="control"
      @closed="showCustomAppDisplayLevelDialog = false"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import {
  AppDisplayLevels,
  ControlInput,
  ControlModelV2,
  ExecuteControlCommandInput,
  PendingControlCommand,
  SetControlAppDisplayLevelInput,
  SetDisplayNameInput,
} from '../../../../../../../eco-domain-store-modules/src/plcOperation/models'

import { DropdownOption } from '../../../../../../../shared-components/src'

import { ControlCommand, ControlContextV2, ControlViewModelV2 } from '../../../../../../../shared-components/src/components/deviceControls/v2/models'

import CustomAppDisplayLevelDialog from './CustomAppDisplayLevelDialog.vue'

const App = namespace('app')
const Resource = namespace('resource')
const PlcOperation = namespace('plcOperation')
const ParametrizationUi = namespace('parametrizationUi')

@Component({
  components: {
    // avoid circular references in recursive components
    ControlComponentV2: () => import('@ecocoach/shared-components/src/components/deviceControls/ControlComponentV2.vue'),
    CustomAppDisplayLevelDialog,
  },
})
export default class DeviceControl extends Vue {
  @Prop() public control: ControlModelV2
  @App.Getter public selectedLanguage: string
  @Resource.Getter public dictionary: (id: string) => string
  @PlcOperation.Getter('controls') public allControls: ControlModelV2[]
  @PlcOperation.Getter public pendingControlCommand: (controlId: string) => PendingControlCommand | undefined
  @PlcOperation.Action public createArrayEntry: (payload: { input: ControlInput<{}>, language: string }) => Promise<void>
  @PlcOperation.Action public deleteArrayEntry: (input: ControlInput<{}>) => Promise<void>
  @PlcOperation.Action public setDisplayName: (input: SetDisplayNameInput) => Promise<void>
  @PlcOperation.Action public setControlAppDisplayLevel: (input: SetControlAppDisplayLevelInput) => Promise<void>
  @PlcOperation.Action public executeControlCommand: (input: ExecuteControlCommandInput) => Promise<void>
  @ParametrizationUi.Getter public appDisplayLevelOptions: DropdownOption[]

  public showCustomAppDisplayLevelDialog = false

  public get contextV2(): ControlContextV2 {
    return {
      executeCommand: (controlId: string, command: ControlCommand) => {
        this.executeControlCommand({
          controlId,
          payload: command,
        })
      },
      resolveStringResource: (resourceKey: string): string => {
        return this.dictionary(resourceKey)
      },
      createArrayEntry: async (controlId: string) => {
        const parentControl = this.allControls.find(c => c.id === controlId)! as ControlModelV2
        const getChildIndices = () => this.allControls
          .filter(c => c.parentControlId === controlId && c.id !== parentControl.attributes?.activateControlId)
          .map(c => c.index)
        const indicesBefore = getChildIndices()
        await this.createArrayEntry({
          input: {
            controlId,
            payload: {},
          },
          language: this.selectedLanguage,
        })
        const indicesAfter = getChildIndices()
        const newIndex = indicesAfter.filter(i => !indicesBefore.includes(i))[0]
        const newControl = this.allControls.filter(c => c.parentControlId === controlId && c.index === newIndex)[0] as ControlModelV2
        return {
          id: newControl.id,
          type: newControl.type,
          attributes: newControl.attributes,
          state: {},
          pendingState: {},
          parentControlId: newControl.parentControlId,
          displayName: newControl.displayName,
          index: newControl.index,
        } as ControlViewModelV2
      },
      deleteArrayEntry: (controlId: string) => {
        return this.deleteArrayEntry({
          controlId,
          payload: {},
        })
      },
      setDisplayName: (controlId: string, displayName: string) => {
        return this.setDisplayName({
          controlId,
          payload: {
            displayName,
          },
        })
      },
    } as ControlContextV2
  }

  public get controlViewModelV2() {
    const controlV2 = this.control as ControlModelV2
    return {
      id: controlV2.id,
      type: controlV2.type,
      attributes: controlV2.attributes,
      state: controlV2.state || {},
      pendingState: this.pendingControlCommand(this.control.id)?.pendingValue ?? {},
      parentControlId: controlV2.parentControlId,
      displayName: controlV2.displayName,
      index: controlV2.index,
    } as ControlViewModelV2
  }
  
  public get controlViewModelsV2() {
    return this.allControls
      .filter(c => c.deviceId === this.control.deviceId)
      .map(c => {
        const controlV2 = c as ControlModelV2
        return {
          id: controlV2.id,
          type: controlV2.type,
          attributes: controlV2.attributes,
          state: controlV2.state || {},
          pendingState: this.pendingControlCommand(this.control.id)?.pendingValue ?? {},
          parentControlId: controlV2.parentControlId,
          displayName: controlV2.displayName,
          index: controlV2.index,
        } as ControlViewModelV2
      })
  }

  public get appVisibilityIcon() {
    return this.control.attributes.appDisplayLevel === AppDisplayLevels.NOT_DISPLAYED
      ? 'visibility_off'
      : 'visibility'
  }

  public get appVisibilityStyle() {
    switch (this.control.attributes.appDisplayLevel) {
    case AppDisplayLevels.NOT_DISPLAYED:
    case AppDisplayLevels.DISPLAYED_ON_THIRD_LEVEL:
      return { opacity: 0.5 }
    default: 
      return { opacity: 1 }
    }
  }

  public get appVisibilityTooltip() {
    const currentLevelLabel = this.appDisplayLevelOptions.find(_ => _.id === this.control.attributes.appDisplayLevel)?.label
    return `ecoMobileApp: ${currentLevelLabel}`
  }

  public get canChangeAppVisibility() {
    return !this.control.isMappedClassicControl
  }

  public onChangeAppDisplayLevel() {
    const appDisplayLevel = this.control.attributes.appDisplayLevel === AppDisplayLevels.NOT_DISPLAYED
      ? AppDisplayLevels.DISPLAYED_ON_FIRST_LEVEL
      : AppDisplayLevels.NOT_DISPLAYED

    this.setControlAppDisplayLevel({
      controlId: this.control.id,
      payload: {
        appDisplayLevel,
      },
    } as SetControlAppDisplayLevelInput)
  }
}
</script>
<style lang="scss" scoped>
.control-component-wrapper {
  display: flex;
  align-items: center;
  padding: 7px 0px;
  .app-display-level {
    margin: -7px;
    margin-right: 0px;
  }
}
</style>